import React, { FormEvent, useState } from 'react';

import { LinearProgress } from '@mui/material';
import { RouteComponentProps } from 'react-router-dom';

import { appClient } from 'App';
import { Body_login_for_access_token_token_post } from 'assets';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';
import useUser from 'functions/Hooks/useUser';

import LoginDesktop from './Desktop/LoginDesktop';
import LoginMobile from './Mobile/LoginMobile';

export interface LoginProps extends RouteComponentProps {}

const Login: React.FunctionComponent<LoginProps> = props => {
  const currentWidth = useWidth();
  const [error, setError] = useState<boolean>(false);
  const [blocked, setBlocked] = useState<boolean>(false);

  const label = useLabel();

  const [status, setStatus] = useState<string>('first_login');
  const { signIn } = useUser();

  const handleSubmit = async (eventL: FormEvent<HTMLFormElement>) => {
    setBlocked(false);
    setError(false);
    eventL.preventDefault();

    if (status === 'first_login' && signIn) {
      const formdata = new FormData(eventL.currentTarget);
      const loginCredatials: Body_login_for_access_token_token_post = {
        username: formdata.get('email') as string,
        password: formdata.get('password') as string,
      };

      // appClient.default.postLoginForAccessToken(loginCredatials);
      const response = await signIn(loginCredatials).catch((r: { status: number }) => {
        if (r.status === 403) {
          setBlocked(true);
        } else {
          setError(true);
        }

        return r;
      });

      if ('access_token' in response) {
        switch (response.mfa) {
          case 'setup':
            setStatus('TwoFPSetUp');
            break;
          case 'required':
            setStatus('TwoFPRequired');
            break;
          default:
            window.location.reload();
            break;
        }
      }
    } else if (status === 'TwoFPRequired') {
      const formdata = new FormData(eventL.currentTarget);
      const response = await appClient.auth
        .postAuthLoginWithMfa({ mfa: formdata.get('mfa_code') as string })
        .catch(r => {
          if (r.status === 403) {
            setBlocked(true);
          } else {
            setError(true);
          }

          return r;
        });
      if (response.mfa === 'none') {
        const { access_token } = response;
        const jwt = `Bearer ${access_token}`;
        localStorage.setItem('jwt', jwt);
        props.history.push('/');
        window.location.reload();
      }
    }
  };

  return (
    <>
      {label ? (
        currentWidth === 'xs' ? (
          <LoginMobile
            handleSubmit={handleSubmit}
            error={error}
            blocked={blocked}
            status={status}
            setStatus={setStatus}
          />
        ) : (
          <LoginDesktop
            handleSubmit={handleSubmit}
            error={error}
            blocked={blocked}
            status={status}
            setStatus={setStatus}
          />
        )
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default Login;
