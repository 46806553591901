import React, { useContext, useEffect, useState } from 'react';

import { appClient } from 'App';
import { BaseLabel } from 'assets';

import useUser from './useUser';

const tempLabel: ILabel = {
  id: 0,
  gtm_id: '',
  name: 'Studyworks',
  main_color: '',
  selected: true,
  light_color: '',
  dark_color: '',
  contrast_color: '',
  domain: '',
  is_active: false,
  logo: '',
};

export interface ILabel extends BaseLabel {
  selected?: boolean;
}
const LabelContext = React.createContext<ILabel | undefined>(tempLabel);

const LabelSource = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const [labels, setLabels] = useState<BaseLabel[]>([]);
  const [currentLabel, setCurrentLabel] = useState<ILabel>();
  const { user } = useUser();

  useEffect(() => {
    appClient.labels.getLabelsViewAllLabels().then(result => {
      if (result !== null) {
        setLabels(result);
      }
    });
  }, []);

  useEffect(() => {
    if (user?.guardian?.id) {
      appClient.guardians.getGuardiansViewGuardianSimple(user.guardian.id).then(r => {
        setCurrentLabel(r.label);
      });
    }
  }, [user]);

  useEffect(() => {
    if (labels) {
      const label =
        labels.find(search => {
          return window.location.href.includes(
            search.name.toLowerCase().replace('.com', '').replace('.eu', '')
          );
        }) || labels[0];
      setCurrentLabel(label);
    }
  }, [window.location.href, labels]);

  return <LabelContext.Provider value={currentLabel}>{children}</LabelContext.Provider>;
};

const useLabel = () => useContext(LabelContext);

export { LabelSource };
export default useLabel;
