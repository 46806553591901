import React, { useState } from 'react';

import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { t, Translation } from 'i18n';

import logoSW from 'assets/img/brand/logo-studyworks-origineel1.png';
import logoOB from 'assets/img/brand/LOGO_OB.png';
import logoBEU from 'assets/img/brand/LogoBijlesEU.png';
import logoVBL from 'assets/img/brand/VlakbijlesLogo.png';
import { Button, TextField } from 'components/Elements/Forms/Inputs';
import useLabel from 'functions/Hooks/useLabel';


interface IResetFormDesktopProps {
  handleSubmit: () => void;
  status: string;
  setStatus: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
}

const ResetFormDesktop: React.FunctionComponent<IResetFormDesktopProps> = ({
  handleSubmit,
  status,
  email,
  setEmail,
}) => {
  const label = useLabel();
  const [logo, setLogo] = useState<string>();
  const [height, setHeight] = useState<string>();

  React.useEffect(() => {
    if (label) {
      switch (label?.name) {
        case 'StudyWorks':
          setLogo(logoSW);
          setHeight('30px');
          break;
        case 'VlakBijles':
          setLogo(logoVBL);
          setHeight('80px');
          break;
        case 'bijleseu.nl':
          setLogo(logoBEU);
          setHeight('80px');
          break;
        case 'Bijles.EU':
          setLogo(logoBEU);
          setHeight('60px');
          break;
        case 'Online-Bijles.com':
          setLogo(logoOB);
          setHeight('80px');
          break;
        default:
          break;
      }
    }
  }, [label]);

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container>
          <Grid item md={'auto'}>
            <img
              src={logo}
              style={{
                height: height,
                objectFit: 'contain',
              }}
            />
          </Grid>
          <Grid item md={12}>
            <CardHeader
              title={
                <Typography
                  variant="h3"
                  color={'#3C3C3B'}
                  sx={{
                    fontSize: window.innerWidth > 2000 ? '1.4vw' : '1.7vw',
                    lineHeight: '1.2',
                  }}>
                  <Translation i18nKey="translation.auth.resetpw.header" values={{ label: label?.name }} />
                  <Typography
                    component="span"
                    variant="h3"
                    color="primary"
                    sx={{
                      fontSize: window.innerWidth > 2000 ? '1.4vw' : '1.7vw',
                      marginLeft: '5px',
                    }}>
                    {t('translation.auth.resetpw.headerportal')}
                  </Typography>
                  .
                </Typography>
              }
              subheader={
                <Typography
                  sx={{
                    marginTop: '20px',
                    color: '#6F6F6F',
                    fontSize: '1vw',
                  }}>
                  {t('translation.auth.resetpw.subheader')}
                </Typography>
              }
              sx={{ paddingLeft: '0px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={
                status === 'error' &&
                !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  email.toLowerCase()
                )
              }
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mailadres"
              name="email"
              helperText={
                status === 'error' &&
                  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    email.toLowerCase()
                  )
                  ? 'Dit is niet een geldig emailadres'
                  : ''
              }
              autoComplete="email"
              onChange={event => setEmail(event.target.value)}
              autoFocus
            />
            {status === 'error' && <Alert severity="error">{t('translation.auth.resetpw.error')}</Alert>}
            {status === 'success' && <Alert severity="success">{t('translation.auth.resetpw.succes')}</Alert>}
            {status === 'loading' && <LinearProgress />}

            <Grid container spacing={2}>
              <Grid item xs>
                <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                  {t('translation.auth.resetpw.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ResetFormDesktop;
