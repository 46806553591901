/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum schemas__disciplines__SchoolLevelEnum {
    VMBO_BASIS = 'vmbo-basis',
    VMBO_KADER = 'vmbo-kader',
    VMBO_TL = 'vmbo-tl',
    MBO = 'mbo',
    HAVO = 'havo',
    HBO = 'hbo',
    VWO = 'vwo',
    GYMNASIUM = 'gymnasium',
    WO = 'wo',
}
