import { checkMail, checkPhone, checkZip } from 'functions/HelperFunctions/Regex';

export const CheckFirstName = (validateError: boolean, value: string): boolean => {
  let test = false;
  if (validateError) {
    if (!value && value === undefined) {
      test = true;
    } else if (value === '') {
      test = true;
    } else {
      test = false;
    }
  } else {
    if (value === '') {
      test = true;
    } else {
      test = false;
    }
  }

  return test;
};

export const CheckLastName = (validateError: boolean, value: string): boolean => {
  let test = false;
  if (validateError) {
    if (!value && value === undefined) {
      test = true;
    } else if (value === '') {
      test = true;
    } else {
      test = false;
    }
  } else {
    if (value === '') {
      test = true;
    } else {
      test = false;
    }
  }

  return test;
};

export const CheckGender = (validateError: boolean, value: string): boolean => {
  let test = false;
  if (validateError) {
    if (!value && value === undefined) {
      test = true;
    } else if (value === '') {
      test = true;
    } else {
      test = false;
    }
  } else {
    if (value === '') {
      test = true;
    } else {
      test = false;
    }
  }

  return test;
};

export const CheckDateOfBirth = (validateError: boolean, value: string): boolean => {
  let test = false;
  if (validateError) {
    if (!value && value === undefined) {
      test = true;
    } else if (value === '') {
      test = true;
    } else {
      test = false;
    }
  } else {
    if (value === '') {
      test = true;
    } else {
      test = false;
    }
  }

  return test;
};

export const CheckZipCode = (validateError: boolean, value: string): boolean => {
  let test = false;
  if (validateError) {
    if (!value && value === undefined && !checkZip(value)) {
      test = true;
    } else if (value === '') {
      test = true;
    } else {
      test = false;
    }
  } else {
    if (value === '') {
      test = true;
    } else {
      test = false;
    }
  }

  return test;
};

export const CheckHouseNumber = (validateError: boolean, value: string): boolean => {
  let test = false;
  if (validateError) {
    if (!value && value === undefined && !/^[1-9][0-9]*.*$/.test(value)) {
      test = true;
    } else if (value === '') {
      test = true;
    } else {
      test = false;
    }
  } else {
    if (value === '') {
      test = true;
    } else {
      test = false;
    }
  }

  return test;
};

export const CheckStreetName = (validateError: boolean, value: string): boolean => {
  let test = false;
  if (validateError) {
    if (!value && value === undefined) {
      test = true;
    } else if (value === '') {
      test = true;
    } else {
      test = false;
    }
  } else {
    if (value === '') {
      test = true;
    } else {
      test = false;
    }
  }

  return test;
};

export const CheckCity = (validateError: boolean, value: string): boolean => {
  let test = false;
  if (validateError) {
    if (!value && value === undefined) {
      test = true;
    } else if (value === '') {
      test = true;
    } else {
      test = false;
    }
  } else {
    if (value === '') {
      test = true;
    } else {
      test = false;
    }
  }

  return test;
};

export const CheckMail = (validateError: boolean, value: string): boolean => {
  let test = false;
  if (validateError) {
    if (!value && value === undefined && !checkMail(value)) {
      test = true;
    } else if (value === '') {
      test = true;
    } else {
      test = false;
    }
  } else {
    if (value === '') {
      test = true;
    } else {
      test = false;
    }
  }

  return test;
};

export const CheckPhone = (validateError: boolean, value: string): boolean => {
  let test = false;
  if (validateError) {
    if (!value && value === undefined && !checkPhone(value)) {
      test = true;
    } else if (value === '') {
      test = true;
    } else {
      test = false;
    }
  } else {
    if (value === '') {
      test = true;
    } else {
      test = false;
    }
  }

  return test;
};
