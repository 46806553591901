import {
  BaseTextFieldProps,
  ButtonProps,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  SelectProps,
  TextFieldProps,
} from '@mui/material';
import MuiButton from '@mui/material/Button';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import theme from 'theme';

export interface ITextfieldWithLabelProps extends FormControlProps {
  textfieldProps?: BaseTextFieldProps;
  formLabelProps?: FormLabelProps;
  formHelperTextProps?: FormHelperTextProps;
  helperText?: string;
  label?: string;
}

export type ITextFieldProps = {
  backgroundColor?: string;
  border?: string;
  dontShowBorder?: boolean;
} & TextFieldProps;

const TextField = styled(({ ...textFieldProps }: ITextFieldProps) => (
  <MuiTextField {...textFieldProps} />
))(props => ({
  '& > label': { transform: 'translate(14px, 8px) scale(1)' },
  '& > .MuiInputLabel-shrink': { transform: 'translate(14px, -10px) scale(0.8)' },
  '& > .MuiOutlinedInput-root': {
    '& > .MuiOutlinedInput-input': {
      padding: props.select ? `10px 35px 10px 14px` : props.multiline ? '' : `10px 14px`,
    },
    '& > .MuiOutlinedInput-notchedOutline': {
      border: props.dontShowBorder && !props.error ? 'unset' : props.border,
    },
    borderRadius: '20px',
    background: props.backgroundColor || 'white',
  },
}));

const TextfieldWithLabel = styled(
  ({
    label,
    formLabelProps,
    helperText,
    formHelperTextProps,
    textfieldProps,
    ...props
  }: ITextfieldWithLabelProps) => (
    <FormControl {...props}>
      {label && <FormLabel {...formLabelProps}>{label}</FormLabel>}
      <MuiTextField {...textfieldProps} />
      {helperText && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  )
)({
  '& > label': { padding: '2px 20px' },
  '& > p': { marginLeft: '20px' },
  '& > .MuiFormControl-root > .MuiOutlinedInput-root': {
    '& > .MuiOutlinedInput-input': { padding: '10px 14px' },
    borderRadius: '20px',
  },
});

const Button = styled(
  (
    props: ButtonProps & {
      component?: string;
    }
  ) => (
    <MuiButton
      {...props}
      sx={{
        ...(props.variant === 'contained' && {
          color: 'white',
        }),
        ...(props.variant === 'outlined' && {
          color: theme.palette.primary.main,
        }),
        ...(props.size === 'small' && {
          font: `normal normal bold 16px/21px Source Sans Pro`,
          padding: '4px 20px 4px 20px',
        }),
        ...(props.size === 'medium' && {
          font: theme.typography.h4,
          padding: '7px 30px 7px 30px',
        }),
        ...(props.size === 'large' && {
          font: theme.typography.h4,
          padding: '10px 50px 10px 50px',
        }),
      }}
    />
  )
)({
  borderRadius: 30,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
});

const Select = styled(
  (
    props: SelectProps & {
      currentWidth?: string;
    }
  ) => (
    <MuiSelect
      {...props}
      sx={{
        ...(props.currentWidth === 'xs' && {
          fontSize: '14px',
          lineHeight: '16px',
          padding: '0px',
          margin: '0px 0px -4px 0px',
        }),
      }}
    />
  )
)({
  '& > label': { transform: 'translate(14px, 8px) scale(1)' },
  borderRadius: '20px',
  '& > .MuiSelect-select': { padding: '10px 14px' },
});

const MenuItemSelect = styled(
  (
    props: MenuItemProps & {
      currentWidth?: string;
    }
  ) => (
    <MenuItem
      {...props}
      dense={props.currentWidth === 'xs' ? true : false}
      sx={{
        ...(props.currentWidth === 'xs' && {
          fontSize: '14px',
          lineHeight: '16px',
        }),
      }}
    />
  )
)({
  //Necessary to prevent error
});

const SelectFormControl = styled((props: FormControlProps) => <FormControl {...props} />)({
  '& > label': { transform: 'translate(14px, 10px) scale(1) !important' },
  '& > .MuiInputLabel-shrink': { transform: 'translate(14px, -9px) scale(0.75) !important' },
});

export { TextField, TextfieldWithLabel, Button, Select, SelectFormControl, MenuItemSelect };
