/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum NoteTypeEnum {
    SIMPLE = 'simple',
    CALL = 'call',
    MAIL = 'mail',
    ACTIVITY = 'activity',
}
