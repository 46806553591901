/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseBranch } from '../models/BaseBranch';
import type { BaseProvider } from '../models/BaseProvider';
import type { BaseProviderWithBranchLocations } from '../models/BaseProviderWithBranchLocations';
import type { Body_add_provider_picture_sp_providers_provider__provider_id__add_picture_patch } from '../models/Body_add_provider_picture_sp_providers_provider__provider_id__add_picture_patch';
import type { BranchEdit } from '../models/BranchEdit';
import type { BranchIn } from '../models/BranchIn';
import type { GenericStatus } from '../models/GenericStatus';
import type { OrderByProvidersEnum } from '../models/OrderByProvidersEnum';
import type { ProviderEdit } from '../models/ProviderEdit';
import type { ProviderIn } from '../models/ProviderIn';
import type { SimpleBranch } from '../models/SimpleBranch';
import type { SimpleBranchWithCompletionStatus } from '../models/SimpleBranchWithCompletionStatus';
import type { SimpleProvider } from '../models/SimpleProvider';
import type { UserLocationIn } from '../models/UserLocationIn';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SPProvidersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Providers
     * @param nameSearch
     * @param orderBy
     * @param showInactive
     * @param orderAscending
     * @param limit
     * @param offset
     * @returns SimpleProvider Successful Response
     * @throws ApiError
     */
    public getSPProvidersGetProviders(
        nameSearch?: (string | null),
        orderBy?: OrderByProvidersEnum,
        showInactive: boolean = true,
        orderAscending: boolean = true,
        limit: number = 30,
        offset?: number,
    ): CancelablePromise<Array<SimpleProvider>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/providers/providers',
            query: {
                'name_search': nameSearch,
                'order_by': orderBy,
                'show_inactive': showInactive,
                'order_ascending': orderAscending,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Provider
     * @param providerId
     * @returns SimpleProvider Successful Response
     * @throws ApiError
     */
    public getSPProvidersGetProvider(
        providerId: number,
    ): CancelablePromise<SimpleProvider> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/providers/provider/{provider_id}',
            path: {
                'provider_id': providerId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Provider
     * @param providerId
     * @param requestBody
     * @returns SimpleProvider Successful Response
     * @throws ApiError
     */
    public patchSPProvidersEditProvider(
        providerId: number,
        requestBody: ProviderEdit,
    ): CancelablePromise<SimpleProvider> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/providers/provider/{provider_id}',
            path: {
                'provider_id': providerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Provider
     * @param providerId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteSPProvidersDeleteProvider(
        providerId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/providers/provider/{provider_id}',
            path: {
                'provider_id': providerId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Provider Detailed
     * @param providerId
     * @returns BaseProvider Successful Response
     * @throws ApiError
     */
    public getSPProvidersGetProviderDetailed(
        providerId: number,
    ): CancelablePromise<BaseProvider> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/providers/provider/{provider_id}/detailed',
            path: {
                'provider_id': providerId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Provider Detailed With Branch Locations
     * @param providerId
     * @returns BaseProviderWithBranchLocations Successful Response
     * @throws ApiError
     */
    public getSPProvidersGetProviderDetailedWithBranchLocations(
        providerId: number,
    ): CancelablePromise<BaseProviderWithBranchLocations> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/providers/provider/{provider_id}/detailed_with_branch_locations',
            path: {
                'provider_id': providerId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Provider
     * @param requestBody
     * @returns SimpleProvider Successful Response
     * @throws ApiError
     */
    public postSPProvidersCreateProvider(
        requestBody: ProviderIn,
    ): CancelablePromise<SimpleProvider> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/providers/new',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Provider Picture
     * Add a picture to the provider.
     *
     * Parameters:
     * - provider: The provider object to update.
     * - db: The database session.
     * - attachment: The image file to upload.
     *
     * Returns:
     * The updated provider object with the new picture.
     *
     * Raises:
     * - HTTPException(400): If the attachment is not a JPEG or PNG image, or if it is not a square image.
     * @param providerId
     * @param formData
     * @returns SimpleProvider Successful Response
     * @throws ApiError
     */
    public patchSPProvidersAddProviderPicture(
        providerId: number,
        formData: Body_add_provider_picture_sp_providers_provider__provider_id__add_picture_patch,
    ): CancelablePromise<SimpleProvider> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/providers/provider/{provider_id}/add_picture',
            path: {
                'provider_id': providerId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Provider Branches
     * @param providerId
     * @returns SimpleBranch Successful Response
     * @throws ApiError
     */
    public getSPProvidersGetProviderBranches(
        providerId: number,
    ): CancelablePromise<Array<SimpleBranch>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/providers/provider/{provider_id}/branches',
            path: {
                'provider_id': providerId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Branches
     * @param nameSearch
     * @param lat
     * @param lon
     * @param distance
     * @param limit
     * @param offset
     * @param allowIncomplete
     * @returns SimpleBranch Successful Response
     * @throws ApiError
     */
    public getSPProvidersGetBranches(
        nameSearch?: (string | null),
        lat?: (number | null),
        lon?: (number | null),
        distance?: (number | null),
        limit: number = 30,
        offset?: number,
        allowIncomplete: boolean = false,
    ): CancelablePromise<Array<SimpleBranch>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/providers/branches',
            query: {
                'name_search': nameSearch,
                'lat': lat,
                'lon': lon,
                'distance': distance,
                'limit': limit,
                'offset': offset,
                'allow_incomplete': allowIncomplete,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Branch
     * @param branchId
     * @returns SimpleBranch Successful Response
     * @throws ApiError
     */
    public getSPProvidersGetBranch(
        branchId: number,
    ): CancelablePromise<SimpleBranch> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/providers/branch/{branch_id}',
            path: {
                'branch_id': branchId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Branch
     * @param branchId
     * @param requestBody
     * @returns SimpleBranch Successful Response
     * @throws ApiError
     */
    public patchSPProvidersEditBranch(
        branchId: number,
        requestBody: BranchEdit,
    ): CancelablePromise<SimpleBranch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/providers/branch/{branch_id}',
            path: {
                'branch_id': branchId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Branch
     * @param branchId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteSPProvidersDeleteBranch(
        branchId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/providers/branch/{branch_id}',
            path: {
                'branch_id': branchId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Branch Detailed
     * @param branchId
     * @returns BaseBranch Successful Response
     * @throws ApiError
     */
    public getSPProvidersGetBranchDetailed(
        branchId: number,
    ): CancelablePromise<BaseBranch> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/providers/branch/{branch_id}/detailed',
            path: {
                'branch_id': branchId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Branch
     * @param providerId
     * @param requestBody
     * @param delayLocation
     * @returns SimpleBranch Successful Response
     * @throws ApiError
     */
    public postSPProvidersCreateBranch(
        providerId: number,
        requestBody: BranchIn,
        delayLocation: boolean = false,
    ): CancelablePromise<SimpleBranch> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/providers/branch/new/{provider_id}',
            path: {
                'provider_id': providerId,
            },
            query: {
                'delay_location': delayLocation,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Branch Provider
     * @param branchId
     * @param providerId
     * @returns SimpleBranch Successful Response
     * @throws ApiError
     */
    public patchSPProvidersChangeBranchProvider(
        branchId: number,
        providerId: number,
    ): CancelablePromise<SimpleBranch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/providers/branch/{branch_id}/change_provider/{provider_id}',
            path: {
                'branch_id': branchId,
                'provider_id': providerId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Branch Location
     * @param branchId
     * @param requestBody
     * @returns BaseBranch Successful Response
     * @throws ApiError
     */
    public patchSPProvidersChangeBranchLocation(
        branchId: number,
        requestBody: UserLocationIn,
    ): CancelablePromise<BaseBranch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/providers/branch/{branch_id}/change_location',
            path: {
                'branch_id': branchId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Incomplete Branches
     * @returns SimpleBranchWithCompletionStatus Successful Response
     * @throws ApiError
     */
    public getSPProvidersGetAllIncompleteBranches(): CancelablePromise<Array<SimpleBranchWithCompletionStatus>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/providers/branches/incomplete/all',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
            },
        });
    }

    /**
     * Make Branch Complete
     * Make a branch complete, This means that the branch is ready to be used. Incomplete branches stem from importing them from the job-csv.
     *
     * This endpoint makes a new location based on the given UserLocationIn model.
     *
     * Args:
     *
     * - `new_branch_location`: The new location of the branch.
     *
     * - `description`: The new description of the branch.
     *
     * - `branch`: The branch to make complete.
     *
     * Returns:
     *
     * - The updated branch in `SimpleBranch` model
     *
     * Warning:
     *
     * - This is a slower endpoint, because it performs an external API call to get the coordinates of the location based on the zipcode.
     * @param branchId
     * @param description
     * @param requestBody
     * @returns SimpleBranchWithCompletionStatus Successful Response
     * @throws ApiError
     */
    public patchSPProvidersMakeBranchComplete(
        branchId: number,
        description: string,
        requestBody: UserLocationIn,
    ): CancelablePromise<SimpleBranchWithCompletionStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/providers/branch/{branch_id}/make_complete',
            path: {
                'branch_id': branchId,
            },
            query: {
                'description': description,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
