import React, { FormEvent } from 'react';

import { Grid } from '@mui/material';

import CreatePasswordFormDesktop from './Components/CreatePasswordFormDesktop';
import CreatePasswordImageWindow from './Components/CreatePasswordImageWindow';

interface ICreatePasswordDesktopProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  status: string;
  setPassword: (value: string) => void;
  setPasswordConfirm: (value: string) => void;
}
const CreatePasswordDesktop: React.FunctionComponent<ICreatePasswordDesktopProps> = ({
  handleSubmit,
  status,
  setPassword,
  setPasswordConfirm,
}) => {
  return (
    <Grid container>
      <Grid item md={4.5}>
        <Grid container>
          <Grid item md={12} sx={{ padding: '20% 80px' }}>
            <CreatePasswordFormDesktop
              handleSubmit={handleSubmit}
              status={status}
              setPassword={setPassword}
              setPasswordConfirm={setPasswordConfirm}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={7.5}>
        <Grid container>
          <Grid item md={12}>
            <CreatePasswordImageWindow />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreatePasswordDesktop;
