import { FC } from 'react';

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next, Trans, TransProps } from 'react-i18next';

import en_UK from './translations/en_UK/translations.json';
import nl_NL from './translations/nl_NL/translations.json';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
  nl_NL,
  en_UK,
};

// IMPORTANT: For more information on how we use i18next: https://stackoverflowteams.com/c/studyworks/questions/124
// This includes an explanation on how we organise our translations and how to add new translations.
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'nl_NL',
    debug: false,
    keySeparator: '.',
    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: { escapeValue: false },
    react: { useSuspense: false },
    compatibilityJSON: 'v3',
  });

// T is the dictionary, S ist the next string part of the object property path
// If S does not match dict shape, return its next expected properties 
type DeepKeys<T, S extends string> =
  T extends object
  ? S extends `${infer I1}.${infer I2}`
  ? I1 extends keyof T
  // fix issue allowed last dot
  ? T[I1] extends object
  ? `${I1}.${DeepKeys<T[I1], I2>}`
  : keyof T & string
  : keyof T & string
  : S extends keyof T
  ? `${S}`
  : keyof T & string
  : ""

//Extend TransProps and make i18nKey required and type check on TranslationKeys
type TranslationProps = {
  i18nKey: string;
  variables?: string[];
} & Omit<TransProps<any, any, any, any, any>, 'i18nKey'>;

//Replaces Trans component with typecheck on i18nKey
export const Translation: FC<TranslationProps> = ({ i18nKey, ...props }) => {
  return <Trans i18nKey={i18nKey.toString().slice(12)} {...props} />;
};

export const t = <S extends string>(i18nKey: DeepKeys<typeof nl_NL, S>) => {
  return i18n.t(i18nKey.toString().slice(12));
};

export const checkKey = <S extends string>(i18nKey: DeepKeys<typeof nl_NL, S>) => {
  return i18nKey
};

export default i18n;
