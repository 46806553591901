import React, { FormEvent } from 'react';

import { Grid } from '@mui/material';

import ChangePasswordFormDesktop from './Components/ChangePasswordFormDesktop';
import ChangePasswordImageWindow from './Components/ChangePasswordImageWindow';

interface IChangePasswordDesktopProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  state: string;
  password: string;
  passwordComfirm: string;
  setPassword: (value: string) => void;
  setPasswordComfirm: (value: string) => void;
}
const ChangePasswordDesktop: React.FunctionComponent<IChangePasswordDesktopProps> = ({
  handleSubmit,
  state,
  password,
  passwordComfirm,
  setPassword,
  setPasswordComfirm,
}) => {
  return (
    <Grid container>
      <Grid item md={4.5}>
        <Grid container>
          <Grid item md={12} sx={{ padding: '20% 80px' }}>
            <ChangePasswordFormDesktop
              handleSubmit={handleSubmit}
              state={state}
              password={password}
              passwordComfirm={passwordComfirm}
              setPassword={setPassword}
              setPasswordComfirm={setPasswordComfirm}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={7.5}>
        <Grid container>
          <Grid item md={12}>
            <ChangePasswordImageWindow />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangePasswordDesktop;
