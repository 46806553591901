import React, { FormEvent } from 'react';

import { Grid } from '@mui/material';

import LoginFormMobile from './MobileComponent/LoginFormMobile';
import LoginMobileTopHeader from './MobileComponent/LoginMobileTopHeader';

interface ILoginMobileProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  error: boolean;
  blocked: boolean;
  status: string;
  setStatus: (value: string) => void;
}

const LoginMobile: React.FunctionComponent<ILoginMobileProps> = ({
  handleSubmit,
  error,
  blocked,
  status,
  setStatus,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <LoginMobileTopHeader />
      </Grid>

      <Grid item xs={12}>
        <LoginFormMobile
          handleSubmit={handleSubmit}
          error={error}
          blocked={blocked}
          status={status}
          setStatus={setStatus}
        />
      </Grid>
    </Grid>
  );
};

export default LoginMobile;
