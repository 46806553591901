/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedStub } from '../models/AdvancedStub';
import type { CoachQuery } from '../models/CoachQuery';
import type { InactiveStudent } from '../models/InactiveStudent';
import type { RegularCoach } from '../models/RegularCoach';
import type { RegularGuardian } from '../models/RegularGuardian';
import type { RegularStudent } from '../models/RegularStudent';
import type { RegularUser } from '../models/RegularUser';
import type { SearchCoach } from '../models/SearchCoach';
import type { SearchForGoodJobMatchParams } from '../models/SearchForGoodJobMatchParams';
import type { StubType } from '../models/StubType';
import type { SuggestedCoach } from '../models/SuggestedCoach';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SearchService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Search Inactive Students
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSearchSearchInactiveStudents(
        offset?: number,
        limit: number = 50,
    ): CancelablePromise<(Array<InactiveStudent> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/students/inactive',
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Inactive Students Count
     * @returns number Successful Response
     * @throws ApiError
     */
    public getSearchSearchInactiveStudentsCount(): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/students/inactive/count',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Search Coaches
     * @param fieldName
     * @param query
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSearchSearchCoaches(
        fieldName: string,
        query: string,
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<RegularCoach> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/coaches',
            query: {
                'field_name': fieldName,
                'query': query,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Guardians
     * @param fieldName
     * @param query
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSearchSearchGuardians(
        fieldName: string,
        query: string,
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<RegularGuardian> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/guardians',
            query: {
                'field_name': fieldName,
                'query': query,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Guardians By Name
     * @param query
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSearchSearchGuardiansByName(
        query: string,
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<RegularGuardian> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/guardians/by_name',
            query: {
                'query': query,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Guardians By Email
     * @param query
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSearchSearchGuardiansByEmail(
        query: string,
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<RegularGuardian> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/guardians/by_email',
            query: {
                'query': query,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Coaches By Name
     * @param query
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSearchSearchCoachesByName(
        query: string,
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<RegularCoach> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/coaches/by_name',
            query: {
                'query': query,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Coaches By Email
     * @param query
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSearchSearchCoachesByEmail(
        query: string,
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<RegularCoach> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/coaches/by_email',
            query: {
                'query': query,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Student Search By Disciplines
     * @param requestBody
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public postSearchStudentSearchByDisciplines(
        requestBody: Array<number>,
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<RegularStudent> | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/search/students/by_disciplines',
            query: {
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Student Search By Name
     * @param query
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public postSearchStudentSearchByName(
        query: string,
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<RegularStudent> | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/search/students/by_name',
            query: {
                'query': query,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Coach Search By Disciplines
     * @param requestBody
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public postSearchCoachSearchByDisciplines(
        requestBody: Array<number>,
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<RegularCoach> | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/search/coach/by_disciplines',
            query: {
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * User Search By Name
     * @param query
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public postSearchUserSearchByName(
        query: string = '',
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<RegularUser> | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/search/user/by_name',
            query: {
                'query': query,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * User Search By Email
     * @param query
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public postSearchUserSearchByEmail(
        query: string = '',
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<RegularUser> | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/search/user/by_email',
            query: {
                'query': query,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Coach Search By Provinces And Tags
     * @param requestBody
     * @returns SearchCoach Successful Response
     * @throws ApiError
     */
    public postSearchCoachSearchByProvincesAndTags(
        requestBody?: (CoachQuery | null),
    ): CancelablePromise<Array<SearchCoach>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/search/coach/search_by_province_and_tags',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Coach Search By Provinces And Tags Download
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public postSearchCoachSearchByProvincesAndTagsDownload(
        requestBody?: (CoachQuery | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/search/coach/search_by_province_and_tags/download',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Stubs
     * @param query
     * @param type
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSearchSearchStubs(
        query: string,
        type: StubType,
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<AdvancedStub> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/stubs',
            query: {
                'query': query,
                'type': type,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search
     * @param objectType
     * @param query
     * @returns any Successful Response
     * @throws ApiError
     */
    public getSearchSearch(
        objectType: string,
        query: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/search/search/',
            query: {
                'object_type': objectType,
                'query': query,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Suggested Coaches For Job
     * @param jobId
     * @param requestBody
     * @param offset
     * @param limit
     * @returns SuggestedCoach Successful Response
     * @throws ApiError
     */
    public postSearchGetSuggestedCoachesForJob(
        jobId: number,
        requestBody: (SearchForGoodJobMatchParams | null),
        offset?: number,
        limit: number = 10,
    ): CancelablePromise<Array<SuggestedCoach>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/search/sp/{job_id}/suggested_matches',
            path: {
                'job_id': jobId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Suggested Coaches For Params
     * @param requestBody
     * @returns SuggestedCoach Successful Response
     * @throws ApiError
     */
    public postSearchGetSuggestedCoachesForParams(
        requestBody: SearchForGoodJobMatchParams,
    ): CancelablePromise<Array<SuggestedCoach>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/search/sp/search_coaches',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
