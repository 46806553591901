import React, { FormEvent } from 'react';

import { Grid } from '@mui/material';

import LoginFormDesktop from './Components/LoginFormDesktop';
import LoginImageWIndow from './Components/LoginImageWIndow';

interface ILoginDesktopProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  error: boolean;
  blocked: boolean;
  status: string;
  setStatus: (value: string) => void;
}
const LoginDesktop: React.FunctionComponent<ILoginDesktopProps> = ({
  handleSubmit,
  error,
  blocked,
  status,
  setStatus,
}) => {
  return (
    <Grid container>
      <Grid item md={4.5}>
        <Grid container>
          <Grid item md={12} sx={{ margin: window.innerWidth > 2000 ? '10% 80px' : '20% 80px' }}>
            <LoginFormDesktop
              handleSubmit={handleSubmit}
              error={error}
              blocked={blocked}
              status={status}
              setStatus={setStatus}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={7.5}>
        <Grid container>
          <Grid item md={12}>
            <LoginImageWIndow />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginDesktop;
