import React, { useEffect, useState } from 'react';

import {
  Card, CardContent, CardHeader, CircularProgress, Grid, MenuItem, Select, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18n';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { appClient } from 'App';
import {
  EnumOrderType,
  GuardianEdit,
  OrderIn,
  OrderItemIn,
  RegularGuardianWithChildren,
  RegularProduct,
} from 'assets';
import { Button, TextField } from 'components/Elements/Forms/Inputs';
import { checkIBAN } from 'functions/HelperFunctions/Regex';
import useNavContext from 'functions/Hooks/useNav';
import useProductStoreContex from 'functions/Hooks/useProductsStore';
import useUser from 'functions/Hooks/useUser';

const CollectionPaymentCard = () => {
  const NavProvider = useNavContext();
  NavProvider.setShow(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useUser();
  const [name, setName] = useState<string>();
  const [IBAN, setIBAN] = useState<string>();
  const [nameOf, setNameOf] = useState<string>();
  const [terms, setTerms] = useState<number>(1);
  const productStore = useProductStoreContex();
  const [guardian, setGuardian] = useState<RegularGuardianWithChildren>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingIBAN, setLoadingIBAN] = useState<boolean>(true);

  // Retrieve information from backend
  useEffect(() => {
    const fetchGuardianData = async () => {
      if (user && user.guardian) {
        // Name of user
        setName(`${user.first_name} ${user.last_name}`);
        setNameOf(`${user.first_name} ${user.last_name}`);

        try {
          // Get guardian information from backend
          const guardianData = await appClient.guardians.getGuardiansViewGuardianSimple(user.guardian.id);
          setGuardian(guardianData);
          setName(`${guardianData.user.first_name} ${guardianData.user.last_name}`);
          setNameOf(`${guardianData.user.first_name} ${guardianData.user.last_name}`);
          setLoading(false);

          // Get IBAN from backend
          const ibanData = await appClient.guardians.getGuardiansViewPrivateGuardian(user.guardian.id);
          if (ibanData?.iban) {
            setIBAN(ibanData.iban);
          }
          setLoadingIBAN(false);
        } catch (error) {
          setLoading(false);
          setLoadingIBAN(false);
        }
      }
    };

    fetchGuardianData();
  }, [user]);

  const handlePaymentEvent = () => {
    const OrderItem: OrderItemIn[] = [];
    productStore.products.forEach((product: RegularProduct) => {
      OrderItem.push({ product_id: product.id });
    });

    const orderData: OrderIn = {
      items: OrderItem,
      order_type: EnumOrderType.INVOICE,
      collection_terms: terms,
    };

    if (
      name === '' ||
      name === undefined ||
      IBAN === '' ||
      IBAN === undefined ||
      nameOf === '' ||
      nameOf === undefined ||
      terms === 0 ||
      terms === undefined
    ) {
      enqueueSnackbar(t('translation.portalShared.payment.emptyFields'), { variant: 'error' });
    } else if (!checkIBAN(IBAN)) {
      enqueueSnackbar(t('translation.portalShared.payment.invalidIBAN'), { variant: 'error' });
    } else if (guardian) {
      const guardianData: GuardianEdit = {
        iban: IBAN,
      };

      appClient.guardians.postGuardiansEditGuardian(guardian.id, guardianData);

      appClient.orders
        .postOrdersCreateOrder(guardian.user.id, orderData)
        .then(() => {
          enqueueSnackbar(t('translation.portalShared.payment.paymentSuccesful'), {
            variant: 'success',
          });
          NavProvider.setShow(true);
          history.push('/SuccesPayment');
        })
        .catch(() => {
          enqueueSnackbar(t('translation.portalShared.payment.paymentUnsuccessful'), {
            variant: 'error',
          });
        });
    }
  };

  /* Build the select items based on the terms in the product */
  const buildTerms = (selectedProducts: RegularProduct[]) => {
    let terms: number = 0;
    selectedProducts.forEach((prod: RegularProduct) => {
      terms = terms + prod.max_terms;
    });

    const rows = [];

    for (let i = 0; i < terms / selectedProducts.length; i++) {
      rows.push(
        <MenuItem key={i + 1} value={i + 1}>
          {i + 1}
        </MenuItem>
      );
    }

    return rows;
  };

  return (
    <Box sx={{ bgcolor: '#F2F2F2' }}>
      <Grid container sx={{ padding: '10px' }} spacing={2}>
        <Grid item xs={12}>
          <Card elevation={0} sx={{ borderRadius: '15px' }}>
            <CardHeader
              title={t('translation.common.words.collectionInfo')}
              titleTypographyProps={{
                color: '#6F6F6F',
              }}
            />
            <CardContent
              sx={{
                borderTop: '2px solid #F2F2F2',
              }}
            >
              {
                (loading && loadingIBAN) ?
                  (
                    <Grid container xs={12} justifyContent="center">
                      <CircularProgress size={'36px'} />

                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: '#2B2E34',
                            fontSize: '18px',
                          }}>
                          {t('translation.portalShared.invoucePayment.check')}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: '#2B2E34',
                            fontSize: '18px',
                            fontWeight: '600',
                          }}>
                          {t('translation.common.words.name')}
                        </Typography>
                        <TextField fullWidth value={name} onChange={e => setName(e.target.value)} />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: '#2B2E34',
                            fontSize: '18px',
                            fontWeight: '600',
                          }}>
                          {t('translation.common.words.iban')}
                        </Typography>
                        <TextField fullWidth value={IBAN} onChange={e => setIBAN(e.target.value)} />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: '#2B2E34',
                            fontSize: '18px',
                            fontWeight: '600',
                          }}>
                          {t('translation.common.words.inNameOf')}
                        </Typography>
                        <TextField fullWidth value={nameOf} onChange={e => setNameOf(e.target.value)} />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: '#2B2E34',
                            fontSize: '18px',
                            fontWeight: '600',
                          }}>
                          {t('translation.common.words.term')}
                        </Typography>
                        <Select
                          fullWidth
                          id="terms"
                          labelId="terms"
                          value={terms}
                          onChange={e => setTerms(e.target.value as number)}
                          sx={{ borderRadius: '45px' }}>
                          {buildTerms(productStore.products)}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: '#2B2E34',
                            fontSize: '18px',
                          }}>
                          {t('translation.portalShared.payment.CollectionPayment.collectionCost')}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant={'contained'}
                          fullWidth
                          onClick={() => handlePaymentEvent()}
                          sx={{ padding: '10px', fontSize: '18px' }}>
                          {t('translation.common.words.agreesign')}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
            </CardContent>
          </Card >
        </Grid >
      </Grid >
    </Box >
  );
};

export default CollectionPaymentCard;
