/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseTask } from '../models/BaseTask';
import type { Body_import_legacy_bundles_orders_finance_import_legacy_bundles_post } from '../models/Body_import_legacy_bundles_orders_finance_import_legacy_bundles_post';
import type { Body_mollie_webhook_orders_webhook_mollie_post } from '../models/Body_mollie_webhook_orders_webhook_mollie_post';
import type { CustomOrderIn } from '../models/CustomOrderIn';
import type { FinanceOrder } from '../models/FinanceOrder';
import type { OrderIn } from '../models/OrderIn';
import type { OrderWithPaymentInformation } from '../models/OrderWithPaymentInformation';
import type { RegularGuardianWithChildren } from '../models/RegularGuardianWithChildren';
import type { RegularOrder } from '../models/RegularOrder';
import type { RegularOrderItem } from '../models/RegularOrderItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrdersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Order
     * Get an order by id
     *
     * <br>
     * Args:
     *
     * - `order_id` (int): the id of the order to get
     *
     * <br>
     * Returns the __order__ with the corresponding id
     *
     * <br>
     * HTTPExceptions:
     *
     * - 404: Order not found
     * @param orderId
     * @returns RegularOrder Successful Response
     * @throws ApiError
     */
    public getOrdersGetOrder(
        orderId: number,
    ): CancelablePromise<RegularOrder> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orders/order/{order_id}',
            path: {
                'order_id': orderId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Items
     * Get the items of an order by id
     *
     * <br>
     * Args:
     *
     * - `order_id` (int): the id of the order to get the items for
     *
     * <br>
     * Returns the __items__ of the order with the corresponding id
     *
     * <br>
     * HTTPExceptions:
     *
     * - 404: Order not found
     * @param orderId
     * @returns RegularOrderItem Successful Response
     * @throws ApiError
     */
    public getOrdersGetOrderItems(
        orderId: number,
    ): CancelablePromise<Array<RegularOrderItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orders/order/{order_id}/items',
            path: {
                'order_id': orderId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Orders For User
     * Get all orders for a user with pagination
     *
     * <br>
     * Args:
     *
     * - `user_id` (int): the id of the user to get the orders for
     *
     * - `offset` (int): the offset of the orders, default = 0
     *
     * - `limit` (int): the number of orders per page, default = 100
     *
     * <br>
     * Returns the __orders__ on the corresponding page for the user
     *
     * <br>
     * ! This endpoint is deprecated and should not be used !
     * @param userId
     * @param offset
     * @param limit
     * @returns OrderWithPaymentInformation Successful Response
     * @throws ApiError
     */
    public getOrdersGetOrdersForUser(
        userId: number,
        offset?: number,
        limit: number = 100,
    ): CancelablePromise<Array<OrderWithPaymentInformation>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orders/user/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Orders Count For User
     * Get the total count of orders for a user
     *
     * <br>
     * Args:
     *
     * - `user_id` (int): the id of the user to get the orders for
     *
     * <br>
     * Returns the total count of __orders__ for the user
     * <br>
     * ! This endpoint is deprecated and should not be used !
     * @param userId
     * @returns number Successful Response
     * @throws ApiError
     */
    public getOrdersGetOrdersCountForUser(
        userId: number,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orders/user/{user_id}/count',
            path: {
                'user_id': userId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Order
     * Create an order
     *
     * <br>
     * Args:
     *
     * - `new_order` (`OrderIn`) as request body:
     *
     * - `items` (`List[OrderItemIn]`): the items of the order:
     *
     * - `product_id` (`int`): the id of the product
     *
     * - `order_type` (`EnumOrderType`): the type of the order
     *
     * See schemas for the possible values
     *
     * - `customer_user` (`Use`r`): the user to create the order for
     *
     * <br>
     * This creates an order for the customer_user
     *
     * The seller is the current_user, which is also saved in the order
     *
     * <br>
     * Limitations in this release:
     *
     * - Coaches are not allowed to sell products
     *
     * - Only guardians can buy
     *
     * - Only one product per order
     *
     * - No IDeal payments
     *
     * <br>
     * Returns the __created order__
     * @param customerUserId
     * @param requestBody
     * @returns OrderWithPaymentInformation Successful Response
     * @throws ApiError
     */
    public postOrdersCreateOrder(
        customerUserId: number,
        requestBody: OrderIn,
    ): CancelablePromise<OrderWithPaymentInformation> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orders/order',
            query: {
                'customer_user_id': customerUserId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel Order
     * Cancel an order
     *
     * <br>
     * Args:
     *
     * - `order_id` (int): the id of the order to cancel
     *
     * <br>
     * Returns the cancelled __order__
     * @param orderId
     * @returns RegularOrder Successful Response
     * @throws ApiError
     */
    public patchOrdersCancelOrder(
        orderId: number,
    ): CancelablePromise<RegularOrder> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/orders/order/{order_id}/cancel',
            path: {
                'order_id': orderId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Mollie Webhook
     * Mollie webhook for payment status updates
     *
     * <br>
     * Args:
     *
     * - `id` (str): the id of the payment reference
     *
     * <br>
     * Returns a status code 200 with {status: "ok"}
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public postOrdersMollieWebhook(
        formData: Body_mollie_webhook_orders_webhook_mollie_post,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orders/webhook/mollie',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Finance Orders
     * Get finance orders with pagination, used for the finance dashboard
     *
     * <br>
     * Args:
     *
     * - `limit` (`Optional[int]`): the number of orders per page, default = `100`
     *
     * - `offset` (`Optional[int]`): the offset of the orders, default = `0`
     *
     * - `is_cancelled` (`Optional[bool]`): whether the orders are cancelled or not, default = `False`
     *
     * - `processed_by_admin` (`Optional[bool]`): whether the orders are processed by admin or not, default = `False`
     *
     * - `lessons_only` (`Optional[bool]`): whether the orders are lessons only or not, default = `False`
     *
     * <br>
     * Returns the __orders__ on the corresponding page with `is_cancelled` and `processed_by_admin` as filters
     * @param limit
     * @param offset
     * @param isCancelled
     * @param processedByAdmin
     * @param lessonsOnly
     * @param searchName
     * @returns FinanceOrder Successful Response
     * @throws ApiError
     */
    public getOrdersGetFinanceOrders(
        limit: number = 100,
        offset?: number,
        isCancelled: boolean = false,
        processedByAdmin: boolean = false,
        lessonsOnly: boolean = false,
        searchName?: (string | null),
    ): CancelablePromise<Array<FinanceOrder>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orders/finance/orders',
            query: {
                'limit': limit,
                'offset': offset,
                'is_cancelled': isCancelled,
                'processed_by_admin': processedByAdmin,
                'lessons_only': lessonsOnly,
                'search_name': searchName,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Process Orders
     * (Un)Process orders, used for the finance dashboard
     *
     * Sets the `processed_by_admin` flag on the orders
     *
     * <br>
     * Args:
     *
     * - `order_id_list` (List[int]): the list of order ids to (un)process
     *
     * - `is_cancelled` (Optional[bool]): whether the orders are cancelled or not
     *
     * - `lessons_only` (Optional[bool]): whether the orders are lessons only or not
     *
     * - `processed_by_admin` (Optional[bool]): the boolean set for processed_by_admin
     *
     * - `offset` (Optional[int]): the offset of the orders, default = 0
     *
     * - `limit` (Optional[int]): the number of orders per page, default = 100
     *
     * <br>
     * Returns the __orders__ that are to be (un)processed, corresponding to the current page (offset, limit, is_cancelled, lessons_only, unprocess)
     * <br>
     * HTTPExceptions:
     *
     * - 403: Not enough permissions
     * @param requestBody
     * @param isCancelled
     * @param lessonsOnly
     * @param processedByAdmin
     * @param offset
     * @param limit
     * @param searchName
     * @returns FinanceOrder Successful Response
     * @throws ApiError
     */
    public postOrdersProcessOrders(
        requestBody: Array<number>,
        isCancelled: boolean = false,
        lessonsOnly: boolean = false,
        processedByAdmin: boolean = true,
        offset?: number,
        limit: number = 100,
        searchName?: (string | null),
    ): CancelablePromise<Array<FinanceOrder>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orders/finance/process',
            query: {
                'is_cancelled': isCancelled,
                'lessons_only': lessonsOnly,
                'processed_by_admin': processedByAdmin,
                'offset': offset,
                'limit': limit,
                'search_name': searchName,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Custom Order
     * Create a custom order for a guardian
     *
     * <br>
     * Takes a `CustomBundleDetail` as request body:
     *
     * - `total_hours` (float): the total hours of the bundle
     *
     * - `start_date` (datetime): the start date of the bundle
     *
     * - `end_date` (datetime): the end date of the bundle
     *
     * <br>
     * Returns the created order
     *
     * <br>
     * HTTPExceptions:
     *
     * - 406: Only guardians can buy a bundle
     *
     * - 403: Not enough permissions to create order
     *
     * - 406: IDeal payments not supported in this release
     *
     * <br>
     * Limitations in this release:
     *
     * - Coaches are not allowed to sell products
     *
     * - Only guardians can buy
     *
     * - Only one product per order
     *
     * - No IDeal payments
     * @param customerUserId
     * @param requestBody
     * @returns RegularOrder Successful Response
     * @throws ApiError
     */
    public postOrdersCreateCustomOrder(
        customerUserId: number,
        requestBody: CustomOrderIn,
    ): CancelablePromise<RegularOrder> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orders/order/custom',
            query: {
                'customer_user_id': customerUserId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Lessons To Order
     * Takes all the __accorded__ lessons from the previous month and puts them bundles in orders
     *
     * These orders are all created with the `invoice` order type
     *
     * <br>
     * Returns the __all orders__ created from the lessons with filter `is_cancelled` as `False` and `processed_by_admin` as `False`.
     *
     * <br>
     * !!! This is a __slow__ operation and should be used with caution. !!!
     * @returns BaseTask Successful Response
     * @throws ApiError
     */
    public postOrdersLessonsToOrder(): CancelablePromise<BaseTask> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orders/finance/lessons/to_order',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Lessons To Order
     * Get the orders created from the lessons to order task
     *
     * <br>
     * Args:
     *
     * - `task_id` (str): the id of the task
     *
     * <br>
     * Returns the __orders__ created from the lessons with the corresponding task_id
     * @param taskId
     * @returns BaseTask Successful Response
     * @throws ApiError
     */
    public getOrdersGetLessonsToOrder(
        taskId: string,
    ): CancelablePromise<BaseTask> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orders/finance/lessons_to_order/task/{task_id}',
            path: {
                'task_id': taskId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Legacy Bundles
     * Import all the legacy bundles from the previous system (excel)
     *
     * and create orders for them
     *
     * <br>
     * Args:
     *
     * - csv: `UploadFile` the csv file with the legacy orders
     *
     * - (Optional) `guardian_id_column_name` (str): the column name of the guardian id, default = "guardian_id"
     *
     * - (Optional) `remaining_hours_column_name` (str): the column name of the remaining hours, default = "remaining_hours"
     *
     * - (Optional) `end_date_column_name` (str): the column name of the end date, default = "end_date"
     *
     * <br>
     * Returns a tuple with the __created orders__ and the __failed orders__
     *
     * - The failed orders are a list of the values of the failed orders (or skipped)
     *
     * <br>
     * HTTPExceptions:
     *
     * - 403: Not enough permissions
     * - 400: File must be a CSV file
     * - 400: CSV must contain given columns:
     * @param formData
     * @param guardianIdColumnName
     * @param remainingHoursColumnName
     * @param endDateColumnName
     * @returns any Successful Response
     * @throws ApiError
     */
    public postOrdersImportLegacyBundles(
        formData: Body_import_legacy_bundles_orders_finance_import_legacy_bundles_post,
        guardianIdColumnName?: (string | null),
        remainingHoursColumnName?: (string | null),
        endDateColumnName?: (string | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orders/finance/import_legacy_bundles',
            query: {
                'guardian_id_column_name': guardianIdColumnName,
                'remaining_hours_column_name': remainingHoursColumnName,
                'end_date_column_name': endDateColumnName,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Guardian From Order
     * Get the guardian from an order
     *
     * <br>
     * Args:
     *
     * - `order_id` (int): the id of the order to get the guardian for
     *
     * <br>
     * Returns the __guardian__ of the order with the corresponding id
     *
     *
     * If not guardian is found, returns `None`
     *
     *
     * HTTPExceptions:
     *
     * - 404: Order not found
     * <br>
     * @param orderId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getOrdersGetGuardianFromOrder(
        orderId: number,
    ): CancelablePromise<(RegularGuardianWithChildren | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orders/finance/orders/{order_id}/guardian',
            path: {
                'order_id': orderId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
