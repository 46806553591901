import React, { FormEvent, useState } from 'react';

import { LinearProgress } from '@mui/material';
import { useParams } from 'react-router-dom';

import { appClient } from 'App';
import { PasswordResetIn } from 'assets';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import CreatePasswordDesktop from './Desktop/CreatePasswordDesktop';
import CreatePasswordMobile from './Mobile/CreatePasswordMobile';

const CreatePassword = () => {
  const { token } = useParams<{ token: string }>();
  const label = useLabel();
  const currentWidth = useWidth();
  const [password, setPassword] = useState<string>('');
  const [passwordComfirm, setPasswordComfirm] = useState<string>('');
  const [state, setState] = useState<string>('');

  const handleSubmit = (eventL: FormEvent<HTMLFormElement>) => {
    eventL.preventDefault();
    setState('loading');

    if (password === passwordComfirm) {
      const data: PasswordResetIn = { password: password };

      appClient.auth
        .postAuthResetUserPassword(token, data)
        .then(res => {
          if (res.status === '200') {
            setState('success');
            localStorage.clear();
          } else if (res.status === 'ok') {
            setState('success');
            localStorage.clear();
          } else {
            setState('error');
          }
        })
        .catch(() => {
          setState('error');
        });
    }
  };

  return (
    <>
      {label ? (
        currentWidth === 'xs' ? (
          <CreatePasswordMobile
            handleSubmit={handleSubmit}
            status={state}
            setPassword={setPassword}
            setPasswordConfirm={setPasswordComfirm}
          />
        ) : (
          <CreatePasswordDesktop
            handleSubmit={handleSubmit}
            status={state}
            setPassword={setPassword}
            setPasswordConfirm={setPasswordComfirm}
          />
        )
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default CreatePassword;
