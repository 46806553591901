import React, { FormEvent } from 'react';

import { Grid } from '@mui/material';

import ChangePasswordFormMobile from './MobileComponent/ChangePasswordFormMobile';
import ChangePasswordMobileTopHeader from './MobileComponent/ChangePasswordMobileTopHeader';

interface IChangePasswordMobileProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  state: string;
  password: string;
  passwordComfirm: string;
  setPassword: (value: string) => void;
  setPasswordComfirm: (value: string) => void;
}

const ChangePasswordMobile: React.FunctionComponent<IChangePasswordMobileProps> = ({
  handleSubmit,
  state,
  password,
  passwordComfirm,
  setPassword,
  setPasswordComfirm,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ChangePasswordMobileTopHeader />
      </Grid>

      <Grid item xs={12}>
        <ChangePasswordFormMobile
          handleSubmit={handleSubmit}
          state={state}
          password={password}
          passwordComfirm={passwordComfirm}
          setPassword={setPassword}
          setPasswordComfirm={setPasswordComfirm}
        />
      </Grid>
    </Grid>
  );
};

export default ChangePasswordMobile;
