import * as React from 'react';
import { useState } from 'react';

import { Close, Edit } from '@mui/icons-material';
import { CardHeader, Grid, IconButton } from '@mui/material';

interface EditFormProps {
  ComponentsWhenNotEditing: React.FunctionComponent;
  ComponentsWhenEditing: React.FunctionComponent;
  title: string;
  subheader: string;
}

const EditForm: React.FunctionComponent<EditFormProps> = ({
  ComponentsWhenEditing,
  ComponentsWhenNotEditing,
  ...restProps
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <CardHeader
          sx={{ padding: '16px 0px' }}
          {...restProps}
          action={
            <IconButton onClick={() => setIsEditing(!isEditing)}>
              {isEditing ? <Close /> : <Edit />}
            </IconButton>
          }
        />
      </Grid>
      {isEditing ? ComponentsWhenEditing({}) : ComponentsWhenNotEditing({})}
    </>
  );
};

export default EditForm;
