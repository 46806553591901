import React, { useContext, useState } from 'react';

import { appClient, setToken } from 'App';
import {
  Body_login_for_access_token_token_post,
  RegularUserWithGroupsAndTypes,
  Token,
} from 'assets';

const UserContext = React.createContext<IUseUser>({
  jwt: () => 'No jwt',
  setUser: () => {
    /* Do nothing */
  },
});

export type UserProps = 'first_name' | 'last_name' | 'email_address';

export interface ILoginCredentials {
  username: string;
  password: string;
}

export interface IUseUser {
  isValidUser?: boolean;
  isLoadingUser?: boolean;
  user?: IUser;
  getUser?: () => Promise<IUser>;
  // getUser: (onSucces?: (response: IUser) => void, onFailed?: (response: any) => void) => void;
  setUser: (user: IUser) => void;
  signIn?: (login: ILoginCredentials) => Promise<Token>;
  jwt: () => string;
  shiftsProvider?: boolean;
  setShiftsProvider?: (value: boolean) => void;
}

export type IUser = RegularUserWithGroupsAndTypes;

const UserSource = ({ children }: { children: JSX.Element[] }) => {
  const [user, setUser] = useState<IUser>();
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isValidUser] = useState(true);
  const [shiftsProvider, setShiftsProvider] = useState(false);

  const jwt = (): string => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) return jwt;
    else {
      return 'not set';
    }
  };

  const signIn = async (login: Body_login_for_access_token_token_post) => {
    const response = await appClient.default.postLoginForAccessToken(login);
    // if (response.mfa === "none"){
    const { access_token } = response;
    const jwt = `Bearer ${access_token}`;
    setToken(access_token);
    localStorage.setItem('jwt', jwt);

    // }
    return response;
  };

  const getUser = async (force?: boolean) => {
    setIsLoadingUser(true);
    if (!user || force) {
      const response = await appClient.auth.getAuthGetCurrentUser().catch(r => {
        setIsLoadingUser(false);
        throw new Error(r);
      });
      setIsLoadingUser(false);
      setUser(response);

      return response;
    } else {
      setIsLoadingUser(false);

      return await Promise.resolve(user);
    }
  };

  return (
    <UserContext.Provider
      value={{
        isValidUser,
        jwt,
        isLoadingUser,
        user,
        getUser,
        setUser,
        signIn,
        shiftsProvider,
        setShiftsProvider,
      }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

export { UserSource };
export default useUser;
