import React from 'react';

import { Card, CardActions, CardContent, Grid, Modal, Typography } from '@mui/material';

import { Button } from 'components/Elements/Forms/Inputs';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import { IExtendedBundleIn, IFlowPageProps } from '..';

interface IPackageModalProps extends IFlowPageProps {
  bundle: IExtendedBundleIn;
  setBundle: (bundle: IExtendedBundleIn) => void;
  open: boolean;
  handleButtonClick: () => void;
  handlePrimaryButton: () => void;
  selectedPackage?: string;
}

const PackageModal: React.FunctionComponent<IPackageModalProps> = ({
  open,
  handleButtonClick,
  handlePrimaryButton,
  selectedPackage,
}) => {
  const label = useLabel();
  const currentWidth = useWidth();

  return (
    <Modal
      open={open}
      onClose={handlePrimaryButton}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Card
        sx={{
          position: 'absolute',
          top: currentWidth === 'xs' ? '30%' : '40%',
          left: currentWidth === 'xs' ? 'unset' : '35%',
          padding: '10px',
          borderRadius: '15px',
          backgroundColor: '#f5f5f5',
          width: currentWidth === 'xs' ? '100%' : '30%',
        }}>
        <CardContent>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                        Text in a modal
                    </Typography> */}
          <Typography>
            Weet u zeker dat u gebruik wilt maken van losse lessen? Met een pakket bent u veel
            voordeliger uit en in combinatie met een automatische incasso kunt u in termijnen
            betalen en krijgt u €10,- korting op de administratiekosten.
          </Typography>
        </CardContent>
        <CardActions sx={{ padding: '5px 15px 15px 15px' }}>
          <Grid container spacing={currentWidth === 'xs' ? 1 : 0}>
            <Grid item xs={12} md={4}>
              <Button
                variant="outlined"
                onClick={handleButtonClick}
                fullWidth
                sx={{ padding: '8px 0px' }}>
                Terug
              </Button>
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                onClick={handlePrimaryButton}
                fullWidth
                sx={{
                  backgroundColor: label?.main_color,
                  float: 'right',
                  padding: '8px 0px',
                }}>
                {selectedPackage}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default PackageModal;
