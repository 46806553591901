/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DisputeCategory {
    COACH_VERKEERDE_DUUR = 'coach_verkeerde_duur',
    COACH_VERKEERDE_DAG = 'coach_verkeerde_dag',
    COACH_NOSHOW = 'coach_noshow',
    COACH_OTHER = 'coach_other',
}
