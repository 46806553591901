import React, { useState } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { appClient } from 'App';
import { BaseDiscipline, BaseStub, SchoolLevelEnum_Output } from 'assets';
import { TextField } from 'components/Elements/Forms/Inputs';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

import { IFlowPageProps } from '..';

interface ISubjectPageProps extends IFlowPageProps {
  data: BaseStub;
  setData: (baseStub: BaseStub) => void;
}

const SubjectPage: React.FunctionComponent<ISubjectPageProps> = ({ data, setData }) => {
  const [update, setUpdate] = useState(false);
  const [subjects, setSubjects] = useState<BaseDiscipline[]>();
  const { enqueueSnackbar } = useSnackbar();
  const currentWidth = useWidth();

  React.useEffect(() => {
    if (update === true) {
      setUpdate(false);
    }
  }, [update]);

  React.useEffect(() => {
    if (!subjects) {
      try {
        appClient.lessons.getLessonsViewDisciplines().then((r: BaseDiscipline[] | null) => {
          if (r !== null) {
            setSubjects(
              r.sort((a: BaseDiscipline, b: BaseDiscipline) => a.name.localeCompare(b.name))
            );
          }
        });
      } catch (e) {
        // Nothing
      }
    }
  });

  const handleNext = async (event: any) => {
    const disciplines: BaseDiscipline[] = data.data_bundle.students[0].disciplines;
    if (!data.data_bundle.students[0].school_level) {
      enqueueSnackbar('Selecteer a.u.b. het gewenste niveau.', { variant: 'error' });

      return;
    }

    if (!data.data_bundle.students[0].school_year) {
      enqueueSnackbar('Selecteer a.u.b het schooljaar.', { variant: 'error' });

      return;
    }

    if (disciplines === undefined || disciplines.length === 0) {
      enqueueSnackbar('Selecteer a.u.b op zijn minst 1 bijles vak.', { variant: 'error' });

      return;
    }
    event.detail();
  };

  React.useEffect(() => {
    document.addEventListener('onNext', handleNext);

    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [data]);

  const setLeerjaar = (school_year: number) => {
    const tempdata = { ...data };
    tempdata.data_bundle.students[0].school_year = school_year;
    setData(tempdata);
  };

  const setNiveau = (school_level: string) => {
    const tempdata = { ...data };
    tempdata.data_bundle.students[0].school_level = school_level;
    tempdata.data_bundle.students[0].school_year = '';
    if (tempdata.data_bundle.students?.[0].disciplines !== undefined) {
      tempdata.data_bundle.students[0].disciplines = [];
    }
    setData(tempdata);
  };

  const setVak = (vak: BaseDiscipline) => {
    const tempdata = { ...data };
    if (!tempdata.data_bundle?.students?.[0].disciplines) {
      tempdata.data_bundle.students[0].disciplines = [];
      tempdata.data_bundle.students[0].disciplines.push(vak.id);
    } else {
      const disciplines: Array<number> = tempdata.data_bundle?.students?.[0].disciplines;
      if (disciplines.indexOf(vak.id) == -1) {
        disciplines.push(vak.id);
      } else {
        const index = tempdata.data_bundle.students?.[0].disciplines.indexOf(vak.id);
        tempdata.data_bundle.students[0].disciplines.splice(index, 1);
      }
    }
    setData(tempdata);
  };

  const returnGrade = () => {
    const items = [];
    let years;
    switch (data.data_bundle.students?.[0]?.school_level) {
      case 'basisschool':
        years = 8;
        break;
      case 'vmbo':
        years = 4;
        break;
      case 'vmbo-tl':
        years = 4;
        break;
      case 'vmbo-kader':
        years = 4;
        break;
      case 'vmbo-basis':
        years = 4;
        break;
      case 'havo':
        years = 5;
        break;
      case 'vwo':
        years = 6;
        break;
      case 'gymnasium':
        years = 6;
        break;
      case 'mbo':
        years = 4;
        break;
      case 'hbo':
        years = 4;
        break;
      case 'wo':
        years = 4;
        break;
      default:
        years = 8;
        break;
    }
    for (let i = 1; i <= years; i++) {
      items.push(
        <MenuItem onClick={() => setLeerjaar(i)} value={i}>
          {i}
        </MenuItem>
      );
    }

    return items;
  };

  const schoolVakkenRadio = (vakken: BaseDiscipline[]) => {
    return (
      <Grid container xs={12}>
        {vakken.map((vak: BaseDiscipline) => {
          if (data.data_bundle.students?.[0].school_level === 'basisschool') {
            if (vak.is_primary_school) {
              return (
                <Grid item xs={6} md={4}>
                  <FormControlLabel
                    value="huis"
                    control={
                      <Checkbox
                        checked={
                          data.data_bundle?.students[0].disciplines
                            ? data.data_bundle?.students[0].disciplines.indexOf(vak.id) == -1
                              ? false
                              : true
                            : false
                        }
                        onClick={() => setVak(vak)}
                      />
                    }
                    label={
                      <Typography fontSize={currentWidth === 'xs' ? '15px' : '18px'}>
                        {vak.name}
                      </Typography>
                    }
                    sx={{ width: currentWidth === 'xs' ? '100%' : 'auto' }}
                  />
                </Grid>
              );
            }
          } else {
            if (!vak.is_primary_school) {
              return (
                <Grid item xs={6} md={4}>
                  <FormControlLabel
                    value="huis"
                    control={
                      <Checkbox
                        checked={
                          data.data_bundle?.students[0].disciplines
                            ? data.data_bundle?.students[0].disciplines.indexOf(vak.id) == -1
                              ? false
                              : true
                            : false
                        }
                        onClick={() => setVak(vak)}
                      />
                    }
                    label={
                      <Typography fontSize={currentWidth === 'xs' ? '15px' : '18px'}>
                        {vak.name}
                      </Typography>
                    }
                    sx={{ width: currentWidth === 'xs' ? '100%' : 'auto' }}
                  />
                </Grid>
              );
            }
          }
        })}
      </Grid>
    );
  };

  return (
    <div>
      <Card
        elevation={0}
        sx={{
          marginTop: '-5px',
          marginBottom: currentWidth === 'xs' ? '-30px' : 'unset',
        }}>
        <CardContent>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  <CardHeader
                    title={'Kies het schoolniveau en het leerjaar van uw kind.'}
                    // subheader={"Kies het schoolniveau en het leerjaar van uw kind"}
                    titleTypographyProps={{
                      fontSize: '18px',
                      fontWeight: '400',
                      lineHeight: '1.4',
                      color: 'rgba(0, 0, 0, 0.6);',
                    }}
                    sx={{
                      marginTop: '10px',
                      marginBottom: currentWidth === 'xs' ? '0px' : '0px',
                      paddingBottom: '10px',
                      paddingTop: '0px',
                      paddingLeft: '0px',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CardHeader
                    title={'Schoolniveau van uw kind:'}
                    titleTypographyProps={{ fontSize: '18px' }}
                    sx={{
                      marginTop: '10px',
                      marginBottom: '0px',
                      paddingBottom: '10px',
                      paddingTop: '0px',
                      paddingLeft: '0px',
                    }}
                    // subheader={"Kies het schoolniveau en het leerjaar van uw kind"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    // labelId="Niveau-label"
                    // label="Niveau"
                    backgroundColor="#f5f5f5"
                    border="unset"
                    value={data.data_bundle.students?.[0].school_level || {}}
                    onChange={e => setNiveau(e.target.value)}>
                    {Object.keys(SchoolLevelEnum_Output).map(school_level => (
                      <MenuItem value={school_level.toLowerCase().replace('_', '-')}>
                        {school_level.toLowerCase().replace('_', ' ')}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} sx={{ marginTop: currentWidth === 'xs' ? '-20px' : '25px' }}>
              <Grid container>
                <Grid item xs={12}>
                  <CardHeader
                    title={'Leerjaar van uw kind:'}
                    titleTypographyProps={{ fontSize: '18px' }}
                    sx={{
                      marginTop: '30px',
                      marginBottom: '0px',
                      paddingBottom: '10px',
                      paddingTop: '0px',
                      paddingLeft: '0px',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    // labelId="Leerjaar-label"
                    // label="Leerjaar"
                    backgroundColor="#f5f5f5"
                    border="unset"
                    value={data.data_bundle.students?.[0].school_year || {}}>
                    {returnGrade()}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            {data.data_bundle.students?.[0].school_year &&
              data.data_bundle.students?.[0].school_level && (
                <Grid item xs={12}>
                  <CardHeader
                    title={'Vink aan in welke vakken uw kind bijles wenst te krijgen.'}
                    titleTypographyProps={{
                      fontSize: '18px',
                      fontWeight: '400',
                      lineHeight: '1.4',
                    }}
                    sx={{
                      marginTop: '30px',
                      marginBottom: '0px',
                      paddingBottom: '10px',
                      paddingTop: '0px',
                      paddingLeft: '0px',
                    }}
                  />
                </Grid>
              )}
            <Grid
              item
              xs={12}
              sx={{
                marginLeft: '-15px',
                marginRight: '-15px',
              }}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    {data.data_bundle.students?.[0].school_year &&
                      data.data_bundle.students?.[0].school_level && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                sx={{ marginLeft: '15px' }}>
                                {subjects && schoolVakkenRadio(subjects)}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </React.Fragment>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default SubjectPage;
