import React, { FC } from 'react';

import { Card, Grid, Radio, Typography } from '@mui/material';
import { t } from 'i18n';

import { EnumOrderType } from 'assets';
import IncassoIcon from 'assets/img/brand/icoon_autoincasso.png';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

interface IIncassoProps {
  selectedPaymentMethod: EnumOrderType;
  setSelectedPaymentMethod: (value: EnumOrderType) => void;
  firstOrder?: boolean;
}

const Incasso: FC<IIncassoProps> = ({ selectedPaymentMethod, setSelectedPaymentMethod }) => {
  const currentWidth = useWidth();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentMethod(event.target.value as EnumOrderType);
  };

  return (
    <Card
      elevation={0}
      /* height needs to be 100px with koring now temp lower */
      sx={{
        borderRadius: '15px',
        height: currentWidth === 'xs' ? '80px' : '100px'
      }}>
      <Grid container sx={{ padding: '10px', borderRadius: '15px' }}>
        <Grid item xs={2}>
          <Radio
            checked={selectedPaymentMethod === EnumOrderType.COLLECTION}
            onChange={handleChange}
            value={EnumOrderType.COLLECTION}
            name="radio-buttons"
            inputProps={{ 'aria-label': 'A' }}
          />
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={2}>
              <img
                src={IncassoIcon}
                style={{
                  width: '20px',
                  height: '20px',
                  objectFit: 'cover',
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography
                sx={{ fontSize: '17px', fontWeight: '600', color: '#2B2E34', marginTop: '3px' }}>
                {t('translation.portalShared.payment.collection.payBy')}
              </Typography>
            </Grid>
            {/* temp comment is not working on BE */}
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '14px', color: '#8AD35D', margin: '5px 0px 0px 5px' }}>
                {t('translation.portalShared.payment.collection.info')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Incasso;
