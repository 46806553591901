/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasePushNotificationToken } from '../models/BasePushNotificationToken';
import type { PushNotificationApps } from '../models/PushNotificationApps';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PushNotificationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Register Push Notification Token
     * Registers an FCM token for push notifications
     * @param userId
     * @param token
     * @param app
     * @returns BasePushNotificationToken Successful Response
     * @throws ApiError
     */
    public postPushNotificationsRegisterPushNotificationToken(
        userId: number,
        token: string,
        app: PushNotificationApps,
    ): CancelablePromise<BasePushNotificationToken> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/push_notifications/tokens/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'token': token,
                'app': app,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Push Notification Tokens
     * Returns all FCM tokens for a user
     * @param userId
     * @param app
     * @returns BasePushNotificationToken Successful Response
     * @throws ApiError
     */
    public getPushNotificationsGetPushNotificationTokens(
        userId: number,
        app?: PushNotificationApps,
    ): CancelablePromise<Array<BasePushNotificationToken>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/push_notifications/tokens/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'app': app,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Push Notification Token By Id
     * Deletes an FCM token basewd on the provided ID
     * @param pushNotificationTokenId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deletePushNotificationsDeletePushNotificationTokenById(
        pushNotificationTokenId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/push_notifications/tokens/delete_by_id/{push_notification_token_id}',
            path: {
                'push_notification_token_id': pushNotificationTokenId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Push Notification Token By Token
     * Deletes an FCM token based on the provided token string
     * @param pushNotificationTokenString
     * @returns any Successful Response
     * @throws ApiError
     */
    public deletePushNotificationsDeletePushNotificationTokenByToken(
        pushNotificationTokenString: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/push_notifications/tokens/delete_by_token/{push_notification_token_string}',
            path: {
                'push_notification_token_string': pushNotificationTokenString,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
