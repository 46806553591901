/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedReturnDefaultTariff } from '../models/AdvancedReturnDefaultTariff';
import type { BaseReturnDefaultTariff } from '../models/BaseReturnDefaultTariff';
import type { EditDefaultTariff } from '../models/EditDefaultTariff';
import type { EnumTariffType } from '../models/EnumTariffType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultTariffService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Current Default Tariff
     * Get the current default tariff for the given tariff type.
     *
     * <br>
     * Args:
     *
     * - `tariff_type`: `EnumTariffType`
     *
     * <br>
     * Permissions:
     *
     * - `Public` -> necessary for parent flow
     *
     * <br>
     * Returns:
     *
     * - `BaseReturnDefaultTariff`: The current default tariff for the given tariff type.
     *
     * <br>
     * HTTPExceptions:
     *
     * - `404`: If no current default tariff is found for the given tariff type.
     * @param tariffType
     * @returns BaseReturnDefaultTariff Successful Response
     * @throws ApiError
     */
    public getDefaultTariffGetCurrentDefaultTariff(
        tariffType: EnumTariffType,
    ): CancelablePromise<BaseReturnDefaultTariff> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/default_tariff/default_tariff',
            query: {
                'tariff_type': tariffType,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Default Tariffs
     * Get all the default tariffs.
     *
     * When `current` is set to `True`, only the current default tariffs are returned. Vice versa.
     *
     * <br>
     * Args:
     *
     * - `current`: `bool`
     *
     * <br>
     * Permissions:
     *
     * - `can_manage_products`
     * <br>
     * Returns:
     *
     * - `List[BaseReturnDefaultTariff]`: A list of all the default tariffs.
     *
     * <br>
     * HTTPExceptions:
     *
     * - `403`: If the user does not have the permission to view the default tariffs.
     * @param current
     * @returns AdvancedReturnDefaultTariff Successful Response
     * @throws ApiError
     */
    public getDefaultTariffGetAllDefaultTariffs(
        current?: (boolean | null),
    ): CancelablePromise<Array<AdvancedReturnDefaultTariff>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/default_tariff/default_tariff/all',
            query: {
                'current': current,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Default Tariff By Id
     * Get the default tariff for the given default tariff id.
     *
     * <br>
     * Args:
     *
     * - `default_tariff_id`: `int`
     *
     * <br>
     * Permissions:
     *
     * - `can_manage_products`
     * <br>
     * Returns:
     *
     * - `BaseReturnDefaultTariff`: The default tariff for the given default tariff id.
     *
     * <br>
     * HTTPExceptions:
     *
     * - `404`: If no default tariff is found for the given default tariff id.
     *
     * - `403`: If the user does not have the permission to view the default tariff.
     * @param defaultTariffId
     * @returns AdvancedReturnDefaultTariff Successful Response
     * @throws ApiError
     */
    public getDefaultTariffGetDefaultTariffById(
        defaultTariffId: number,
    ): CancelablePromise<AdvancedReturnDefaultTariff> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/default_tariff/default_tariff/{default_tariff_id}',
            path: {
                'default_tariff_id': defaultTariffId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Default Tariff
     * Edit the default tariff for the given default tariff id.
     *
     * If you want to disable the default tariff, you should pass a tariff value of 0.
     *
     * <br>
     * Args:
     *
     * - `default_tariff_id`: `int`
     *
     * - `updated_default_tariff`: `BaseReturnDefaultTariff`
     *
     * <br>
     * Permissions:
     *
     * - `can_manage_products`
     * <br>
     * Returns:
     *
     * - `BaseReturnDefaultTariff`: The updated default tariff for the given default tariff id.
     *
     * <br>
     * HTTPExceptions:
     *
     * - `404`: If no default tariff is found for the given default tariff id.
     *
     * - `403`: If the user does not have the permission to edit the default tariff.
     * @param defaultTariffId
     * @param requestBody
     * @returns AdvancedReturnDefaultTariff Successful Response
     * @throws ApiError
     */
    public patchDefaultTariffEditDefaultTariff(
        defaultTariffId: number,
        requestBody: EditDefaultTariff,
    ): CancelablePromise<AdvancedReturnDefaultTariff> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/default_tariff/default_tariff/{default_tariff_id}',
            path: {
                'default_tariff_id': defaultTariffId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
