import * as React from 'react';
import { useEffect, useState } from 'react';

import { Warning } from '@mui/icons-material';
import { Grid, InputProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { appClient } from 'App';
import { PrivateGuardianInformation, RegularGuardianWithChildren } from 'assets';
import { TextField } from 'components/Elements/Forms/Inputs';
import {
  checkBSN,
  checkIBAN,
  checkMail,
  checkPhone,
  checkZip,
} from 'functions/HelperFunctions/Regex';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useUser, { IUser } from 'functions/Hooks/useUser';

import EditForm from '../Components/EditForm';

interface PersonalDetailsProps {}

type userProps = 'first_name' | 'last_name' | 'email_address';
const userInfoToCheck: userProps[] = ['first_name', 'last_name', 'email_address'];
type guardianProps = 'phone_number' | 'city' | 'street' | 'zipcode' | 'iban';
const coachInfoToCheck: guardianProps[] = ['phone_number', 'city', 'street', 'zipcode'];
interface propsToValidateType {
  [key: string]: (value: any) => RegExpMatchArray | boolean | null;
}
const propsToValidate: propsToValidateType = {
  email_address: checkMail,
  phone_number: checkPhone,
  zipcode: checkZip,
  bsn: checkBSN,
};

type CombinedGuardian = RegularGuardianWithChildren & PrivateGuardianInformation;

const GuardianPersonalDetails: React.FunctionComponent<PersonalDetailsProps> = () => {
  const { user } = useUser();
  const [userChange, setUserChange] = useState(user);
  const [guardian, setGuardian] = useState<CombinedGuardian>();
  const { enqueueSnackbar } = useSnackbar();
  const width = useWidth();

  const handleNext = async (event: CustomEvent<() => void>) => {
    for (let index = 0; index < userInfoToCheck.length; index++) {
      const key = userInfoToCheck[index];
      const value = userChange?.[key];
      if (!value) return enqueueSnackbar(`${key} is nog niet ingevuld`, { variant: 'warning' });
      if (Object.keys(propsToValidate).includes(key) && !propsToValidate[key](value))
        return enqueueSnackbar(`${key} is niet correct ingevuld`, { variant: 'error' });
    }

    appClient.auth.patchAuthEditUser(user?.id || 0, userChange as IUser);

    for (let index = 0; index < coachInfoToCheck.length; index++) {
      const key = coachInfoToCheck[index];
      const value = guardian?.[key];
      if (!value) return enqueueSnackbar(`${key} is nog niet ingevuld`, { variant: 'warning' });
      if (Object.keys(propsToValidate).includes(key) && !propsToValidate[key](value))
        return enqueueSnackbar(`${key} is nog correct ingevuld`, { variant: 'error' });
    }

    appClient.guardians.postGuardiansEditGuardian(
      guardian!.id,
      guardian as RegularGuardianWithChildren
    );

    // let response = await checkZipcode(guardian?.zipcode);

    // if (response.length === 0) {
    //   enqueueSnackbar("Uw postcode lijkt niet te klopen. Controleer deze nog een keer.", { variant: "error" });
    //   return;
    // }

    event.detail();
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext as unknown as EventListener);

    return () => {
      document.removeEventListener('onNext', handleNext as unknown as EventListener);
    };
  }, [userChange, guardian]);

  useEffect(() => {
    appClient.auth.getAuthGetCurrentUser().then(setUserChange);
  }, []);

  useEffect(() => {
    appClient.guardians.getGuardiansViewGuardianSimple(user!.guardian!.id).then(baseGuardian => {
      appClient.guardians
        .getGuardiansViewPrivateGuardian(user!.guardian!.id)
        .then(privateGuardian => {
          if (baseGuardian !== null && privateGuardian !== null) {
            setGuardian({
              ...baseGuardian,
              ...privateGuardian,
            });
          }
        });
    });
  }, [user]);

  const handleChange: InputProps['onChange'] = ({ target: { name, value } }) => {
    if (name.includes('user')) {
      setUserChange({
        ...(userChange as IUser),
        [name.replace('user.', '') as userProps]: value,
      });
    } else {
      setGuardian({
        ...(guardian as CombinedGuardian),
        [name as guardianProps]: value,
      });
    }
  };

  return (
    <Grid container spacing={1} justifyContent="">
      <EditForm
        title="Identiteit"
        subheader="Gevevens die horen bij uw profiel"
        ComponentsWhenEditing={() => (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                onChange={handleChange}
                label="Voornaam"
                name="user.first_name"
                value={userChange?.first_name || ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                onChange={handleChange}
                label="Achternaam"
                name="user.last_name"
                value={userChange?.last_name || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                onChange={handleChange}
                label="Email"
                error={Boolean(userChange?.email_address) && !checkMail(userChange?.email_address)}
                name="user.email_address"
                value={userChange?.email_address || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                onChange={handleChange}
                label="Telefoonnummer"
                error={Boolean(guardian?.phone_number) && !checkPhone(guardian?.phone_number)}
                name="phone_number"
                value={guardian?.phone_number || ''}
              />
            </Grid>
          </>
        )}
        ComponentsWhenNotEditing={() => (
          <>
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Voornaam
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {userChange?.first_name || <Warning color="error" />}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Achternaam
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign={'right'} variant="h4" color="initial">
                {userChange?.last_name || <Warning color="error" />}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Telefoon
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography noWrap textAlign="right" variant="h4" color="initial">
                {guardian?.phone_number || <Warning color="error" />}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                E-mailadres
              </Typography>
            </Grid>
            <Grid item xs={12} sm>
              <Typography
                noWrap
                textAlign={width === 'xs' ? 'left' : 'right'}
                variant="h4"
                color="initial">
                {userChange?.email_address || <Warning color="error" />}
              </Typography>
            </Grid>
          </>
        )}
      />
      <EditForm
        title="Adresgegevens"
        subheader="Gegevens die horen bij uw woonplaats en adres"
        ComponentsWhenEditing={() => (
          <>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                value={guardian?.city || ''}
                fullWidth
                label="Woonplaats"
                name="city"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                onChange={handleChange}
                value={guardian?.street || ''}
                fullWidth
                label="Staatnaam en huisnummer"
                name="street"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                onChange={handleChange}
                value={guardian?.zipcode || ''}
                error={Boolean(guardian?.zipcode) && !checkZip(guardian?.zipcode)}
                fullWidth
                label="Postcode"
                name="zipcode"
              />
            </Grid>
          </>
        )}
        ComponentsWhenNotEditing={() => (
          <>
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Woonplaats
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {guardian?.city || <Warning color="error" />}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Adres
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {guardian?.street || <Warning color="error" />}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Postcode
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {guardian?.zipcode || <Warning color="error" />}
              </Typography>
            </Grid>
          </>
        )}
      />
      <EditForm
        title="Bankgegevens"
        subheader="Deze gegevens hebben we nodig voor betalingen"
        ComponentsWhenEditing={() => (
          <>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                value={guardian?.iban || ''}
                error={Boolean(guardian?.iban) && !checkIBAN(guardian?.iban)}
                fullWidth
                label="IBAN"
                name="iban"
              />
            </Grid>
          </>
        )}
        ComponentsWhenNotEditing={() => (
          <>
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Iban
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {guardian?.iban || <Warning color="error" />}
              </Typography>
            </Grid>
          </>
        )}
      />
      <Grid item xs={12} />
    </Grid>
  );
};

export default GuardianPersonalDetails;
