/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompetenceEdit } from '../models/CompetenceEdit';
import type { CompetenceIn } from '../models/CompetenceIn';
import type { GenericStatus } from '../models/GenericStatus';
import type { SimpleCompetence } from '../models/SimpleCompetence';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SPCompetencesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Competences
     * @param limit
     * @param offset
     * @returns SimpleCompetence Successful Response
     * @throws ApiError
     */
    public getSPCompetencesGetAllCompetences(
        limit: number = 30,
        offset?: number,
    ): CancelablePromise<Array<SimpleCompetence>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/competences/competences',
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All General Competences
     * @returns SimpleCompetence Successful Response
     * @throws ApiError
     */
    public getSPCompetencesGetAllGeneralCompetences(): CancelablePromise<Array<SimpleCompetence>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/competences/competences/general',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
            },
        });
    }

    /**
     * Get All Competences By Sector
     * @param sectorId
     * @returns SimpleCompetence Successful Response
     * @throws ApiError
     */
    public getSPCompetencesGetAllCompetencesBySector(
        sectorId: number,
    ): CancelablePromise<Array<SimpleCompetence>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/competences/competences/sector/{sector_id}',
            path: {
                'sector_id': sectorId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Competences By Coach
     * @param coachId
     * @returns SimpleCompetence Successful Response
     * @throws ApiError
     */
    public getSPCompetencesGetAllCompetencesByCoach(
        coachId: number,
    ): CancelablePromise<Array<SimpleCompetence>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/competences/competences/coach/{coach_id}',
            path: {
                'coach_id': coachId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Competences
     * @param competenceId
     * @returns SimpleCompetence Successful Response
     * @throws ApiError
     */
    public getSPCompetencesGetCompetences(
        competenceId: number,
    ): CancelablePromise<SimpleCompetence> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/competences/competence/{competence_id}',
            path: {
                'competence_id': competenceId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Competence
     * @param competenceId
     * @param requestBody
     * @returns SimpleCompetence Successful Response
     * @throws ApiError
     */
    public patchSPCompetencesEditCompetence(
        competenceId: number,
        requestBody: CompetenceEdit,
    ): CancelablePromise<SimpleCompetence> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/competences/competence/{competence_id}',
            path: {
                'competence_id': competenceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Competence
     * @param competenceId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteSPCompetencesDeleteCompetence(
        competenceId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/competences/competence/{competence_id}',
            path: {
                'competence_id': competenceId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Competence
     * @param requestBody
     * @param sectorId
     * @returns SimpleCompetence Successful Response
     * @throws ApiError
     */
    public postSPCompetencesCreateCompetence(
        requestBody: CompetenceIn,
        sectorId?: (number | null),
    ): CancelablePromise<SimpleCompetence> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/competences/new',
            query: {
                'sector_id': sectorId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Competence Sector
     * @param competenceId
     * @param sectorId
     * @returns SimpleCompetence Successful Response
     * @throws ApiError
     */
    public patchSPCompetencesEditCompetenceSector(
        competenceId: number,
        sectorId?: (number | null),
    ): CancelablePromise<SimpleCompetence> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/competences/competence/{competence_id}/change_sector',
            path: {
                'competence_id': competenceId,
            },
            query: {
                'sector_id': sectorId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
