import React, { FormEvent, useState } from 'react';

import { Alert, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { t, Translation } from 'i18n';

import logoSW from 'assets/img/brand/logo-studyworks-origineel1.png';
import logoOB from 'assets/img/brand/LOGO_OB.png';
import logoBEU from 'assets/img/brand/LogoBijlesEU.png';
import logoVBL from 'assets/img/brand/VlakbijlesLogo.png';
import { Button, TextField } from 'components/Elements/Forms/Inputs';
import useLabel from 'functions/Hooks/useLabel';


interface ICreatePasswordFormDesktopProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  status: string;
  setPassword: (value: string) => void;
  setPasswordConfirm: (value: string) => void;
}

const CreatePasswordFormDesktop: React.FunctionComponent<ICreatePasswordFormDesktopProps> = ({
  handleSubmit,
  status,
  setPassword,
  setPasswordConfirm,
}) => {
  const label = useLabel();
  const [logo, setLogo] = useState<string>();
  const [height, setHeight] = useState<string>();

  React.useEffect(() => {
    if (label) {
      switch (label?.name) {
        case 'StudyWorks':
          setLogo(logoSW);
          setHeight('30px');
          break;
        case 'VlakBijles':
          setLogo(logoVBL);
          setHeight('80px');
          break;
        case 'bijleseu.nl':
          setLogo(logoBEU);
          setHeight('80px');
          break;
        case 'Bijles.EU':
          setLogo(logoBEU);
          setHeight('60px');
          break;
        case 'Online-Bijles.com':
          setLogo(logoOB);
          setHeight('80px');
          break;
        default:
          break;
      }
    }
  }, [label]);

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container>
          <Grid item md={'auto'}>
            <img
              src={logo}
              style={{
                height: height,
                objectFit: 'contain',
              }}
            />
          </Grid>
          <Grid item md={12}>
            <CardHeader
              title={
                <Typography
                  variant="h3"
                  color={'#3C3C3B'}
                  sx={{
                    fontSize: '1.7vw',
                    lineHeight: '1.2',
                  }}>
                  <Translation i18nKey="translation.auth.createpw.header" values={{ label: label?.name }} />
                </Typography>
              }
              subheader={
                <Typography
                  sx={{
                    marginTop: '20px',
                    color: '#6F6F6F',
                    fontSize: '1vw',
                  }}>
                  {t('translation.auth.createpw.subheader')}
                </Typography>
              }
              sx={{ paddingLeft: '0px' }}
            />
          </Grid>

          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Grid container>
                {status !== 'success' && (
                  <Grid item xs={12} md={10}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Nieuw wachtwoord"
                      type="password"
                      id="password"
                      onChange={e => setPassword(e.target.value)}
                      autoComplete="new-password"
                    />
                  </Grid>
                )}
                {status !== 'success' && (
                  <Grid item xs={12} md={10}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="passwordConfirm"
                      label="Bevestig nieuw wachtwoord"
                      type="password"
                      id="passwordConfirm"
                      onChange={e => setPasswordConfirm(e.target.value)}
                      autoComplete="new-password"
                    />
                  </Grid>
                )}
                {status !== 'success' && (
                  <Grid item md={10} sx={{ marginTop: '15px' }}>
                    <Button type="submit" variant="contained" color="primary">
                      {t('translation.auth.createpw.submitBTN')}
                    </Button>
                  </Grid>
                )}
              </Grid>
              {status === 'error' && <Alert severity="error">{t('translation.auth.createpw.error')}</Alert>}
              {status === 'success' && (
                <Alert severity="success">{t('translation.auth.createpw.succes')}</Alert>
              )}
            </form>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CreatePasswordFormDesktop;
