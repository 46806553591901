/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseNote } from '../models/BaseNote';
import type { Body_add_attachment_notes__note_id__add_attachment_patch } from '../models/Body_add_attachment_notes__note_id__add_attachment_patch';
import type { GenericStatus } from '../models/GenericStatus';
import type { NoteEdit } from '../models/NoteEdit';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NotesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Edit Note
     * @param noteId
     * @param requestBody
     * @returns BaseNote Successful Response
     * @throws ApiError
     */
    public patchNotesEditNote(
        noteId: number,
        requestBody: NoteEdit,
    ): CancelablePromise<BaseNote> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/notes/edit/{note_id}',
            path: {
                'note_id': noteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Attachment
     * @param noteId
     * @param formData
     * @returns BaseNote Successful Response
     * @throws ApiError
     */
    public patchNotesAddAttachment(
        noteId: number,
        formData: Body_add_attachment_notes__note_id__add_attachment_patch,
    ): CancelablePromise<BaseNote> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/notes/{note_id}/add_attachment',
            path: {
                'note_id': noteId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Attachment
     * @param noteId
     * @returns BaseNote Successful Response
     * @throws ApiError
     */
    public deleteNotesRemoveAttachment(
        noteId: number,
    ): CancelablePromise<BaseNote> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/notes/{note_id}/remove_attachment',
            path: {
                'note_id': noteId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Note
     * @param noteId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteNotesRemoveNote(
        noteId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/notes/{note_id}',
            path: {
                'note_id': noteId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Note
     * @param noteId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchNotesRestoreNote(
        noteId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/notes/{note_id}/restore',
            path: {
                'note_id': noteId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Pin Note
     * @param noteId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchNotesPinNote(
        noteId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/notes/{note_id}/pin',
            path: {
                'note_id': noteId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unpin Note
     * @param noteId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchNotesUnpinNote(
        noteId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/notes/{note_id}/unpin',
            path: {
                'note_id': noteId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
