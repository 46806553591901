import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, Theme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { LabelSource } from 'functions/Hooks/useLabel';

import App from './App';
import { UserSource } from './functions/Hooks/useUser';
import reportWebVitals from './reportWebVitals';

import 'index.css';
import './i18n';
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

/**
 * Only initialize Sentry when the code is a production build (so it's not development).
 * You snould know that the environment is set by the hostname.
 */
if (process.env.NODE_ENV === 'production') {
  const hostname = window.location.hostname;
  let environment = '';

  if (hostname === 'acceptatie.studyworks.nl') {
    environment = 'acceptance';
  } else if (hostname === 'spz-frontend.studyworks.nl') {
    environment = 'staging-spz';
  } else if (
    [
      'portaal.studyworks.nl',
      'portaal.vlakbijles.com',
      'portaal.online-examentraining.com',
      'portaal.online-bijles.com',
      'portaal.bijleseu.nl',
    ].includes(hostname)
  ) {
    environment = 'production';
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: environment,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/production\.api\.core\.studyworks\.nl\//,
      /^https:\/\/preview\.core\.studyworks\.nl\//,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <CssBaseline />
    <BrowserRouter>
      <UserSource>
        <link href="https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css" rel="stylesheet" />
        <link rel="stylesheet" href="//cdn.quilljs.com/1.2.6/quill.snow.css" />
        <LabelSource>
          <App />
        </LabelSource>
      </UserSource>
    </BrowserRouter>
  </StyledEngineProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
