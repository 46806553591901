import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';

import { Badge, Person, Schedule, School, SupervisorAccount } from '@mui/icons-material';
import { Avatar, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

import useUser from 'functions/Hooks/useUser';

interface WelcomeProps {}

const Welcome: FunctionComponent<WelcomeProps> = () => {
  const { user } = useUser();

  const handleNext = (event: CustomEvent<() => void>) => {
    event.detail();
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext as EventListener);

    return () => {
      document.removeEventListener('onNext', handleNext as EventListener);
    };
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2">Welkom in het nieuwe portaal</Typography>
        {user?.coach ? (
          <Typography>
            Wij hebben, zoals je weet, het portaal een upgrade gegeven. Sommige dingen zijn
            hetzelfde gebleven maar een hoop is ook veranderd. Met deze veranderingen zijn gegevens
            die wij van jou hadden misschien niet meer kloppend. Wij willen graag met jou samen door
            jou gegevens doorlopen om te kijken of wij wat hebben gemist.
          </Typography>
        ) : (
          <Typography>
            Wij hebben, zoals u weet, het portaal een upgrade gegeven. Sommige dingen zijn hetzelfde
            gebleven maar een hoop is ook veranderd. Met deze veranderingen zijn gegevens die wij
            van u hadden misschien niet meer kloppend. Wij willen graag met u samen door uw gegevens
            doorlopen om te kijken of wij wat hebben gemist.
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">Dit zijn de gegevens die we willen controleren.</Typography>
        {user?.coach ? (
          <>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <SupervisorAccount />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Bijles geven"
                secondary="Heb je eerder les gegeven en met welk kind?"
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Badge />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Persoonlijke gegevens"
                secondary="Gegevens zoals je naam, woongegevens en BSN/IBAN"
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Schedule />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="beschikbaar" secondary="Wanneer je waar beschikbaar bent." />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <School />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Schoolgegevens"
                secondary="Je huidige opleiding en vakken waarin je graag wilt les in geven."
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Person />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Profiel"
                secondary="Gegevens die vertellen wie jij als coach bent"
              />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <SupervisorAccount />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Huidige lessen"
                secondary="Heeft uw kind momenteel bijles? En zo ja, met welke coach?"
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Badge />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Beschikbaarheid"
                secondary="Check of de beschikbare uren van uw kinderen nog kloppen."
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Schedule />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Persoonlijke informatie"
                secondary="Is al uw persoonlijke informatie nog aanwezig? Zo niet, willen wij graag dat u deze nog even aanvult."
              />
            </ListItem>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Welcome;
