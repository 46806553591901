import * as React from 'react';
import { useEffect, useState } from 'react';

import { Alert, FormHelperText, Grid, InputProps, MenuItem, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { appClient } from 'App';
import { CoachPrivateInformation, RegularCoach } from 'assets';
import { TextField } from 'components/Elements/Forms/Inputs';
import {
  checkBSN,
  checkIBAN,
  checkMail,
  checkPhone,
  checkZip,
} from 'functions/HelperFunctions/Regex';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useUser, { IUser } from 'functions/Hooks/useUser';


import EditForm from '../Components/EditForm';

interface PersonalDetailsProps {}

type userProps = 'first_name' | 'last_name' | 'email_address';
const userInfoToCheck: userProps[] = ['first_name', 'last_name', 'email_address'];
type coachProps = 'phone_number' | 'city' | 'street' | 'zipcode' | 'bsn' | 'iban' | 'gender';
const coachInfoToCheck: coachProps[] = [
  'phone_number',
  'city',
  'street',
  'zipcode',
  'bsn',
  'iban',
  'gender',
];
interface propsToValidateType {
  [key: string]: (value: any) => RegExpMatchArray | boolean | null;
}
const propsToValidate: propsToValidateType = {
  email_address: checkMail,
  phone_number: checkPhone,
  zipcode: checkZip,
  bsn: checkBSN,
  iban: checkIBAN,
};

type CombinedCoach = RegularCoach & CoachPrivateInformation;

const PersonalDetails: React.FunctionComponent<PersonalDetailsProps> = () => {
  const { user } = useUser();
  const [userChange, setUserChange] = useState(user);
  const [coachChange, setCoachChange] = useState<CombinedCoach>();
  const { enqueueSnackbar } = useSnackbar();
  const width = useWidth();
  const history = useHistory();

  const handleNext = async (event: CustomEvent<() => void>) => {
    for (let index = 0; index < userInfoToCheck.length; index++) {
      const key = userInfoToCheck[index];
      const value = userChange?.[key];
      if (!value) return enqueueSnackbar(`${key} is nog niet ingevuld`, { variant: 'warning' });
      if (Object.keys(propsToValidate).includes(key) && !propsToValidate[key](value))
        return enqueueSnackbar(`${key} is niet correct ingevuld`, { variant: 'error' });
    }

    appClient.auth.patchAuthEditUser(user?.id || 0, userChange as IUser);

    for (let index = 0; index < coachInfoToCheck.length; index++) {
      const key = coachInfoToCheck[index];
      const value = coachChange?.[key];
      if (!value) return enqueueSnackbar(`${key} is nog niet ingevuld`, { variant: 'warning' });
      if (Object.keys(propsToValidate).includes(key) && !propsToValidate[key](value))
        return enqueueSnackbar(`${key} is nog correct ingevuld`, { variant: 'error' });
    }

    appClient.coaches.patchCoachesEditCoach(coachChange?.id || 0, coachChange as RegularCoach);

    // let response = await checkZipcode(coachChange?.zipcode);

    // if (response.length === 0) {
    //   enqueueSnackbar("Uw postcode lijkt niet te klopen. Controleer deze nog een keer.", {variant: "error"})
    //   return;
    // }

    if (user?.email_address !== userChange?.email_address) {
      history.push('/login');
    }

    event.detail();
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext as unknown as EventListener);

    return () => {
      document.removeEventListener('onNext', handleNext as unknown as EventListener);
    };
  }, [userChange, coachChange]);

  useEffect(() => {
    appClient.auth.getAuthGetCurrentUser().then(setUserChange);
  }, []);

  useEffect(() => {
    appClient.coaches.getCoachesViewCoachViaId(user!.coach!.id).then(baseCoach => {
      appClient.coaches
        .getCoachesViewCoachViaIdAdvancedPrivate(user!.coach!.id)
        .then(privateCoach => {
          setCoachChange({
            ...baseCoach,
            ...privateCoach,
          });
        });
    });
  }, [user]);

  const handleChange: InputProps['onChange'] = ({ target: { name, value } }) => {
    if (name.includes('user')) {
      setUserChange({
        ...(userChange as IUser),
        [name.replace('user.', '') as userProps]: value,
      });
    } else {
      setCoachChange({
        ...(coachChange as RegularCoach),
        [name as coachProps]: value,
        id_card_back_key: null,
        id_card_front_key: null,
        bsn: null,
        contract: null,
        iban: null,
      });
    }
  };

  return (
    <Grid container spacing={1} justifyContent="">
      <Grid item xs={12}>
        <Alert severity="warning">Let op dat je geslacht correct staat</Alert>
      </Grid>
      <EditForm
        title="Identiteit"
        subheader="Gevevens die horen bij jouw profiel"
        ComponentsWhenEditing={() => (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                onChange={handleChange}
                label="Voornaam"
                name="user.first_name"
                value={userChange?.first_name || ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                onChange={handleChange}
                label="Achternaam"
                name="user.last_name"
                value={userChange?.last_name || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                onChange={handleChange}
                label="Geslacht"
                name="gender"
                value={coachChange?.gender || ''}>
                <MenuItem value="mannelijk">mannelijk</MenuItem>
                <MenuItem value="vrouwelijk">vrouwelijk</MenuItem>
                <MenuItem value="overig">overig</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                onChange={handleChange}
                label="Email"
                error={Boolean(userChange?.email_address) && !checkMail(userChange?.email_address)}
                name="user.email_address"
                value={userChange?.email_address || ''}
              />
              <FormHelperText sx={{ marginLeft: '15px' }}>
                *Als je dit wilt wijzigen, moet je na het wijzigen opnieuw inloggen
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                onChange={handleChange}
                label="Telefoonnummer"
                error={Boolean(coachChange?.phone_number) && !checkPhone(coachChange?.phone_number)}
                name="phone_number"
                value={coachChange?.phone_number || ''}
              />
            </Grid>
          </>
        )}
        ComponentsWhenNotEditing={() => (
          <>
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Voornaam
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {userChange?.first_name}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Achternaam
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign={'right'} variant="h4" color="initial">
                {userChange?.last_name}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Geslacht
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography noWrap textAlign="right" variant="h4" color="initial">
                {coachChange?.gender}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Telefoon
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography noWrap textAlign="right" variant="h4" color="initial">
                {coachChange?.phone_number}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                E-mailadres
              </Typography>
            </Grid>
            <Grid item xs={12} sm>
              <Typography
                noWrap
                textAlign={width === 'xs' ? 'left' : 'right'}
                variant="h4"
                color="initial">
                {userChange?.email_address}
              </Typography>
            </Grid>
          </>
        )}
      />
      <EditForm
        title="Adresgegevens"
        subheader="Vul je eigen naam en woonplaats in"
        ComponentsWhenEditing={() => (
          <>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                value={coachChange?.city || ''}
                fullWidth
                label="Woonplaats"
                name="city"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                onChange={handleChange}
                value={coachChange?.street || ''}
                fullWidth
                label="Staatnaam en huisnummer"
                name="street"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                onChange={handleChange}
                value={coachChange?.zipcode || ''}
                error={Boolean(coachChange?.zipcode) && !checkZip(coachChange?.zipcode)}
                fullWidth
                label="Postcode"
                name="zipcode"
              />
            </Grid>
          </>
        )}
        ComponentsWhenNotEditing={() => (
          <>
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Woonplaats
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {coachChange?.city}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Adres
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {coachChange?.street}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Postcode
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {coachChange?.zipcode}
              </Typography>
            </Grid>
          </>
        )}
      />
      <EditForm
        title="Bankgegevens"
        subheader="Deze gegevens hebben we nodig voor je contract"
        ComponentsWhenEditing={() => (
          <>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                value={coachChange?.bsn || ''}
                error={Boolean(coachChange?.bsn) && !checkBSN(coachChange?.bsn)}
                fullWidth
                label="BSN"
                name="bsn"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleChange}
                value={coachChange?.iban || ''}
                error={Boolean(coachChange?.iban) && !checkIBAN(coachChange?.iban)}
                fullWidth
                label="IBAN"
                name="iban"
              />
            </Grid>
          </>
        )}
        ComponentsWhenNotEditing={() => (
          <>
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Bsn
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {coachChange?.bsn}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Iban
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {coachChange?.iban}
              </Typography>
            </Grid>
          </>
        )}
      />
      <Grid item xs={12} />
    </Grid>
  );
};

export default PersonalDetails;
