/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedStub } from '../models/AdvancedStub';
import type { BaseNote } from '../models/BaseNote';
import type { BasePipeLog } from '../models/BasePipeLog';
import type { BaseStub } from '../models/BaseStub';
import type { BaseTag } from '../models/BaseTag';
import type { Body_add_attachement_stubs_stub__stub_secret_key__add_attachement__dict_key__post } from '../models/Body_add_attachement_stubs_stub__stub_secret_key__add_attachement__dict_key__post';
import type { Body_add_signature_stubs_stub__stub_secret_key__signature_post } from '../models/Body_add_signature_stubs_stub__stub_secret_key__signature_post';
import type { GenericStatus } from '../models/GenericStatus';
import type { NoteIn } from '../models/NoteIn';
import type { SecretKeyType } from '../models/SecretKeyType';
import type { StubCoachIn } from '../models/StubCoachIn';
import type { StubEdit } from '../models/StubEdit';
import type { StubIn } from '../models/StubIn';
import type { UserIn } from '../models/UserIn';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StubsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Stub
     * @param requestBody
     * @returns BaseStub Successful Response
     * @throws ApiError
     */
    public postStubsCreateStub(
        requestBody: StubIn,
    ): CancelablePromise<BaseStub> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/stub/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Coach Stub
     * @param email
     * @param requestBody
     * @returns BaseStub Successful Response
     * @throws ApiError
     */
    public postStubsCreateCoachStub(
        email: string,
        requestBody: StubCoachIn,
    ): CancelablePromise<BaseStub> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/stub/create/coach',
            query: {
                'email': email,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Guardian Stub
     * @param email
     * @param labelId
     * @returns BaseStub Successful Response
     * @throws ApiError
     */
    public postStubsCreateGuardianStub(
        email: string,
        labelId: number,
    ): CancelablePromise<BaseStub> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/stub/create/guardian',
            query: {
                'email': email,
                'label_id': labelId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Stub
     * @param stubSecretKey
     * @param requestBody
     * @returns BaseStub Successful Response
     * @throws ApiError
     */
    public postStubsUpdateStub(
        stubSecretKey: string,
        requestBody: StubEdit,
    ): CancelablePromise<BaseStub> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/stub/{stub_secret_key}/update',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Stub
     * @param stubSecretKey
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public postStubsDeleteStub(
        stubSecretKey: string,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/stub/{stub_secret_key}/delete',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Pipe
     * @param stubSecretKey
     * @param pipeId
     * @returns AdvancedStub Successful Response
     * @throws ApiError
     */
    public postStubsChangePipe(
        stubSecretKey: string,
        pipeId: number,
    ): CancelablePromise<AdvancedStub> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/stub/{stub_secret_key}/change_pipe/{pipe_id}',
            path: {
                'stub_secret_key': stubSecretKey,
                'pipe_id': pipeId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Pipe Simple
     * @param stubSecretKey
     * @param pipeId
     * @returns BaseStub Successful Response
     * @throws ApiError
     */
    public postStubsChangePipeSimple(
        stubSecretKey: string,
        pipeId: number,
    ): CancelablePromise<BaseStub> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/stub/{stub_secret_key}/change_pipe/{pipe_id}/simple',
            path: {
                'stub_secret_key': stubSecretKey,
                'pipe_id': pipeId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tag Stub
     * @param stubSecretKey
     * @param tagId
     * @returns AdvancedStub Successful Response
     * @throws ApiError
     */
    public postStubsTagStub(
        stubSecretKey: string,
        tagId: number,
    ): CancelablePromise<AdvancedStub> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/stub/{stub_secret_key}/tag/{tag_id}',
            path: {
                'stub_secret_key': stubSecretKey,
                'tag_id': tagId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Untag Stub
     * @param stubSecretKey
     * @param tagId
     * @returns AdvancedStub Successful Response
     * @throws ApiError
     */
    public deleteStubsUntagStub(
        stubSecretKey: string,
        tagId: number,
    ): CancelablePromise<AdvancedStub> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/stubs/stub/{stub_secret_key}/tag/{tag_id}',
            path: {
                'stub_secret_key': stubSecretKey,
                'tag_id': tagId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Attachement
     * @param dictKey
     * @param stubSecretKey
     * @param formData
     * @returns BaseStub Successful Response
     * @throws ApiError
     */
    public postStubsAddAttachement(
        dictKey: string,
        stubSecretKey: string,
        formData: Body_add_attachement_stubs_stub__stub_secret_key__add_attachement__dict_key__post,
    ): CancelablePromise<BaseStub> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/stub/{stub_secret_key}/add_attachement/{dict_key}',
            path: {
                'dict_key': dictKey,
                'stub_secret_key': stubSecretKey,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Stub
     * @param stubSecretKey
     * @returns BaseStub Successful Response
     * @throws ApiError
     */
    public getStubsReadStub(
        stubSecretKey: string,
    ): CancelablePromise<BaseStub> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stubs/stub/{stub_secret_key}/read',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Stub Advanced
     * @param stubSecretKey
     * @returns AdvancedStub Successful Response
     * @throws ApiError
     */
    public getStubsReadStubAdvanced(
        stubSecretKey: string,
    ): CancelablePromise<AdvancedStub> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stubs/stub/{stub_secret_key}/read/advanced',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Stub By Id Advanced
     * @param stubId
     * @returns AdvancedStub Successful Response
     * @throws ApiError
     */
    public getStubsReadStubByIdAdvanced(
        stubId: number,
    ): CancelablePromise<AdvancedStub> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stubs/stub/by_id/{stub_id}/read/advanced',
            path: {
                'stub_id': stubId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Stub Contract
     * @param stubSecretKey
     * @returns any Successful Response
     * @throws ApiError
     */
    public getStubsGetStubContract(
        stubSecretKey: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stubs/stub/{stub_secret_key}/contract',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Signature
     * @param stubSecretKey
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public postStubsAddSignature(
        stubSecretKey: string,
        formData: Body_add_signature_stubs_stub__stub_secret_key__signature_post,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/stub/{stub_secret_key}/signature',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Contracts
     * @param stubSecretKey
     * @returns any Successful Response
     * @throws ApiError
     */
    public getStubsViewContracts(
        stubSecretKey: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stubs/stub/view/contracts/{stub_secret_key}',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Complete Contracts
     * @param stubSecretKey
     * @returns any Successful Response
     * @throws ApiError
     */
    public getStubsViewCompleteContracts(
        stubSecretKey: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stubs/stub/view/complete/contracts/{stub_secret_key}',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Transfer Stub
     * @param stubSecretKey
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public patchStubsTransferStub(
        stubSecretKey: string,
        requestBody: UserIn,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/stubs/stub/{stub_secret_key}/transfer',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View All Tags
     * @returns any Successful Response
     * @throws ApiError
     */
    public getStubsViewAllTags(): CancelablePromise<(Array<BaseTag> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stubs/stub/tags/all',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * View Stub Notes
     * @param stubSecretKey
     * @returns any Successful Response
     * @throws ApiError
     */
    public getStubsViewStubNotes(
        stubSecretKey: string,
    ): CancelablePromise<(Array<BaseNote> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stubs/view/{stub_secret_key}/notes',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Stub Note
     * @param stubSecretKey
     * @param requestBody
     * @returns BaseNote Successful Response
     * @throws ApiError
     */
    public postStubsAddStubNote(
        stubSecretKey: string,
        requestBody: NoteIn,
    ): CancelablePromise<BaseNote> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/view/{stub_secret_key}/notes',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pipe Log
     * @param stubSecretKey
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getStubsGetPipeLog(
        stubSecretKey: string,
        offset?: number,
        limit: number = 500,
    ): CancelablePromise<(Array<BasePipeLog> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stubs/stubs/{stub_secret_key}/pipe_log',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Incomplete Transfer
     * @param stubSecretKey
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public postStubsIncompleteTransfer(
        stubSecretKey: string,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/stubs/view/{stub_secret_key}/incomplete_transfer',
            path: {
                'stub_secret_key': stubSecretKey,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Secret Key Type
     * @param secretKey
     * @returns SecretKeyType Successful Response
     * @throws ApiError
     */
    public getStubsGetSecretKeyType(
        secretKey: string,
    ): CancelablePromise<SecretKeyType> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/stubs/type/{secret_key}',
            path: {
                'secret_key': secretKey,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
