import React from 'react';

// *******

// Desktop Stages
// paspoort: 1
// persoonlijke gegevens: 2
// school: 3
// voorkeuren: 4
// contract: 5

// Mobile Stages

// paspoort: 1
// naam: 2
// idgegevens: 3
// adres: 4
// Contact: 5
// bank: 6
// bsn: 7
// school: 8
// locatie: 9
// vakken: 10
// beschikbaarheid: 11
// contract: 12

// ******

export const initialState = {
  phone_number: '',
  gender: 'mannelijk',
  street: '',
  city: '',
  zipcode: '',
  email_address: '',
  BSN: '',
  country: '',
  iban: '',
  date_of_birth: '',
  emergency_phone_number: '',
  is_pabo: false,
  current_study: '',
  school_level: '',
  school_year: '',
  id_card_front_key: '',
  id_card_back_key: '',
  isUsingLocation: true,
  disciplines: [],
  availabilities: [],
  user_locations: [],
};

export const reducer = (state, { type, ...action }) => {
  switch (type) {
    case 'Initilize':
      return {
        ...state,
        ...action,
      };
    case 'reset':
      return initialState;
    case 'update':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'updateVakken': {
      let disciplines = action.disciplines;
      console.log(disciplines);
      return {
        ...state,
        disciplines,
      };
    }
    case 'createAvailability': {
      let availabilities = state.availabilities || [];
      let item = availabilities.push(action.item);
      return {
        ...state,
        availabilities,
      };
    }
    case 'updateAvailability': {
      let availabilities = state.availabilities;
      availabilities[action.index] = action.item;
      return {
        ...state,
        availabilities,
      };
    }
    case 'deleteAvailability': {
      let availabilities = state.availabilities;
      availabilities.splice(action.index, 1);
      console.log('test', availabilities);
      return {
        ...state,
        availabilities,
      };
    }
    case 'updateFormOK': {
      let copyState = state.formOK;
      copyState[action.name] = action.value;
      return {
        ...state,
        formOK: copyState,
      };
    }
    default:
      return state;
  }
};

export const Context = React.createContext();
