/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AllCoachCancelCodes {
    COACH_ZIEK = 'coach_ziek',
    STUDENT_ZIEK = 'student_ziek',
    LES_VERZET = 'les_verzet',
    STUDENT_NOSHOW = 'student_noshow',
    COACH_ONVERWACHT = 'coach_onverwacht',
    COACH_NOSHOW = 'coach_noshow',
    COACH_KAN_NIET = 'coach_kan_niet',
    COACH_NIET_GEINTERESSEERD = 'coach_niet_geinteresseerd',
    OUDER_ANNULERING = 'ouder_annulering',
}
