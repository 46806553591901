import * as React from 'react';
import { useEffect, useState } from 'react';

import { Avatar, CardHeader, Divider, Grid, Typography } from '@mui/material';

import { appClient } from 'App';
import { RegularCoach } from 'assets';
import { Button, TextField } from 'components/Elements/Forms/Inputs';
import useUser from 'functions/Hooks/useUser';

interface CoachProfileProps {}

const CoachProfile: React.FunctionComponent<CoachProfileProps> = () => {
  const { user } = useUser();
  const [coach, setCoach] = useState<RegularCoach>();

  useEffect(() => {
    appClient.coaches.getCoachesViewCoachViaId(user?.coach?.id || 0).then(setCoach);
  }, [user]);

  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    appClient.coaches
      .patchCoachesAddProfilePicture(coach?.id || 0, { attachment: e?.target?.files?.[0] as File })
      .then(() => {
        appClient.coaches.getCoachesViewCoachViaId(user?.coach?.id || 0).then(setCoach);
      });
  };

  const handleNext = (event: CustomEvent<() => void>) => {
    appClient.coaches.patchCoachesEditCoach(coach?.id || 0, coach as RegularCoach);
    event.detail();
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext as EventListener);

    return () => {
      document.removeEventListener('onNext', handleNext as EventListener);
    };
  }, [coach]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2">Jouw profiel</Typography>
        <Typography variant="body1">
          Je kan hier extra informatie over jezelf aan je profiel toevoegen. Bijvoorbeeld een leuke
          profielfoto, of een leuke biografie. In je biografie kan je vertellen over waarom je
          bijles geeft, wat je hobby&apos;s zijn, etc. Met een compleet profiel wordt je sneller
          gematched met een geschikt bijleskind.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CardHeader
          sx={{ padding: 0 }}
          avatar={
            <Avatar
              src={`${process.env.REACT_APP_PROFILEPHOTOBUCKET}/${coach?.profile_picture}`}
              sx={{
                width: '65px',
                height: '65px',
              }}
            />
          }
          titleTypographyProps={{ variant: 'h2' }}
          title={`${coach?.user.first_name} ${coach?.user.last_name}`}
          subheader={
            <>
              <input
                onChange={handlePhotoUpload}
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button>Foto uploaden</Button>
              </label>
            </>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider>Biografie</Divider>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={coach?.bio}
          onChange={(e: { target: { value: any } }) => {
            setCoach({
              ...(coach as RegularCoach),
              bio: e.target.value,
            });
          }}
          fullWidth
          multiline
          minRows={3}
        />
      </Grid>
      <Grid item xs={12} />
    </Grid>
  );
};

export default CoachProfile;
