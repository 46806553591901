import React from 'react'

import { Grid, Typography } from '@mui/material'
import { t } from 'i18n'

import StandardDialog from 'components/Templates/Pages/StandardDialog'



interface IAdministratioCostInfoProps {
  open: boolean
  setOpen: (value: boolean) => void
}

const AdministratioCostInfo: React.FC<IAdministratioCostInfoProps> = ({ open, setOpen }) => {

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <StandardDialog
      title={t("translation.portalShared.payment.administrationModal.title")}
      handleClose={handleClose}
      open={open}
      setOpen={setOpen}
    >
      <Grid item margin="0px 16px 16px 12px">
        <Typography>
          {t("translation.portalShared.payment.administrationModal.explanation")}
        </Typography>
      </Grid>
    </StandardDialog>
  )
}

export default AdministratioCostInfo