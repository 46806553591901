import React, { useState } from 'react';

import { Edit, MyLocation, Place } from '@mui/icons-material';
import {
  CardActions,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
} from '@mui/material';

import { BaseUserLocation } from 'assets';
import { Button, TextField } from 'components/Elements/Forms/Inputs';
import findMyLocation from 'functions/location';
import { ILocation } from 'interfaces/Location';

interface LocationItemProps extends ILocation {
  index: number;
  readOnly?: boolean;
  onChange: (
    element:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | { target: { name: string; value: string } },
    index: number
  ) => void;
  onSave: (index: number) => void;
  onEdit: (index: number, setOldData: React.Dispatch<React.SetStateAction<ILocation>>) => void;
  onCancel: (index: number, oldData: ILocation) => void;
  onDelete: (index: number) => void;
  validAdressError?: boolean;
  selectedLocation?: BaseUserLocation
  setSelectedLocation?: (location: BaseUserLocation) => void
  isEditing?: boolean;
}

const LocationItem: React.FunctionComponent<LocationItemProps> = ({
  readOnly,
  index,
  onChange,
  onCancel,
  onEdit,
  onDelete,
  onSave,
  validAdressError,
  isEditing,
  isNew,
  selectedLocation,
  setSelectedLocation,
  ...location
}) => {
  const [oldData, setOldData] = useState(location);

  return (isEditing && isEditing === true) ? (
    <ListItem>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Straatnaam & huisnummer"
            onChange={e => onChange(e, index)}
            value={location.street}
            name="street"
          />
        </Grid>
        <Grid item xs>
          <TextField
            fullWidth
            label="Postcode"
            onChange={e => onChange(e, index)}
            value={location.zipcode}
            name="zipcode"
            error={validAdressError}
            helperText={validAdressError ? 'Postcode niet gevonden controleer nog een keer!' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Woonplaats"
            value={location.city}
            name="city"
            onChange={e => onChange(e, index)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      findMyLocation((r: { cityName: string }) =>
                        onChange(
                          {
                            target: {
                              name: 'city',
                              value: r.cityName,
                            },
                          },
                          index
                        )
                      )
                    }>
                    <MyLocation />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CardActions>
            <Button
              type="submit"
              onClick={() => {
                if (location.zipcode && location.street && location.city) setOldData(location);
                onSave(index);
              }}>
              Opslaan
            </Button>
            {!isNew && (
              <Button
                onClick={() => {
                  onCancel(index, oldData);
                }}>
                Annuleren
              </Button>
            )}
            <Button onClick={() => onDelete(index)}>Verwijderen</Button>
          </CardActions>
        </Grid>
      </Grid>
    </ListItem>
  ) : !readOnly ? (
    <ListItemButton>
      {location && setSelectedLocation && (
        <>
          <Radio
            checked={selectedLocation?.id === location.id ? true : false}
            onClick={() => setSelectedLocation(location as unknown as BaseUserLocation)}
          />
        </>
      )}
      <ListItemText
        primary={`${location.city}`}
        secondary={`${location.zipcode}, ${location.street}`}
      />
      <Edit onClick={() => onEdit(index, setOldData)} />
    </ListItemButton>
  ) : (
    <ListItem
      sx={{
        mb: '0px',
        pb: '0px',
      }}>
      <ListItemText
        primary={`${location.city}`}
        secondary={`${location.zipcode}, ${location.street}`}
      />
      <IconButton
        target="_blank"
        href={`https://www.google.com/maps/place/${location.zipcode}+${location.city}+${location.street}`}>
        <Place />
      </IconButton>
    </ListItem>
  );
};

export default LocationItem;
