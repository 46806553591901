import React from 'react';

import { Grid } from '@mui/material';

import LoginFormMobile from './MobileComponent/ResetFormMobile';
import LoginMobileTopHeader from './MobileComponent/ResetMobileTopHeader';

interface IResetMobileProps {
  handleSubmit: () => void;
  status: string;
  setStatus: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
}

const ResetMobile: React.FunctionComponent<IResetMobileProps> = ({
  handleSubmit,
  status,
  setStatus,
  setEmail,
  email,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <LoginMobileTopHeader />
      </Grid>

      <Grid item xs={12}>
        <LoginFormMobile
          handleSubmit={handleSubmit}
          status={status}
          setStatus={setStatus}
          email={email}
          setEmail={setEmail}
        />
      </Grid>
    </Grid>
  );
};

export default ResetMobile;
