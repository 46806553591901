import React, { FormEvent, useState } from 'react';

import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { t, Translation } from 'i18n';

import logoSW from 'assets/img/brand/logo-studyworks-origineel1.png';
import logoOB from 'assets/img/brand/LOGO_OB.png';
import logoBEU from 'assets/img/brand/LogoBijlesEU.png';
import logoVBL from 'assets/img/brand/VlakbijlesLogo.png';
import { Button, TextField } from 'components/Elements/Forms/Inputs';
import useLabel from 'functions/Hooks/useLabel';


interface IChangePasswordFormDesktopProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  state: string;
  password: string;
  passwordComfirm: string;
  setPassword: (value: string) => void;
  setPasswordComfirm: (value: string) => void;
}

const ChangePasswordFormDesktop: React.FunctionComponent<IChangePasswordFormDesktopProps> = ({
  handleSubmit,
  state,
  password,
  passwordComfirm,
  setPassword,
  setPasswordComfirm,
}) => {
  const label = useLabel();
  const [logo, setLogo] = useState<string>();
  const [height, setHeight] = useState<string>();

  React.useEffect(() => {
    if (label) {
      switch (label?.name) {
        case 'StudyWorks':
          setLogo(logoSW);
          setHeight('30px');
          break;
        case 'VlakBijles':
          setLogo(logoVBL);
          setHeight('80px');
          break;
        case 'bijleseu.nl':
          setLogo(logoBEU);
          setHeight('80px');
          break;
        case 'Bijles.EU':
          setLogo(logoBEU);
          setHeight('60px');
          break;
        case 'Online-Bijles.com':
          setLogo(logoOB);
          setHeight('80px');
          break;
        default:
          break;
      }
    }
  }, [label]);

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container>
          <Grid item md={'auto'}>
            <img
              src={logo}
              style={{
                height: height,
                objectFit: 'contain',
              }}
            />
          </Grid>
          <Grid item md={12}>
            <CardHeader
              title={
                <Typography
                  variant="h3"
                  color={'#3C3C3B'}
                  sx={{
                    fontSize: '1.7vw',
                    lineHeight: '1.2',
                  }}>
                  <Translation i18nKey="translation.auth.editpw.header" values={{ label: label?.name }} />{' '}
                  <Typography
                    component="span"
                    variant="h3"
                    color="primary"
                    sx={{ fontSize: '1.7vw' }}>
                    {t('translation.auth.editpw.headerportal')}
                  </Typography>
                  .
                </Typography>
              }
              subheader={
                <Typography
                  sx={{
                    marginTop: '20px',
                    color: '#6F6F6F',
                    fontSize: '1vw',
                  }}>
                  {t('translation.auth.editpw.subheader')}
                </Typography>
              }
              sx={{ paddingLeft: '0px' }}
            />
          </Grid>

          <Grid item xs={12}>
            <form noValidate onSubmit={handleSubmit}>
              {state !== 'success' && (
                <Grid item xs={12} md={8}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    error={password !== passwordComfirm || state === 'error'}
                    required
                    fullWidth
                    name="password"
                    label="Nieuw wachtwoord"
                    type="password"
                    id="password"
                    onChange={event => setPassword(event.target.value)}
                    autoComplete="new-password"
                  />
                </Grid>
              )}
              {state !== 'success' && (
                <Grid item xs={12} md={8}>
                  <TextField
                    error={password !== passwordComfirm || state === 'error'}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="passwordConfirm"
                    label="Bevestig nieuw wachtwoord"
                    type="password"
                    id="passwordConfirm"
                    onChange={event => setPasswordComfirm(event.target.value)}
                    autoComplete="new-password"
                  />
                </Grid>
              )}
              {password !== passwordComfirm && (
                <Alert severity="error">{t('translation.auth.editpw.errorNoMatch')}</Alert>
              )}
              {state === 'error' && <Alert severity="error">{t('translation.auth.editpw.error')}</Alert>}
              {state === 'success' && <Alert severity="success">{t('translation.auth.editpw.succes')}</Alert>}
              {state === 'loading' && <LinearProgress />}
              {state !== 'success' && (
                <Button type="submit" variant="contained" color="primary">
                  <Typography fontSize={'1.2vw'}>{t('translation.auth.editpw.submit')}</Typography>
                </Button>
              )}
            </form>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ChangePasswordFormDesktop;
