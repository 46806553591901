import React, { useEffect, useState } from 'react';

import { Box, Container } from '@mui/material';
import moment from 'moment';

import { appClient } from 'App';
import { EnumPaymentStatus, OrderWithPaymentInformation } from 'assets';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useUser from 'functions/Hooks/useUser';

import PaymentFailedCard from './PaymentFailedCard';
import PaymentSuccesCard from './PaymentSuccesCard';

const PaymentStatusPage = () => {
  const currentWidth = useWidth();
  const { user } = useUser();
  const [latestOrder, setLatestOrder] = useState<OrderWithPaymentInformation>();

  /** fetch orders from user and search for the latest based on date */
  useEffect(() => {
    if (user?.guardian?.id)
      appClient.orders
        .getOrdersGetOrdersForUser(user?.id)
        .then((r: OrderWithPaymentInformation[]) => {
          r.sort(function (a, b) {
            return moment(b.created_at).diff(a.created_at);
          });

          setLatestOrder(r[0]);
        });
  }, []);

  return (
    <Box sx={{ height: '100%', bgcolor: '#0000000D' }}>
      {' '}
      {currentWidth !== 'xs' ? (
        <Container maxWidth={'sm'} sx={{ paddingTop: '10%' }}>
          {latestOrder?.payment_status === EnumPaymentStatus.EXPIRED_OR_FAILED && (
            <PaymentFailedCard order={latestOrder} />
          )}
          {latestOrder?.payment_status === EnumPaymentStatus.OPEN && (
            <PaymentFailedCard order={latestOrder} />
          )}
          {latestOrder?.payment_status === EnumPaymentStatus.PAID && <PaymentSuccesCard />}
        </Container>
      ) : (
        <PaymentSuccesCard />
      )}
    </Box>
  );
};

export default PaymentStatusPage;
