import React, { FormEvent, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material';
import { t, Translation } from 'i18n';

import { Button, TextField } from 'components/Elements/Forms/Inputs';
import useLabel from 'functions/Hooks/useLabel';
import useUser from 'functions/Hooks/useUser';

import TwoFPSetup from '../../TwoSetup';


interface ILoginFormMobileProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  error: boolean;
  blocked: boolean;
  status: string;
  setStatus: (value: string) => void;
}

const LoginFormMobile: React.FunctionComponent<ILoginFormMobileProps> = ({
  handleSubmit,
  error,
  blocked,
  status,
  setStatus,
}) => {
  const label = useLabel();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { isLoadingUser } = useUser();

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container>
          <Grid item md={12}>
            <CardHeader
              title={
                <Typography
                  variant="h3"
                  color={'#3C3C3B'}
                  sx={{
                    fontSize: '22px',
                    lineHeight: '1.2',
                  }}>
                  <Translation i18nKey="translation.auth.login.header" values={{ label: label?.name }} />
                  <Typography
                    component="span"
                    variant="h3"
                    color="primary"
                    sx={{
                      fontSize: '22px',
                      marginLeft: '5px',
                    }}>
                    {t('translation.auth.login.headerportal')}
                  </Typography>
                  .
                </Typography>
              }
              subheader={
                <Typography
                  fontSize={'16px'}
                  sx={{
                    marginTop: '20px',
                    color: '#6F6F6F',
                  }}
                  lineHeight={'1.2'}>
                  {t('translation.auth.login.subheader')}
                </Typography>
              }
              sx={{ paddingTop: '0px' }}
            />
          </Grid>

          <Grid item xs={12} style={{ marginLeft: '10px' }}>
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    disabled={isLoadingUser || status === 'TwoFPRequired'}
                    error={error}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="E-mailadres"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    disabled={isLoadingUser || status === 'TwoFPRequired'}
                    error={error}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Wachtwoord"
                    type={showPassword ? 'normal' : 'password'}
                    id="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="current-password"
                  />
                </Grid>
                <Grid item xs={12} md={8} component={Collapse} in={status === 'TwoFPRequired'}>
                  {status === 'TwoFPRequired' && (
                    <TextField
                      required
                      disabled={isLoadingUser}
                      error={error}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="mfa_code"
                      label="Tweefactorauthenticatie code"
                      name="mfa_code"
                      autoComplete="otp_code"
                      autoFocus
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  {error && <Alert severity="error">{t('translation.auth.login.error')}</Alert>}
                </Grid>
                <Grid item xs={12}>
                  {blocked && <Alert severity="error">{t('translation.auth.login.blocked')}</Alert>}
                </Grid>
                <Grid item xs={12}>
                  <Link href="/Reset" style={{ textDecoration: 'none' }}>
                    <Typography
                      textAlign={'center'}
                      fontSize={'14px'}
                      color={'primary'}
                      sx={{ textDecoration: 'none' }}>
                      {t('translation.auth.login.forgetpwBTN')}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '10px' }}>
                  <Button
                    disabled={isLoadingUser}
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      marginTop: '20px',
                      padding: '10px',
                    }}>
                    <Typography>{t('translation.auth.login.loginBTN')}</Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <TwoFPSetup status={status} onClose={() => setStatus('TwoFPRequired')} />
      </CardContent>
    </Card>
  );
};

export default LoginFormMobile;
