async function findMyLocation(onSucces) {
  // form.preventDefault();
  const keyMap = 'uXiGedjIEZQpS4UjwDoH';
  let cityName, country, state;
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(successFunction, errorFunction, { timeout: 10000 });
  }
  function successFunction(position) {
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    initialize(lat, lng);
  }
  function errorFunction() {
    //alert("Kunnen helaas geen connectie maken met de servers.");
  }
  function initialize(lat, lng) {
    const settings = {
      async: true,
      crossDomain: true,
      url: `https://api.maptiler.com/geocoding/${lng},${lat}.json?key=${keyMap}`,
      method: 'GET',
    };
    const handleResponse = function (status, response) {
      const y = response;
      if (y) {
        cityName = filterByValue(y.features, 'city')[0].text;
        country = filterByValue(y.features, 'The Netherlands');
        state = filterByValue(y.features, 'state')[0].place_name;
        onSucces &&
          onSucces({
            cityName,
            country,
            state,
          });
      }
    };
    const xhr = new XMLHttpRequest();
    const handleStateChange = function () {
      switch (xhr.readyState) {
        case 0: // console.log("Uninitialized");
        case 1: // console.log("loading");
        case 2: // console.log("loaded");
        case 3: // console.log("waiting");
        case 4:
          handleResponse(xhr.status, xhr.response);
          break;
        default:
          alert('error, could not make a connection with the servers');
      }
    };
    xhr.onreadystatechange = handleStateChange;
    xhr.responseType = 'json';
    xhr.open(settings.method, settings.url, settings.async);
    xhr.send(null);
  }
  function locationCheck(countryIsNL) {
    if (countryIsNL) {
      // form.locatie.value = cityName;
    }
  }
  function filterByValue(array, value) {
    return array.filter(
      data => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}

export default findMyLocation;
