/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrderByProvidersEnum {
    ID = 'id',
    NAME = 'name',
    DESCRIPTION = 'description',
    IS_ACTIVE = 'is_active',
}
