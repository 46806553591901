/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseLabel } from '../models/BaseLabel';
import type { Body_attach_logo_labels_label__label_id__attach_logo_patch } from '../models/Body_attach_logo_labels_label__label_id__attach_logo_patch';
import type { GenericStatus } from '../models/GenericStatus';
import type { LabelEdit } from '../models/LabelEdit';
import type { LabelIn } from '../models/LabelIn';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LabelsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * View Label
     * @param labelId
     * @returns BaseLabel Successful Response
     * @throws ApiError
     */
    public getLabelsViewLabel(
        labelId: number,
    ): CancelablePromise<BaseLabel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/labels/label/{label_id}',
            path: {
                'label_id': labelId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Label
     * @param labelId
     * @param requestBody
     * @returns BaseLabel Successful Response
     * @throws ApiError
     */
    public patchLabelsEditLabel(
        labelId: number,
        requestBody: LabelEdit,
    ): CancelablePromise<BaseLabel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/labels/label/{label_id}',
            path: {
                'label_id': labelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Label
     * @param labelId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteLabelsDeleteLabel(
        labelId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/labels/label/{label_id}',
            path: {
                'label_id': labelId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View All Labels
     * @returns any Successful Response
     * @throws ApiError
     */
    public getLabelsViewAllLabels(): CancelablePromise<(Array<BaseLabel> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/labels/labels/all',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Create Label
     * @param requestBody
     * @returns BaseLabel Successful Response
     * @throws ApiError
     */
    public postLabelsCreateLabel(
        requestBody: LabelIn,
    ): CancelablePromise<BaseLabel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/labels/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Attach Logo
     * @param labelId
     * @param formData
     * @returns BaseLabel Successful Response
     * @throws ApiError
     */
    public patchLabelsAttachLogo(
        labelId: number,
        formData: Body_attach_logo_labels_label__label_id__attach_logo_patch,
    ): CancelablePromise<BaseLabel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/labels/label/{label_id}/attach_logo',
            path: {
                'label_id': labelId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
