import { appClient } from 'App';
import { EmailValidationIn, EmailValidationOut } from 'assets';

//Send email to check if sendgrid accepts it as a correct email-address and save it to the data_bundle if it is correct.
export const validateEmailSendGrid = async (emailValue: string): Promise<EmailValidationOut> => {
  let request: EmailValidationOut = {
    status: '',
    found: false,
    verdict: '',
  };

  await appClient.auth
    .postAuthValidateEmailAddress({ email: emailValue } as EmailValidationIn)
    .then((r: EmailValidationOut) => {
      request = r;
    })
    .catch((response: EmailValidationOut) => {
      request = response;
    });

  return request;
};
