import {
  IconButton,
  Dialog,
  DialogContent,
  Typography,
  Box,
  CardHeader,
  Card,
  Container,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import DoneIcon from '@mui/icons-material/Done';

import './Popup.css';
import '../../Persoonlijk/Persoonlijk.css';

const Popup = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <IconButton
        aria-label="close component"
        onClick={() => onClose()}
        style={{
          float: 'right',
          color: '#23282c',
          position: 'absolute',
          top: '4px',
          right: '4px',
        }}>
        <CloseIcon />
      </IconButton>
      <Card>
        <CardHeader
          title="Richtlijnen voor paspoort / ID-kaart"
          subheader="Fotografeer of scan jouw paspoort of ID-kaart en upload deze. Een geschikte foto moet aan de volgende eisen voldoen:"
        />

        <Container sx={{ paddingBottom: '15px' }}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginBottom: '15px',
            }}>
            <DoneIcon color="primary" />
            Pasfoto is helder en zichtbaar.
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginBottom: '15px',
            }}>
            <DoneIcon color="primary" />
            BSN nummer moet volledig leesbaar zijn.
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginBottom: '15px',
            }}>
            <DoneIcon color="primary" />
            Geen bewegend beeld.
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginBottom: '15px',
            }}>
            <DoneIcon color="primary" />
            Geen reflectie op de foto.
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginBottom: '15px',
            }}>
            <DoneIcon color="primary" />
            De foto mag maximaal 5 MB zijn
          </Typography>
        </Container>
      </Card>
    </Dialog>
  );
};

export default Popup;
