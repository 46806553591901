import React from 'react';

import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import theme from 'theme';

import check from 'assets/img/brand/check.png';
import { Button } from 'components/Elements/Forms/Inputs';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

const PaymentSuccesCard = () => {
  const currentWidth = useWidth();
  const history = useHistory();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card elevation={0} sx={{ borderRadius: currentWidth === 'xs' ? 'unset' : '15px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CardHeader
                  title={'Nieuw bijlespakket'}
                  subheader={
                    'Vergroot de leermogelijkheden voor uw kind en kies een voordelig bijlespakket.'
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0} sx={{ borderRadius: currentWidth === 'xs' ? 'unset' : '15px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h1"
                  sx={{ textAlign: 'center', color: theme.palette.primary.main }}>
                  Betaling geslaagd!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={check} style={{ height: '90px', objectFit: 'contain' }} />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography textAlign={'center'} color={'#6F6F6F'}>
                    Uw nieuwe pakket(ten) zijn succesvol toegevoegd aan uw account. Veel plezier met
                    de aankomende bijlessen!
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                  <Grid container justifyContent={'center'} justifyItems={'center'}>
                    <Button
                      fullWidth={currentWidth === 'xs' ? true : false}
                      variant="contained"
                      onClick={() => history.push('/MijnPakket')}>
                      Bekijk mijn pakket
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PaymentSuccesCard;
