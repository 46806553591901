import { Card, Container, FormHelperText, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import '../Persoonlijk/Persoonlijk.css';
import AvailabilityGrid from 'components/Functional/Availability/AvailabilityGrid';
import { useSnackbar } from 'notistack';
import { TextField } from 'components/Elements/Forms/Inputs';
import AvailabilityGrid_V2 from 'components/Functional/Availability/AvailabilityGrid_V2';

const PreferencePage = ({ setData, data }) => {
  const { enqueueSnackbar } = useSnackbar();

  const saveAvailabilityData = value => {
    let change = { ...data };
    change.data_bundle.availabilities.push(value);
    setData(change);
  };

  const removeAvailabilityData = value => {
    let change = { ...data };
    let index = change.data_bundle.availabilities.indexOf(value);
    change.data_bundle.availabilities.splice(index, 1);
    setData(change);
  };

  const handleNext = async event => {
    if (data.data_bundle.availabilities.length <= 0) {
      enqueueSnackbar('Vink tenminste één beschikbaarheid aan.', { variant: 'error' });
      return;
    }

    event.detail();
  };

  React.useEffect(() => {
    document.addEventListener('onNext', handleNext);
    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [data]);

  React.useEffect(() => {
    if (!data?.data_bundle?.availabilities) {
      let change = { ...data };
      change.data_bundle.availabilities = [];
      setData(change);
    }
  }, []);

  return (
    <>
      <Container
        style={{
          backgroundColor: 'white',
          paddingBottom: '10px',
        }}>
        <Typography
          style={{
            paddingTop: '15px',
            marginBottom: '15px',
          }}
          variant="h1">
          Beschikbaarheid
        </Typography>
        <Typography style={{ marginBottom: '15px' }}>
          Vul hier je beschikbaarheid in. Dit gaat om je beschikbaarheid per dag voor een gemiddelde
          week. Ben je over het algemeen op woensdagavond beschikbaar? Dan geef je dat hier aan.
        </Typography>
      </Container>
      <Card
        style={{
          marginTop: '15px',
          marginBottom: '15px',
        }}
        square
        elevation={0}>
        {/* <AvailabilityGrid
          user_locations={data?.data_bundle?.user_locations || []}
          availability={data?.data_bundle?.availabilities || []}
          onAdd={(availability) => {
            saveAvailabilityData(availability);
          }}
          onDelete={(availability) => {
            removeAvailabilityData(availability);
          }}
        /> */}

        <AvailabilityGrid_V2
          availabilities={data?.data_bundle?.availabilities || []}
          handleAdd={saveAvailabilityData}
          handleRemove={removeAvailabilityData}
        />
      </Card>
      <Container style={{ paddingBottom: '10px' }}>
        <TextField
          multiline
          fullWidth
          InputLabelProps={{ shrink: true }}
          minRows={2}
          label="Opmerking"
          value={data.data_bundle.comment}
          onChange={e => {
            setData({
              ...data,
              data_bundle: {
                ...data.data_bundle,
                comment: e.target.value,
              },
            });
          }}
        />
        <FormHelperText>
          Geef hier aan of je een opmerking hebt op je aangegeven beschikbaarheid.
        </FormHelperText>
      </Container>
    </>
  );
};

export default PreferencePage;
