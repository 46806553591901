/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseSector } from '../models/BaseSector';
import type { GenericStatus } from '../models/GenericStatus';
import type { SectorEdit } from '../models/SectorEdit';
import type { SectorIn } from '../models/SectorIn';
import type { SimpleSector } from '../models/SimpleSector';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SPSectorsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Sectors
     * @param limit
     * @param offset
     * @param includeInactive
     * @returns SimpleSector Successful Response
     * @throws ApiError
     */
    public getSPSectorsGetAllSectors(
        limit: number = 30,
        offset?: number,
        includeInactive: boolean = false,
    ): CancelablePromise<Array<SimpleSector>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/sectors/sectors',
            query: {
                'limit': limit,
                'offset': offset,
                'include_inactive': includeInactive,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sector
     * @param sectorId
     * @returns SimpleSector Successful Response
     * @throws ApiError
     */
    public getSPSectorsGetSector(
        sectorId: number,
    ): CancelablePromise<SimpleSector> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/sectors/sector/{sector_id}',
            path: {
                'sector_id': sectorId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Sector
     * @param sectorId
     * @param requestBody
     * @returns SimpleSector Successful Response
     * @throws ApiError
     */
    public patchSPSectorsEditSector(
        sectorId: number,
        requestBody: SectorEdit,
    ): CancelablePromise<SimpleSector> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/sectors/sector/{sector_id}',
            path: {
                'sector_id': sectorId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Sector
     * @param sectorId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteSPSectorsDeleteSector(
        sectorId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/sectors/sector/{sector_id}',
            path: {
                'sector_id': sectorId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sector Detailed
     * @param sectorId
     * @returns BaseSector Successful Response
     * @throws ApiError
     */
    public getSPSectorsGetSectorDetailed(
        sectorId: number,
    ): CancelablePromise<BaseSector> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/sectors/sector/{sector_id}/detailed',
            path: {
                'sector_id': sectorId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Sector
     * @param requestBody
     * @param tagId
     * @returns SimpleSector Successful Response
     * @throws ApiError
     */
    public postSPSectorsCreateSector(
        requestBody: SectorIn,
        tagId?: (number | null),
    ): CancelablePromise<SimpleSector> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/sectors/new',
            query: {
                'tag_id': tagId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Sector Sector
     * @param sectorId
     * @param tagId
     * @returns SimpleSector Successful Response
     * @throws ApiError
     */
    public patchSPSectorsEditSectorSector(
        sectorId: number,
        tagId: number,
    ): CancelablePromise<SimpleSector> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/sectors/sector/{sector_id}/change_tag',
            path: {
                'sector_id': sectorId,
            },
            query: {
                'tag_id': tagId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
