import { Event, LaptopMac, PeopleAlt, Person, Savings } from '@mui/icons-material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Typography, Card, Container, Grid, CardHeader } from '@mui/material';
import People from 'assets/img/brand/pexels-keira.jpg';
import React, { useState, useEffect } from 'react';

const FinishPage = () => {
  return (
    <Container sx={{ marginTop: '15px' }}>
      <Grid container>
        <Grid item md={6} xs={12}>
          <CardHeader title="Welkom bij StudyWorks!" />
          <Typography sx={{ marginLeft: '16px' }}>
            Je hebt een mail van ons gekregen waarmee je toegang tot het coachportaal krijgt. <br />
            <br /> In het portaal kan je vinden:
          </Typography>

          <Timeline>
            <TimelineItem sx={{ '&::before': { display: 'none' } }}>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Event />
                </TimelineDot>
                <TimelineConnector style={{ height: '30px' }} />
              </TimelineSeparator>
              <TimelineContent>
                <Typography style={{ fontWeight: 'bold' }}>Agenda & beschikbaarheid</Typography>
                <Typography>Bekijk aankomende lessen en pas je beschikbaarheid aan.</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={{ '&::before': { display: 'none' } }}>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Savings />
                </TimelineDot>
                <TimelineConnector style={{ height: '30px' }} />
              </TimelineSeparator>
              <TimelineContent>
                <Typography style={{ fontWeight: 'bold' }}>Financiën</Typography>
                <Typography>Bekijk je verwachte vergoeding voor deze maand.</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={{ '&::before': { display: 'none' } }}>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <PeopleAlt />
                </TimelineDot>
                <TimelineConnector style={{ height: '30px' }} />
              </TimelineSeparator>
              <TimelineContent>
                <Typography style={{ fontWeight: 'bold' }}>Bijleskinderen</Typography>
                <Typography>Een overzicht van alle leerlingen aan wie jij bijles geeft.</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={{ '&::before': { display: 'none' } }}>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Person />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Typography style={{ fontWeight: 'bold' }}>Persoonsgegevens</Typography>
                <Typography>Hier kan jij jouw persoonlijke gegevens aanpassen.</Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            borderRadius: '10px',
            backgroundImage: `url(${People})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}></Grid>
      </Grid>
    </Container>
  );
};

export default FinishPage;
