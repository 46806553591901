import React, { useContext, useState } from 'react';

import { OrderWithPaymentInformation } from 'assets';

interface IPaymentStateProviderProps {
  children: React.ReactNode;
}

interface IPaymnetStateContexProps {
  state: OrderWithPaymentInformation | undefined;
  setState: React.Dispatch<React.SetStateAction<OrderWithPaymentInformation | undefined>>;
}

export const PaymentStateContex = React.createContext<IPaymnetStateContexProps | null>(null);

/** Hook for transfer the payment stage between pages */
export const PaymentStateProvider: React.FC<IPaymentStateProviderProps> = ({ children }) => {
  const [state, setState] = useState<OrderWithPaymentInformation | undefined>();

  return (
    <PaymentStateContex.Provider
      value={{
        state,
        setState,
      }}>
      {children}
    </PaymentStateContex.Provider>
  );
};

export const usePaymentStateContext = () => {
  const context = useContext(PaymentStateContex);
  if (!context) {
    throw new Error('useNavContext must be used within a NavContextProvider');
  }

  return context;
};

export default usePaymentStateContext;
