import React, { useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18n';
import theme from 'theme';

import BackgroundBULogo from 'assets/img/LoginImages/BijlesEUInlog.png';
import CollageBEU from 'assets/img/LoginImages/CORE_inlogscherm_collage_BEU.png';
import CollageOB from 'assets/img/LoginImages/CORE_inlogscherm_collage_OB.png';
import CollageSW from 'assets/img/LoginImages/CORE_inlogscherm_collage_SW.png';
import CollageVBL from 'assets/img/LoginImages/CORE_inlogscherm_collage_VBL.png';
import BackgroundOBLogo from 'assets/img/LoginImages/huisjeOB.png';
import BackgroundSWLogo from 'assets/img/LoginImages/logo-studyworks-krans.png';
import BackgroundVBLogo from 'assets/img/LoginImages/VBLInlog.png';
import useLabel from 'functions/Hooks/useLabel';

const CreatePasswordImageWindow = () => {
  const label = useLabel();
  const [backgroundLogo, setBackgroundLogo] = useState<string>();
  const [collage, setCollage] = useState<string>();
  const [opacity, setOpacity] = useState<number>(1);

  React.useEffect(() => {
    if (label) {
      switch (label?.name) {
        case 'StudyWorks':
          setBackgroundLogo(BackgroundSWLogo);
          setCollage(CollageSW);
          setOpacity(0.6);
          break;
        case 'VlakBijles':
          setBackgroundLogo(BackgroundVBLogo);
          setCollage(CollageVBL);
          setOpacity(0.2);
          break;
        case 'bijleseu.nl':
          setBackgroundLogo(BackgroundBULogo);
          setCollage(CollageBEU);
          setOpacity(0.3);
          break;
        case 'Bijles.EU':
          setBackgroundLogo(BackgroundBULogo);
          setCollage(CollageBEU);
          setOpacity(0.3);
          break;
        case 'Online-Bijles.com':
          setBackgroundLogo(BackgroundOBLogo);
          setCollage(CollageOB);
          setOpacity(0.3);
          break;
        default:
          setBackgroundLogo(BackgroundSWLogo);
          setCollage(CollageSW);
          break;
      }
    }
  }, [label]);

  return (
    <Grid
      item
      xs={false}
      sm
      md
      sx={{
        background: `linear-gradient(to left, ${label?.light_color} 0%, ${label?.main_color} 100%)`,
        position: 'relative',
        [theme.breakpoints.up('md')]: { height: '100vh' },
      }}>
      {backgroundLogo && (
        <Box
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: opacity,
            background: `url(${backgroundLogo}) no-repeat center bottom`,
            backgroundSize: '100%',
            zIndex: 1,
          }}
        />
      )}
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <img
            src={collage}
            style={{
              height: '70vh',
              width: 'auto',
              objectFit: 'cover',
              zIndex: 2,
              marginTop: '20px',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: '5px 40px',
            zIndex: 3,
            marginTop: '-40px',
          }}>
          <Typography
            sx={{ width: '400px' }}
            variant="h1"
            fontSize={window.innerWidth > 2000 ? '1.4vw' : '1.7vw'}
            color="white"
            textAlign="center"
            lineHeight={1.2}>
            {t('translation.auth.shared.desktop.image.header')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: '15px 40px',
            zIndex: 3,
          }}
          color="white"
          textAlign="center">
          <Typography fontSize={window.innerWidth > 2000 ? '1vw' : '1.2vw'} sx={{ width: '400px' }}>
            {t('translation.auth.shared.desktop.image.subheader')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: '30px 40px',
            zIndex: 3,
          }}
          color="white"
          textAlign="center"
        />
      </Grid>
    </Grid>
  );
};

export default CreatePasswordImageWindow;
