import React, { useContext } from 'react';

import { Breakpoint, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type BreakpointOrNull = Breakpoint | null;

function useWidth() {
  const theme: Theme = useTheme();
  const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();

  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));

      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

export interface IDeviceDetect {
  isMobile: boolean;
  currentWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const DeviceDetectContext = React.createContext<IDeviceDetect>({
  isMobile: false,
  currentWidth: 'xl',
});

const DeviceDetectSource = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const currentWidth = useWidth();

  return (
    <DeviceDetectContext.Provider
      value={{
        isMobile: currentWidth === 'xs',
        currentWidth,
      }}>
      {children}
    </DeviceDetectContext.Provider>
  );
};

const useDeviceDetect = () => useContext(DeviceDetectContext);

export { DeviceDetectSource, useWidth };
export default useDeviceDetect;
