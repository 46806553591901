import { Grid } from '@mui/material';
import { Box, Container } from '@mui/system';

import { useWidth } from 'functions/Hooks/useDeviceDetect';

import CollectionPaymentCard from './Components/CollectionPaymentCard';
import CollectionPaymentHeader from './Components/CollectionPaymentHeader';

const CollectionPaymentPage = () => {
  const currentWidth = useWidth();

  return (
    <Box sx={{
      bgcolor: '#F2F2F2',
      height: currentWidth === "xs" ? 'calc(100vh - 57px)' : '100vh',
      marginTop: "-65px"
    }}
    >
      <Grid container sx={{ padding: '56px 10px 10px 10px' }} spacing={2}>
        <Grid item xs={12} sx={{ marginTop: '-50px' }}>
          <CollectionPaymentHeader />
        </Grid>

        <Grid item xs={12}>
          {currentWidth !== 'xs' ? (
            <Container maxWidth={'sm'}>
              <CollectionPaymentCard />
            </Container>
          ) : (
            <CollectionPaymentCard />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CollectionPaymentPage;
