import React, { useState } from 'react';

import { Avatar, Card, CardContent, CardHeader, Grid, Slider, Typography } from '@mui/material';

import { BaseDiscipline } from 'assets';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import { IBundleFlowPageProps } from '..';

interface ILessonSubjectCardProps extends IBundleFlowPageProps {
  subjectId: any;
  index: number;
  subjects: BaseDiscipline[];
  subjectGrade?: number;
}

export interface IGrades {
  id: number;
  grade: number;
}

const LessonSubjectCard: React.FunctionComponent<ILessonSubjectCardProps> = ({
  subjectId,
  subjects,
  bundle,
  setBundle,
  subjectGrade,
}) => {
  const currentWidth = useWidth();
  const [grade, setGrade] = useState<number>(5.5);
  const label = useLabel();
  const [show, setShow] = useState<boolean>();

  React.useEffect(() => {
    if (subjectGrade) {
      setGrade(subjectGrade);
    }
  }, [grade]);

  React.useEffect(() => {
    if (
      subjects.find(subject => subjectId === subject.id)?.name === 'Huiswerkbegeleiding' ||
      subjects.find(subject => subjectId === subject.id)?.name === 'Organiseren & Plannen'
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [show]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    const tempData = { ...bundle };
    if (typeof newValue === 'number') {
      setGrade(newValue);

      if (!tempData.data_bundle.students[0].grade) {
        tempData.data_bundle.students[0].grade = [];
        setBundle(tempData);
      }

      if (tempData.data_bundle.students[0].grade.findIndex((x: any) => x.id == subjectId) >= 0) {
        const subjectObject = {
          id: subjectId,
          grade: newValue,
        };

        const foundIndex = tempData.data_bundle.students[0].grade.findIndex(
          (x: any) => x.id == subjectId
        );
        tempData.data_bundle.students[0].grade[foundIndex] = subjectObject;
        setBundle(tempData);
      } else {
        const subjectObject = {
          id: subjectId,
          grade: newValue,
        };

        tempData.data_bundle.students[0].grade.push(subjectObject);
        setBundle(tempData);
      }
    }
  };

  return (
    <>
      {show && (
        <Grid item xs={12} md={6}>
          <Card
            elevation={0}
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '15px',
              paddingBottom: 'unset',
            }}>
            <Grid container>
              <Grid item xs={10}>
                <CardHeader
                  title={subjects.find(subject => subjectId === subject.id)?.name}
                  titleTypographyProps={{
                    fontWeight: 'light',
                    fontSize: '18px',
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Grid container justifyContent={'center'}>
                  <Grid item xs={'auto'}>
                    <Avatar
                      sx={{
                        backgroundColor: label?.main_color,
                        marginTop: '12px',
                        marginRight: '10px',
                      }}>
                      <Typography>{grade}</Typography>
                    </Avatar>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <CardContent
              sx={{
                marginTop: '-25px',
                marginBottom: '-20px',
              }}>
              <Grid container spacing={1} sx={{}}>
                <Grid item xs={1}>
                  <Typography
                    sx={{
                      fontWeight: 'light',
                      marginTop: currentWidth === 'xs' ? '9px' : '2px',
                    }}>
                    1
                  </Typography>
                </Grid>
                <Grid item xs={10} sx={{ paddingRight: '5px' }}>
                  <Slider
                    min={1}
                    step={0.5}
                    max={10}
                    value={grade}
                    // valueLabelDisplay="on"
                    onChange={handleChange}
                    marks
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    sx={{
                      fontWeight: 'light',
                      marginTop: currentWidth === 'xs' ? '9px' : '2px',
                    }}>
                    10
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default LessonSubjectCard;
