import React, { FC, useEffect, useState } from 'react';

import { QuestionMark } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18n';
import { useHistory } from 'react-router-dom';

import { appClient } from 'App';
import {
  EnumOrderType,
  OrderIn,
  OrderItemIn,
  OrderWithPaymentInformation,
  RegularProduct,
} from 'assets';
import { Button } from 'components/Elements/Forms/Inputs';
import useProductStoreContex from 'functions/Hooks/useProductsStore';
import useUser from 'functions/Hooks/useUser';

import AdministratioCostInfo from './AdministratioCostInfo';
import OrderCard from './OrderCard';

interface ICheckOutProps {
  selectedProducts: RegularProduct[];
  selectedPaymentMethod: EnumOrderType;
}
const CheckOut: FC<ICheckOutProps> = ({ selectedPaymentMethod, selectedProducts }) => {
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const user = useUser();
  const history = useHistory();
  const productStore = useProductStoreContex();
  const [hasAdminCosts, setHasAdminCosts] = useState<boolean>(false);

  // Calculate the total price of the selected products
  useEffect(() => {
    GetTotalPrice();
  }, [selectedProducts, selectedPaymentMethod]);

  const GetTotalPrice = async () => {
    let tempTotalPrice = 0;
    selectedProducts.forEach((product: RegularProduct) => {
      tempTotalPrice = tempTotalPrice + product.price;
    });

    /* temp comment is not working on BE */
    if (selectedPaymentMethod === EnumOrderType.TRANSACTION) {
      tempTotalPrice -= 10;
    }

    if (selectedPaymentMethod === EnumOrderType.COLLECTION) {
      tempTotalPrice -= 5;
    }

    if (selectedPaymentMethod === EnumOrderType.INVOICE) {
      tempTotalPrice += 2.5;
    }

    /**adminisation costs */
    if (await CheckAdministrationCost()) {
      tempTotalPrice += 35;
    }

    setTotalPrice(tempTotalPrice);
  };

  const CheckAdministrationCost = (): Promise<boolean> => {
    if (user.user?.guardian?.id) {
      appClient.guardians.getGuardiansGetBundles(user.user?.guardian?.id).then(r => {
        setHasAdminCosts(r.length === 0);

        return r.length === 0;
      });
    }

    return Promise.resolve(false);
  };

  /* temp comment is not working on BE */
  // Get the discount based on the selected payment method
  const getPaymentDiscount = () => {
    let discountText: string = '';
    let discount: number = 0;

    if (selectedPaymentMethod === EnumOrderType.TRANSACTION) {
      discountText = 'Korting iDEAL betaling';
      discount = 10;
    }

    if (selectedPaymentMethod === EnumOrderType.COLLECTION) {
      discountText = 'Korting betalen via automatische incasso';
      discount = 5;
    }

    return (
      <>
        {selectedPaymentMethod === undefined ||
          (selectedPaymentMethod != EnumOrderType.INVOICE && (
            <Grid container>
              <Grid item xs={10}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: '18px', color: '#8AD35D' }}>
                      {discountText}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid container justifyContent={'flex-end'}>
                  <Grid item>
                    <Typography sx={{ fontSize: '18px', color: '#8AD35D' }}>
                      -€{discount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </>
    );
  };

  /* Get the the payment text based on payment method */
  const GetPaymentText = (): string => {
    let text: string = '';

    if (selectedPaymentMethod === EnumOrderType.TRANSACTION) {
      text = t('translation.portalShared.payment.methods.transaction');
    }

    if (selectedPaymentMethod === EnumOrderType.INVOICE) {
      text = t('translation.portalShared.payment.methods.invoice');
    }

    if (selectedPaymentMethod === EnumOrderType.COLLECTION) {
      text = t('translation.portalShared.payment.methods.collection');
    }

    return text;
  };

  // Validate whether a bundle and a paymentmethod have been selected
  const canPressPay = (): boolean => {
    return selectedProducts.length > 0 && selectedPaymentMethod !== undefined;
  };

  /* handle the payment method if a use clicks the payment button */
  const handlePaymentEvent = () => {
    if (selectedPaymentMethod === EnumOrderType.TRANSACTION) {
      handleIdealPayment();
    }

    if (selectedPaymentMethod === EnumOrderType.INVOICE) {
      productStore.setProducsts(selectedProducts);

      history.push('/Invoice');
    }

    if (selectedPaymentMethod === EnumOrderType.COLLECTION) {
      productStore.setProducsts(selectedProducts);
      history.push('/Collection');
    }
  };

  /** Create a order and get the mollie link for  the ideal payment */
  const handleIdealPayment = () => {
    const OrderItem: OrderItemIn[] = [];
    selectedProducts.forEach((product: RegularProduct) => {
      OrderItem.push({ product_id: product.id });
    });

    const orderData: OrderIn = {
      items: OrderItem,
      order_type: selectedPaymentMethod,
    };
    if (user && user.user?.id) {
      appClient.orders
        .postOrdersCreateOrder(user.user?.id, orderData)
        .then((r: OrderWithPaymentInformation) => {
          if (r && r.payment_details) {
            const details: { links: any } = r.payment_details;
            window.location.href = details.links.checkout.href;
          }
        });
    }
  };

  return (
    <>
      <Card elevation={0} sx={{ borderRadius: '15px' }}>
        <CardHeader title={t('translation.portalShared.payment.checkOut.title')} />
        <CardContent sx={{}}>
          <Grid container>
            <Grid item xs={12} sx={{ marginTop: '-15px', borderTop: '2px solid #F2F2F2' }}>
              <Grid container>
                {selectedProducts.map((product: RegularProduct) => {
                  return <OrderCard product={product} />;
                })}
              </Grid>
            </Grid>
            {hasAdminCosts && (
              <Grid
                item
                xs={12}
                sx={{
                  borderBottom:
                    selectedPaymentMethod === EnumOrderType.INVOICE ? 'unset' : '1px solid #F2F2F2',
                  padding:
                    selectedPaymentMethod === EnumOrderType.INVOICE
                      ? '20px 0px 0px 0px'
                      : '20px 0px',
                }}>
                <Grid container>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Stack direction={'row'}>
                          <Typography sx={{ fontSize: '18px', color: '#6F6F6F' }}>
                            {t('translation.common.words.administationCost')}
                          </Typography>
                          <IconButton
                            onClick={() => setOpen(!open)}
                            sx={{
                              backgroundColor: '#F2F2F2',
                              width: '20px',
                              height: '20px',
                              marginLeft: '10px',
                            }}>
                            <QuestionMark sx={{ fontSize: '14px' }} />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container justifyContent={'flex-end'}>
                      <Grid item>
                        <Typography sx={{ fontSize: '18px', color: '#6F6F6F' }}>€35</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* temp comment is not working on BE */}
            {selectedPaymentMethod === EnumOrderType.INVOICE && (
              <Grid item xs={12} sx={{ borderBottom: '1px solid #F2F2F2', padding: '20px 0px' }}>
                <Grid container>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: '18px', color: '#E6451F' }}>
                          {t('translation.common.words.invouceCost')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container justifyContent={'flex-end'}>
                      <Grid item>
                        <Typography sx={{ fontSize: '18px', color: '#E6451F' }}>€2,50</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} sx={{ padding: '10px 0px' }}>
              <TextField
                fullWidth
                placeholder={t('translation.portalShared.payment.checkOut.discountCode')}
                sx={{
                  borderRadius: '20px',
                  '& input::placeholder': {
                    fontSize: '18px',
                  },
                }}
              />
            </Grid>

            {selectedPaymentMethod === undefined ||
              (selectedPaymentMethod != EnumOrderType.INVOICE && (
                <Grid item xs={12} sx={{ padding: '20px 0px' }}>
                  {getPaymentDiscount()}
                </Grid>
              ))}
            {totalPrice > 35 && (
              <Grid item xs={12} sx={{ borderTop: '2px solid #F2F2F2', padding: '20px 0px' }}>
                <Grid container>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#6F6F6F' }}>
                          {t('translation.common.words.total')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container justifyContent={'flex-end'}>
                      <Grid item>
                        <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#6F6F6F' }}>
                          €{totalPrice.toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                variant={'contained'}
                fullWidth
                disabled={!canPressPay()}
                onClick={() => handlePaymentEvent()}
                sx={{ padding: '10px', fontSize: '18px' }}>
                Betaal via {GetPaymentText()}
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '10px' }}>
              {totalPrice < 40 && (
                <Typography sx={{ color: '#E6451F', fontSize: '14px' }}>
                  {t('translation.portalShared.payment.selectPackage')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <AdministratioCostInfo open={open} setOpen={setOpen} />
    </>
  );
};

export default CheckOut;
