
import React, { useContext, useState } from 'react'

import { RegularProduct } from 'assets'

interface IProductStoreProviderProps{
  children: React.ReactNode
}

interface IProductStoreContexProps{
  products:RegularProduct[]
  setProducsts:React.Dispatch<React.SetStateAction<RegularProduct[]>>
}

export const ProductStoreContex = React.createContext<IProductStoreContexProps | null>(null)


export const ProductStoreContextProvider: React.FC< IProductStoreProviderProps> = ({children}) => {
  const [products,setProducsts] =useState<RegularProduct[]>([])



  return (
    <ProductStoreContex.Provider value={{
      products,
      setProducsts
    }}>
        {children}
    </ProductStoreContex.Provider>
  )
}

export const useProductStoreContex = () => {
  const context = useContext(ProductStoreContex)
  if(!context){
    throw new Error("useNavContext must be used within a ProductStoreContex")
  }

  return context
}



export default useProductStoreContex