import moment from 'moment';

import { IExtendedBundleIn } from '../..';

//TODO: Staat dit niet in de verkeerde folder? Dit hoort niet thuis in de pagina's folder.

export class PackageCalculator {
  private score: number;
  private data: IExtendedBundleIn;

  constructor(data: IExtendedBundleIn) {
    this.data = data;
    this.setScore();
  }

  public setData = (data: IExtendedBundleIn) => {
    this.data = data;
    this.setScore();
  };

  public getData = (): IExtendedBundleIn => {
    return this.data;
  };

  private setScore = (): void => {
    if (this.data.data_bundle.students[0]) {
      const student = this.data.data_bundle.students[0];
      this.score =
        this.getGradeScore(student) +
        this.getYearScore(student) +
        this.getMultipleChildScore(this.data.data_bundle);
    }
  };

  public getScore = (): number => {
    return this.score;
  };

  /**get  a score from the grades based on the divrence between the current grade and the targeted grade*/
  private getGradeScore = (student: any): number => {
    let score = 0;

    if (student.grade) {
      student.grade.forEach((grade: any) => {
        if (grade.grade <= 3) {
          score = score + 7;
        } else if (grade.grade > 3 && grade.grade <= 6) {
          score = score + 4;
        } else if (grade.grade >= 6) {
          score = score + 1;
        }
      });
    }

    return score;
  };

  /** get a score from the date based on how many days it is to the test */
  private getDateScore = (student: any): number => {
    let score = 0;
    let dayDiv = 0;

    if (student.date_of_test) {
      dayDiv = moment(student.date_of_test).dayOfYear() - moment().dayOfYear();

      if (dayDiv <= 7) {
        score = 1;
      } else if (dayDiv > 14 && dayDiv <= 30) {
        score = 3;
      } else if (dayDiv >= 31) {
        score = 20;
      }
    }

    return score;
  };

  /** get a score based on how many subject are selected */
  private getSubjectScore = (student: any): number => {
    let score = 0;
    let disciplinesLength = 0;
    if (student.disciplines.length > 0) {
      disciplinesLength = student.disciplines.length;
      if (disciplinesLength <= 1) {
        score = 1;
      } else if (disciplinesLength > 1 && disciplinesLength <= 2) {
        score = 3;
      } else if (disciplinesLength >= 3) {
        score = 5;
      }
    }

    return score;
  };

  /** get a score based on if you are in your final year of your study and in what month you are in your final year */
  private getYearScore = (student: any): number => {
    let score = 0;
    let maxYears = 0;

    if (student.school_level && student.school_year) {
      switch (student.school_level) {
        case 'groep':
          maxYears = 8;
          break;
        case 'vmbo':
          maxYears = 4;
          break;
        case 'vmbotl':
          maxYears = 4;
          break;
        case 'vmbokader':
          maxYears = 4;
          break;
        case 'vmbobasis':
          maxYears = 4;
          break;
        case 'havo':
          maxYears = 5;
          break;
        case 'vwo':
          maxYears = 6;
          break;
        case 'gymnasium':
          maxYears = 6;
          break;
        default:
          maxYears = 8;
          break;
      }

      if (student.school_year == maxYears) {
        if (moment().toDate().getMonth() < 6) {
          score = 1;
        } else {
          score = 3;
        }
      } else {
        score = 6;
      }
    }

    return score;
  };

  /** get a score based on constrains the child has */
  private getBackpackScore = (student: any): number => {
    let score = 0;
    if (student.comment) {
      let c: string = student.comment;
      c = c.toLocaleLowerCase();

      if (c.includes('adhd')) {
        score = score + 1;
      }
      if (c.includes('add')) {
        score = score + 1;
      }
      if (c.includes('autisme')) {
        score = score + 3;
      }
      if (c.includes('pddnos')) {
        score = score + 3;
      }
      if (c.includes('asperger')) {
        score = score + 2;
      }
      if (c.includes('hoogbegaafd')) {
        score = score + 1;
      }
      if (c.includes('faal angst') || c.includes('faalangst') || c.includes('faal-angst')) {
        score = score + 3;
      }
      if (c.includes('dyslexie') && c.includes('dislectisch')) {
        score = score + 2;
      }
      if (c.includes('dyscalculie')) {
        score = score + 2;
      }
    }

    return score;
  };

  private getMultipleChildScore = (bundle: any): number => {
    let score = 0;
    if (bundle.multiple_children) {
      score = 25;
    } else {
      score = 0;
    }

    return score;
  };
}
