/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseFlag } from '../models/BaseFlag';
import type { FlagIn } from '../models/FlagIn';
import type { GenericStatus } from '../models/GenericStatus';
import type { RegularUserWithFlags } from '../models/RegularUserWithFlags';
import type { UserHasFlag } from '../models/UserHasFlag';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FlagsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Flags
     * @returns BaseFlag Successful Response
     * @throws ApiError
     */
    public getFlagsGetAllFlags(): CancelablePromise<Array<BaseFlag>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/flags/all',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * View Flag
     * @param flagId
     * @returns BaseFlag Successful Response
     * @throws ApiError
     */
    public getFlagsViewFlag(
        flagId: number,
    ): CancelablePromise<BaseFlag> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/flags/{flag_id}',
            path: {
                'flag_id': flagId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Flag
     * @param flagId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteFlagsDeleteFlag(
        flagId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/flags/{flag_id}',
            path: {
                'flag_id': flagId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Flag
     * @param requestBody
     * @returns BaseFlag Successful Response
     * @throws ApiError
     */
    public postFlagsCreateFlag(
        requestBody: FlagIn,
    ): CancelablePromise<BaseFlag> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/flags/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Flag
     * @param userId
     * @param flagId
     * @param value
     * @returns RegularUserWithFlags Successful Response
     * @throws ApiError
     */
    public patchFlagsUpdateFlag(
        userId: number,
        flagId: number,
        value: boolean,
    ): CancelablePromise<RegularUserWithFlags> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/flags/user/{user_id}/flag/{flag_id}',
            path: {
                'user_id': userId,
                'flag_id': flagId,
            },
            query: {
                'value': value,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Flag
     * @param userId
     * @param flagId
     * @returns UserHasFlag Successful Response
     * @throws ApiError
     */
    public getFlagsGetFlag(
        userId: number,
        flagId: number,
    ): CancelablePromise<UserHasFlag> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/flags/user/{user_id}/flag/{flag_id}',
            path: {
                'user_id': userId,
                'flag_id': flagId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
