import React from 'react';

import { Grid, Typography } from '@mui/material';

import { AvailabilityIn, BaseAvailability } from 'assets';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

import AvailabilityGridHeader from './Components/AvailabilityGridHeader';
import AvailabilityGridRaster from './Components/AvailabilityGridRaster';


export interface IAvailabilityTimes {
  start_time: string;
  end_time: string;
}

interface IAvailabilityGridProps {
  availabilities?: BaseAvailability[];
  handleAdd?: (item: AvailabilityIn) => void;
  handleRemove?: (item: BaseAvailability) => void;
  disabled?: boolean;
  index?: number;
}

const AvailabilityGrid_V2: React.FunctionComponent<IAvailabilityGridProps> = ({
  availabilities,
  handleAdd,
  handleRemove,
  disabled,
  index,
}) => {
  const currentWidth = useWidth();
  const days = {
    1: 'MA',
    2: 'DI',
    3: 'WO',
    4: 'DO',
    5: 'VR',
    6: 'ZA',
    7: 'ZO',
  };
  const times: IAvailabilityTimes[] = [
    {
      start_time: '07:00',
      end_time: '10:00',
    },
    {
      start_time: '10:00',
      end_time: '13:00',
    },
    {
      start_time: '13:00',
      end_time: '16:00',
    },
    {
      start_time: '16:00',
      end_time: '19:00',
    },
    {
      start_time: '19:00',
      end_time: '22:00',
    },
    {
      start_time: '07:00',
      end_time: '22:00',
    },
  ];

  const handleClickAvailability = (day: number, start_time: string, end_time: string) => {
    const found: BaseAvailability | undefined = availabilities?.find(
      (availability: BaseAvailability) =>
        availability.start_time === start_time &&
        availability.end_time === end_time &&
        availability.day_number == day
    );
    if (found && handleRemove) {
      handleRemove(found);
    } else {
      const newAvailabilityData: AvailabilityIn = {
        day_number: day,
        start_time: start_time,
        end_time: end_time,
      };

      if (
        newAvailabilityData.start_time === '07:00:00' &&
        newAvailabilityData.end_time === '22:00:00'
      ) {
        /** Removes all availabilities in the day if select a full day */

        const found: BaseAvailability[] | undefined = availabilities?.filter(
          (availability: BaseAvailability) => availability.day_number == day
        );

        if (found && handleRemove) {
          found.forEach((f: BaseAvailability) => {
            handleRemove(f);
          });
        }
      } else {
        /** Removes full day if a specific time is selected the same day */
        const found: BaseAvailability | undefined = availabilities?.find(
          (availability: BaseAvailability) =>
            availability.start_time === '07:00:00' &&
            availability.end_time === '22:00:00' &&
            availability.day_number == day
        );

        if (found && handleRemove) {
          handleRemove(found);
        }
      }

      if (handleAdd) {
        handleAdd(newAvailabilityData);
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <AvailabilityGridHeader days={days} />
      </Grid>
      <Grid item xs={2}>
        <Grid container>
          {times.map((time: IAvailabilityTimes, i: number) => {
            return (
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: i === 5 ? '10px' : 'unset',
                  height: currentWidth === 'xs' ? '40px' : '48px',
                  border: '1px solid gray',
                  borderRadius:
                    i === 0 ? '15px 0px 0px 0px' : i === 5 ? '0px 0px  0px 15px' : 'unset',
                }}>
                {i !== 5 ? (
                  <Typography
                    sx={{
                      height: '48px',
                      lineHeight: '48px',
                      fontSize: '16px',
                      textAlign: 'center',
                      whiteSpace: 'pre-wrap',
                      ...(currentWidth === 'xs' && {
                        paddingLeft: '4px',
                        paddingTop: '4px',
                        fontSize: '14px',
                        lineHeight: '16px',
                      }),
                    }}>
                    {`${time.start_time} -${currentWidth === 'xs' ? '\r\n' : ' '}${time.end_time}${
                      currentWidth === 'xs' ? '  ' : ''
                    }`}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      height: '48px',
                      lineHeight: '48px',
                      fontSize: '16px',
                      textAlign: 'center',
                      whiteSpace: 'pre-wrap',
                      ...(currentWidth === 'xs' && {
                        paddingLeft: '4px',
                        paddingTop: '4px',
                        fontSize: '14px',
                        lineHeight: '16px',
                      }),
                    }}>
                    Hele dag
                  </Typography>
                )}
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      <Grid item xs={10}>
        <AvailabilityGridRaster
          availabilities={availabilities}
          days={days}
          times={times}
          handleClickAvailability={handleClickAvailability}
          disabled={disabled}
          index={index}
        />
      </Grid>
    </Grid>
  );
};

export default AvailabilityGrid_V2;
