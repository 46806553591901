/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { schemas__logs__BaseLog } from '../models/schemas__logs__BaseLog';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LogsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Latest Logs
     * @param offset
     * @param limit
     * @returns schemas__logs__BaseLog Successful Response
     * @throws ApiError
     */
    public getLogsGetLatestLogs(
        offset?: number,
        limit: number = 100,
    ): CancelablePromise<Array<schemas__logs__BaseLog>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/logs/latest',
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Latest Logs Of User
     * @param userId
     * @param offset
     * @param limit
     * @returns schemas__logs__BaseLog Successful Response
     * @throws ApiError
     */
    public getLogsGetLatestLogsOfUser(
        userId: number,
        offset?: number,
        limit: number = 100,
    ): CancelablePromise<Array<schemas__logs__BaseLog>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/logs/user/{user_id}/latest',
            path: {
                'user_id': userId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Latest Logs Of Template
     * @param logTemplateId
     * @param offset
     * @param limit
     * @returns schemas__logs__BaseLog Successful Response
     * @throws ApiError
     */
    public getLogsGetLatestLogsOfTemplate(
        logTemplateId: number,
        offset?: number,
        limit: number = 100,
    ): CancelablePromise<Array<schemas__logs__BaseLog>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/logs/template/{log_template_id}/latest',
            path: {
                'log_template_id': logTemplateId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
