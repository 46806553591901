import React from 'react';

import { Grid, Typography } from '@mui/material';
import { t } from 'i18n';

import { RegularProduct } from 'assets';
import { handlePriceDecimalsNL } from 'functions/StringConversions/PriceConversions';

interface IOrderCardProps {
  product: RegularProduct;
}

const OrderCard: React.FC<IOrderCardProps> = ({ product }) => {
  return (
    <Grid item xs={12} sx={{ borderBottom: '1px solid #F2F2F2', padding: '20px 0px' }}>
      <Grid container>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#E67534' }}>
                {product.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '14px', color: '#A2A2A2', marginTop: '5px' }}>
                {product.bundle_details && (
                  <>
                    ({product.bundle_details?.total_hours} {t('translation.common.words.hourForPrice')}{' '}
                    {handlePriceDecimalsNL(product.price / product.bundle_details?.total_hours)}{' '}
                    {t('translation.common.words.perHour')})
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent={'flex-end'}>
            <Grid item sx={{ marginTop: '20%' }}>
              <Typography sx={{ fontSize: '18px', color: '#6F6F6F' }}>€{product.price}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderCard;
