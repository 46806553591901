import React, { FormEvent, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material';
import { t, Translation } from 'i18n';

import logoSW from 'assets/img/brand/logo-studyworks-origineel1.png';
import logoOB from 'assets/img/brand/LOGO_OB.png';
import logoBEU from 'assets/img/brand/LogoBijlesEU.png';
import logoVBL from 'assets/img/brand/VlakbijlesLogo.png';
import { Button, TextField } from 'components/Elements/Forms/Inputs';
import useLabel from 'functions/Hooks/useLabel';
import useUser from 'functions/Hooks/useUser';

import TwoFPSetup from '../../TwoSetup';

interface ILoginFormDesktopProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  error: boolean;
  blocked: boolean;
  status: string;
  setStatus: (value: string) => void;
}

const LoginFormDesktop: React.FunctionComponent<ILoginFormDesktopProps> = ({
  handleSubmit,
  error,
  blocked,
  status,
  setStatus,
}) => {
  const label = useLabel();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { isLoadingUser } = useUser();
  const [logo, setLogo] = useState<string>();
  const [height, setHeight] = useState<string>();

  /** Set states (logo, margin and height) for the logo based on label */
  React.useEffect(() => {
    if (label) {
      switch (label?.name) {
        case 'StudyWorks':
          setLogo(logoSW);
          setHeight('30px');
          break;
        case 'VlakBijles':
          setLogo(logoVBL);
          setHeight('80px');
          break;
        case 'bijleseu.nl':
          setLogo(logoBEU);
          setHeight('80px');
          break;
        case 'Bijles.EU':
          setLogo(logoBEU);
          setHeight('60px');
          break;
        case 'Online-Bijles.com':
          setLogo(logoOB);
          setHeight('80px');
          break;
        default:
          break;
      }
    }
  }, [label]);

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container>
          <Grid item md={'auto'}>
            <img
              src={logo}
              style={{
                height: height,
                objectFit: 'contain',
              }}
            />
          </Grid>
          <Grid item md={12}>
            <CardHeader
              title={
                <Typography
                  variant="h3"
                  color={'#3C3C3B'}
                  sx={{
                    fontSize: window.innerWidth > 2000 ? '1.4vw' : '1.7vw',
                    lineHeight: '1.2',
                  }}>
                  <Translation i18nKey="translation.auth.login.header" values={{ label: label?.name }} />{' '}
                  <Typography
                    component="span"
                    variant="h3"
                    color="primary"
                    sx={{
                      fontSize: window.innerWidth > 2000 ? '1.4vw' : '1.7vw',
                      lineHeight: '1.2',
                    }}>
                    {t('translation.auth.login.headerportal')}
                  </Typography>
                  .
                </Typography>
              }
              subheader={
                <Typography
                  sx={{
                    marginTop: '20px',
                    color: '#6F6F6F',
                    fontSize: '1vw',
                  }}>
                  {t('translation.auth.login.subheader')}
                </Typography>
              }
              sx={{ paddingLeft: '0px' }}
            />
          </Grid>

          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item md={10}>
                  <TextField
                    disabled={isLoadingUser || status === 'TwoFPRequired'}
                    error={error}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="E-mailadres"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </Grid>

                <Grid item md={10}>
                  <TextField
                    disabled={isLoadingUser || status === 'TwoFPRequired'}
                    error={error}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Wachtwoord"
                    type={showPassword ? 'normal' : 'password'}
                    id="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="current-password"
                  />
                </Grid>
                <Grid item xs={12} md={8} component={Collapse} in={status === 'TwoFPRequired'}>
                  {status === 'TwoFPRequired' && (
                    <TextField
                      required
                      disabled={isLoadingUser}
                      error={error}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="mfa_code"
                      label="Tweefactorauthenticatie code"
                      name="mfa_code"
                      autoComplete="otp_code"
                      autoFocus
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  {error && <Alert severity="error">{t('translation.auth.login.error')}</Alert>}
                </Grid>
                <Grid item xs={12}>
                  {blocked && <Alert severity="error">{t('translation.auth.login.blocked')}</Alert>}
                </Grid>
                <Grid item xs={12}>
                  <Link
                    href="/Reset"
                    variant="body2"
                    sx={{ marginLeft: '10px' }}
                    fontSize={window.innerWidth > 2000 ? '0.7vw' : '1vw'}>
                    {t('translation.auth.login.forgetpwBTN')}
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={isLoadingUser}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: '20px' }}>
                    {t('translation.auth.login.loginBTN')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <TwoFPSetup status={status} onClose={() => setStatus('TwoFPRequired')} />
      </CardContent>
    </Card>
  );
};

export default LoginFormDesktop;
