/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedCoach } from '../models/AdvancedCoach';
import type { AvailabilityEdit } from '../models/AvailabilityEdit';
import type { AvailabilityIn } from '../models/AvailabilityIn';
import type { BaseAvailability } from '../models/BaseAvailability';
import type { BaseContract } from '../models/BaseContract';
import type { BaseLesson } from '../models/BaseLesson';
import type { BaseLessonRequestWithOpenStudentRequests } from '../models/BaseLessonRequestWithOpenStudentRequests';
import type { BaseLessonRequestWithStudentRequests } from '../models/BaseLessonRequestWithStudentRequests';
import type { BaseNote } from '../models/BaseNote';
import type { BasePipeLog } from '../models/BasePipeLog';
import type { BaseStudentCoach } from '../models/BaseStudentCoach';
import type { BaseStudentCoachesEdit } from '../models/BaseStudentCoachesEdit';
import type { BaseStudentRequest } from '../models/BaseStudentRequest';
import type { BaseTag } from '../models/BaseTag';
import type { BaseUserLocation } from '../models/BaseUserLocation';
import type { Body_add_grades_attachment_coaches_coach__coach_id__add_grades_attachment_patch } from '../models/Body_add_grades_attachment_coaches_coach__coach_id__add_grades_attachment_patch';
import type { Body_add_id_card_attachment_coaches_coach__coach_id__add_id_card_attachment_post } from '../models/Body_add_id_card_attachment_coaches_coach__coach_id__add_id_card_attachment_post';
import type { Body_add_profile_picture_coaches_coach__coach_id__add_profile_picture_patch } from '../models/Body_add_profile_picture_coaches_coach__coach_id__add_profile_picture_patch';
import type { Body_create_coach_coaches_create_post } from '../models/Body_create_coach_coaches_create_post';
import type { Body_sign_coach_contract_coaches_coach__coach_id__signature_post } from '../models/Body_sign_coach_contract_coaches_coach__coach_id__signature_post';
import type { CoachAccordanceSnapshot } from '../models/CoachAccordanceSnapshot';
import type { CoachEdit } from '../models/CoachEdit';
import type { CoachEditAdvanced } from '../models/CoachEditAdvanced';
import type { CoachPrivateInformation } from '../models/CoachPrivateInformation';
import type { CoachSalary } from '../models/CoachSalary';
import type { CoachTotalHours } from '../models/CoachTotalHours';
import type { CoachUnaccordedMonths } from '../models/CoachUnaccordedMonths';
import type { ContractGeneration } from '../models/ContractGeneration';
import type { FileUploadBase64 } from '../models/FileUploadBase64';
import type { GenericStatus } from '../models/GenericStatus';
import type { KdvFavoriteBranches } from '../models/KdvFavoriteBranches';
import type { NoteIn } from '../models/NoteIn';
import type { RegularCoach } from '../models/RegularCoach';
import type { schemas__accordance_logs__BaseLog } from '../models/schemas__accordance_logs__BaseLog';
import type { SimpleStudentWithGuardian } from '../models/SimpleStudentWithGuardian';
import type { StudentCoachFinancialMontly } from '../models/StudentCoachFinancialMontly';
import type { StudentRequestIn } from '../models/StudentRequestIn';
import type { ToAccordLessonSchema } from '../models/ToAccordLessonSchema';
import type { UserLocationIn } from '../models/UserLocationIn';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CoachesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Coach
     * @param requestBody
     * @returns AdvancedCoach Successful Response
     * @throws ApiError
     */
    public postCoachesCreateCoach(
        requestBody: Body_create_coach_coaches_create_post,
    ): CancelablePromise<AdvancedCoach> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Add Disciplines To Coach
     * @param coachId
     * @param requestBody
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public patchCoachesBulkAddDisciplinesToCoach(
        coachId: number,
        requestBody: Array<number>,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/add_disciplines/bulk',
            path: {
                'coach_id': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Remove Disciplines From Coach
     * @param coachId
     * @param requestBody
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public patchCoachesBulkRemoveDisciplinesFromCoach(
        coachId: number,
        requestBody: Array<number>,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/remove_disciplines/bulk',
            path: {
                'coach_id': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Location To Coach
     * @param coachId
     * @param requestBody
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public postCoachesAddLocationToCoach(
        coachId: number,
        requestBody: UserLocationIn,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/add_location',
            path: {
                'coach_id': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Student To Coach
     * @param coachId
     * @param studentId
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public postCoachesAddStudentToCoach(
        coachId: number,
        studentId: number,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/students/{student_id}/add',
            path: {
                'coach_id': coachId,
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Via Id
     * @param coachId
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachViaId(
        coachId: number,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Coach
     * @param coachId
     * @param requestBody
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public patchCoachesEditCoach(
        coachId: number,
        requestBody: CoachEdit,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}',
            path: {
                'coach_id': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Via Id Advanced
     * @param coachId
     * @returns AdvancedCoach Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachViaIdAdvanced(
        coachId: number,
    ): CancelablePromise<AdvancedCoach> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/advanced',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Coach Advanced
     * @param coachId
     * @param requestBody
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public patchCoachesEditCoachAdvanced(
        coachId: number,
        requestBody: CoachEditAdvanced,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/advanced',
            path: {
                'coach_id': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Via Key
     * @param key
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachViaKey(
        key: string,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/key/{key}',
            path: {
                'key': key,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Via Key Advanced
     * @param key
     * @returns AdvancedCoach Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachViaKeyAdvanced(
        key: string,
    ): CancelablePromise<AdvancedCoach> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/key/{key}/advanced',
            path: {
                'key': key,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Availability To Coach
     * @param coachId
     * @param userLocationId
     * @param requestBody
     * @returns BaseAvailability Successful Response
     * @throws ApiError
     */
    public postCoachesAddAvailabilityToCoach(
        coachId: number,
        userLocationId: number,
        requestBody: AvailabilityIn,
    ): CancelablePromise<BaseAvailability> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/add_availability/location/{user_location_id}',
            path: {
                'coach_id': coachId,
                'user_location_id': userLocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Availability To Coach
     * @param coachId
     * @returns any Successful Response
     * @throws ApiError
     */
    public postCoachesUpdateAvailabilityToCoach(
        coachId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/update_availability/maintain',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit User Location
     * @param userLocationId
     * @param requestBody
     * @returns BaseUserLocation Successful Response
     * @throws ApiError
     */
    public patchCoachesEditUserLocation(
        userLocationId: number,
        requestBody: UserLocationIn,
    ): CancelablePromise<BaseUserLocation> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/location/{user_location_id}/edit',
            path: {
                'user_location_id': userLocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User Location
     * @param userLocationId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteCoachesDeleteUserLocation(
        userLocationId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/coaches/coach/location/{user_location_id}/delete',
            path: {
                'user_location_id': userLocationId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Coach To Inactive
     * @param coachId
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public patchCoachesSetCoachToInactive(
        coachId: number,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/set_inactive',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Availability
     * @param availabilityId
     * @param requestBody
     * @returns BaseAvailability Successful Response
     * @throws ApiError
     */
    public patchCoachesEditAvailability(
        availabilityId: number,
        requestBody: AvailabilityEdit,
    ): CancelablePromise<BaseAvailability> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/availability/{availability_id}/edit',
            path: {
                'availability_id': availabilityId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Availability Location
     * @param availabilityId
     * @param userLocationId
     * @returns BaseAvailability Successful Response
     * @throws ApiError
     */
    public patchCoachesChangeAvailabilityLocation(
        availabilityId: number,
        userLocationId: number,
    ): CancelablePromise<BaseAvailability> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/availability/{availability_id}/change_location/{user_location_id}',
            path: {
                'availability_id': availabilityId,
                'user_location_id': userLocationId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Availability
     * @param availabilityId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteCoachesDeleteAvailability(
        availabilityId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/coaches/coach/availability/{availability_id}/delete',
            path: {
                'availability_id': availabilityId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Pipe For Coach
     * @param pipeId
     * @param coachId
     * @returns AdvancedCoach Successful Response
     * @throws ApiError
     */
    public patchCoachesChangePipeForCoach(
        pipeId: number,
        coachId: number,
    ): CancelablePromise<AdvancedCoach> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/change_pipe/{pipe_id}',
            path: {
                'pipe_id': pipeId,
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Pin Coach
     * @param coachId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchCoachesPinCoach(
        coachId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/pin',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unpin Coach
     * @param coachId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchCoachesUnpinCoach(
        coachId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/unpin',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Coach Unvisible
     * @param coachId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchCoachesSetCoachUnvisible(
        coachId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/set_unvisible',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Coach Visible
     * @param coachId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchCoachesSetCoachVisible(
        coachId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/set_visible',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pipe Log
     * @param coachId
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesGetPipeLog(
        coachId: number,
        offset?: number,
        limit: number = 500,
    ): CancelablePromise<(Array<BasePipeLog> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/pipe_log',
            path: {
                'coach_id': coachId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Schedule
     * @param coachId
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachSchedule(
        coachId: number,
        start?: (string | null),
        end?: (string | null),
    ): CancelablePromise<(Array<BaseLesson> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/schedule',
            path: {
                'coach_id': coachId,
            },
            query: {
                'start': start,
                'end': end,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tag Coach
     * @param coachId
     * @param tagId
     * @returns AdvancedCoach Successful Response
     * @throws ApiError
     */
    public postCoachesTagCoach(
        coachId: number,
        tagId: number,
    ): CancelablePromise<AdvancedCoach> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/tag/{tag_id}',
            path: {
                'coach_id': coachId,
                'tag_id': tagId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Untag Coach
     * @param coachId
     * @param tagId
     * @returns AdvancedCoach Successful Response
     * @throws ApiError
     */
    public deleteCoachesUntagCoach(
        coachId: number,
        tagId: number,
    ): CancelablePromise<AdvancedCoach> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/coaches/coach/{coach_id}/tag/{tag_id}',
            path: {
                'coach_id': coachId,
                'tag_id': tagId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View All Tags
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesViewAllTags(): CancelablePromise<(Array<BaseTag> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/tags/all',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Add Profile Picture
     * @param coachId
     * @param formData
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public patchCoachesAddProfilePicture(
        coachId: number,
        formData: Body_add_profile_picture_coaches_coach__coach_id__add_profile_picture_patch,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/add_profile_picture',
            path: {
                'coach_id': coachId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Profile Picture Base64
     * @param coachId
     * @param requestBody
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public postCoachesAddProfilePictureBase64(
        coachId: number,
        requestBody: FileUploadBase64,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/add_profile_picture_base64',
            path: {
                'coach_id': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Profile Picture
     * @param coachId
     * @returns RegularCoach Successful Response
     * @throws ApiError
     */
    public deleteCoachesRemoveProfilePicture(
        coachId: number,
    ): CancelablePromise<RegularCoach> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/coaches/coach/{coach_id}/remove_profile_picture',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Students
     * @param coachId
     * @returns SimpleStudentWithGuardian Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachStudents(
        coachId: number,
    ): CancelablePromise<Array<SimpleStudentWithGuardian>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/students',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Lessons Count
     * @param coachId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachLessonsCount(
        coachId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/lessons/count',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Students Financial
     * @param coachId
     * @param year
     * @param month
     * @param showDisputed
     * @returns StudentCoachFinancialMontly Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachStudentsFinancial(
        coachId: number,
        year: number = 2024,
        month: number = 7,
        showDisputed: boolean = false,
    ): CancelablePromise<Array<StudentCoachFinancialMontly>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/students/financial',
            path: {
                'coach_id': coachId,
            },
            query: {
                'year': year,
                'month': month,
                'show_disputed': showDisputed,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Students Financial Unaccorded Months
     * @param coachId
     * @returns CoachUnaccordedMonths Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachStudentsFinancialUnaccordedMonths(
        coachId: number,
    ): CancelablePromise<Array<CoachUnaccordedMonths>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/students/financial/unaccorded_months',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach To Accord
     * @param coachId
     * @param year
     * @param month
     * @param showDisputed
     * @returns ToAccordLessonSchema Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachToAccord(
        coachId: number,
        year: number = 2024,
        month: number = 7,
        showDisputed: boolean = false,
    ): CancelablePromise<Array<ToAccordLessonSchema>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/to_accord',
            path: {
                'coach_id': coachId,
            },
            query: {
                'year': year,
                'month': month,
                'show_disputed': showDisputed,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Coach Accordance
     * @param coachId
     * @param month
     * @param year
     * @param force
     * @param showDisputed
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public postCoachesCoachAccordance(
        coachId: number,
        month: number,
        year: number,
        force: boolean = false,
        showDisputed: boolean = false,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/accordance',
            path: {
                'coach_id': coachId,
            },
            query: {
                'month': month,
                'year': year,
                'force': force,
                'show_disputed': showDisputed,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Coach Accordance Snapshots
     * @param coachId
     * @returns CoachAccordanceSnapshot Successful Response
     * @throws ApiError
     */
    public getCoachesCoachAccordanceSnapshots(
        coachId: number,
    ): CancelablePromise<Array<CoachAccordanceSnapshot>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/snapshots',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Coach Unaccordance
     * @param coachId
     * @param month
     * @param year
     * @param force
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchCoachesCoachUnaccordance(
        coachId: number,
        month: number,
        year: number,
        force: boolean = false,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/unaccord',
            path: {
                'coach_id': coachId,
            },
            query: {
                'month': month,
                'year': year,
                'force': force,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Unaccorded Coaches
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesViewUnaccordedCoaches(
        start?: (string | null),
        end?: (string | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/unaccorded_coaches',
            query: {
                'start': start,
                'end': end,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Coach Student
     * @param studentId
     * @param coachId
     * @param requestBody
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchCoachesUpdateCoachStudent(
        studentId: number,
        coachId: number,
        requestBody: BaseStudentCoachesEdit,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/update_student/{student_id}',
            path: {
                'student_id': studentId,
                'coach_id': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Student
     * @param studentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachStudent(
        studentId: number,
    ): CancelablePromise<(SimpleStudentWithGuardian | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/student/{student_id}',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Student Coach
     * @param coachId
     * @returns BaseStudentCoach Successful Response
     * @throws ApiError
     */
    public getCoachesViewStudentCoach(
        coachId: number,
    ): CancelablePromise<Array<BaseStudentCoach>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/student_coach',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Salary
     * @param coachId
     * @param year
     * @param month
     * @returns CoachSalary Successful Response
     * @throws ApiError
     */
    public getCoachesViewSalary(
        coachId: number,
        year: number = 2024,
        month: number = 8,
    ): CancelablePromise<CoachSalary> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/salary',
            path: {
                'coach_id': coachId,
            },
            query: {
                'year': year,
                'month': month,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Notes
     * @param coachId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachNotes(
        coachId: number,
    ): CancelablePromise<(Array<BaseNote> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/view/{coach_id}/notes',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Coach Note
     * @param coachId
     * @param requestBody
     * @returns BaseNote Successful Response
     * @throws ApiError
     */
    public postCoachesAddCoachNote(
        coachId: number,
        requestBody: NoteIn,
    ): CancelablePromise<BaseNote> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/view/{coach_id}/notes',
            path: {
                'coach_id': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Ical
     * @param icalToken
     * @param start
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachIcal(
        icalToken: string,
        start?: (string | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/ics/{ical_token}',
            path: {
                'ical_token': icalToken,
            },
            query: {
                'start': start,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View All Coaches
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesViewAllCoaches(
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<AdvancedCoach> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coaches/view/all',
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Open Student Request
     * @param coachId
     * @param lessonRequestId
     * @param requestBody
     * @returns BaseStudentRequest Successful Response
     * @throws ApiError
     */
    public postCoachesOpenStudentRequest(
        coachId: number,
        lessonRequestId: number,
        requestBody: StudentRequestIn,
    ): CancelablePromise<BaseStudentRequest> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/request/{lesson_request_id}',
            path: {
                'coach_id': coachId,
                'lesson_request_id': lessonRequestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Close Student Request
     * @param studentRequestId
     * @param approve
     * @returns BaseStudentRequest Successful Response
     * @throws ApiError
     */
    public postCoachesCloseStudentRequest(
        studentRequestId: number,
        approve: boolean = false,
    ): CancelablePromise<BaseStudentRequest> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coaches/request/{student_request_id}/close',
            path: {
                'student_request_id': studentRequestId,
            },
            query: {
                'approve': approve,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Via Id Advanced Private
     * @param coachId
     * @returns CoachPrivateInformation Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachViaIdAdvancedPrivate(
        coachId: number,
    ): CancelablePromise<CoachPrivateInformation> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/private',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Student Request
     * @param studentRequestId
     * @returns BaseStudentRequest Successful Response
     * @throws ApiError
     */
    public getCoachesViewStudentRequest(
        studentRequestId: number,
    ): CancelablePromise<BaseStudentRequest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coaches/request/{student_request_id}/view',
            path: {
                'student_request_id': studentRequestId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Open Student Requests
     * @returns BaseLessonRequestWithOpenStudentRequests Successful Response
     * @throws ApiError
     */
    public getCoachesViewOpenStudentRequests(): CancelablePromise<Array<BaseLessonRequestWithOpenStudentRequests>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coaches/requests/view/open',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * View All Student Requests
     * @returns BaseLessonRequestWithStudentRequests Successful Response
     * @throws ApiError
     */
    public getCoachesViewAllStudentRequests(): CancelablePromise<Array<BaseLessonRequestWithStudentRequests>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coaches/requests/view/all',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * View Coach Hours
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachHours(
        start?: (string | null),
        end?: (string | null),
    ): CancelablePromise<(Array<CoachTotalHours> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coaches/total_hours',
            query: {
                'start': start,
                'end': end,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Hours Download
     * @param start
     * @param end
     * @param needCancelled
     * @param needAccorded
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachHoursDownload(
        start?: (string | null),
        end?: (string | null),
        needCancelled?: (boolean | null),
        needAccorded?: (boolean | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coaches/total_hours/download',
            query: {
                'start': start,
                'end': end,
                'need_cancelled': needCancelled,
                'need_accorded': needAccorded,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Overview
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachOverview(
        start?: (string | null),
        end?: (string | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coaches/overview/download',
            query: {
                'start': start,
                'end': end,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Coach Contract
     * @param coachId
     * @param isLaborContract
     * @returns ContractGeneration Successful Response
     * @throws ApiError
     */
    public getCoachesGenerateCoachContract(
        coachId: number,
        isLaborContract: boolean = false,
    ): CancelablePromise<ContractGeneration> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/contract',
            path: {
                'coach_id': coachId,
            },
            query: {
                'is_labor_contract': isLaborContract,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sign Coach Contract
     * @param coachId
     * @param formData
     * @param isLaborContract
     * @returns any Successful Response
     * @throws ApiError
     */
    public postCoachesSignCoachContract(
        coachId: number,
        formData: Body_sign_coach_contract_coaches_coach__coach_id__signature_post,
        isLaborContract: boolean = false,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/signature',
            path: {
                'coach_id': coachId,
            },
            query: {
                'is_labor_contract': isLaborContract,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Contracts
     * @param coachId
     * @returns BaseContract Successful Response
     * @throws ApiError
     */
    public getCoachesViewContracts(
        coachId: number,
    ): CancelablePromise<Array<BaseContract>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/view/contracts/{coach_id}',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Complete Contracts
     * @param coachId
     * @returns BaseContract Successful Response
     * @throws ApiError
     */
    public getCoachesViewCompleteContracts(
        coachId: number,
    ): CancelablePromise<Array<BaseContract>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/view/complete/contracts/{coach_id}',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reject Contract
     * @param coachId
     * @returns any Successful Response
     * @throws ApiError
     */
    public postCoachesRejectContract(
        coachId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/reject_contract',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Verify Coach
     * @param coachId
     * @param verify
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public getCoachesVerifyCoach(
        coachId: number,
        verify: boolean = true,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/verify',
            path: {
                'coach_id': coachId,
            },
            query: {
                'verify': verify,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Contract
     * @param coachId
     * @param isLaborContract
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public postCoachesResetContract(
        coachId: number,
        isLaborContract: boolean = false,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/reset_contract',
            path: {
                'coach_id': coachId,
            },
            query: {
                'is_labor_contract': isLaborContract,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Id Card Attachment
     * @param coachId
     * @param formData
     * @param isFront
     * @returns CoachPrivateInformation Successful Response
     * @throws ApiError
     */
    public postCoachesAddIdCardAttachment(
        coachId: number,
        formData: Body_add_id_card_attachment_coaches_coach__coach_id__add_id_card_attachment_post,
        isFront: boolean = true,
    ): CancelablePromise<CoachPrivateInformation> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coaches/coach/{coach_id}/add_id_card_attachment',
            path: {
                'coach_id': coachId,
            },
            query: {
                'is_front': isFront,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Id
     * @param coachId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchCoachesRemoveId(
        coachId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/remove/id',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Grades Attachment
     * @param coachId
     * @param formData
     * @returns CoachPrivateInformation Successful Response
     * @throws ApiError
     */
    public patchCoachesAddGradesAttachment(
        coachId: number,
        formData: Body_add_grades_attachment_coaches_coach__coach_id__add_grades_attachment_patch,
    ): CancelablePromise<CoachPrivateInformation> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/add_grades_attachment',
            path: {
                'coach_id': coachId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coach Accordance Logs
     * @param coachId
     * @param startDate
     * @param endDate
     * @param limit
     * @param offset
     * @param userId
     * @returns schemas__accordance_logs__BaseLog Successful Response
     * @throws ApiError
     */
    public getCoachesViewCoachAccordanceLogs(
        coachId: number,
        startDate?: (string | null),
        endDate?: (string | null),
        limit: number = 30,
        offset?: number,
        userId?: (number | null),
    ): CancelablePromise<Array<schemas__accordance_logs__BaseLog>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/accordance_logs',
            path: {
                'coach_id': coachId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'limit': limit,
                'offset': offset,
                'user_id': userId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Coach Kvk Number
     * @param coachId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesGetCoachKvkNumber(
        coachId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/kvk_number',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Coach Kvk Number
     * @param coachId
     * @param kvkNumber
     * @returns any Successful Response
     * @throws ApiError
     */
    public patchCoachesEditCoachKvkNumber(
        coachId: number,
        kvkNumber: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/kvk_number',
            path: {
                'coach_id': coachId,
            },
            query: {
                'kvk_number': kvkNumber,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Coach Vat Number
     * @param coachId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getCoachesGetCoachVatNumber(
        coachId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/vat_number',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Coach Vat Number
     * @param coachId
     * @param vatNumber
     * @returns any Successful Response
     * @throws ApiError
     */
    public patchCoachesEditCoachVatNumber(
        coachId: number,
        vatNumber: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/coaches/coach/{coach_id}/vat_number',
            path: {
                'coach_id': coachId,
            },
            query: {
                'vat_number': vatNumber,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Coach Favorite Locations
     * This endpoint returns the favorite locations of a coach
     *
     * Args:
     * - coach_id: int: the id of the coach
     *
     * Returns:
     *
     * - KdvFavoriteBranches: list of favorite branch-locations of the coach and the amount of the total favorite
     *
     * NOTE: This endpoint returns mocking data
     * @param coachId
     * @returns KdvFavoriteBranches Successful Response
     * @throws ApiError
     */
    public getCoachesGetCoachFavoriteLocations(
        coachId: number,
    ): CancelablePromise<KdvFavoriteBranches> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/coaches/coach/{coach_id}/favorite_branches',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
