import React, { useState } from 'react';

import { Check } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';

import { BaseStub, RegularProduct } from 'assets';
import beu_background from 'assets/img/brand/BEU_wit23.png';
import ob_background from 'assets/img/brand/OB_wit23.png';
import sw_background from 'assets/img/brand/SW_helemaalwit_rond23.png';
import vb_background from 'assets/img/brand/VBL_wit23.png';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import { IPackage } from '..';

interface PackageCardMobileProps extends IPackage {
  score: number;
  data?: BaseStub;
  setData?: (baseStub: BaseStub) => void;
  radioValue?: string;
  advice?: boolean;

  displayMatch?: boolean;
  noAdvice?: boolean;
  skipAdvice?: boolean;
  product: RegularProduct;
  selectedProducts?: RegularProduct[];
  setSelectedProducts?: (value: RegularProduct[]) => void;
}

const PackageCardMobile: React.FunctionComponent<PackageCardMobileProps> = ({
  selected,
  advice,
  skipAdvice,
  product,
}) => {
  const label = useLabel();
  const currentWidth = useWidth();
  const [losseLes, setLosseles] = useState<boolean>(false);

  React.useEffect(() => {
    if (product.name === 'Losse les') {
      setLosseles(true);
    } else {
      setLosseles(false);
    }
  }, [losseLes]);

  const img = () => {
    if (label?.name) {
      switch (label.name.toLowerCase()) {
        case 'studyworks':
          return `url(${sw_background})`;
        case 'bijles.eu':
          return `url(${beu_background})`;
        case 'online-bijles.com':
          return `url(${ob_background})`;
        case 'vlakbijles':
          return `url(${vb_background})`;
      }
    }

    return `url(${sw_background})`;
  };
  const strippenkaarten = label?.name == 'VlakBijles' || label?.name == 'Bijles.EU';

  /* Adds products to the selected product array */
  // const handleAdd = () => {
  //   const tempSelectedProducts = [...selectedProducts];
  //   if (tempSelectedProducts.length >= 1) {
  //     /* TODO add to i18n */
  //     enqueueSnackbar('Er mag maar 1 product geselecteerd worden', {
  //       variant: 'warning',
  //     });

  //     return;
  //   } else {
  //     tempSelectedProducts.push(product);
  //     setSelectedProducts(tempSelectedProducts);
  //   }
  // };

  /* Comment this part in for selling multiple package  */
  /* Removes products from the selected product array */
  // const handleRemove = () => {
  //   const tempSelectedProducts = [...selectedProducts];
  //   tempSelectedProducts.splice(tempSelectedProducts.indexOf(product), 1);
  //   setSelectedProducts(tempSelectedProducts);
  // };

  /* Removes all entries from the selected products*/
  // const handleRemoveAll = () => {
  //   const tempSelectedProducts = [...selectedProducts];
  //   tempSelectedProducts.splice(tempSelectedProducts.indexOf(product));
  //   setSelectedProducts(tempSelectedProducts);
  // };

  // const handleCheck = () => {
  //   handleSelectedProducts() ? handleRemoveAll() : handleAdd();
  // };

  /* Comment this part in for selling multiple package  */
  /* gets the count of the product */
  // const getProductCount = (): number => {
  //   const tempSelectedProducts = [...selectedProducts];
  //   const indexes = tempSelectedProducts.filter(
  //     (tempProd: RegularProduct, index) =>
  //       tempSelectedProducts.indexOf(tempProd) !== index && tempProd.id === product.id
  //   );

  //   return indexes.length + 1;
  // };

  /* FInd the product if the product is selected return a bool to make the ckeckbox selected */
  // const handleSelectedProducts = (): boolean => {
  //   let selected: boolean = false;

  //   const found = selectedProducts.find(seProduct => seProduct.id === product.id);
  //   if (found) {
  //     selected = true;
  //   } else {
  //     selected = false;
  //   }

  //   return selected;
  // };

  return (
    <Box
      sx={{
        width: '100%',
        // height: "100%",
      }}>
      <Card
        sx={{
          width: '100%',
          // height: currentWidth === "xs" ? "100%" : "unset",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: '10px',
          backgroundImage: selected && (!losseLes || strippenkaarten) ? img : 'none',
          backgroundPosition: 'right',
          backgroundColor: selected ? label?.main_color : losseLes ? 'white' : '#f5f5f5',
          border: losseLes ? '1px solid black' : 'unset',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
        elevation={selected ? 10 : 0}>
        {losseLes && !strippenkaarten ? (
          <>
            <CardHeader
              title={
                <Grid
                  container
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <span style={{ color: selected ? 'white' : '#767271' }}>{product.name}</span>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}>
                    {product.bundle_details && (
                      <span
                        style={{
                          color: selected ? 'white' : '#767271',
                          fontSize: 20,
                        }}>
                        {' '}
                        {'€' +
                          Math.round(product.price / product.bundle_details?.total_hours).toFixed(
                            2
                          )}{' '}
                      </span>
                    )}
                    <span
                      style={{
                        color: selected ? 'white' : '#767271',
                        fontSize: 14,
                        marginLeft: '4px',
                      }}>
                      {' '}
                      {' p/u'}{' '}
                    </span>
                  </Grid>
                </Grid>
              }
            />
          </>
        ) : (
          <>
            <CardHeader
              //dispays the last word of the title in grey
              title={
                selected ? (
                  <span style={{ color: 'white' }}>{product.name}</span>
                ) : (
                  product.name.split(' ').map((word, index) => {
                    if (index === product.name.split(' ').length - 1) {
                      return <span style={{ color: '#767271' }}>{word}</span>;
                    } else if (label?.main_color !== null) {
                      return <span style={{ color: label?.main_color }}> {word} </span>;
                    }
                  })
                )
              }
              titleTypographyProps={{
                variant: 'h5',
                fontWeight: 'bold',
              }}
              sx={{
                textAlign: 'center',
                // paddingBottom: 0,
                padding: '8px 0px',
                backgroundColor: 'transparent',
              }}
            />
            <CardContent
              sx={{
                height: currentWidth === 'xs' ? '120px' : '140px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingRight: 0,
                paddingTop: 0,
                backgroundColor: 'transparent',
              }}>
              <Grid container xs={12}>
                <Grid item xs={12} sx={{ margin: '0px 0px 15px 0px' }}>
                  {product.bundle_details && (
                    <Typography
                      sx={{
                        color: selected ? 'white' : '#767271',
                        fontWeight: 'bold',
                        fontSize: 20,
                        textAlign: 'center',
                      }}>
                      {'€' +
                        Math.round(product.price / product.bundle_details?.total_hours).toFixed(2)}
                      <span
                        style={{
                          color: selected ? 'white' : '#767271',
                          fontSize: 14,
                          marginLeft: '4px',
                        }}>
                        {' '}
                        {' p/u'}{' '}
                      </span>
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {/* <CardItem contents=
                                                /> */}
                      <Stack direction={'row'}>
                        <Check sx={{ color: selected ? 'white' : label?.main_color }} />
                        {product.bundle_details && (
                          <Typography
                            sx={{
                              color: selected ? 'white' : label?.main_color,
                              fontSize: '15px',
                            }}>
                            {strippenkaarten && losseLes
                              ? `Startend vanaf €${Math.round(
                                  product.price / product.bundle_details?.total_hours
                                ).toFixed(2)} per uur`
                              : `${product.bundle_details.total_hours} uur bijles`}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={6} sx={{ marginTop: '-4px' }}>
                      <Grid container>
                        {!skipAdvice && advice && (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              width: '100%',
                              height: '30px',
                              backgroundColor: selected ? 'white' : '#00C94C',
                              borderTopLeftRadius: 5,
                              borderBottomLeftRadius: 5,
                              padding: '2px 0px',
                            }}>
                            <Typography
                              sx={{
                                color: selected ? '#00C94C' : 'white',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}>
                              Ons advies
                            </Typography>
                          </Grid>
                        )}
                        {product.name == 'Succes pakket' && skipAdvice && (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              width: '100%',
                              height: '30px',
                              backgroundColor: selected ? 'white' : '#00C94C',
                              borderTopLeftRadius: 5,
                              borderBottomLeftRadius: 5,
                              padding: '2px 0px',
                            }}>
                            <Typography
                              sx={{
                                color: selected ? '#00C94C' : 'white',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}>
                              Meest gekozen
                            </Typography>
                          </Grid>
                        )}
                        {product.name == 'Succes plus pakket' && !advice && (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              width: '100%',
                              height: '30px',
                              backgroundColor: 'white',
                              borderTopLeftRadius: 5,
                              borderBottomLeftRadius: 5,
                              padding: '2px 0px',
                            }}>
                            <Typography
                              sx={{
                                color: '#767271',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}>
                              Meest voordelig
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </>
        )}

        {/* <CardActionArea
          sx={{
            background: handleSelectedProducts()
              ? 'linear-gradient(90deg, #E67534 0%, #FDB937 100%) 0% 0% no-repeat padding-box'
              : 'unset',
            borderRadius: '0px',
          }}>
          <Grid item xs={12} sx={{ borderTop: '1px solid #6F6F6F', padding: '0px 20px' }}>
            <Grid container sx={{}}>
              <Grid item xs={7}>
                <Stack direction={'row'}>
                  <Checkbox
                    checked={handleSelectedProducts()}
                    onClick={handleCheck}
                    sx={{
                      '&.Mui-checked': {
                        color: 'white',
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      marginTop: '12px',
                      fontSize: '16px',
                      color: handleSelectedProducts() ? '#FFFFFF' : theme.palette.primary.main,
                    }}>
                    {handleSelectedProducts() ? 'Geselecteerd' : 'Selecteer'}
                  </Typography>
                </Stack>
              </Grid>

              
              <Grid item xs={5}>
                {handleSelectedProducts() && (
                  <Grid container justifyContent={'center'}>
                    <Grid item xs={4}>
                      <IconButton onClick={handleRemove}>
                        <Remove sx={{ textAlign: 'center', color: 'white' }} />
                      </IconButton>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      sx={{ bgcolor: 'white', borderRadius: '5px', margin: '5px 0px' }}>
                      <Typography sx={{ textAlign: 'center', marginTop: '6px' }}>
                        {getProductCount()}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <IconButton onClick={handleAdd}>
                        <Add sx={{ textAlign: 'center', color: 'white' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardActionArea> */}
      </Card>
    </Box>
  );
};

export default PackageCardMobile;
