import * as React from 'react';
import { useEffect, useState } from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { appClient } from 'App';
import { BaseDiscipline, RegularCoach, SchoolLevelCoachEnum } from 'assets';
import { TextField } from 'components/Elements/Forms/Inputs';
import useUser from 'functions/Hooks/useUser';

import EditForm from '../Components/EditForm';

interface SchoolDetailsProps {}
type DisciplineChangeFunctionType = (discipline: BaseDiscipline, checked?: boolean) => void;

const SchoolDetails: React.FunctionComponent<SchoolDetailsProps> = () => {
  const [possibleDisciplines, setPossibleDisciplines] = useState<BaseDiscipline[]>([]);
  const [coach, setCoach] = useState<RegularCoach>();
  const [disciplines, setDisciplines] = useState<BaseDiscipline[]>([]);
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const handleNext = async (event: CustomEvent<() => void>) => {
    if (!coach?.school_level)
      return enqueueSnackbar('Geef asjeblieft aan welk niveau je hebt gehaald', {
        variant: 'error',
      });
    if (disciplines?.length <= 0 && !coach.is_pabo)
      return enqueueSnackbar('Voeg minimaal een bijlesvak toe', { variant: 'error' });

    await appClient.coaches.patchCoachesEditCoach(coach?.id || 0, coach);
    await appClient.coaches.patchCoachesBulkRemoveDisciplinesFromCoach(
      coach.id,
      possibleDisciplines.map(item => item.id)
    );
    await appClient.coaches.patchCoachesBulkAddDisciplinesToCoach(
      coach.id,
      disciplines.map(item => item.id)
    );

    event.detail();
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext as unknown as EventListener);

    return () => {
      document.removeEventListener('onNext', handleNext as unknown as EventListener);
    };
  }, [disciplines, coach]);

  useEffect(() => {
    appClient.coaches.getCoachesViewCoachViaId(user?.coach?.id || 0).then(r => {
      setCoach(r);
      setDisciplines(r.disciplines || []);
    });
  }, [user]);

  useEffect(() => {
    appClient.lessons.getLessonsViewDisciplines().then(result => {
      if (result !== null) {
        setPossibleDisciplines(result);
      }
    });
  }, []);

  const handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => void = (
    { target: { value, name } },
    checked
  ) => {
    setCoach({
      ...(coach as RegularCoach),
      [name]: checked !== undefined ? checked : value,
    });
  };

  const handleDisciplineChange: DisciplineChangeFunctionType = (discipline, checked) => {
    if (checked) setDisciplines([...disciplines, discipline]);
    else setDisciplines(disciplines.filter(search => search.name !== discipline.name));
  };

  const schoolVakkenRadio = () => {
    return (
      <>
        {possibleDisciplines.map(discipline => {
          return (
            <Grid item xs={12} lg="auto">
              <FormControlLabel
                // value="huis"
                control={
                  <Radio
                    name={discipline.name}
                    checked={disciplines.findIndex(search => discipline.id === search.id) !== -1}
                    onClick={() =>
                      handleDisciplineChange(
                        discipline,
                        !(disciplines.findIndex(search => discipline.id === search.id) !== -1)
                      )
                    }
                  />
                }
                label={discipline.name}
                // onClick={() => setVak(vak)}
              />
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <Grid container spacing={1}>
      <EditForm
        title="Opleiding"
        subheader="Vertel ons over je afgeronde of huidige opleiding(en)"
        ComponentsWhenEditing={() => (
          <>
            <Grid item xs={12}>
              <TextField
                select
                value={coach?.school_level || 'vmbo_basis'}
                onChange={e => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
                fullWidth
                label="Hoogstbehaalde opleiding"
                name="school_level">
                {Object.keys(SchoolLevelCoachEnum).map(school_level => (
                  <MenuItem value={school_level.toLowerCase().replace('_', '-')}>
                    {school_level.toLowerCase().replace('_', ' ')}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                checked={coach?.is_pabo}
                control={
                  <Checkbox name="is_pabo" checked={coach?.is_pabo} onChange={handleChange} />
                }
                label={'Ik ben een pabo-student'}
              />
              <Typography>
                Vink dit aan als je een onderwijsgerelateerde studie volgt of hebt afgerond.
                Voorbeelden van opleidingen zijn: docentenopleiding, PABO, onderwijsassistent,
                pedagogiek, pedagogische wetenschappen, enzovoort
              </Typography>
            </Grid>
          </>
        )}
        ComponentsWhenNotEditing={() => (
          <>
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Niveau
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {coach?.school_level?.replace('_', ' ')}
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs="auto">
              <Typography variant="h3" color="initial">
                Ik ben een pabo-student
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography textAlign="right" variant="h4" color="initial">
                {coach?.is_pabo ? 'Ja' : 'Nee'}
              </Typography>
            </Grid>
          </>
        )}
      />
      <EditForm
        title="Bijlesvakken"
        subheader="Vakken waarin je bijles wilt geven"
        ComponentsWhenEditing={() => (
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              sx={{ marginLeft: '20px' }}>
              {schoolVakkenRadio()}
            </RadioGroup>
          </FormControl>
        )}
        ComponentsWhenNotEditing={() => (
          <Grid item xs={12}>
            <Typography variant="h4" color="initial">
              {disciplines.map(
                (discipline, index) =>
                  `${discipline.name}${index !== (disciplines?.length || 0) - 1 ? ',' : ''} `
              )}
            </Typography>
          </Grid>
        )}
      />

      <Grid item xs={12} />
    </Grid>
  );
};

export default SchoolDetails;
