import React, { useState } from 'react';

import { Grid } from '@mui/material';

import BackgroundBULogo from 'assets/img/LoginImages/CORE_inlogscherm_collage_mobiel_BEU.png';
import BackgroundOBLogo from 'assets/img/LoginImages/CORE_inlogscherm_collage_mobiel_OB.png';
import BackgroundSWLogo from 'assets/img/LoginImages/CORE_inlogscherm_collage_mobiel_SW.png';
import BackgroundVBLogo from 'assets/img/LoginImages/CORE_inlogscherm_collage_mobiel_VBL.png';
import useLabel from 'functions/Hooks/useLabel';

const ChangePasswordMobileTopHeader = () => {
  const label = useLabel();
  const [backgroundLogo, setBackgroundLogo] = useState<string>();

  React.useEffect(() => {
    if (label) {
      switch (label?.name) {
        case 'StudyWorks':
          setBackgroundLogo(BackgroundSWLogo);
          break;
        case 'VlakBijles':
          setBackgroundLogo(BackgroundVBLogo);
          break;
        case 'bijleseu.nl':
          setBackgroundLogo(BackgroundBULogo);
          break;
        default:
          setBackgroundLogo(BackgroundOBLogo);
          break;
      }
    }
  }, [label]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          width: '100%',
          height: 'auto',
        }}>
        {backgroundLogo && (
          <img
            src={backgroundLogo}
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ChangePasswordMobileTopHeader;
