import React, { useEffect, useState } from 'react';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Button, Card, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import './Contract.css';
import { useTheme } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { appClient } from 'App';
import { BaseStub } from 'assets';
import { dataURItoBlob } from 'functions/HelperFunctions/dataUrlToBlob';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

import DualContractView from './DualContractView';
import Signature from './Signature';

interface IContractPageProps {
  data: BaseStub;
  isTransfering: boolean;
  setIsTransfering: (value: boolean) => void;
}

const ContractPage: React.FunctionComponent<IContractPageProps> = ({
  data,
  setIsTransfering,
  isTransfering,
}) => {
  const [contract, setContract] = React.useState<string>();
  const [secondContract, setSecondContract] = React.useState<string>();
  const [signature, setSignature] = useState(undefined);
  const theme = useTheme();
  const currentWidth = useWidth();
  const { key: secretkey }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [allowSign, setAllowSign] = useState<boolean>(false);

  const [SkipContract] = useState<boolean>(false);
  const [canDownload, setCanDownload] = useState<boolean>(false);

  const fetchContract = () => {
    appClient.stubs.getStubsGetStubContract(secretkey).then(async r => {
      const mainContract = r.mainContract.object_key_render_url;
      setTimeout(function () {
        fetchAWSContract(mainContract, setContract);
      }, 3000);

      setAllowSign(true);
      if (r.secondContract) {
        const secondContract = r.secondContract.object_key_render_url;

        setTimeout(function () {
          fetchAWSContract(secondContract, setSecondContract);
        }, 3000);

        if (currentWidth === 'xs') {
          setAllowSign(true);
        } else {
          setAllowSign(false);
        }
      }
    });
  };

  const fetchAWSContract = async (url: string, callback: (contract: string) => void) => {
    fetch(url)
      .then(async r => {
        if (r.status === 403) {
          await fetchAWSContract(url, callback);
          setCanDownload(false);
        } else if (r.status === 200) {
          setCanDownload(true);
        }

        return r;
      })
      .then(r => r.blob())
      .then(r => {
        callback(URL.createObjectURL(r));
      });
  };

  useEffect(() => {
    fetchContract();
    setIsTransfering(false);
  }, [secretkey]);

  const handleNext = async (event: any) => {
    if (!isTransfering) {
      setIsTransfering(true);
    }

    if (!SkipContract) {
      if (!contract) {
        setIsTransfering(false);

        return enqueueSnackbar('Wacht eerst op het overeenkomst', { variant: 'error' });
      }

      if (!signature) {
        setIsTransfering(false);

        return enqueueSnackbar('Wij hebben je handtekening nodig', { variant: 'error' });
      } else {
        await appClient.stubs
          .postStubsAddSignature(secretkey, { signature: dataURItoBlob(signature) })
          .then(async () => {
            const info = {
              first_name: data?.data_bundle.first_name,
              last_name: data?.data_bundle.last_name,
              email_address: data?.data_bundle.email_address,
            };

            const copy = { ...data };
            const street = data?.data_bundle.street + ' ' + data?.data_bundle.street_number;
            copy.data_bundle.street = street;

            await appClient.stubs.postStubsUpdateStub(secretkey, data).catch(error => {
              enqueueSnackbar('Er is iets fout gegaan. ' + error, { variant: 'error' });
              setIsTransfering(false);
            });
            await appClient.stubs.patchStubsTransferStub(secretkey, info).catch(error => {
              enqueueSnackbar('Er is iets fout gegaan. ' + error, { variant: 'error' });
              setIsTransfering(false);
            });

            event.detail();
          });
      }
    } else {
      /** START: bypass for contract service */
      const info = {
        first_name: data?.data_bundle.first_name,
        last_name: data?.data_bundle.last_name,
        email_address: data?.data_bundle.email_address,
      };

      const copy = { ...data };
      const street = data?.data_bundle.street + ' ' + data?.data_bundle.street_number;
      copy.data_bundle.street = street;

      data.data_bundle.contract = 'bullshit';

      await appClient.stubs.postStubsUpdateStub(secretkey, data).catch(error => {
        enqueueSnackbar('Er is iets fout gegaan. ' + error, { variant: 'error' });
        setIsTransfering(false);
      });
      await appClient.stubs.patchStubsTransferStub(secretkey, info).catch(error => {
        enqueueSnackbar('Er is iets fout gegaan. ' + error, { variant: 'error' });
        setIsTransfering(false);
      });

      event.detail();
      /** END: bypass for contract service */
    }
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext);

    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [contract, signature]);

  const DownloadContract = (blobURL: string, title = 'blob-test.txt') => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';

    a.href = blobURL;
    a.download = title;
    a.click();
    URL.revokeObjectURL(blobURL);
  };

  return (
    <Paper
      elevation={0}
      square
      sx={{ padding: '16px' }}
      // className={isMobile ? "paperMobile" : "paperSectie"}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant={'h1'}>Teken je overeenkomst</Typography>

          <Typography>
            Bedankt! Wij hebben de door jou opgegeven informatie in een overeenkomst verwerkt. Lees
            het overeenkomst goed door. Zodra je akkoord gaat kun je het overeenkomst ondertekenen.
            Let wel op: het genereren van dit overeenkomst kan tot maximaal tien seconden duren. Is
            het overeenkomst dan nog niet geladen? Druk dan op knop hieronder om hem opnieuw te
            genereren.
          </Typography>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Staat er iets fout in je overeenkomst? Klik hier om hem opnieuw te genereren.
            <Button onClick={fetchContract}>
              <AutorenewIcon />
            </Button>
          </Typography>
        </Grid>
        {currentWidth === 'xs' ? (
          <>
            {contract ? (
              <Card
                sx={{
                  marginTop: '15px',
                  marginBottom: '15px',
                  padding: '15px',
                  borderLeft: 'solid',
                  borderWidth: '15px',
                  borderColor: theme.palette.primary.main,
                }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>
                      Download hier uw overeenkomst. Lees het goed door voordat je akkoord gaat.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      disabled={!canDownload}
                      onClick={() => DownloadContract(contract, 'first-contract.pdf')}>
                      Download overeenkomst
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            ) : (
              <LinearProgress />
            )}

            {secondContract ? (
              <Card
                sx={{
                  marginTop: '15px',
                  marginBottom: '15px',
                  padding: '15px',

                  borderLeft: 'solid',
                  borderWidth: '15px',
                  borderColor: theme.palette.primary.main,
                }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>
                      Download hier uw overeenkomst. Lees het goed door voordat je akkoord gaat.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      disabled={!canDownload}
                      onClick={() => DownloadContract(secondContract, 'second-contract.pdf')}>
                      Download overeenkomst
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            ) : (
              <LinearProgress />
            )}
          </>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {secondContract && contract ? (
                <DualContractView
                  contract={contract}
                  secondContract={secondContract}
                  setAllowSign={setAllowSign}
                />
              ) : (
                <Grid item xs={12} md={secondContract ? 6 : 12}>
                  {contract ? (
                    <iframe
                      style={{
                        height: '800px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                      title="ContractView"
                      frameBorder="0"
                      src={contract}
                    />
                  ) : (
                    <LinearProgress />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '15px',
            marginBottom: '15px',
          }}>
          <Typography variant="h3">Wij hebben je handtekening nodig.</Typography>
          <Typography>
            Heb je alles gelezen en ga je akkoord? Zet dan je handtekening in het veld hieronder en
            klik op akkoord.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Signature setSignature={setSignature} allowSign={allowSign} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ContractPage;
