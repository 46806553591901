/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TaskStatus {
    PENDING = 'PENDING',
    STARTED = 'STARTED',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    RETRY = 'RETRY',
    REVOKED = 'REVOKED',
}
