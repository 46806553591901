import React, { useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import { List, ListItem, ListItemText } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { BaseUserLocation, GenericStatus } from 'assets';
import useUser from 'functions/Hooks/useUser';
import { ILocation } from 'interfaces/Location';

import LocationItem from './Componets/LocationItem';


type propType = 'city' | 'street' | 'zipcode';
export type asyncFunction = (location: ILocation, index?: number) => Promise<ILocation> | ILocation;
interface LocationListProps {
  user_locations: ILocation[];
  submitEvent?: string;
  readOnly?: boolean;
  onPost?: asyncFunction;
  onPatch?: asyncFunction;
  onDelete?: (
    location: ILocation,
    index?: number
  ) => Promise<GenericStatus | ILocation> | ILocation;
  selectedLocation?: BaseUserLocation;
  setSelectedLocation?: (location: BaseUserLocation) => void;
}

const LocationList: React.FunctionComponent<LocationListProps> = ({
  selectedLocation,
  setSelectedLocation,
  readOnly,
  user_locations,
  submitEvent,
  onPost,
  onPatch,
  onDelete,
}) => {
  const [locations, setLocations] = useState<ILocation[]>(user_locations || []);
  const { jwt } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [validAdressError, setValidAdressError] = useState(false);

  const handleAdd = async () => {
    const change = [...locations]
    const index = locations.findIndex((location) =>
      location.isEditing || location.isNew)

    if (index > -1 && onPost && onPatch) {
      if (
        change[index].city !== '' &&
        change[index].street !== '' &&
        change[index].zipcode !== ''
      ) {
        const response = change[index].isNew
          ? await onPost(change[index], index)
          : await onPatch(change[index], index);
        if (change[index].isNew) change[index].id = response.id;
        change[index].isEditing = false;
        change[index].isNew = false;
      } else return enqueueSnackbar('Vul eerst alle gegevens in.', { variant: 'warning' });
    }

    setLocations([
      ...change,
      {
        id: change.length,
        created_at: moment().toISOString(),
        isEditing: true,
        isNew: true,
        street: '',
        city: '',
        zipcode: '',
        country: '',
        updated_at: '',
      },
    ]);
  };

  const handleChange = (
    {
      target: { name, value },
    }:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | { target: { name: string; value: string } },
    index: number
  ) => {
    const change = [...locations];
    change[index][name as propType] = value;

    if (name === 'zipcode') {
      if (value.length > 5) {
        checkZipcode(value);
      }
    }

    setLocations(change);
  };

  const handleSave = async (index: number) => {
    if (
      locations[index].city !== '' &&
      locations[index].street !== '' &&
      locations[index].zipcode !== '' &&
      onPost &&
      onPatch
    ) {
      const change = [...locations];
      const response = change[index].isNew
        ? await onPost(change[index], index)
        : await onPatch(change[index], index);
      if (change[index].isNew) change[index].id = response.id;
      change[index].isEditing = false;
      change[index].isNew = false;
      setLocations(change);
    }
  };

  const handleEdit = (index: number, reset: React.Dispatch<React.SetStateAction<ILocation>>) => {
    const change = [...locations];
    const otherIndex = locations.findIndex(location => location.isEditing || location.isNew);

    if (otherIndex > -1) {
      if (
        change[otherIndex].city !== '' &&
        change[otherIndex].street !== '' &&
        change[otherIndex].zipcode !== ''
      ) {
        change[otherIndex].isEditing = false;
        reset(change[otherIndex]);
      } else return enqueueSnackbar('Vul eerst alle gegevens in.', { variant: 'warning' });
    }
    change[index].isEditing = true;

    setLocations(change);
  };

  const handleCancel = (index: number, oldData: ILocation) => {
    const change = [...locations];
    change[index] = oldData;
    change[index].isEditing = false;
    setLocations(change);
  };

  const handleDeletion = async (index: number) => {
    let response;
    if (!locations[index].isNew && onDelete) response = await onDelete(locations[index], index);

    if (!response || (response as GenericStatus).status === 'ok' || (response as ILocation).city) {
      setLocations(locations.filter((_e, otherIndex) => otherIndex !== index));
    }
  };

  const handleSubmit = () => {
    const change = [...locations];
    const index = locations.findIndex(location => location.isEditing || location.isNew);
    if (index > -1) {
      if (
        change[index].city !== '' &&
        change[index].street !== '' &&
        change[index].zipcode !== ''
      ) {
        handleSave(index);
      }
    }
  };

  const checkZipcode = (zipcode: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: jwt(),
      },
    };
    fetch(
      `https://nominatim.openstreetmap.org/search/${zipcode}?format=json&addressdetails=1&limit=1`,
      requestOptions
    )
      .then(r => r.json())
      .then(r => {
        if (r.length > 0) {
          setValidAdressError(false);
        } else {
          setValidAdressError(true);
        }
      });
  };

  useEffect(() => {
    submitEvent && document.addEventListener(submitEvent, handleSubmit);

    return () => {
      submitEvent && document.removeEventListener(submitEvent, handleSubmit);
    };
  }, [locations]);

  useEffect(() => {
    setLocations(user_locations);
  }, [user_locations]);

  return (
    <List sx={{ m: "0px" }}>
      {locations.map((location, index) =>
      (
        <LocationItem
          index={index}
          readOnly={readOnly}
          onChange={(e) => handleChange(e, index)}
          onSave={() => handleSave(index)}
          onDelete={() => handleDeletion(index)}
          onEdit={(_, r) => handleEdit(index, r)}
          onCancel={(_, old) => handleCancel(index, old)}
          validAdressError={validAdressError}
          {...location}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          isEditing={location.isEditing}
        />

      ))}
      {!readOnly && (
        <ListItem button onClick={handleAdd}>
          <ListItemText primary="Voeg een locatie toe" />
          <Add color="primary" />
        </ListItem>
      )}
    </List>
  );
};

export default LocationList;
