import React, { FormEvent } from 'react';

import { Grid } from '@mui/material';

import CreatePasswordFormMobile from './MobileComponent/CreatePasswordFormMobile';
import CreatePasswordMobileTopHeader from './MobileComponent/CreatePasswordMobileTopHeader';

interface ICreatePasswordMobileProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  status: string;
  setPassword: (value: string) => void;
  setPasswordConfirm: (value: string) => void;
}

const CreatePasswordMobile: React.FunctionComponent<ICreatePasswordMobileProps> = ({
  handleSubmit,
  status,
  setPassword,
  setPasswordConfirm,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <CreatePasswordMobileTopHeader />
      </Grid>

      <Grid item xs={12}>
        <CreatePasswordFormMobile
          handleSubmit={handleSubmit}
          status={status}
          setPassword={setPassword}
          setPasswordConfirm={setPasswordConfirm}
        />
      </Grid>
    </Grid>
  );
};

export default CreatePasswordMobile;
