import * as React from 'react';
import { useEffect } from 'react';

import { Card, CardContent, Typography } from '@mui/material';

import { appClient } from 'App';
import { RegularStudent } from 'assets';
import AvailabilityGrid from 'components/Functional/Availability/AvailabilityGrid';
import useUser from 'functions/Hooks/useUser';
import { DayNumber } from 'interfaces/Availability';

interface GuardianLessonsProps {}

const GuardianAvailabilities: React.FunctionComponent<GuardianLessonsProps> = () => {
  const { user } = useUser();
  const [students, setStudents] = React.useState<RegularStudent[]>();

  const handleNext = (event: CustomEvent<() => void>) => {
    event.detail();
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext as EventListener);

    return () => {
      document.removeEventListener('onNext', handleNext as EventListener);
    };
  }, []);

  useEffect(() => {
    appClient.guardians
      .getGuardiansViewGuardianSimple(user?.guardian?.id || 0)
      .then(({ students }) => {
        if (students !== null) {
          setStudents(students);
        }
      });
  }, [user]);

  return (
    <>
      {students?.map(student => (
        <>
          <Typography variant="h2">Dit is de beschikbaarheid van uw kind.</Typography>
          <Card
            elevation={0}
            sx={{
              background: '#f5f5f5',
              borderRadius: '10px',
              margin: '10px 0px',
            }}>
            <CardContent>
              <Typography variant="h3">
                De beschikbaarheid van {student.first_name} {student.last_name}
              </Typography>
            </CardContent>
            <AvailabilityGrid
              user_locations={student.user_locations}
              availability={(student.availabilities || []).map(availability => ({
                ...availability,
                day_number: availability.day_number as DayNumber,
              }))}
              onDelete={availabilityItem => {
                appClient.guardians.deleteGuardiansDeleteAvailability(availabilityItem?.id || 0);
              }}
              onAdd={availabilityItem => {
                appClient.guardians.postGuardiansAddAvailabilityToStudent(
                  student.id || 0,
                  availabilityItem.user_location?.id || 0,
                  availabilityItem
                );
              }}
            />
          </Card>
        </>
      ))}
    </>
  );
};

export default GuardianAvailabilities;
