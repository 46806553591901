import * as React from 'react';
import { useEffect, useState } from 'react';

import { Warning } from '@mui/icons-material';
import {
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { appClient } from 'App';
import {
  BaseDiscipline,
  GenderEnum,
  RegularStudent,
  SchoolLevelCoachEnum,
  SchoolLevelEnum_Output,
  StudentEdit,
  UserLocationIn,
} from 'assets';
import { TextField } from 'components/Elements/Forms/Inputs';
import LocationList from 'components/Functional/Lists/LocationList';
import {
  checkBSN,
  checkIBAN,
  checkMail,
  checkPhone,
  checkZip,
} from 'functions/HelperFunctions/Regex';
import useUser from 'functions/Hooks/useUser';
import { ILocation } from 'interfaces/Location';

import EditForm from '../Components/EditForm';

interface PersonalDetailsProps {}

type studentProps =
  | 'first_name'
  | 'last_name'
  | 'gender'
  | 'date_of_birth'
  | 'school_level'
  | 'school_year';
const studentInfoToCheck: studentProps[] = [
  'first_name',
  'last_name',
  'gender',
  'date_of_birth',
  'school_level',
  'school_year',
];
type DisciplineChangeFunctionType = (
  student: RegularStudent,
  discipline: BaseDiscipline,
  checked?: boolean
) => void;
interface propsToValidateType {
  [key: string]: (value: any) => RegExpMatchArray | boolean | null;
}
const propsToValidate: propsToValidateType = {
  email_address: checkMail,
  phone_number: checkPhone,
  zipcode: checkZip,
  bsn: checkBSN,
  iban: checkIBAN,
};

const StudentDetails: React.FunctionComponent<PersonalDetailsProps> = () => {
  const { user } = useUser();
  const [students, setStudents] = useState<RegularStudent[]>([]);
  const [possibleDisciplines, setPossibleDisciplines] = useState<BaseDiscipline[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleNext = async (event: CustomEvent<() => void>) => {
    for (let jIndex = 0; jIndex < students.length; jIndex++) {
      const student = students[jIndex];
      for (let index = 0; index < studentInfoToCheck.length; index++) {
        const key = studentInfoToCheck[index];
        const value = student?.[key];
        if (!value) return enqueueSnackbar(`${key} is nog niet ingevuld`, { variant: 'warning' });
        if (Object.keys(propsToValidate).includes(key) && !propsToValidate[key](value))
          return enqueueSnackbar(`${key} is niet correct ingevuld`, { variant: 'error' });
      }

      if (student?.user_locations?.length && student?.user_locations?.length < 1)
        enqueueSnackbar(`Geen bijles locatie toegevoegd`, { variant: 'warning' });
      if (student?.disciplines?.length && student?.disciplines?.length < 1)
        enqueueSnackbar(`Geen vakken toegevoegd`, { variant: 'warning' });

      await appClient.guardians.patchGuardiansEditStudent(student.id, student as StudentEdit);
      await appClient.guardians.patchGuardiansBulkRemoveDisciplinesFromStudent(
        student.id,
        possibleDisciplines.map(item => item.id)
      );
      await appClient.guardians.patchGuardiansBulkAddDisciplinesToStudent(
        student.id,
        student?.disciplines?.map(item => item.id) || []
      );
    }

    event.detail();
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext as unknown as EventListener);

    return () => {
      document.removeEventListener('onNext', handleNext as unknown as EventListener);
    };
  }, [students]);

  useEffect(() => {
    appClient.lessons.getLessonsViewDisciplines().then(result => {
      if (result !== null) setPossibleDisciplines(result);
    });
  }, []);

  useEffect(() => {
    appClient.guardians
      .getGuardiansViewGuardianSimple(user!.guardian!.id)
      .then(({ students }) => setStudents(students!));
  }, [user]);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    student: RegularStudent
  ) => {
    const value = event.target.value;
    const name = event.target.name as studentProps;
    const change = [...students];
    const index = change.findIndex(search => search.id === student.id);
    if (name === 'school_level') change[index][name] = value as SchoolLevelEnum_Output;
    else if (name === 'gender') change[index][name] = value as GenderEnum;
    else if (name === 'school_year') change[index][name] = Number(value);
    else change[index][name] = value;
    setStudents(change);
  };

  const handleDisciplineChange: DisciplineChangeFunctionType = (student, discipline, checked) => {
    const index = students.findIndex(search => search.id === student.id);
    if (checked) student.disciplines = [...(student.disciplines as BaseDiscipline[]), discipline];
    else if (student.disciplines != null)
      student.disciplines = student.disciplines?.filter(search => search.id !== discipline.id);

    const change = [...students];
    change[index] = student;
    setStudents(change);
  };

  const schoolVakkenRadio = (student: RegularStudent, disciplines: BaseDiscipline[]) => {
    return (
      <>
        {possibleDisciplines.map(discipline => {
          return (
            <Grid item xs={12} lg="auto">
              <FormControlLabel
                // value="huis"
                control={
                  <Radio
                    name={discipline.name}
                    checked={disciplines.findIndex(search => discipline.id === search.id) !== -1}
                    onClick={() =>
                      handleDisciplineChange(
                        student,
                        discipline,
                        !(disciplines.findIndex(search => discipline.id === search.id) !== -1)
                      )
                    }
                  />
                }
                label={discipline.name}
                // onClick={() => setVak(vak)}
              />
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <>
      {students.map(student => (
        <Grid container spacing={1} justifyContent="">
          <EditForm
            title={`Identiteit van ${student.first_name} ${student.last_name}`}
            subheader={`Gevevens die horen bij het profiel van ${student.first_name} ${student.last_name}`}
            ComponentsWhenEditing={() => (
              <>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    onChange={event => handleChange(event, student)}
                    label="Voornaam"
                    name="first_name"
                    value={student?.first_name || ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    onChange={event => handleChange(event, student)}
                    label="Achternaam"
                    name="last_name"
                    value={student?.last_name || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    onChange={event => handleChange(event, student)}
                    label="Geslacht"
                    name="gender"
                    value={student?.gender || ''}>
                    <MenuItem value="mannelijk">mannelijk</MenuItem>
                    <MenuItem value="vrouwelijk">vrouwelijk</MenuItem>
                    <MenuItem value="overig">overig</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="date"
                    fullWidth
                    onChange={event => handleChange(event, student)}
                    label="Geboortedatum"
                    name="date_of_birth"
                    value={student.date_of_birth}
                  />
                </Grid>
              </>
            )}
            ComponentsWhenNotEditing={() => (
              <>
                <Grid item xs="auto">
                  <Typography variant="h3" color="initial">
                    Voornaam
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography textAlign="right" variant="h4" color="initial">
                    {student?.first_name || <Warning color="error" />}
                  </Typography>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs="auto">
                  <Typography variant="h3" color="initial">
                    Achternaam
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography textAlign={'right'} variant="h4" color="initial">
                    {student?.last_name || <Warning color="error" />}
                  </Typography>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs="auto">
                  <Typography variant="h3" color="initial">
                    Geslacht
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography noWrap textAlign="right" variant="h4" color="initial">
                    {student?.gender}
                  </Typography>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs="auto">
                  <Typography variant="h3" color="initial">
                    Geboortedatum
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography noWrap textAlign="right" variant="h4" color="initial">
                    {moment(student?.date_of_birth).format('D MMMM yyyy')}
                  </Typography>
                </Grid>
                <Grid item xs={12} />
              </>
            )}
          />
          <EditForm
            title="Schoolgegevens"
            subheader="De schoolgevens die horen bij deze student"
            ComponentsWhenEditing={() => (
              <>
                <Grid item xs>
                  <TextField
                    select
                    value={student?.school_level || 'vmbo_basis'}
                    onChange={e => handleChange(e as React.ChangeEvent<HTMLInputElement>, student)}
                    fullWidth
                    label="Hoogstbehaalde opleiding"
                    name="school_level">
                    {Object.keys(SchoolLevelCoachEnum).map(school_level => (
                      <MenuItem value={school_level.toLowerCase().replace('_', '-')}>
                        {school_level.toLowerCase().replace('_', ' ')}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    type="number"
                    fullWidth
                    onChange={event => handleChange(event, student)}
                    label="Schooljaar"
                    name="school_year"
                    value={student?.school_year || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3" color="initial">
                    Vakken
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      sx={{ marginLeft: '20px' }}>
                      {schoolVakkenRadio(student, student.disciplines || [])}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            )}
            ComponentsWhenNotEditing={() => (
              <>
                <Grid item xs="auto">
                  <Typography variant="h3" color="initial">
                    Niveau
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography textAlign="right" variant="h4" color="initial">
                    {student?.school_level || <Warning color="error" />}
                  </Typography>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs="auto">
                  <Typography variant="h3" color="initial">
                    Schooljaar
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography textAlign="right" variant="h4" color="initial">
                    {student?.school_year || <Warning color="error" />}
                  </Typography>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs="auto">
                  <Typography variant="h3" color="initial">
                    Vakken
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography align="right" variant="h4" color="initial">
                    {student?.disciplines && student.disciplines.length > 0 ? (
                      student?.disciplines?.map(
                        (discipline, index) =>
                          `${discipline.name}${
                            index !== (student?.disciplines?.length || 0) - 1 ? ',' : ''
                          } `
                      )
                    ) : (
                      <Warning color="error" />
                    )}
                  </Typography>
                </Grid>
              </>
            )}
          />
          <Grid item xs={12}>
            <CardHeader
              sx={{ padding: '16px 0px' }}
              title={`Bijleslocaties`}
              subheader={`Locaties waar de student bijles krijgt`}
            />
          </Grid>
          <Grid item xs={12}>
            <LocationList
              user_locations={student.user_locations || []}
              onPost={async value => {
                const locationIn: UserLocationIn = {
                  city: value.city as string,
                  street: value.street as string,
                  country: value.country || 'Netherlands',
                  zipcode: value.zipcode as string,
                };

                return await appClient.guardians
                  .postGuardiansAddStudentLocation(student.id, locationIn)
                  .then(r => r.user_locations?.[r.user_locations?.length - 1] as ILocation);
              }}
              onPatch={async value => {
                const locationIn: UserLocationIn = {
                  city: value.city as string,
                  street: value.street as string,
                  country: value.country || 'Netherlands',
                  zipcode: value.zipcode as string,
                };

                return await appClient.guardians.patchGuardiansEditUserLocation(
                  student.id,
                  locationIn
                );
              }}
              onDelete={async value => {
                return await appClient.guardians
                  .deleteGuardiansDeleteUserLocation(value.id)
                  .then(() => value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default StudentDetails;
