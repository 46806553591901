import * as React from 'react';
import { useEffect } from 'react';

import { Call, Person } from '@mui/icons-material';
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { appClient } from 'App';
import { SimpleCoach } from 'assets';
import { Button } from 'components/Elements/Forms/Inputs';
import useUser from 'functions/Hooks/useUser';

interface GuardianLessonsProps {}

const GuardianLessons: React.FunctionComponent<GuardianLessonsProps> = () => {
  const { user } = useUser();
  const [status, setStatus] = React.useState('idle');
  const [guardianCoaches, setGuardianCoaches] = React.useState<SimpleCoach[]>();
  const { enqueueSnackbar } = useSnackbar();
  const handleNext = (event: CustomEvent<() => void>) => {
    switch (status) {
      case 'Has no lessons':
        event.detail();
        break;
      case 'Coaches accurate':
        event.detail();
        break;
      case 'Coaches inaccurate':
        enqueueSnackbar('Neem contact met ons op', { variant: 'info' });
        break;
      default:
        enqueueSnackbar('Geef eerst antwoord', { variant: 'info' });
        break;
    }
  };

  const handleRadioButton1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus((event.target as HTMLInputElement).value);
  };

  const handleRadioButton2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext as EventListener);

    return () => {
      document.removeEventListener('onNext', handleNext as EventListener);
    };
  }, [status]);

  useEffect(() => {
    appClient.guardians.getGuardiansViewGuardianCoaches(user?.guardian?.id || 0).then(result => {
      if (result !== null) {
        setGuardianCoaches(result);
      }
    });
  }, [user]);

  return (
    <>
      <Collapse in={status == 'idle'}>
        <Box>
          <Typography variant="h2">
            Heeft een van uw kinderen op dit moment een bijlescoach?
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              onChange={handleRadioButton1}>
              <FormControlLabel value="Has lessons" control={<Radio />} label="Ja" />
              <FormControlLabel value="Has no lessons" control={<Radio />} label="Nee" />
            </RadioGroup>
          </FormControl>
        </Box>
      </Collapse>
      {/* Wanneer geen bijles */}
      <Collapse in={status == 'Has no lessons'}>
        <Typography variant="h2">Bedankt, ga verder met stap 3.</Typography>
      </Collapse>
      {/* Wanneer wel bijles */}
      <Collapse in={status == 'Has lessons'}>
        <Grid container sx={{ marginTop: '15px' }}>
          <Grid item>
            <Typography variant="h2">Dit zijn uw coaches, klopt dat?</Typography>
            {(!guardianCoaches || guardianCoaches?.length <= 0) && (
              <>
                <Typography>
                  We hebben geen coaches gevonden, heeft u het juiste antwoord geven? Neem anders
                  contact op met onze klantenservice:
                </Typography>
                <Grid container sx={{ marginTop: '15px' }}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}>
                      <Call />
                      Telefoonnummer
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginTop: '15px' }}>
                    <Button onClick={() => setStatus('idle')}>Check antwoord</Button>
                  </Grid>
                </Grid>
              </>
            )}
            {guardianCoaches?.map(item => (
              <>
                <Typography
                  sx={{
                    marginTop: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                  <Person /> {item.user.first_name + ' ' + item.user.last_name}
                </Typography>
              </>
            ))}
            {guardianCoaches && (
              <FormControl sx={{ marginTop: '5px' }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  onChange={handleRadioButton2}>
                  <FormControlLabel
                    value="Coaches accurate"
                    control={<Radio />}
                    label="Ja, dit klopt"
                  />
                  <FormControlLabel
                    value="Coaches inaccurate"
                    control={<Radio />}
                    label="Nee, dit klopt niet"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
        </Grid>
      </Collapse>
      {/* Coaches kloppen */}
      <Collapse in={status == 'Coaches accurate'}>
        <Grid container sx={{ marginTop: '15px' }}>
          <Grid item>
            <Typography variant="h2">Bedankt! Ga verder op stap 3.</Typography>
          </Grid>
        </Grid>
      </Collapse>
      {status == 'Coaches inaccurate' && (
        <Grid container sx={{ marginTop: '15px' }}>
          <Grid item>
            <Typography variant="h2">Excuses, Het blijkt dat er iets is misgegaan.</Typography>
            <Typography>
              Als niet al uw coaches zijn weergeven zijn, is er iets fout gegaan aan onze kant. Neem
              contact op met ons klantenservice zodat wij u verder kunnen helpen:
            </Typography>
            <Typography
              sx={{
                marginTop: '5px',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
              <Call />
              Telefoonnummer
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default GuardianLessons;
