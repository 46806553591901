/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WebhooksService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Return Challenge From Facebook
     * @param hubVerifyToken
     * @param hubChallenge
     * @returns any Successful Response
     * @throws ApiError
     */
    public getWebhooksReturnChallengeFromFacebook(
        hubVerifyToken: string,
        hubChallenge: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/webhooks/facebook/new_lead/',
            query: {
                'hub.verify_token': hubVerifyToken,
                'hub.challenge': hubChallenge,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Data From Facebook Leadgen Webhook
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public postWebhooksGetDataFromFacebookLeadgenWebhook(
        requestBody?: any,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhooks/facebook/new_lead/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Long Lived User Token
     * @param shortLivedUserToken
     * @returns any Successful Response
     * @throws ApiError
     */
    public getWebhooksGetLongLivedUserToken(
        shortLivedUserToken: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/webhooks/facebook/long_lived_user_token/',
            query: {
                'short_lived_user_token': shortLivedUserToken,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Long Lived Page Token
     * @param longLivedUserToken
     * @returns any Successful Response
     * @throws ApiError
     */
    public getWebhooksGetLongLivedPageToken(
        longLivedUserToken: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/webhooks/facebook/get_long_lived_page_token/',
            query: {
                'long_lived_user_token': longLivedUserToken,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Register Facebook Webhook
     * @returns any Successful Response
     * @throws ApiError
     */
    public postWebhooksRegisterFacebookWebhook(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhooks/facebook/register/',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Subscribed Apps
     * @returns any Successful Response
     * @throws ApiError
     */
    public getWebhooksGetSubscribedApps(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/webhooks/facebook/subscribed_apps/',
            errors: {
                404: `Not found`,
            },
        });
    }

}
