import React from 'react';

import { Grid, Typography } from '@mui/material';
import theme from 'theme';

import { useWidth } from 'functions/Hooks/useDeviceDetect';

interface IAvailabilityGridHeaderProps {
  days: Record<string, unknown>;
}

const AvailabilityGridHeader: React.FunctionComponent<IAvailabilityGridHeaderProps> = ({
  days,
}) => {
  const currentWidth = useWidth();

  return (
    <Grid container>
      <Grid item md={2} xs={2} />
      <Grid item xs={10}>
        <Grid container>
          {days &&
            Object.values(days).map((day, i) => {
              return (
                <Grid
                  key={i}
                  item
                  md={1.714}
                  xs={1.714}
                  sx={{
                    border: '1px solid gray',
                    borderRadius:
                      i === 0 ? '15px 0px 0px 0px' : i === 6 ? '0px 15px 0px 0px' : 'unset',
                    backgroundColor: theme.palette.primary.main,
                    ...(currentWidth === 'xs' && { height: '40px' }),
                  }}>
                  <Typography
                    sx={{
                      lineHeight: '48px',
                      fontWeight: '600',
                      textAlign: 'center',
                      color: 'white',
                      ...(currentWidth === 'xs' && {
                        fontSize: '14px',
                        lineHeight: '40px',
                      }),
                    }}>
                    {day as string}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AvailabilityGridHeader;
