/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EnumPaymentStatus {
    OPEN = 'open',
    PENDING = 'pending',
    EXPIRED_OR_FAILED = 'expired_or_failed',
    PAID = 'paid',
    AUTHORIZED = 'authorized',
}
