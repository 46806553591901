import * as React from 'react';

import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface ProgressSpinnerProps {
  circularProgressProps?: CircularProgressProps;
  textColor?: string;
  value: number;
  max: number;
  variant:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
  fontSize?: number;
  fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 'bold';
}

function ProgressSpinner(props: ProgressSpinnerProps) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
      }}>
      <CircularProgress
        {...props.circularProgressProps}
        variant="determinate"
        value={(100 / props.max) * props.value}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography
          variant={props.variant}
          component="div"
          fontSize={props.fontSize || 18}
          fontWeight={props.fontWeight || 400}
          color={props?.textColor || 'text.secondary'}>{`${Math.round(props.value)} / ${
          props.max
        }`}</Typography>
      </Box>
    </Box>
  );
}

export default ProgressSpinner;
