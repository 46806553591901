import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';

import {
  AppBar,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';

import { appClient } from 'App';
import { BaseStub } from 'assets';
import BEUBanner from 'assets/img/brand/BEU_header_COREcontainer.jpg';
import SWLogo from 'assets/img/brand/logo-text-dark.svg';
import OBLogo from 'assets/img/brand/LOGO_OB.png';
import BEULogo from 'assets/img/brand/LogoBijlesEU.png';
import OBBanner from 'assets/img/brand/OB_header_COREcontainer.jpg';
import OELogo from 'assets/img/brand/OE_logo.png';
import SWBanner from 'assets/img/brand/Oudercontainer_header.png';
import VBLBanner from 'assets/img/brand/VBL_header_COREcontainer.jpg';
import VBLLogo from 'assets/img/brand/VlakbijlesLogo.png';
import { Button } from 'components/Elements/Forms/Inputs';
import ProgressSpinner from 'components/Elements/ProgressSpinner';
import { GenerateUUID } from 'functions/HelperFunctions/GenerateUUID';
import useLabel from 'functions/Hooks/useLabel';

import ContractPage from './Pages/Contract/Contract';
import FinishPage from './Pages/FinishPage/FinishPage';
import PaspoortPage from './Pages/Paspoort/Paspoort';
import PersoonlijkeGegevensPage from './Pages/Persoonlijk/Persoonlijk';
import School from './Pages/School/School';
import StartPage from './Pages/Startpage';
import PreferencePage from './Pages/Voorkeuren/Voorkeuren';

type ComponentType = FunctionComponent<{
  setIsTransfering?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  isTransfering?: boolean;
  data?: BaseStub;
  setData?: React.Dispatch<React.SetStateAction<BaseStub | undefined>>;
}>;

interface IParam {
  key: string;
}

const scrollToRef = () => window.scrollTo(0, document.documentElement.offsetTop);

const Landing: React.FunctionComponent = () => {
  const [data, setData] = useState<BaseStub>();
  const { key: secretkey } = useParams<IParam>();
  const [isTransfering, setIsTransfering] = React.useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const label = useLabel();
  const theme = useTheme();
  const pages = [
    {
      title: 'Welkom',
      Component: StartPage as ComponentType,
      toPipe: 150,
    },
    {
      title: 'Persoonsgegevens',
      Component: PersoonlijkeGegevensPage as ComponentType,
      toPipe: 154,
    },
    {
      title: 'Schoolgegevens',
      Component: School as ComponentType,
      toPipe: 152,
    },
    {
      title: 'Beschikbaarheid',
      Component: PreferencePage as ComponentType,
      toPipe: 155,
    },
    {
      title: 'Paspoort / ID-kaart',
      Component: PaspoortPage as ComponentType,
      toPipe: 157,
    },
    {
      title: 'Overeenkomst',
      Component: ContractPage as ComponentType,
    },
    {
      title: 'Akkoord',
      Component: FinishPage as ComponentType,
    },
  ];

  const currentPage = pages[currentIndex];

  const history = useHistory();
  React.useEffect(() => {
    document.title = 'Coachflow';
  });

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [trigger]);

  React.useEffect(() => {
    setTrigger(false);
    scrollToRef();
  }, [currentIndex]);

  const handleScroll = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 75 && !trigger) setTrigger(true);
    if (scrolled < 75 && trigger) setTrigger(false);
  };

  const whiteLabelBanner = () => {
    if (label?.name) {
      switch (label.name.toLowerCase()) {
        case 'studyworks':
          return SWBanner;
        case 'bijles.eu':
          return BEUBanner;
        case 'online-bijles.com':
          return OBBanner;
        case 'online-examentraining.com':
          return OBBanner;
        case 'vlakbijles':
          return VBLBanner;
      }
    } else {
      return SWBanner;
    }
  };

  const whiteLabelLogo = () => {
    if (label?.name) {
      switch (label.name.toLowerCase()) {
        case 'studyworks':
          return SWLogo;
        case 'bijles.eu':
          return BEULogo;
        case 'online-bijles.com':
          return OBLogo;
        case 'online-examentraining.com':
          return OELogo;
        case 'vlakbijles':
          return VBLLogo;
      }
    } else {
      return SWLogo;
    }
  };

  useEffect(() => {
    if (secretkey) {
      appClient.stubs.getStubsReadStub(secretkey).then((r: BaseStub) => {
        if (!r.contract_key) {
          r.contract_key = GenerateUUID();
        }

        setData(r);
      });
    }
  }, [secretkey]);

  const handleNext = async () => {
    const event = new CustomEvent('onNext', {
      detail: () => {
        setCurrentIndex(currentIndex + 1);
        setIsTransfering(false);
      },
    });
    if (currentIndex < 6) {
      if (currentPage.toPipe)
        await appClient.stubs
          .postStubsChangePipeSimple(secretkey, currentPage.toPipe)
          .then(() => {})
          .catch(() => {});

      await appClient.stubs.postStubsUpdateStub(secretkey, { data_bundle: data?.data_bundle });
      await document.dispatchEvent(event);
    } else {
      history.push(`/reset`);
    }
  };

  return (
    <div>
      <Grid container direction={'column'}>
        <Grid item xs={12} sx={{ padding: '20px' }}>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12}>
              <img
                src={whiteLabelLogo()}
                alt="logo"
                style={{
                  float: 'left',
                  height: '60px',
                  objectFit: 'contain',
                  maxWidth: '150px',
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          sx={{
            height: '114px',
            background: `url(${whiteLabelBanner()}) left no-repeat`,
          }}>
          <AppBar
            sx={{ background: `url(${whiteLabelBanner()}) right no-repeat` }}
            elevation={0}
            position={!trigger ? 'static' : 'fixed'}>
            <Container
              sx={{
                paddingTop: '16px',
                paddingBottom: '16px',
              }}>
              <CardHeader
                sx={{ padding: '0px' }}
                title={'Stap ' + (currentIndex + 1)}
                titleTypographyProps={{
                  color: 'white',
                  variant: 'h3',
                }}
                subheader={currentPage.title}
                subheaderTypographyProps={{
                  color: 'white',
                  variant: 'h1',
                }}
                avatar={
                  <div
                    style={{
                      borderRight: '1px solid rgba(255, 255, 255, .5)',
                      paddingRight: '15px',
                    }}>
                    <ProgressSpinner
                      max={pages.length}
                      value={currentIndex + 1}
                      variant="h5"
                      textColor="white"
                      circularProgressProps={{
                        sx: { color: 'white' },
                        size: 75,
                      }}
                    />
                  </div>
                }
              />
            </Container>
          </AppBar>
        </Grid>
      </Grid>

      <Grid item>
        <Container
          sx={{
            paddingBottom: '5px',
            [theme.breakpoints.down('sm')]: { padding: '0px !important' },
          }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Grid container direction={'column'}>
                {data ? (
                  <currentPage.Component
                    isTransfering={isTransfering}
                    setIsTransfering={setIsTransfering}
                    data={data}
                    setData={setData}
                  />
                ) : (
                  <LinearProgress />
                )}
              </Grid>
            </Grid>
            <Grid item container sx={{ padding: '5px' }}>
              <Grid item xs>
                {currentIndex < 6 && currentIndex > 0 && (
                  <Button variant="outlined" onClick={() => setCurrentIndex(currentIndex - 1)}>
                    <Typography variant={'body2'} component={'p'}>
                      Vorige
                    </Typography>
                  </Button>
                )}
              </Grid>

              <Grid item xs="auto">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isTransfering}
                  onClick={() => handleNext()}>
                  {currentIndex < 6 ? (
                    <Typography variant={'body2'} component={'p'}>
                      {isTransfering ? (
                        <CircularProgress sx={{ color: 'white' }} />
                      ) : (
                        <>
                          {currentIndex !== 5 && `Naar stap ${currentIndex + 2}: `}
                          {pages[currentIndex + 1] ? pages[currentIndex + 1].title : 'voltooid'}
                        </>
                      )}
                    </Typography>
                  ) : (
                    <Typography variant={'body2'} component={'p'}>
                      Stel een wachtwoord in
                    </Typography>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </div>
  );
};

export default Landing;
