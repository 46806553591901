// Change a price string so it uses a comma instead of a dot for decimals, rounds to 2 decimals or a - if it has no decimals.
export const handlePriceDecimalsNL = (price: number): string => {
  let p = ""
  if (price % 1 !== 0) {
    p = price.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  else {
    p = price.toString() + ",-";
  }

  return p
}