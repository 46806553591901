import { Grid, Paper, Typography, Card, Container, Fab, IconButton } from '@mui/material';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button } from 'components/Elements/Forms/Inputs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useTheme } from '@mui/material/styles';
import { useHistory, useParams } from 'react-router-dom';
// import { Spinner } from 'reactstrap';
import Popup from './popup/Popup';
import './Paspoort.css';
import { Add, Edit, Help, Upload } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { QuestionMark } from '@mui/icons-material';

const PasportPage = ({ setData, data }) => {
  // const { id_card_back_key, id_card_front_key } = data.data_bundle;
  const theme = useTheme();
  const themeColor = theme.palette.primary.main;
  const [spinner, setSpinner] = useState(false);
  const [themeMessage, setTheme] = useState('neutral');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCount, setErrorCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const { key: secretkey } = useParams();

  const getBase64 = async (file, name) => {
    const API_ENDPOINT = `${process.env.REACT_APP_BRANCH}/stubs/stub/${secretkey}/add_attachement/${name}`;
    const request = new XMLHttpRequest();
    const formData = new FormData();

    request.open('POST', API_ENDPOINT, true);
    // request.setRequestHeader("accept", "application/json"());
    request.onreadystatechange = r => {
      if (request.readyState == 4 && request.status == 200) {
        let newState = JSON.parse(request.responseText);
        setData(newState);
      }
    };
    formData.append('attachment', file);
    request.send(formData);
  };

  const setField = event => {
    getBase64(event.target.files[0], event.target.name);
    // setData({
    //   ...data,
    //   [event.target.name]:
    // });
  };

  React.useEffect(() => {
    document.addEventListener('onNext', handleNext);
    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [data]);

  const handleNext = async event => {
    if (!data.data_bundle.id_card_front_key) {
      enqueueSnackbar('Geen voorkant geüpload.', { variant: 'error' });
      return;
    }
    if (!data.data_bundle.id_card_back_key) {
      enqueueSnackbar('Geen achterkant geüpload.', { variant: 'error' });
      return;
    }
    event.detail();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  // useEffect(() => {
  //   if (errorCount === 5) {
  //     setData({ type: "updateFormOK", name: "paspoort", value: true });
  //     // Boolean zodat recruitement er naar kan kijken
  //     setData({
  //       type: "updateFormOK",
  //       name: "manualCheckPaspoort",
  //       value: true,
  //     });
  //     setSpinner(false);
  //     setTheme("server");
  //     setErrorMessage(
  //       `De foto's worden handmatig nagekeken, je kunt voorlopig verder om de gegevens in te voeren`
  //     );
  //   }
  // }, [errorCount]);

  return (
    <>
      <Card elevation={0} square={true} sx={{ marginTop: '15px' }}>
        <Grid container spacing={1}>
          <Popup open={isOpen} onClose={handleClose}></Popup>
          <Grid item xs="12" md="8">
            <Typography variant="h1">Upload je paspoort / ID-kaart</Typography>
          </Grid>
          <Grid item xs="12" md="12">
            <p className="subtekst">
              Om jouw identiteit te controleren, hebben wij een foto nodig van de voor- en
              achterkant van jouw paspoort of ID-kaart. Hierop moeten alle gegevens leesbaar zijn.
              Een watermerk mag wel worden toegevoegd.
              <IconButton onClick={event => setIsOpen(true)}>
                <Help
                  color="primary"
                  sx={{
                    width: '25px',
                    height: '25px',
                  }}
                />
              </IconButton>
            </p>
          </Grid>
          {errorMessage && (
            <Grid item xs="12" className={themeMessage}>
              {errorMessage && <p>{errorMessage}</p>}
              {/* {spinner && <Spinner color="primary" />} */}
              {themeMessage === 'error' && (
                <p
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                  onClick={() => setIsOpen(true)}>
                  Let op deze punten als je een foto maakt!
                </p>
              )}
            </Grid>
          )}

          <Grid item xs="12" md="6">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="left"
              sx={{ marginBottom: '15px' }}>
              <Grid item xs>
                <Typography variant="h2">Voorkant paspoort/ID-kaart</Typography>
              </Grid>
            </Grid>
            <Container
              style={{
                width: '100%',
                height: '300px',
                position: 'relative',
                backgroundColor: '#e5e5e5',
                marginBottom: '25px',
                display: 'flex',
                zIndex: '0',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {data?.data_bundle?.id_card_front_key ? (
                <img
                  className="desktopPhoto"
                  alt="voorkant id"
                  src={
                    process.env.REACT_APP_PROFILEPHOTOBUCKET +
                    '/' +
                    data.data_bundle.id_card_front_key
                  }></img>
              ) : (
                <>
                  <div className="inner-box" style={{ border: `2px solid ${themeColor}` }}>
                    <PermIdentityIcon color="primary" className="personIcon" />
                    <div className="id-lines">
                      <div className="id-line" style={{ backgroundColor: themeColor }}></div>
                      <div className="id-line" style={{ backgroundColor: themeColor }}></div>
                      <div className="id-line" style={{ backgroundColor: themeColor }}></div>
                    </div>
                  </div>
                </>
              )}

              <Fab
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  margin: '10px',
                  right: '0',
                  bottom: '0',
                }}
                color="primary"
                aria-label="add">
                <Button variant="hidden" component="label" color="primary">
                  <input
                    type="file"
                    name="id_card_front_key"
                    accept="image/*"
                    onChange={setField}
                    hidden
                  />
                  {data?.data_bundle?.id_card_front_key ? <Edit /> : <Upload />}
                </Button>
              </Fab>
            </Container>
          </Grid>

          <Grid item xs="12" md="6">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="left"
              sx={{ marginBottom: '15px' }}>
              <Grid item xs>
                <Typography variant="h2">Achterkant paspoort/ID-kaart</Typography>
              </Grid>
            </Grid>
            <Container
              style={{
                width: '100%',
                height: '300px',
                position: 'relative',
                backgroundColor: '#e5e5e5',
                marginBottom: '25px',
                display: 'flex',
                zIndex: '0',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {data?.data_bundle?.id_card_back_key ? (
                <img
                  className="desktopPhoto"
                  alt="achterkant id"
                  src={
                    process.env.REACT_APP_PROFILEPHOTOBUCKET +
                    '/' +
                    data.data_bundle.id_card_back_key
                  }></img>
              ) : (
                <div className="inner-box" style={{ border: `2px solid ${themeColor}` }}>
                  <PermIdentityIcon color="primary" className="personIcon" />
                  <div className="id-lines">
                    <div className="id-line" style={{ backgroundColor: themeColor }}></div>
                    <div className="id-line" style={{ backgroundColor: themeColor }}></div>
                    <div className="id-line" style={{ backgroundColor: themeColor }}></div>
                  </div>
                </div>
              )}
              <Fab
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  margin: '10px',
                  right: '0',
                  bottom: '0',
                }}
                color="primary"
                aria-label="add">
                <Button variant="hidden" component="label" color="primary">
                  <input
                    type="file"
                    name="id_card_back_key"
                    accept="image/*"
                    onChange={setField}
                    hidden
                  />
                  {data?.data_bundle?.id_card_back_key ? <Edit /> : <Upload />}
                </Button>
              </Fab>
            </Container>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default PasportPage;
