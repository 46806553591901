import React from 'react';

import { Grid } from '@mui/material';

import LoginFormDesktop from './Components/ResetFormDesktop';
import LoginImageWIndow from './Components/ResetImageWIndow';

interface IResetDesktopProps {
  handleSubmit: () => void;
  status: string;
  setStatus: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
}
const ResetDesktop: React.FunctionComponent<IResetDesktopProps> = ({
  handleSubmit,
  status,
  setStatus,
  email,
  setEmail,
}) => {
  return (
    <Grid container>
      <Grid item md={4.5}>
        <Grid container>
          <Grid item md={12} sx={{ margin: window.innerWidth > 2000 ? '10% 80px' : '20% 80px' }}>
            <LoginFormDesktop
              handleSubmit={handleSubmit}
              status={status}
              setStatus={setStatus}
              email={email}
              setEmail={setEmail}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={7.5}>
        <Grid container>
          <Grid item md={12}>
            <LoginImageWIndow />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetDesktop;
