import React from 'react';

import { Grid, LinearProgress, Typography } from '@mui/material';

import { Button } from 'components/Elements/Forms/Inputs';

interface IDualContractViewProps {
  contract: string;
  secondContract: string;
  setAllowSign: (value: boolean) => void;
}

const DualContractView: React.FunctionComponent<IDualContractViewProps> = ({
  contract,
  secondContract,
  setAllowSign,
}) => {
  const [step, setStep] = React.useState<number>(1);

  React.useEffect(() => {
    if (step === 2) {
      setAllowSign(true);
    }
  }, [step]);

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} sx={{ marginBottom: '20px' }}>
          <Grid container>
            {step === 1 && contract ? (
              <Grid item xs={12}>
                <iframe
                  style={{
                    height: '800px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  title="ContractView"
                  frameBorder="0"
                  src={contract} />
              </Grid>
            ) : (
              <LinearProgress />
            )}

            {step === 2 && secondContract ? (
              <Grid item xs={12}>
                <iframe
                  style={{
                    height: '800px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  title="ContractView"
                  frameBorder="0"
                  src={secondContract} />
              </Grid>
            ) : (
              <LinearProgress />
            )}
          </Grid>
        </Grid>

        <Grid item xs={2}>
          {step > 1 && (
            <Button variant="contained" onClick={() => setStep(1)} sx={{ float: 'left' }}>
              <Typography>Vorige</Typography>
            </Button>
          )}
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={2}>
          {step < 2 && (
            <Button variant="contained" onClick={() => setStep(2)} sx={{ float: 'right' }}>
              <Typography>Volgende</Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DualContractView;
