import React from 'react';

import { Card, CardActions, CardContent, Grid, Modal, Typography } from '@mui/material';

import { Button } from 'components/Elements/Forms/Inputs';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import { IExtendedBundleIn, IFlowPageProps } from '..';

interface IPaymentModalProps extends IFlowPageProps {
  bundle: IExtendedBundleIn;
  setBundle: (bundle: IExtendedBundleIn) => void;
  open: boolean;
  handleButtonClick: () => void;
  handlePrimaryButton: () => void;
  paymentMethod?: string;
}

const PaymentModal: React.FunctionComponent<IPaymentModalProps> = ({
  open,
  handleButtonClick,
  handlePrimaryButton,
  paymentMethod,
}) => {
  const label = useLabel();
  const currentWidth = useWidth();

  const showPaymentMethod = (): string => {
    let paymentMethodSting = '';
    switch (paymentMethod) {
      case 'achteraf':
        paymentMethodSting = 'Losse factuur';
        break;
      case 'none':
        paymentMethodSting = 'Afronden';
        break;
    }

    return paymentMethodSting;
  };

  return (
    <Modal
      open={open}
      onClose={handlePrimaryButton}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Card
        sx={{
          position: 'absolute',
          top: currentWidth === 'xs' ? '30%' : '40%',
          left: currentWidth === 'xs' ? 'unset' : '35%',
          padding: '10px',
          borderRadius: '15px',
          backgroundColor: '#f5f5f5',
          width: currentWidth === 'xs' ? '100%' : '30%',
        }}>
        <CardContent>
          {paymentMethod === 'achteraf' && (
            <Typography>
              Weet u zeker dat u met losse facturen wilt betalen? Bij een automatische incasso
              ontvangt u namelijk €10,- korting op de administratiekosten.
            </Typography>
          )}

          {paymentMethod === 'none' && (
            <Typography>
              Weet u zeker dat u door wilt gaan zonder een betalingsmethode te kiezen? Hierdoor
              kiest u automatisch voor losse facturen inclusief €2,50 per opgemaakte factuur.
            </Typography>
          )}
        </CardContent>
        <CardActions sx={{ padding: '5px 15px 15px 15px' }}>
          <Grid container spacing={currentWidth === 'xs' ? 1 : 0}>
            <Grid item xs={12} md={5}>
              <Button
                variant="outlined"
                onClick={handleButtonClick}
                fullWidth
                sx={{ padding: '8px 0px' }}>
                Terug
              </Button>
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={5}>
              <Button
                variant="contained"
                onClick={handlePrimaryButton}
                fullWidth
                sx={{
                  backgroundColor: label?.main_color,
                  float: 'right',
                  padding: '8px 0px',
                }}>
                {showPaymentMethod()}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default PaymentModal;
