import * as React from 'react';
import { FunctionComponent, useState } from 'react';

import { Check } from '@mui/icons-material';
import {
  AppBar,
  Box,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { useSnackbar } from 'notistack';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';

import { appClient } from 'App';
import { BaseStub, BundleIn, SecretKeyType, StubIn, StubType, UserIn } from 'assets';
import BEUBanner from 'assets/img/brand/BEU_header_COREcontainer.jpg';
import SWLogo from 'assets/img/brand/logo-text-dark.svg';
import OBLogo from 'assets/img/brand/LOGO_OB.png';
import BEULogo from 'assets/img/brand/LogoBijlesEU.png';
import OBBanner from 'assets/img/brand/OB_header_COREcontainer.jpg';
import OELogo from 'assets/img/brand/OE_logo.png';
import SWBanner from 'assets/img/brand/Oudercontainer_header.png';
import VBLBanner from 'assets/img/brand/VBL_header_COREcontainer.jpg';
import VBLLogo from 'assets/img/brand/VlakbijlesLogo.png';
import ProgressSpinner from 'components/Elements/ProgressSpinner';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import FooterButtons from './Components/FooterButtons';
import PackageModal from './Components/PackageModal';
import PaymentModal from './Components/PaymentModal';
import { GuardianContextProvider } from './Hooks/useGuardian';
import { ProductsContextProvider } from './Hooks/useProduct';
import InformationPage from './Pages/InformationPage';
import LessonPrefrences from './Pages/LessonPreferences';
// import PaymentMethodPage from './Pages/PaymentMethodPage';
import PaymentMethodPageOld from './Pages/PaymentMethodPageOld';
import PersonalAdvice from './Pages/PersonalAdvice';
import SchedulePage from './Pages/SchedulePage';
import SubjectPage from './Pages/SubjectsPage';
import SummaryPage from './Pages/SummaryPage';
import ThankYouPage from './Pages/ThankYouPage';
import flowroutes from './routes';

type ComponentType = FunctionComponent<{
  setIsTransfering?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  isTransfering?: boolean;
  data?: BaseStub;
  currentIndex?: number;
  setCurrentIndex?: (value: number) => void;
  setData?: React.Dispatch<React.SetStateAction<BaseStub | undefined>>;
  setMailExist?: (value: boolean) => void;
  payLater?: boolean;
  setPayLater?: (value: boolean) => void;
  skipAdvice?: boolean;
  setSkipAdvice?: (value: boolean) => void;
  skipPackage?: boolean;
  setSkipPackage?: (value: boolean) => void;
  skipPayment?: boolean;
  setSkipPayment?: (value: boolean) => void;
}>;

interface IPage {
  title: string;
  Component: ComponentType;
  toPipe?: number;
  isTransfering?: boolean;
  isFinalizing?: boolean;
  thankYouPage?: boolean;
  url?: string;
  to?: string;
  index?: number;
}

export interface IPackage {
  title?: string;
  price?: number;
  discount?: number;
  hours?: number;
  minScore?: number;
  maxScore?: number;
  selected?: boolean;
  displayMatch?: boolean;
  priority?: number;
}

export interface IBundleFlowPageProps extends IFlowPageProps {
  bundle: IExtendedBundleIn;
  setBundle: (bundle: IExtendedBundleIn) => void;
}

export interface IFlowPageProps {
  currentIndex?: number;
  setCurrentIndex?: (value: number) => void;
  setMailExist?: (value: boolean) => void;
  payLater?: boolean;
  setPayLater?: (value: boolean) => void;
  skipAdvice?: boolean;
  setSkipAdvice?: (value: boolean) => void;
  skipPackage?: boolean;
  setSkipPackage?: (value: boolean) => void;
  skipPayment?: boolean;
  setSkipPayment?: (value: boolean) => void;
}

export type valueType = string | number | Date | boolean;

export interface IParam {
  key: string;
}

const scrollToRef = () => {
  window.scrollTo(0, document.documentElement.offsetTop);
};

export interface IExtendedBundleIn extends BundleIn {
  data_bundle: any;
}

const Landing: React.FunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [indexInit, setIndexInit] = useState<boolean>();
  const [data, setData] = useState<BaseStub>();
  const [bundle, setBundle] = useState<IExtendedBundleIn>();
  const { key } = useParams<IParam>();
  const [secretkey, setSecretkey] = useState<string>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState<IPage>();
  const [trigger, setTrigger] = useState(false);
  const label = useLabel();
  const theme = useTheme();

  const [mailExist, setMailExist] = useState<boolean>(false);
  const [payLater, setPayLater] = useState<boolean>(false);
  const [skipAdvice, setSkipAdvice] = useState<boolean>(false); //TODO: dit kan netter
  const [skipPayment, setSkipPayment] = useState<boolean>(false); //TODO: dit kan netter

  const [packageModalOpen, setPackageModalOpen] = useState<boolean>(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const [maySwitchPage, setMaySwitchPage] = useState<boolean>(true);
  const history = useHistory();
  const currentWidth = useWidth();

  const [pages] = useState<IPage[]>(flowroutes as IPage[]);
  // const currentPage = pages[currentIndex];
  let init = false;

  const getBaseUrl = (): string => {
    const baseUrl = '/OuderSignUp/' + secretkey;

    return baseUrl;
  };

  React.useEffect(() => {
    if (!currentPage) {
      setCurrentPage(pages[currentIndex]);
    }
  });

  React.useEffect(() => {
    const path = document.location.pathname.split('/');
    const pageObj = pages.find(page => page.url === '/' + path[3]);
    if (pageObj) {
      setCurrentIndex(pageObj?.index as number);
    }
  }, [currentIndex]);

  React.useEffect(() => {
    if (label) {
      const tagManagerArgs: TagManagerArgs = {
        gtmId: label?.gtm_id,
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          data: bundle ? bundle : data,
        },
      };

      TagManager.initialize(tagManagerArgs);
      setIndexInit(true);
      /** THIS COULD FIX THE DATALAYER URL CHANGE */
      // TagManager.dataLayer(
      //   tagManagerArgs
      // )
    }
  }, [label, indexInit]);

  React.useEffect(() => {
    document.title = 'Ouderflow';
  });

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [trigger]);

  React.useEffect(() => {
    setTrigger(false);
    scrollToRef();
  }, [currentPage]);

  React.useEffect(() => {
    if (key) {
      setSecretkey(key);
      // history.push("/OuderSignUp/" + key + "/corebedankt");
    } else {
      if (!secretkey || secretkey === 'undefined') {
        const data: StubIn = {
          origin: StubType.GUARDIAN,
          label_id: label?.id ? label?.id : 3,
          data_bundle: {},
          comments: 'string',
        };
        appClient.stubs
          .postStubsCreateStub(data)
          .then(res => {
            if (res.secret_key) {
              setSecretkey(res.secret_key);
              // history.push("/OuderSignUp/" + res.secret_key);
              history.push('/OuderSignUp/' + res.secret_key + '/Algemenegegevens');
            }
          })
          .catch(() => {});
      }
    }
  }, [secretkey, label]);

  React.useEffect(() => {
    if (!init && secretkey) {
      appClient.stubs.getStubsGetSecretKeyType(secretkey).then((r: SecretKeyType) => {
        if (r.type === 'guardian') {
          history.push(getBaseUrl() + '/Bijlesadvies');

          const pageObj = pages.find(page => page.url === '/Bijlesadvies');
          if (pageObj) {
            setCurrentIndex(pageObj?.index as number);
          }
        } else {
          appClient.stubs.getStubsReadStub(secretkey).then(r => {
            setData(r);
          });
          history.push(getBaseUrl() + '/Algemenegegevens');
        }
        init = true;
      });
    }
  }, [secretkey]);

  const handleScroll = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 75 && !trigger) setTrigger(true);
    if (scrolled < 75 && trigger) setTrigger(false);
  };

  const whiteLabelBanner = () => {
    if (label?.name) {
      switch (label.name.toLowerCase()) {
        case 'studyworks':
          return SWBanner;
        case 'bijles.eu':
          return BEUBanner;
        case 'online-bijles.com':
          return OBBanner;
        case 'online-examentraining.com':
          return OBBanner;
        case 'vlakbijles':
          return VBLBanner;
      }
    } else {
      return SWBanner;
    }
  };

  const whiteLabelLogo = () => {
    if (label?.name) {
      switch (label.name.toLowerCase()) {
        case 'studyworks':
          return SWLogo;
        case 'bijles.eu':
          return BEULogo;
        case 'online-bijles.com':
          return OBLogo;
        case 'online-bijles':
          return OBLogo;
        case 'online-examentraining':
          return OELogo;
        case 'vlakbijles':
          return VBLLogo;
      }
    } else {
      return SWLogo;
    }
  };

  const updateStub = async () => {
    if (secretkey) {
      await appClient.stubs
        .postStubsUpdateStub(secretkey, { data_bundle: data?.data_bundle })
        .then(() => {
          setMaySwitchPage(true);
        })
        .catch((e: any) => {
          if (e.status === 422) {
            setMaySwitchPage(true); // needs to be false
            // enqueueSnackbar('Uw postcode lijkt niet te kloppen, kunt u deze controleren?', {
            //   variant: 'error',
            // });
          }
        });
    }

    return maySwitchPage;
  };

  const changePipe = async () => {
    if (currentPage) {
      if (currentPage.toPipe && secretkey) {
        await appClient.stubs.postStubsChangePipeSimple(secretkey, currentPage.toPipe);
      }
    }
  };

  const transferStub = async () => {
    const body: UserIn = {
      first_name: data?.data_bundle.first_name,
      last_name: data?.data_bundle.last_name,
      email_address: data?.data_bundle.email_address,
    };

    await appClient.stubs.patchStubsTransferStub(key, body);
  };

  const handleNext = async () => {
    setSkipAdvice(false);
    setSkipPayment(false);

    /**TODO: this needs to be in schedulepage bit is not working there */
    if (pages[currentIndex].title === 'Beschikbaarheid') {
      if (
        data?.data_bundle.students[0].availability === undefined ||
        data.data_bundle.students[0].availability.length < 1
      ) {
        enqueueSnackbar('Geef a.u.b. uw beschikbaarheid op.', { variant: 'error' });

        return;
      }

      if (data?.data_bundle.students[0].start_date === undefined) {
        enqueueSnackbar('Geef a.u.b. een datum op wanneer u wenst te starten.', {
          variant: 'error',
        });

        return;
      }

      const tempdata = { ...data };
      if (tempdata.data_bundle.students[0].online_tutoring === undefined) {
        tempdata.data_bundle.students[0].online_tutoring = false;
      }
      // tempdata.data_bundle.students[0].start_date = selectedDate;
      setData(tempdata);
    }

    if (mailExist) {
      setCurrentIndex(5);
    } else {
      if (secretkey) {
        if (pages[currentIndex].isTransfering) {
          history.push(getBaseUrl() + pages[currentIndex].to);
          setBundle({
            iban: '',
            payment_method: '',
            bundle: '',
            terms: 0,
            accept_incasso: false,
            data_bundle: data?.data_bundle,
          });

          await updateStub();
          await transferStub();
        } else if (pages[currentIndex].isFinalizing) {
          if (bundle) {
            if (bundle?.data_bundle.iban) {
              bundle.iban = bundle?.data_bundle.iban;
            } else {
              bundle.iban = '';
            }
            bundle.payment_method = bundle.data_bundle.payment_method;
            if (bundle.data_bundle.payment_method === 'incasso') {
              bundle.accept_incasso = true;
            } else {
              bundle.accept_incasso = false;
            }
            bundle.bundle = bundle.data_bundle.package;
            if (bundle.data_bundle.term) {
              bundle.terms = bundle.data_bundle.term;
            } else {
              bundle.terms = 0;
            }

            if (bundle.data_bundle.payment_method === 'incasso') {
              if (bundle.data_bundle.iban) {
                const r = await fetch(
                  'https://openiban.com/validate/' +
                    bundle.data_bundle.iban +
                    '?getBIC=true&validateBankCode=true'
                ).then(r => {
                  return r.json();
                });
                if (r.valid === true) {
                  appClient.guardians.postGuardiansSelectBundle(secretkey, bundle);
                }
              }
            } else if (bundle.data_bundle.payment_method === 'achteraf') {
              appClient.guardians.postGuardiansSelectBundle(secretkey, bundle);
            }
          }
        } else {
          if (!bundle) {
            changePipe();
            updateStub();
          }
        }

        if (maySwitchPage) {
          const event: CustomEvent = new CustomEvent('onNext', {
            detail: () => {
              setCurrentIndex(currentIndex + 1);
              setCurrentPage(pages[currentIndex + 1]);
              history.push(getBaseUrl() + pages[currentIndex].to);
            },
          });

          await document.dispatchEvent(event);
        }
      }
    }
  };

  const handlePref = async () => {
    setSkipAdvice(false);
    setSkipPayment(false);
    setPayLater(false);
    setCurrentIndex(currentIndex - 1);
    history.push(getBaseUrl() + pages[currentIndex - 1].url);
  };

  const handlePackageModalClose = () => {
    setPackageModalOpen(false);

    setCurrentIndex(currentIndex + 1);
    setCurrentPage(pages[currentIndex + 1]);
    history.push(getBaseUrl() + pages[currentIndex].to);
  };

  const handlePaymentModalClose = () => {
    setPaymentModalOpen(false);

    setCurrentIndex(currentIndex + 1);
    setCurrentPage(pages[currentIndex + 1]);
    history.push(getBaseUrl() + pages[currentIndex].to);
  };

  const baseUrl = '/OuderSignUp/' + secretkey;

  return (
    <ProductsContextProvider>
      <GuardianContextProvider>
        <div style={{ overflowX: 'hidden' }}>
          <Grid container direction={'row'}>
            <Grid item xs={12} sx={{ padding: '10px' }}>
              <Grid container justifyContent="flex-start">
                {
                  <Grid item xs={12}>
                    <img
                      src={whiteLabelLogo()}
                      alt="logo"
                      style={{
                        float: 'left',
                        height: currentWidth == 'xs' ? '25px' : '60px',
                        objectFit: 'contain',
                      }}
                    />
                  </Grid>
                }
              </Grid>
            </Grid>

            <Grid
              item
              sx={{
                height: '72px',
                background: `url(${whiteLabelBanner()}) left no-repeat`,
                minWidth: '100vw',
              }}>
              <AppBar
                sx={{
                  background: `url(${whiteLabelBanner()}) right no-repeat`,
                  backgroundColor: label?.main_color,
                }}
                elevation={0}
                position={!trigger ? 'static' : 'fixed'}>
                <Container
                  sx={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    margin: '0px 20px',
                  }}>
                  <CardHeader
                    sx={{
                      padding: currentWidth === 'xs' ? '0px ' : '0px 20% 0px 20%',
                    }}
                    title={
                      currentPage && currentPage?.title === 'Samenvatting'
                        ? ''
                        : 'Stap ' + (currentIndex + 1)
                    }
                    titleTypographyProps={{
                      color: 'white',
                      fontSize: '16px',
                    }}
                    subheader={currentPage ? currentPage.title : ''}
                    subheaderTypographyProps={{
                      color: 'white',
                      variant: 'h1',
                      fontSize: currentWidth === 'xs' ? '20px' : '20px',
                    }}
                    avatar={
                      <div
                        style={{
                          borderRight: '1px solid rgba(255, 255, 255, .5)',
                          paddingRight: '15px',
                        }}>
                        {currentPage && currentPage.title === 'Samenvatting' ? (
                          <Box
                            sx={{
                              position: 'relative',
                              display: 'inline-flex',
                              height: 65,
                              width: 65,
                            }}>
                            <CircularProgress
                              variant="determinate"
                              value={100}
                              size={65}
                              sx={{
                                color: 'white',
                                marginTop: '5px',
                              }}
                            />
                            <Box
                              sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}>
                              <Typography variant={'h5'} component="div" color={'white'}>
                                <Check
                                  style={{
                                    color: 'white',
                                    fontSize: 50,
                                    marginTop: '15px',
                                  }}
                                />
                              </Typography>
                            </Box>
                          </Box>
                        ) : (
                          <ProgressSpinner
                            max={pages.length - 1}
                            value={currentIndex + 1}
                            variant="h6"
                            fontWeight={'bold'}
                            textColor="white"
                            circularProgressProps={{
                              sx: {
                                color: 'white',
                                marginTop: '5px',
                              },
                              size: 65,
                            }}
                          />
                        )}
                      </div>
                    }
                  />
                </Container>
              </AppBar>
            </Grid>
          </Grid>

          <Grid item>
            <Container
              sx={{
                paddingBottom: '5px',
                [theme.breakpoints.down('sm')]: { padding: '0px !important' },
              }}>
              <Grid container>
                <Grid item xs={12} md={12} sx={{}}>
                  <Grid container direction={'column'} sx={{ paddingTop: '30px' }}>
                    {data ? (
                      mailExist ? (
                        <ThankYouPage />
                      ) : bundle ? (
                        <Container maxWidth={'md'}>
                          <Switch>
                            <Route path={baseUrl + '/Bijlesadvies'}>
                              <LessonPrefrences
                                bundle={bundle}
                                setBundle={setBundle}
                                skipAdvice={skipAdvice}
                                setSkipAdvice={setSkipAdvice}
                              />
                            </Route>
                            <Route path={baseUrl + '/Persoonlijkadvies'}>
                              <PersonalAdvice
                                bundle={bundle}
                                setBundle={setBundle}
                                SetPackageModalOpen={setPackageModalOpen}
                              />
                            </Route>
                            <Route path={baseUrl + '/Betaalmethode'}>
                              <PaymentMethodPageOld
                                bundle={bundle}
                                setBundle={setBundle}
                                payLater={payLater}
                                setPayLater={setPayLater}
                                skipPayment={skipPayment}
                                setSkipPayment={setSkipPayment}
                                SetPaymentModalOpen={setPaymentModalOpen}
                              />
                            </Route>
                            <Route path={baseUrl + '/Samenvatting'}>
                              <SummaryPage
                                bundle={bundle}
                                setBundle={setBundle}
                                setPayLater={setPayLater}
                              />
                            </Route>
                          </Switch>
                        </Container>
                      ) : (
                        <Container maxWidth={'md'}>
                          <Switch>
                            <Route exact path={'/'}>
                              <InformationPage
                                data={data}
                                setData={setData}
                                setMailExist={setMailExist}
                                secretkey={key}
                              />
                            </Route>
                            <Route exact path={baseUrl + '/Algemenegegevens'}>
                              <InformationPage
                                data={data}
                                setData={setData}
                                setMailExist={setMailExist}
                                secretkey={key}
                              />
                            </Route>
                            <Route path={baseUrl + '/Schoolgegevens'}>
                              <SubjectPage data={data} setData={setData} />
                            </Route>
                            <Route path={baseUrl + '/Beschikbaarheid'}>
                              <SchedulePage data={data} setData={setData} />
                            </Route>
                          </Switch>
                        </Container>
                      )
                    ) : (
                      <LinearProgress />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {!mailExist && (
                    <Grid item container sx={{ padding: '35px 16px 5px 16px' }}>
                      {currentPage?.title != 'Samenvatting' && (
                        <FooterButtons
                          data={data}
                          setData={setData}
                          bundle={bundle}
                          setBundle={setBundle}
                          currentIndex={currentIndex}
                          pages={pages}
                          handleNext={handleNext}
                          handlePref={handlePref}
                          setSkipAdvice={setSkipAdvice}
                          setSkipPayment={setSkipPayment}
                          payLater={payLater}
                          setPayLater={setPayLater}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Container>

            {bundle && (
              <>
                <PackageModal
                  bundle={bundle}
                  setBundle={setBundle}
                  open={packageModalOpen}
                  handleButtonClick={() => setPackageModalOpen(false)}
                  handlePrimaryButton={handlePackageModalClose}
                  selectedPackage={bundle.data_bundle.package}
                />

                <PaymentModal
                  bundle={bundle}
                  setBundle={setBundle}
                  open={paymentModalOpen}
                  handleButtonClick={() => setPaymentModalOpen(false)}
                  handlePrimaryButton={handlePaymentModalClose}
                  paymentMethod={bundle.data_bundle.payment_method}
                />
              </>
            )}
          </Grid>
        </div>
      </GuardianContextProvider>
    </ProductsContextProvider>
  );
};

export default Landing;
