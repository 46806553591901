/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrderByJobsEnum {
    ID = 'id',
    NAME = 'name',
    START_DATE = 'start_date',
    DESCRIPTION = 'description',
    STATUS = 'status',
    CREATED_AT = 'created_at',
    SHIFT_COUNT = 'shift_count',
    SECTOR = 'sector',
    BRANCH = 'branch',
    DISTANCE = 'distance',
}
