import React, { useContext, useState } from 'react';

import { RegularProduct } from 'assets';

interface IProductsProviderProps {
  children: React.ReactNode;
}

interface IProductsContexProps {
  Products: RegularProduct[] | undefined;
  SetProducts: React.Dispatch<React.SetStateAction<RegularProduct[] | undefined>>;
}

export const ProductsContext = React.createContext<IProductsContexProps | null>(null);

/* THis product hook is used to get product between pages */
export const ProductsContextProvider: React.FC<IProductsProviderProps> = ({ children }) => {
  const [Products, SetProducts] = useState<RegularProduct[]>();

  return (
    <ProductsContext.Provider
      value={{
        Products,
        SetProducts,
      }}>
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => {
  const context = useContext(ProductsContext);
  if (!context) {
    throw new Error('useProductsContext must be used within a ProductsContextProvider');
  }

  return context;
};

export default useProductsContext;
