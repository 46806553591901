import React, { ReactNode } from "react"

import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog, Grid, IconButton, Typography,
} from "@mui/material"

import { useWidth } from "functions/Hooks/useDeviceDetect"

interface StandardDialogProps {
  title: string
  handleClose: () => void
  open: boolean
  setOpen: (b: boolean) => void
  children: ReactNode
  width?: string
}
//Dialog that opens when user wants to edit or add lessons during accordation. Two variants exist: edit | add
const StandardDialog: React.FunctionComponent<StandardDialogProps> = ({
  title, open, setOpen, handleClose, children, width
}) => {
  const currentWidth = useWidth();

  //Title at top of dialog and close button
  const TitleAndClose = () => {
    return (
      <Grid item>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          padding={currentWidth === 'xs' ? '0 0 0 12px' : '4px 4px 4px 16px'}>
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                ...(currentWidth === 'xs' && {
                  fontSize: '16px',
                  lineHeight: '18px',
                }),
              }}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                setOpen(false);
              }}>
              <CloseIcon sx={{ ...(currentWidth === 'xs' && { fontSize: '20px' }) }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      onClose={() => {
        handleClose();
      }}
      open={open}
      PaperProps={{
        sx: {
          borderRadius: '15px',
          margin: '16px',
          ...(currentWidth === 'xs' ? { padding: '0px' } : { padding: '6px' }),
        },
      }}
      sx={{ width: '100%' }}>
      <Grid
        container
        direction="column"
        width={currentWidth === "xs" ? "calc(100vw - 32px)" : width}
      >
        <Grid item>
          <TitleAndClose />
          {children}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default StandardDialog;
