import React, { FC } from 'react';

import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { t } from 'i18n';
import theme from 'theme';

import { OrderWithPaymentInformation } from 'assets';
import errorPayment from 'assets/img/brand/error-payment.png';
import { Button } from 'components/Elements/Forms/Inputs';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

interface IPaymentFailedCardProps {
  order: OrderWithPaymentInformation;
}

const PaymentFailedCard: FC<IPaymentFailedCardProps> = ({ order }) => {
  const currentWidth = useWidth();

  const retryIdealPayment = () => {
    if (order.payment_details) {
      const details: { links: any } = order.payment_details;
      window.location.href = details.links.checkout.href;
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card elevation={0} sx={{ borderRadius: currentWidth === 'xs' ? 'unset' : '15px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CardHeader
                  title={t('translation.portalShared.payment.paymentFailedCard.title')}
                  subheader={t('translation.portalShared.payment.paymentFailedCard.subheader')}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0} sx={{ borderRadius: currentWidth === 'xs' ? 'unset' : '15px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h1"
                  sx={{ textAlign: 'center', color: theme.palette.primary.main }}>
                  {t('translation.portalShared.payment.paymentFailedCard.title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={errorPayment} style={{ height: '90px', objectFit: 'contain' }} />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography textAlign={'center'} color={'#6F6F6F'}>
                    {t('translation.portalShared.payment.paymentFailedCard.info')}
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                  <Grid container justifyContent={'center'} justifyItems={'center'}>
                    <Button
                      fullWidth={currentWidth === 'xs' ? true : false}
                      variant="contained"
                      onClick={retryIdealPayment}>
                      {t('translation.portalShared.payment.paymentFailedCard.button')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PaymentFailedCard;
