import React from 'react';

import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import moment from 'moment';

import { BaseDiscipline } from 'assets';
import { Button } from 'components/Elements/Forms/Inputs';
import AvailabilityGrid_V2 from 'components/Functional/Availability/AvailabilityGrid_V2';

import { IExtendedBundleIn, IFlowPageProps, IPackage } from '..';

interface SummaryDesktop extends IFlowPageProps {
  bundle: IExtendedBundleIn;
  setBundle: (bundle: IExtendedBundleIn) => void;
  vakken: BaseDiscipline[] | undefined;
  package?: IPackage[];
  phone: string;
  email: string;
}

const SummaryDesktop: React.FunctionComponent<SummaryDesktop> = ({
  bundle,
  vakken,
  phone,
  email,
}) => {
  const calculateHeight = (): string => {
    const el = document.getElementById('info')?.offsetHeight;

    // el = el!-20
    return el + 'px';
  };

  const getDisiplinesNames = (disipline: number): string => {
    let name = '';
    const vak = vakken?.find(vak => vak.id === disipline);
    if (vak) {
      name = vak?.name;
    }

    return name;
  };

  return (
    <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
      <Grid item xs={12}>
        <CardHeader
          // title={"Gegevens van uw kind"}
          titleTypographyProps={{ fontSize: '18px' }}
          subheader={
            'Uw aanmelding is compleet. U krijgt een bevestigingsmail met daarin een uitnodigingslink voor onze online omgeving. Wij gaan zo snel mogelijk voor u aan de slag. Indien u nog vragen heeft kunt u altijd contact met ons opnemen.'
          }
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ maxHeight: calculateHeight }}>
        <Card
          id={'info'}
          elevation={0}
          sx={{
            borderRadius: '15px',
            backgroundColor: '#F5F5F5',
            marginTop: '20px',
          }}>
          <CardHeader
            title={'Gegevens van uw kind'}
            titleTypographyProps={{
              marginTop: '12px',
              fontSize: '18px',
            }}
          />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid container sx={{ paddingTop: 'unset' }}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography>Voornaam:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{bundle.data_bundle.students?.[0].first_name}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>Achternaam:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{bundle.data_bundle.students?.[0].last_name}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>Geboortedatum:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>
                          {moment(bundle.data_bundle.students?.[0].date_of_birth).format(
                            'DD-MM-yyyy'
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>Geslacht:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{bundle.data_bundle.students?.[0].gender}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>Locatievoorkeur:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>
                          {bundle.data_bundle.students?.[0].online_tutoring ? 'Aan huis' : 'Online'}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sx={{ marginTop: '20px' }}>
                        <Typography>Schoolniveau:</Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ marginTop: '20px' }}>
                        <Typography>{bundle.data_bundle.students?.[0].school_level}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>Leerjaar:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{bundle.data_bundle.students?.[0].school_year}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>Vakken:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container>
                          {bundle.data_bundle.students?.[0].disciplines.map(
                            (discipline: number) => {
                              return (
                                <Grid item xs={12}>
                                  <Typography>{getDisiplinesNames(discipline)}</Typography>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          minHeight: calculateHeight(),
          height: calculateHeight(),
        }}>
        <Card
          elevation={0}
          sx={{
            borderRadius: '15px',
            backgroundColor: '#F5F5F5',
            marginTop: '20px',
            height: calculateHeight(),
          }}>
          <CardHeader
            title={'Uw gegevens'}
            titleTypographyProps={{
              marginTop: '12px',
              fontSize: '18px',
            }}
          />
          <CardContent>
            <Grid container sx={{ paddingTop: 'unset' }}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography>Voornaam:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{bundle.data_bundle.first_name}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Achternaam:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{bundle.data_bundle.last_name}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>E-mail:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{bundle.data_bundle.email_address}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Telefoon:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{bundle.data_bundle.phone_number}</Typography>
                  </Grid>

                  {/* <Grid item xs={6} sx={{ marginTop: '45px' }}>
                    <Typography>Pakket:</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ marginTop: '45px' }}>
                    <Typography>{bundle.data_bundle.package}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Betaal-methode:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {bundle.data_bundle.payment_method
                        ? bundle.data_bundle.payment_method === 'achteraf'
                          ? 'Losse factuur'
                          : bundle.data_bundle.payment_method
                        : 'Wij nemen contact met u op voor uw abonnementskeuze!'}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>IBAN:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{bundle.data_bundle.iban}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>Termijn:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{bundle.data_bundle.term}</Typography>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        sx={{
          height: '100% !important',
          marginTop: '40px',
        }}>
        <Card
          elevation={2}
          sx={{
            borderRadius: '15px',
            backgroundColor: '#F5F5F5',
            // minHeight: calculateHeight,
            height: '100%',
          }}>
          <CardHeader
            title={'Uw beschikbaarheid'}
            titleTypographyProps={{
              marginTop: '12px',
              fontSize: '18px',
            }}
          />
          <CardContent sx={{ maxWidth: '100%' }}>
            {/* <AvailabilityReadSimple
                            availabilities={bundle.data_bundle.students?.[0].availability}
                            dontShowTitle
                        /> */}

            {/* <AvailabilityGrid
                            user_locations={[]}
                            availability={bundle.data_bundle.students[0].availability ? bundle.data_bundle.students[0].availability : []}
                            onAdd={() => { }}
                            onDelete={() => { }}
                            disabled={true}
                        /> */}

            <AvailabilityGrid_V2
              availabilities={
                bundle.data_bundle.students[0].availability
                  ? bundle.data_bundle.students[0].availability
                  : []
              }
              disabled
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <CardHeader title={'Nog vragen?'} titleTypographyProps={{ fontSize: '18px' }} />
          </Grid>

          <Grid item xs={4}>
            <Button
              variant="text"
              startIcon={<PhoneIcon />}
              href={'tel:' + phone}
              sx={{ backgroundColor: '#f5f5f5' }}>
              {phone}
            </Button>
          </Grid>
          <Grid item xs={4} sx={{ alignContent: 'flex-end' }}>
            <Button
              variant="text"
              startIcon={<MailIcon />}
              href={'mailto:' + email}
              sx={{ backgroundColor: '#f5f5f5' }}>
              {email}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryDesktop;
