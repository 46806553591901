import React from 'react';

import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { t, Translation } from 'i18n';

import { Button, TextField } from 'components/Elements/Forms/Inputs';
import useLabel from 'functions/Hooks/useLabel';

interface IResetFormMobileProps {
  handleSubmit: () => void;
  status: string;
  setStatus: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
}

const ResetFormMobile: React.FunctionComponent<IResetFormMobileProps> = ({
  handleSubmit,
  status,
  email,
  setEmail,
}) => {
  const label = useLabel();

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container>
          <Grid item md={12}>
            <CardHeader
              title={
                <Typography
                  variant="h3"
                  color={'#3C3C3B'}
                  sx={{
                    fontSize: '22px',
                    lineHeight: '1.2',
                  }}
                >
                  <Translation i18nKey="translation.auth.resetpw.header" values={{ label: label?.name }} />
                  {" "}
                  <Typography
                    component="span"
                    variant="h3"
                    color="primary"
                    sx={{ fontSize: '22px' }}>
                    {t('translation.auth.resetpw.headerportal')}
                  </Typography>
                  .
                </Typography>
              }
              subheader={
                <Typography
                  fontSize={'16px'}
                  sx={{
                    marginTop: '20px',
                    color: '#6F6F6F',
                  }}
                  lineHeight={'1.2'}>
                  {t('translation.auth.resetpw.subheader')}
                </Typography>
              }
              sx={{ paddingTop: '0px' }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginLeft: '10px' }}>
            {/* REGEX to validate email */}
            <TextField
              error={
                status === 'error' &&
                !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  email.toLowerCase()
                )
              }
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mailadres"
              name="email"
              helperText={
                status === 'error' &&
                  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    email.toLowerCase()
                  )
                  ? 'Dit is niet een geldig emailadres'
                  : ''
              }
              autoComplete="email"
              onChange={event => setEmail(event.target.value)}
              autoFocus
            />
            {status === 'error' && <Alert severity="error">{t('translation.auth.resetpw.error')}</Alert>}
            {status === 'success' && (
              <Alert severity="success">
                <Translation i18nKey="translation.auth.resetpw.succes" components={[<br />]} />
              </Alert>
            )}
            {status === 'loading' && <LinearProgress />}

            <Grid container spacing={2}>
              <Grid item xs>
                <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                  {t('translation.auth.resetpw.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ResetFormMobile;
