import React, { useEffect, useState } from 'react';

import { Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from '@mui/material';
import { t } from 'i18n';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { appClient } from 'App';
import {
  EnumOrderType,
  OrderIn,
  OrderItemIn,
  RegularGuardianWithChildren,
  RegularProduct,
} from 'assets';
import { Button, TextField } from 'components/Elements/Forms/Inputs';
import { checkZip } from 'functions/HelperFunctions/Regex';
import useNavContext from 'functions/Hooks/useNav';
import useProductStoreContex from 'functions/Hooks/useProductsStore';
import useUser from 'functions/Hooks/useUser';

const InvoicePaymentCard = () => {
  const NavProvider = useNavContext();
  NavProvider.setShow(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useUser();
  const [name, setName] = useState<string>();
  const [mail, setMail] = useState<string>();
  const [house, setHouse] = useState<string>();
  const [zipCode, setZipcode] = useState<string>();
  const [city, setCity] = useState<string>();
  const productStore = useProductStoreContex();
  const [loading, setLoading] = useState<boolean>(true)

  const [guardian, setGuardian] = useState<RegularGuardianWithChildren>();

  useEffect(() => {
    if (user && user?.guardian) {
      setName(user.first_name + ' ' + user.last_name);

      appClient.guardians
        .getGuardiansViewGuardianSimple(user.guardian.id)
        .then((r: RegularGuardianWithChildren) => {
          setGuardian(r);
          setLoading(false)
        });
    }
  }, []);

  useEffect(() => {
    setName(guardian?.user.first_name + ' ' + guardian?.user.last_name);
    setMail(guardian?.user.email_address);
    if (guardian?.street) {
      setHouse(guardian?.street);
    }
    if (guardian?.city) {
      setCity(guardian?.city);
    }
    if (guardian?.zipcode) {
      setZipcode(guardian.zipcode);
    }
  }, [guardian]);

  const handlePaymentEvent = () => {
    if (!checkZip(zipCode)) {
      enqueueSnackbar(t('translation.common.warnings.regexZipcode'), {
        variant: 'warning',
      });

      return;
    }
    const OrderItem: OrderItemIn[] = [];

    productStore.products.forEach((product: RegularProduct) => {
      OrderItem.push({ product_id: product.id });
    });

    const orderData: OrderIn = {
      items: OrderItem,
      order_type: EnumOrderType.INVOICE,
      collection_terms: 1,
    };

    if (
      name === '' ||
      name === undefined ||
      mail === '' ||
      mail === undefined ||
      house === '' ||
      house === undefined ||
      zipCode === '' ||
      zipCode === undefined ||
      zipCode === ''
    ) {
      enqueueSnackbar(t('translation.portalShared.payment.emptyFields'), { variant: 'error' });
    }
    if (guardian) {
      appClient.orders
        .postOrdersCreateOrder(guardian.user.id, orderData)
        .then(() => {
          enqueueSnackbar(t('translation.portalShared.payment.paymentSuccesful'), {
            variant: 'success',
          });
          NavProvider.setShow(true);
          history.push('/SuccesPayment');
        })
        .catch(() => {
          enqueueSnackbar(t('translation.portalShared.payment.paymentUnsuccessful'), {
            variant: 'error',
          });
        });
    }
  };

  return (
    <Card elevation={0} sx={{ borderRadius: '15px' }}>
      <CardHeader
        title={t('translation.common.words.invouceInfo')}
        titleTypographyProps={{
          color: '#6F6F6F',
        }}
      />
      <CardContent
        sx={{
          borderTop: '2px solid #F2F2F2',
        }}
      >
        {
          loading ?
            (
              <Grid container xs={12} justifyContent="center">
                <CircularProgress size={'36px'} />

              </Grid>
            ) :
            (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: '#2B2E34',
                      fontSize: '18px',
                    }}>
                    {t('translation.portalShared.payment.CollectionPayment.Check')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: '#2B2E34',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}>
                    {t('translation.common.words.name')}
                  </Typography>
                  <TextField fullWidth value={name} onChange={e => setName(e.target.value)} />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: '#2B2E34',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}>
                    {t('translation.common.words.mail')}
                  </Typography>
                  <TextField fullWidth value={mail} onChange={e => setMail(e.target.value)} />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: '#2B2E34',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}>
                    {t('translation.common.words.streedAndNumber')}
                  </Typography>
                  <TextField fullWidth value={house} onChange={e => setHouse(e.target.value)} />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          color: '#2B2E34',
                          fontSize: '18px',
                          fontWeight: '600',
                        }}>
                        {t('translation.common.words.zipcode')}
                      </Typography>
                      <TextField fullWidth value={zipCode} onChange={e => setZipcode(e.target.value)} />
                    </Grid>

                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          color: '#2B2E34',
                          fontSize: '18px',
                          fontWeight: '600',
                        }}>
                        {t('translation.common.words.city')}
                      </Typography>
                      <TextField fullWidth value={city} onChange={e => setCity(e.target.value)} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: '#2B2E34',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}>
                    {t('translation.common.words.term')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: '#2B2E34',
                      fontSize: '18px',
                    }}>
                    {t('translation.portalShared.invoucePayment.invouceAtDate')}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Button
                    variant={'contained'}
                    fullWidth
                    onClick={() => handlePaymentEvent()}
                    sx={{ padding: '10px', fontSize: '18px' }}>
                    {t('translation.common.buttons.pay')}
                  </Button>
                </Grid>
              </Grid >)}
      </CardContent >
    </Card >
  );
};

export default InvoicePaymentCard;
