import {
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  Container,
  CardContent,
  Divider,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import './Persoonlijk.css';
import { TextField } from 'components/Elements/Forms/Inputs';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/styles';
import {
  checkMail,
  checkZip,
  checkBSN,
  checkIBAN,
  checkPhone,
  checkCity,
} from 'functions/HelperFunctions/Regex';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { CheckBox, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { validateEmailSendGrid } from 'functions/Hooks/validateEmailSendGrid';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

const PersoonlijkeGegevensPage = ({ setData, data }) => {
  const {
    street_number,
    first_name,
    last_name,
    zipcode,
    city,
    street,
    date_of_birth,
    email_address,
    phone_number,
    emergency_phone_number,
    bsn,
    iban,
    gender,
  } = data.data_bundle;

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [canGoNextPage, setCanGoNextPage] = React.useState(true);
  const previousEmail = useRef('');
  const currentWidth = useWidth();

  const genderDropdown = [
    {
      value: 'mannelijk',
      label: 'Mannelijk',
    },
    {
      value: 'vrouwelijk',
      label: 'Vrouwelijk',
    },
    {
      value: 'overig',
      label: 'Overig',
    },
  ];

  const saveData = (name, value) => {
    let change = { ...data };
    change.data_bundle.country = 'nederland';
    change.data_bundle[name] = value;

    if (change.data_bundle.zipcode && change.data_bundle.city && change.data_bundle.street) {
      change.data_bundle.user_locations = [
        {
          zipcode: change.data_bundle.zipcode,
          city: change.data_bundle.city,
          street: change.data_bundle.street,
          country: 'nederland',
        },
      ];
    }
    setData(change);
  };

  const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleNext = async event => {
    event.preventDefault();
    let emailValidation = await validateEmailSendGrid(email_address);

    if (!first_name) {
      enqueueSnackbar('Voornaam is verplicht', { variant: 'error' });
      return;
    }
    if (!last_name) {
      enqueueSnackbar('Achternaam is verplicht', { variant: 'error' });
      return;
    }
    if (!street) {
      enqueueSnackbar('Straatnaam is verplicht', { variant: 'error' });
      return;
    }
    console.log('test', street_number);
    if (!street_number) {
      enqueueSnackbar('Huisnummer is verplicht', { variant: 'error' });
      return;
    }
    if (!(await checkZip(zipcode))) {
      enqueueSnackbar('Postcode is onjuist', { variant: 'error' });
      return;
    }
    if (!date_of_birth) {
      enqueueSnackbar('Geboortedatum is verplicht', { variant: 'error' });
      return;
    }
    if (!city) {
      enqueueSnackbar('Woonplaats is onjuist', { variant: 'error' });
      return;
    } else {
      if (await checkCity(city)) {
        enqueueSnackbar('Woonplaats bevat cijfers', { variant: 'error' });
        return;
      }
    }
    if (!checkMail(email_address)) {
      enqueueSnackbar('E-mail is onjuist', { variant: 'error' });
      return;
    } else if (
      emailValidation &&
      emailValidation.verdict === 'Risky' &&
      previousEmail.current !== email_address
    ) {
      //Check if email-address has been verified before, if it is the first time a validation comes back negative a warning should be thrown.
      previousEmail.current = email_address;
      enqueueSnackbar(
        'U hebt mogelijk een incorrect e-mailadres ingevuld. Kunt u het e-mailadres nog een keer controleren? Indien het klopt, gelieve nog een keer op Volgende te klikken',
        {
          variant: 'info',
          anchorOrigin:
            currentWidth === 'xs'
              ? {
                  vertical: 'top',
                  horizontal: 'center',
                }
              : {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
        }
      );
      return;
    }
    //TODO: Moet uiteindelijk meerdere landen toelaten.
    if (!isValidPhoneNumber(phone_number, 'NL')) {
      enqueueSnackbar('Telefoonnummer is onjuist', { variant: 'error' });
      return;
    }
    if (!checkBSN(bsn)) {
      enqueueSnackbar('BSN is onjuist', { variant: 'error' });
      return;
    }
    if (!checkIBAN(iban)) {
      enqueueSnackbar('Iban is onjuist', { variant: 'error' });
      return;
    }
    if (!gender) {
      enqueueSnackbar('Geslacht is verplicht', { variant: 'error' });
      return;
    }

    event.detail();
  };

  React.useEffect(() => {
    document.addEventListener('onNext', handleNext);
    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [data]);

  return (
    <div>
      <Card
        elevation={0}
        sx={{
          borderRadius: '20px',
          marginTop: '20px',
        }}>
        <CardContent>
          <Grid container>
            <Grid item sm={4}>
              <CardHeader
                title={'Eigen gegevens'}
                subheader={'Vul je persoonlijke gegevens in.'}
                sx={{
                  marginTop: '-20px',
                  marginBottom: isMobile ? '0px' : '20px',
                }}
              />
            </Grid>
            <Grid item sm={8}>
              <Grid container direction={'row'} justify="center" spacing={!isMobile ? 2 : 2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="standard-basic"
                    name="first_name"
                    label="Voornaam"
                    // error={!first_name && first_name !== ""}
                    dontShowBorder
                    backgroundColor="#f5f5f5"
                    required
                    fullWidth
                    value={first_name || ''}
                    onChange={e => saveData('first_name', capitalize(e.target.value))}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="standard-basic"
                    name={'last_name'}
                    label={'Achternaam'}
                    // error={!last_name && last_name !== ""}
                    dontShowBorder
                    backgroundColor="#f5f5f5"
                    required
                    fullWidth
                    value={last_name || ''}
                    onChange={e => saveData('last_name', capitalize(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="standard-basic"
                    type={'date'}
                    error={
                      !date_of_birth &&
                      date_of_birth !== 'dd-mm-jjjj' &&
                      date_of_birth !== undefined
                    }
                    dontShowBorder
                    backgroundColor="#f5f5f5"
                    InputProps={{
                      inputProps: {
                        min: '1970-01-01',
                        max: '3000-05-04',
                      },
                    }}
                    InputLabelProps={{ ...(date_of_birth !== '' && { shrink: true }) }}
                    label="Geboortedatum"
                    required
                    fullWidth
                    value={date_of_birth || ''}
                    onChange={e => saveData('date_of_birth', capitalize(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                  <TextField
                    id="standard-basic"
                    name={'BSN'}
                    label={'BSN'}
                    error={bsn && !checkBSN(bsn)}
                    dontShowBorder
                    backgroundColor="#f5f5f5"
                    fullWidth
                    required
                    value={bsn || ''}
                    onChange={e => saveData('bsn', capitalize(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    select
                    value={data?.data_bundle?.gender || ''}
                    fullWidth
                    required
                    backgroundColor="#f5f5f5"
                    error={!data.data_bundle.gender && data.data_bundle.gender !== undefined}
                    dontShowBorder
                    variant="outlined"
                    label="Geslacht"
                    name="gender"
                    onChange={e => saveData('gender', e.target.value)}>
                    {genderDropdown.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={12}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Divider
        sx={{
          marginTop: '0px',
          marginBottom: '25px',
          marginLeft: '30px',
          marginRight: '20px',
        }}
      />

      <Card elevation={0} sx={{ borderRadius: '10px' }}>
        <CardContent>
          <Grid container>
            <Grid item sm={4}>
              <CardHeader
                title={'Adresgegevens'}
                subheader={'Wat zijn je adresgegevens?'}
                sx={{
                  marginTop: '-20px',
                  marginBottom: isMobile ? '0px' : '20px',
                }}
              />
            </Grid>
            <Grid item sm={8}>
              <Grid container direction={'row'} justify="center" spacing={!isMobile ? 2 : 2}>
                <Grid item xs={12} sm={6} md={8}>
                  <TextField
                    id="standard-basic"
                    name={'street-address'}
                    error={!data.data_bundle.street && data.data_bundle.street !== undefined}
                    dontShowBorder
                    label={'Straatnaam'}
                    backgroundColor="#f5f5f5"
                    fullWidth
                    required
                    value={street || ''}
                    onChange={e => saveData('street', capitalize(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="standard-basic"
                    name={'street_number'}
                    error={!street_number && street_number !== undefined}
                    dontShowBorder
                    label={'Huisnummer + toevoeging'}
                    backgroundColor="#f5f5f5"
                    fullWidth
                    required
                    value={street_number || ''}
                    onChange={e => saveData('street_number', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    id="standard-basic"
                    name={'zipcode'}
                    // error={errorZipCode}
                    error={zipcode && !checkZip(zipcode)}
                    dontShowBorder
                    label={'Postcode'}
                    backgroundColor="#f5f5f5"
                    fullWidth
                    required
                    value={zipcode || ''}
                    onChange={e =>
                      saveData('zipcode', e.target.value.toUpperCase().replace(' ', ''))
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    id="standard-basic"
                    name={'city'}
                    label={'Woonplaats'}
                    error={!data.data_bundle.city && data.data_bundle.city !== undefined}
                    dontShowBorder
                    backgroundColor="#f5f5f5"
                    fullWidth
                    required
                    value={city || ''}
                    onChange={e => saveData('city', capitalize(e.target.value))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Divider
        sx={{
          marginTop: '20px',
          marginBottom: '25px',
          marginLeft: '30px',
          marginRight: '20px',
        }}
      />

      <Card elevation={0} sx={{ borderRadius: '10px' }}>
        <CardContent>
          <Grid container>
            <Grid item sm={4}>
              <CardHeader
                title={'Contactgegevens'}
                subheader={'Hoe kunnen we je bereiken?'}
                sx={{
                  marginTop: '-20px',
                  marginBottom: isMobile ? '0px' : '20px',
                }}
              />
            </Grid>
            <Grid item sm={8}>
              <Grid container direction={'row'} justify="center" spacing={!isMobile ? 2 : 2}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="standard-basic"
                    name={'email_address'}
                    label={'E-mail'}
                    error={email_address && !checkMail(email_address)}
                    dontShowBorder
                    backgroundColor="#f5f5f5"
                    required
                    fullWidth
                    value={email_address || ''}
                    onChange={e => saveData('email_address', e.target.value.toLowerCase())}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <TextField
                    id="standard-basic"
                    name={'phone_number'}
                    label={'Telefoonnummer'}
                    error={phone_number && !checkPhone(phone_number)}
                    backgroundColor="#f5f5f5"
                    dontShowBorder
                    required
                    fullWidth
                    value={phone_number || ''}
                    onChange={e => saveData('phone_number', e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <TextField
                    id="standard-basic"
                    name={'emergency_phone_number'}
                    label={'Noodtelefoonnummer'}
                    backgroundColor="#f5f5f5"
                    error={emergency_phone_number && !checkPhone(emergency_phone_number)}
                    dontShowBorder
                    fullWidth
                    value={emergency_phone_number || ''}
                    onChange={e => saveData('emergency_phone_number', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Divider
        sx={{
          marginTop: '20px',
          marginBottom: '25px',
          marginLeft: '30px',
          marginRight: '20px',
        }}
      />

      <Card elevation={0} sx={{ borderRadius: '10px' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <CardHeader
                title={'Bankgegevens'}
                subheader={'Op welke rekening kunnen wij de betaling overmaken?'}
                sx={{
                  marginTop: '-20px',
                  marginBottom: isMobile ? '0px' : '20px',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container direction={'row'} justify="center" spacing={!isMobile ? 2 : 2}>
                <Grid item xs={12} sm={7} md={7}>
                  <TextField
                    id="standard-basic"
                    name={'iban'}
                    label={'IBAN'}
                    error={iban && !checkIBAN(iban)}
                    dontShowBorder
                    backgroundColor="#f5f5f5"
                    required
                    fullWidth
                    value={iban || ''}
                    onChange={e => saveData('iban', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default PersoonlijkeGegevensPage;
