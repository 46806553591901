import React from 'react';

import { ButtonProps, InputLabel } from '@mui/material';

import { Button, TextField } from 'components/Elements/Forms/Inputs';

type UploadButtonProps = {
  buttonTranslation: JSX.Element;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  inputType?: string;
} & ButtonProps;

// Button that let's a user pick a file from their computer, which is than used by the handleChange function.
// Standard accepted inputType is .pdf
const UploadButton = ({
  buttonTranslation,
  handleChange,
  inputType,
  ...buttonProps
}: UploadButtonProps) => {
  return (
    <React.Fragment>
      <InputLabel htmlFor={`upload-diploma`}>
        <Button
          {...buttonProps}
          variant="contained"
          sx={{
            ...buttonProps.sx,
            height: '100%',
            minWidth: '0px',
            width: '100%',
          }}
          color="primary"
          component="span">
          {buttonTranslation}
        </Button>
      </InputLabel>
      <TextField
        type="file"
        sx={{ display: 'none' }}
        name={`diploma_attachment`}
        id={`upload-diploma`}
        onChange={e => handleChange(e)}
        inputProps={{ accept: inputType ? inputType : '.pdf' }}
      />
    </React.Fragment>
  );
};

export default UploadButton;
