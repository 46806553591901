import * as React from 'react';
import { useEffect } from 'react';

import { EventAvailable, Feedback, Schedule, ViewAgenda } from '@mui/icons-material';
import { Avatar, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { appClient } from 'App';
import useUser from 'functions/Hooks/useUser';

interface ExplanationProps {}

const Explanation: React.FunctionComponent<ExplanationProps> = () => {
  const { user } = useUser();
  const history = useHistory();

  const handleNext = () => {
    appClient.auth.patchAuthCompleteFirstLanding(user!.id).then(() => history.push('/Dashboard'));
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext as EventListener);

    return () => {
      document.removeEventListener('onNext', handleNext as EventListener);
    };
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2">Welkom!</Typography>
        {user?.first_landing_status === 'new' ? (
          <Typography variant="body1">
            Wegens onze verhuizing willen wij het een en ander eerst even uitleggen voordat{' '}
            {user.coach ? 'je' : 'u'} verder gaat in het portaal.
          </Typography>
        ) : user?.coach ? (
          <Typography variant="body1">
            Je bent bijna klaar! Als je op de knop hieronder drukt kom je in het nieuwe portaal
            terecht. We lichten graag nog drie dingen uit die belangrijk zijn voor het nieuwe
            portaal.
          </Typography>
        ) : (
          <Typography variant="body1">
            U bent bijna klaar! Als u op de knop hieronder drukt komt u in het nieuwe portaal
            terecht. We lichten graag nog drie dingen uit die belangrijk zijn voor het nieuwe
            portaal.
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {user?.coach ? (
          <>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Schedule />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Beschikbaarheid"
                secondary="In de agenda ziet u een overzicht van de bijlessen"
              />
            </ListItem>
            <Typography>
              Zorg dat je beschikbaarheid actueel is zodat we je het beste aan bijleskinderen kunnen
              koppelen. Onze automatische systemen koppelen automatisch bijeskinderen en coaches aan
              elkaar op basis van beschikbaarheid en locatie. Zorg daarom dat je minimaal eens in de
              twee weken je algemene beschikbaarheid controleert en indien nodig aanpast.
            </Typography>
          </>
        ) : (
          <>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ViewAgenda />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Agenda"
                secondary="In de agenda ziet u een overzicht van de bijlessen"
              />
            </ListItem>
            <Typography>
              {' '}
              Uw coach plant in overleg met u bijlessen in. Deze bijlessen vindt u terug in de
              agenda.
            </Typography>
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        {user?.coach ? (
          <>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <EventAvailable />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Bijles inplannen" secondary="Dit vervangt het urenbriefje" />
            </ListItem>
            <Typography>
              Vanaf nu plan je altijd je bijlessen in via dit portaal. Voorbij zijn de tijden van de
              urenbriefjes. Je plant je bijlessen van te voren in via dit portaal. Aan het einde van
              de maand berekenen onze systemen automatisch wat je loon is.
            </Typography>
          </>
        ) : (
          <>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Feedback />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Berichtenbox"
                secondary="U krijgt in uw berichtenbox feedback van uw coach en andere belangrijke berichten."
              />
            </ListItem>
            <Typography>
              Het systeem houdt u op de hoogte van de bijlessen. Bijvoorbeeld dat een coach feedback
              heeft achtergelaten over een bijles. Of een nieuwe bijles ingepland heeft. U ontvangt
              hier ook e-mails over.
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        {user?.coach ? (
          <>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Feedback />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Feedback geven" secondary="Doe dit per gegeven bijles" />
            </ListItem>
            <Typography>
              Na een bijles geef je altijd feedback over de les. Ouders vinden het fijn om te weten
              hoe de bijles verliep. Ging de bijles goed? Maakt het bijleskind mooie stappen? Of
              waren er juist verbeterpunten? Na elke les geef je via dit portaal feedback over de
              gegeven les.
            </Typography>
          </>
        ) : (
          <>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <EventAvailable />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Beschikbaarheid"
                secondary="Verandert er iets in de situatie van uw kinderen? Dat past u hier aan."
              />
            </ListItem>
            <Typography>
              {' '}
              Is uw kind overgegaan naar een volgend leerjaar? Of verandert er iets qua
              beschikbaarheid?{' '}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs />
    </Grid>
  );
};

export default Explanation;
