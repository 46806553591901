/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * EnumBundleStatus is an Enum class for the status of a bundle.
 */
export enum EnumBundleStatus {
    AVAILABLE = 'available',
    EMPTY = 'empty',
    INACTIVE = 'inactive',
    EXPIRED = 'expired',
}
