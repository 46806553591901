import React, { useState } from 'react';

import { LinearProgress } from '@mui/material';

import { appClient } from 'App';
import { PasswordResetRequestIn } from 'assets';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import ResetDesktop from './Desktop/ResetDesktop';
import ResetMobile from './Mobile/ResetMobile';

const Reset = () => {
  const label = useLabel();
  const currentWidth = useWidth();
  const [email, setEmail] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const handleSubmit = () => {
    setStatus('loading');

    const data: PasswordResetRequestIn = { email_address: email };
    appClient.auth.postAuthRequestUserPasswordReset(data).then(r => {
      if (r.status === '200') {
        setStatus('success');
      } else if (r.status === 'ok') {
        setStatus('success');
      } else {
        setStatus('error');

        return;
      }
    });
  };

  return (
    <>
      {label ? (
        currentWidth === 'xs' ? (
          <ResetMobile
            handleSubmit={handleSubmit}
            status={status}
            setStatus={setStatus}
            email={email}
            setEmail={setEmail}
          />
        ) : (
          <ResetDesktop
            handleSubmit={handleSubmit}
            status={status}
            setStatus={setStatus}
            email={email}
            setEmail={setEmail}
          />
        )
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default Reset;
