import InformationPage from './Pages/InformationPage';
import SubjectPage from './Pages/SubjectsPage';
import SchedulePage from './Pages/SchedulePage';
import LessonPrefrences from './Pages/LessonPreferences';
import PersonalAdvice from './Pages/PersonalAdvice';
import PaymentMethodPage from './Pages/PaymentMethodPage';
import SummaryPage from './Pages/SummaryPage';
import PaymentMethodPageOld from './Pages/PaymentMethodPageOld';

const flowroutes = [
  {
    title: 'Algemene gegevens',
    Component: InformationPage,
    url: '/Algemenegegevens',
    to: '/Schoolgegevens',
    index: 0,
  },
  {
    title: 'Schoolgegevens',
    Component: SubjectPage,
    url: '/Schoolgegevens',
    to: '/Beschikbaarheid',
    index: 1,
  },
  {
    title: 'Beschikbaarheid',
    Component: SchedulePage,
    isTransfering: true,
    url: '/Beschikbaarheid',
    to: '/Bijlesadvies',
    index: 2,
  },
  {
    title: 'Bijles advies',
    Component: LessonPrefrences,
    url: '/Bijlesadvies',
    to: '/Persoonlijkadvies',
    index: 3,
  },
  {
    title: 'Persoonlijk advies',
    Component: PersonalAdvice,
    url: '/Persoonlijkadvies',
    to: '/Samenvatting',
    index: 4,
  },
  // {
  //   title: 'Betaalmethode',
  //   Component: PaymentMethodPageOld,
  //   isFinalizing: true,
  //   url: '/Betaalmethode',
  //   to: '/Samenvatting',
  //   index: 5,
  // },
  {
    title: 'Samenvatting',
    Component: SummaryPage,
    url: '/Samenvatting',
  },
];

export default flowroutes;
