
import React, { useState } from 'react';

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from '@mui/material';

import { appClient } from 'App';
// import sary from "assets/img/brand/SaryNobackground.png"
import fiene from 'assets/img/brand/FieneVierkant.png';
import { BaseDiscipline } from 'assets/models/BaseDiscipline';
import { Button } from 'components/Elements/Forms/Inputs';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import { PackageCalculator } from './Models/PackageCalculator';
import { IExtendedBundleIn, IFlowPageProps } from '..';
import LessonSubjectCard from '../Components/LessonSubjectCard';

export interface ILessonPrefrencesPageProps extends IFlowPageProps {
  bundle: IExtendedBundleIn;
  setBundle: (bundle: IExtendedBundleIn) => void;
}

const LessonPrefrences: React.FunctionComponent<ILessonPrefrencesPageProps> = ({
  bundle,
  setBundle,
}) => {
  const [multipleChild, setMultipleChild] = useState<string>('nee'); //TODO: ik zou child veranderen naar students want in de programmeer context staat het een beetje raar
  const [selectedButton, setSelectedButton] = useState<string>('Nee');
  const [subjects, setSubjects] = useState<BaseDiscipline[]>();
  const [hours, setHours] = useState<number>(1);
  const label = useLabel();
  const currentWidth = useWidth();

  React.useEffect(() => {
    if (!subjects) {
      appClient.lessons.getLessonsViewDisciplines().then((r: BaseDiscipline[] | null) => {
        if (r !== null) {
          setSubjects(
            r.sort((a: BaseDiscipline, b: BaseDiscipline) => a.name.localeCompare(b.name))
          );
        }
      });
    }
  }, [subjects]);

  React.useEffect(() => {
    if (bundle.data_bundle.multiple_children) {
      if (bundle.data_bundle.multiple_children === true) {
        setMultipleChild('ja');
      } else if (bundle.data_bundle.multiple_children === false) {
        setMultipleChild('false');
      }
    }
  }, [multipleChild]);

  const handleNext = async (event: any) => {
    const tempdata = { ...bundle };
    const pc = new PackageCalculator(tempdata);

    const dataSpread = { ...bundle };
    dataSpread.data_bundle.package = '';
    const tempHours = hours * 2;
    dataSpread.data_bundle.students[0].score = pc.getScore() + tempHours;
    setBundle(dataSpread);

    event.detail();
  };

  React.useEffect(() => {
    document.addEventListener('onNext', handleNext);

    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [bundle]);

  const handleCheckMulitpleChilds = (ev: string): void => {
    let e = false;
    if (ev === 'ja') {
      e = true;
    } else if (ev === 'nee') {
      e = false;
    } else {
      e = false;
    }
    setMultipleChild(ev);
    const tempData = { ...bundle };
    tempData.data_bundle.multiple_children = e;
    setBundle(tempData);
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setHours(newValue as number);
  };

  const showSubjects = (): boolean => {
    let show = true;
    if (subjects) {
      // data.data_bundle.students[0].disciplines.forEach((subjectId: number) => {
      //     if (subjects?.find((subject) => subjectId === subject.id)?.name === "Huiswerkbegeleiding" || subjects!.find((subject) => subjectId === subject.id)?.name === "Organiseren & Plannen") {
      //         show = false
      //     } else {
      //         show = true
      //     }
      // })

      const subid1 = subjects?.find(subject => {
        if (subject.name === 'Huiswerkbegeleiding') {
          return subject.id;
        }
      });
      const subid2 = subjects?.find(subject => {
        if (subject.name === 'Organiseren & Plannen') {
          return subject.id;
        }
      });

      if (
        bundle.data_bundle.students[0].disciplines.includes(subid1) ||
        bundle.data_bundle.students[0].disciplines.includes(subid2)
      ) {
        show = false;
      } else {
        show = true;
      }
    }

    return show;
  };

  const calcMarginForShowSubs = (): string => {
    let mt = '';
    if (showSubjects()) {
      if (currentWidth === 'xs') {
        mt = '30px';
      } else {
        mt = '40px';
      }
    } else {
      mt = '0px';
    }

    return mt;
  };

  return (
    <div>
      <Card elevation={0} sx={{ marginTop: '10px' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={9} md={10}>
              <Grid container>
                <Grid item xs={11}>
                  <Grid
                    container
                    sx={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: '15px',
                    }}>
                    <Grid
                      item
                      xs={12}
                      sx={{ padding: currentWidth === 'xs' ? '10px' : '5px 10px 5px 10px' }}>
                      <Typography
                        sx={{
                          // marginTop: '10px',
                          // marginBottom: '20px',
                          fontSize: currentWidth === 'xs' ? '14px' : '18px',
                        }}>
                        Hoi! Mijn naam is Fiene en ik help u graag met uw pakketkeuze. Met een
                        pakket bent u namelijk voordeliger uit dan met losse lessen.
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{ padding: currentWidth === 'xs' ? '10px' : '5px 10px 5px 10px' }}>
                      <Typography sx={{ fontSize: currentWidth === 'xs' ? '14px' : '18px' }}>
                        Beantwoord de onderstaande vragen, zodat ik u het beste kan adviseren.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={1}>
                  <Box marginTop={'10px'}>
                    <Box
                      sx={{
                        position: 'absolute',
                        width: '60px',
                        height: '40px',
                        backgroundColor: '#f5f5f5',
                        ml: '-10px',
                        borderBottomLeftRadius: '16px',
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        width: '60px',
                        height: '40px',
                        backgroundColor: 'white',
                        mr: '30px',
                        borderBottomLeftRadius: '25px',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} md={2}>
              <Avatar
                sx={{
                  border: 'solid 2px',
                  backgroundColor: '#f5f5f5',
                  color: label?.main_color,
                  width: currentWidth === 'xs' ? 80 : 140,
                  height: currentWidth === 'xs' ? 80 : 140,
                }}>
                <Avatar
                  src={fiene}
                  alt="fiene"
                  sx={{
                    width: currentWidth === 'xs' ? 80 : 140,
                    height: currentWidth === 'xs' ? 80 : 140,
                  }}
                />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card elevation={0} sx={{ marginTop: currentWidth === 'xs' ? '-15px' : '-70px' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ margin: '10px 0px 20px 0px' }} fontWeight="bold">
                Heeft u nog meer kinderen die bijles nodig hebben?
              </Typography>
            </Grid>
            <Grid item xs={12} md={5.8}>
              <RadioGroup>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant={selectedButton == 'Ja' ? 'contained' : 'outlined'}
                      onClick={() => {
                        setSelectedButton('Ja');
                        handleCheckMulitpleChilds('ja');
                      }}
                      sx={{
                        padding: '10px 20px',
                        minWidth: '100%',
                        backgroundColor: selectedButton == 'Ja' ? label?.main_color : '#f5f5f5',
                      }}
                      startIcon={
                        <Radio
                          checked={selectedButton == 'Ja' ? true : false}
                          sx={{
                            padding: 'unset',
                            color: label?.main_color,
                            '&.Mui-checked': { color: 'white' },
                          }}
                        />
                      }>
                      <Typography color={selectedButton == 'Ja' ? 'white' : 'black'}>Ja</Typography>
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      variant={selectedButton == 'Nee' ? 'contained' : 'outlined'}
                      onClick={() => {
                        setSelectedButton('Nee');
                        handleCheckMulitpleChilds('nee');
                      }}
                      sx={{
                        padding: '10px 20px',
                        minWidth: '100%',
                        backgroundColor: selectedButton == 'Nee' ? label?.main_color : '#f5f5f5',
                      }}
                      startIcon={
                        <Radio
                          checked={selectedButton == 'Ja' ? false : true}
                          sx={{
                            // marginTop: "-5px",
                            padding: 'unset',
                            color: label?.main_color,
                            '&.Mui-checked': { color: 'white' },
                          }}
                        />
                      }>
                      <Typography color={selectedButton == 'Nee' ? 'white' : 'black'}>
                        Nee
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card
        elevation={0}
        sx={{
          marginTop: '-40px',
          marginBottom: '-50px',
        }}>
        <CardContent>
          <Grid container xs={12} sx={{ marginTop: currentWidth === 'xs' ? '35px' : '30px' }}>
            {showSubjects() && (
              <>
                <Grid item xs={12} sx={{ marginBottom: '15px' }}>
                  <Typography fontWeight="bold">
                    Huidige cijfers van de aangegeven vakken:
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={currentWidth === 'xs' ? 0 : 2}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        // margin:"0px -15px"
                        marginTop: '5px',
                      }}>
                      <Grid container spacing={2.5}>
                        {subjects &&
                          bundle.data_bundle.students[0].disciplines?.map(
                            (subjectId: any, index: number) => {
                              return (
                                <LessonSubjectCard
                                  subjectId={subjectId}
                                  index={index}
                                  subjects={subjects}
                                  bundle={bundle}
                                  setBundle={setBundle}
                                />
                              );
                            }
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6} sx={{ marginTop: calcMarginForShowSubs() }}>
              <Typography fontWeight="bold">Hoeveel uren per week wilt u bijles?</Typography>

              <Grid
                container
                spacing={1}
                sx={{
                  margin: '10px -10px 30px -10px',
                  // borderRadius: "15px",
                  // padding: "10px",
                  // backgroundColor: "#f5f5f5",
                }}>
                <Grid item xs={12} md={12}>
                  <Card
                    elevation={0}
                    sx={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: '15px',
                      paddingBottom: 'unset',
                    }}>
                    <Grid container>
                      <Grid item xs={10}>
                        {/* <CardHeader
                                                            title={"Uren"}
                                                            titleTypographyProps={{
                                                                fontWeight: 'light',
                                                                fontSize: "18px"
                                                            }}
                                                        /> */}
                      </Grid>
                      <Grid item xs={2}>
                        <Grid container justifyContent={'center'}>
                          <Grid item xs={'auto'}>
                            <Avatar
                              sx={{
                                backgroundColor: label?.main_color,
                                marginTop: '12px',
                                marginRight: '10px',
                              }}>
                              <Typography>{hours > 5 ? '5+' : hours}</Typography>
                            </Avatar>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <CardContent
                      sx={{
                        marginTop: '-15px',
                        marginBottom: '-20px',
                      }}>
                      <Grid container spacing={1} sx={{}}>
                        <Grid item xs={1}>
                          <Typography
                            sx={{
                              fontWeight: 'light',
                              marginTop: currentWidth === 'xs' ? '9px' : '2px',
                            }}>
                            1
                          </Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ paddingRight: '5px' }}>
                          <Slider
                            min={1}
                            step={0.5}
                            max={5.5}
                            // valueLabelDisplay="on"
                            onChange={handleChange}
                            marks
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            sx={{
                              fontWeight: 'light',
                              marginTop: currentWidth === 'xs' ? '9px' : '2px',
                            }}>
                            5+
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {/* <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={hours}
                                //  onChange={handleChange}
                                label="Aantal uur"
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                            </Select> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default LessonPrefrences;
