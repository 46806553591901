import React from 'react';

import { Grid } from '@mui/material';

import { BaseAvailability } from 'assets';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

import { IAvailabilityTimes } from '..';

interface IAvailabilityGridRasterProps {
  availabilities?: BaseAvailability[];
  days: Record<string, unknown>;
  times: IAvailabilityTimes[];
  handleClickAvailability: (day: number, start_time: string, end_time: string) => void;
  disabled?: boolean;
  index?: number;
}

const AvailabilityGridRaster: React.FunctionComponent<IAvailabilityGridRasterProps> = ({
  availabilities,
  days,
  times,
  handleClickAvailability,
  disabled,
  index,
}) => {
  const currentWidth = useWidth();
  const checkAvailability = (day: number, start_time: string, end_time: string): string => {
    /**Check if the availability uses seconds this was not the case with old coach flow. This will add the second to make the grid work */
    availabilities?.forEach((availability: BaseAvailability, i) => {
      if (availability.start_time.length < 6 && availability.end_time.length < 6) {
        availability.start_time = availability.start_time + ':00';
        availability.end_time = availability.end_time + ':00';

        availabilities.splice(i, 1, availability);
      }
    });

    let state = 'unset';
    const found: BaseAvailability | undefined = availabilities?.find(
      (availability: BaseAvailability) =>
        availability.start_time === start_time &&
        availability.end_time === end_time &&
        availability.day_number == day
    );
    if (found) {
      if (index) {
        // switch (index) {
        //   case 0:
        //     state = "#1fd165"
        //     break;
        //   case 1:
        //     state = "#D0E6A4"
        //     break;
        //   case 2:
        //     state = "#FFDD95"
        //     break;
        //   case 3:
        //     state = "#CBABD9"
        //     break;
        //   case 4:
        //     state = "#cdebf9"
        //     break;
        // }
        state = '#1fd165';
      } else {
        state = '#1fd165';
      }
    } else {
      state = 'unset';
    }

    return state;
  };

  const handleFakeClick = () => {
    /* Do nothing */
  };

  return (
    <Grid container>
      {times.map((time: IAvailabilityTimes, i: number) => {
        return (
          <>
            {Object.keys(days).map((day, y) => {
              return (
                <Grid
                  item
                  xs={1.714}
                  onClick={
                    disabled
                      ? () => handleFakeClick()
                      : () =>
                          handleClickAvailability(
                            day as unknown as number,
                            time.start_time + ':00',
                            time.end_time + ':00'
                          )
                  }
                  sx={{
                    marginTop: i === 5 ? '10px' : 'unset',
                    border: '1px solid gray',
                    borderRadius: i === 5 ? (y === 6 ? '0px 0px 15px 0px' : 'unset') : 'unset',
                    backgroundColor: checkAvailability(
                      day as unknown as number,
                      time.start_time + ':00',
                      time.end_time + ':00'
                    ),
                    height: currentWidth === 'xs' ? '40px' : '48px',
                    cursor: disabled ? 'unset' : 'pointer',
                  }} />
              );
            })}
          </>
        );
      })}
    </Grid>
  );
};

export default AvailabilityGridRaster;
