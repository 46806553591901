/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AuthService } from './services/AuthService';
import { BundlesService } from './services/BundlesService';
import { CoachesService } from './services/CoachesService';
import { DefaultService } from './services/DefaultService';
import { DefaultTariffService } from './services/DefaultTariffService';
import { FlagsService } from './services/FlagsService';
import { GuardiansService } from './services/GuardiansService';
import { LabelsService } from './services/LabelsService';
import { LessonsService } from './services/LessonsService';
import { LogsService } from './services/LogsService';
import { MatcherService } from './services/MatcherService';
import { NotesService } from './services/NotesService';
import { OrdersService } from './services/OrdersService';
import { PipesService } from './services/PipesService';
import { ProductsService } from './services/ProductsService';
import { PushNotificationsService } from './services/PushNotificationsService';
import { SearchService } from './services/SearchService';
import { SeleniumService } from './services/SeleniumService';
import { SPCoachesService } from './services/SPCoachesService';
import { SPCompetencesService } from './services/SPCompetencesService';
import { SPJobsService } from './services/SPJobsService';
import { SPProvidersService } from './services/SPProvidersService';
import { SPSectorsService } from './services/SPSectorsService';
import { StubsService } from './services/StubsService';
import { TagsService } from './services/TagsService';
import { TasksService } from './services/TasksService';
import { WebhooksService } from './services/WebhooksService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

    public readonly auth: AuthService;
    public readonly bundles: BundlesService;
    public readonly coaches: CoachesService;
    public readonly default: DefaultService;
    public readonly defaultTariff: DefaultTariffService;
    public readonly flags: FlagsService;
    public readonly guardians: GuardiansService;
    public readonly labels: LabelsService;
    public readonly lessons: LessonsService;
    public readonly logs: LogsService;
    public readonly matcher: MatcherService;
    public readonly notes: NotesService;
    public readonly orders: OrdersService;
    public readonly pipes: PipesService;
    public readonly products: ProductsService;
    public readonly pushNotifications: PushNotificationsService;
    public readonly search: SearchService;
    public readonly selenium: SeleniumService;
    public readonly spCoaches: SPCoachesService;
    public readonly spCompetences: SPCompetencesService;
    public readonly spJobs: SPJobsService;
    public readonly spProviders: SPProvidersService;
    public readonly spSectors: SPSectorsService;
    public readonly stubs: StubsService;
    public readonly tags: TagsService;
    public readonly tasks: TasksService;
    public readonly webhooks: WebhooksService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.auth = new AuthService(this.request);
        this.bundles = new BundlesService(this.request);
        this.coaches = new CoachesService(this.request);
        this.default = new DefaultService(this.request);
        this.defaultTariff = new DefaultTariffService(this.request);
        this.flags = new FlagsService(this.request);
        this.guardians = new GuardiansService(this.request);
        this.labels = new LabelsService(this.request);
        this.lessons = new LessonsService(this.request);
        this.logs = new LogsService(this.request);
        this.matcher = new MatcherService(this.request);
        this.notes = new NotesService(this.request);
        this.orders = new OrdersService(this.request);
        this.pipes = new PipesService(this.request);
        this.products = new ProductsService(this.request);
        this.pushNotifications = new PushNotificationsService(this.request);
        this.search = new SearchService(this.request);
        this.selenium = new SeleniumService(this.request);
        this.spCoaches = new SPCoachesService(this.request);
        this.spCompetences = new SPCompetencesService(this.request);
        this.spJobs = new SPJobsService(this.request);
        this.spProviders = new SPProvidersService(this.request);
        this.spSectors = new SPSectorsService(this.request);
        this.stubs = new StubsService(this.request);
        this.tags = new TagsService(this.request);
        this.tasks = new TasksService(this.request);
        this.webhooks = new WebhooksService(this.request);
    }
}

