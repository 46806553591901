/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseJob } from '../models/BaseJob';
import type { BaseJobMatch } from '../models/BaseJobMatch';
import type { BaseShift } from '../models/BaseShift';
import type { BaseTask } from '../models/BaseTask';
import type { Body_import_jobs_sp_jobs_new_import_bulk_post } from '../models/Body_import_jobs_sp_jobs_new_import_bulk_post';
import type { GenericStatus } from '../models/GenericStatus';
import type { JobBranchReplyInformation } from '../models/JobBranchReplyInformation';
import type { JobEdit } from '../models/JobEdit';
import type { JobIn } from '../models/JobIn';
import type { JobMatchCoachReplyInformation } from '../models/JobMatchCoachReplyInformation';
import type { JobSearchQueryParams } from '../models/JobSearchQueryParams';
import type { JobWithBranchAndLocation } from '../models/JobWithBranchAndLocation';
import type { NotifyBranchParts } from '../models/NotifyBranchParts';
import type { OrderByJobsEnum } from '../models/OrderByJobsEnum';
import type { PrivateJob } from '../models/PrivateJob';
import type { SearchAvailableJobsResult } from '../models/SearchAvailableJobsResult';
import type { ShiftEdit } from '../models/ShiftEdit';
import type { ShiftIdsIn } from '../models/ShiftIdsIn';
import type { ShiftIn } from '../models/ShiftIn';
import type { SimpleJob } from '../models/SimpleJob';
import type { SimpleJobMatch } from '../models/SimpleJobMatch';
import type { SimpleJobMatchWithCoach } from '../models/SimpleJobMatchWithCoach';
import type { SimpleJobMatchWithCoachAndJob } from '../models/SimpleJobMatchWithCoachAndJob';
import type { SimpleJobWithShifts } from '../models/SimpleJobWithShifts';
import type { SimpleShift } from '../models/SimpleShift';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SPJobsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Jobs
     * @param limit
     * @param offset
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public getSPJobsGetJobs(
        limit: number = 30,
        offset?: number,
    ): CancelablePromise<Array<SimpleJob>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/jobs',
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Jobs By Provider
     * @param providerId
     * @param limit
     * @param offset
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public getSPJobsGetJobsByProvider(
        providerId: number,
        limit: number = 30,
        offset?: number,
    ): CancelablePromise<Array<SimpleJob>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/jobs/provider/{provider_id}',
            path: {
                'provider_id': providerId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Jobs By Branch
     * @param branchId
     * @param limit
     * @param offset
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public getSPJobsGetJobsByBranch(
        branchId: number,
        limit: number = 30,
        offset?: number,
    ): CancelablePromise<Array<SimpleJob>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/jobs/branch/{branch_id}',
            path: {
                'branch_id': branchId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Branch Available Jobs
     * @param branchId
     * @param limit
     * @param offset
     * @returns SimpleJobWithShifts Successful Response
     * @throws ApiError
     */
    public getSPJobsGetBranchAvailableJobs(
        branchId: number,
        limit: number = 30,
        offset?: number,
    ): CancelablePromise<Array<SimpleJobWithShifts>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/jobs/branch/{branch_id}/available',
            path: {
                'branch_id': branchId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Jobs Search
     * @param requestBody
     * @param orderBy
     * @param orderAscending
     * @param limit
     * @param offset
     * @param dateStart
     * @param dateEnd
     * @returns JobWithBranchAndLocation Successful Response
     * @throws ApiError
     */
    public postSPJobsGetJobsSearch(
        requestBody: JobSearchQueryParams,
        orderBy?: (OrderByJobsEnum | null),
        orderAscending: boolean = true,
        limit: number = 30,
        offset?: number,
        dateStart?: (string | null),
        dateEnd?: (string | null),
    ): CancelablePromise<Array<JobWithBranchAndLocation>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/jobs/search',
            query: {
                'order_by': orderBy,
                'order_ascending': orderAscending,
                'limit': limit,
                'offset': offset,
                'date_start': dateStart,
                'date_end': dateEnd,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Jobs Search Count
     * @param requestBody
     * @param dateStart
     * @param dateEnd
     * @returns number Successful Response
     * @throws ApiError
     */
    public postSPJobsGetJobsSearchCount(
        requestBody: JobSearchQueryParams,
        dateStart?: (string | null),
        dateEnd?: (string | null),
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/jobs/search/count',
            query: {
                'date_start': dateStart,
                'date_end': dateEnd,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Jobs Search Download In Csv
     * @param requestBody
     * @param orderBy
     * @param orderAscending
     * @param limit
     * @param offset
     * @param dateStart
     * @param dateEnd
     * @returns any Successful Response
     * @throws ApiError
     */
    public postSPJobsGetJobsSearchDownloadInCsv(
        requestBody: JobSearchQueryParams,
        orderBy?: (OrderByJobsEnum | null),
        orderAscending: boolean = true,
        limit: number = 30,
        offset?: number,
        dateStart: string = '2024-07-15',
        dateEnd: string = '2024-08-14',
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/jobs/search/download/csv',
            query: {
                'order_by': orderBy,
                'order_ascending': orderAscending,
                'limit': limit,
                'offset': offset,
                'date_start': dateStart,
                'date_end': dateEnd,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Job By Id
     * @param jobId
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public getSPJobsGetJobById(
        jobId: number,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/job/{job_id}',
            path: {
                'job_id': jobId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Job
     * @param jobId
     * @param requestBody
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public patchSPJobsEditJob(
        jobId: number,
        requestBody: JobEdit,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job/{job_id}',
            path: {
                'job_id': jobId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Job
     * @param jobId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteSPJobsDeleteJob(
        jobId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/jobs/job/{job_id}',
            path: {
                'job_id': jobId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Job By Id Private
     * @param jobId
     * @returns PrivateJob Successful Response
     * @throws ApiError
     */
    public getSPJobsGetJobByIdPrivate(
        jobId: number,
    ): CancelablePromise<PrivateJob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/job/{job_id}/private',
            path: {
                'job_id': jobId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Job By Id Detailed
     * @param jobId
     * @returns BaseJob Successful Response
     * @throws ApiError
     */
    public getSPJobsGetJobByIdDetailed(
        jobId: number,
    ): CancelablePromise<BaseJob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/job/{job_id}/detailed',
            path: {
                'job_id': jobId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create New Job
     * @param branchId
     * @param sectorId
     * @param userLocationId
     * @param requestBody
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public postSPJobsCreateNewJob(
        branchId: number,
        sectorId: number,
        userLocationId: number,
        requestBody: JobIn,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/new/{branch_id}/{sector_id}/{user_location_id}',
            path: {
                'branch_id': branchId,
                'sector_id': sectorId,
                'user_location_id': userLocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Jobs
     * @param formData
     * @param commit
     * @returns BaseTask Successful Response
     * @throws ApiError
     */
    public postSPJobsImportJobs(
        formData: Body_import_jobs_sp_jobs_new_import_bulk_post,
        commit: boolean = false,
    ): CancelablePromise<BaseTask> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/new/import/bulk',
            query: {
                'commit': commit,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Job Branch
     * @param jobId
     * @param branchId
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public patchSPJobsEditJobBranch(
        jobId: number,
        branchId: number,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job/{job_id}/change_branch/{branch_id}',
            path: {
                'job_id': jobId,
                'branch_id': branchId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Job Coach
     * @param jobId
     * @param coachId
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public patchSPJobsEditJobCoach(
        jobId: number,
        coachId: number,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job/{job_id}/change_coach/{coach_id}',
            path: {
                'job_id': jobId,
                'coach_id': coachId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Split Job
     * @param jobId
     * @param requestBody
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public patchSPJobsSplitJob(
        jobId: number,
        requestBody: ShiftIdsIn,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job/{job_id}/split',
            path: {
                'job_id': jobId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Job Location
     * @param jobId
     * @param userLocationId
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public patchSPJobsEditJobLocation(
        jobId: number,
        userLocationId: number,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job/{job_id}/change_location/{user_location_id}',
            path: {
                'job_id': jobId,
                'user_location_id': userLocationId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Job Sector
     * @param jobId
     * @param sectorId
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public patchSPJobsEditJobSector(
        jobId: number,
        sectorId: number,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job/{job_id}/change_sector/{sector_id}',
            path: {
                'job_id': jobId,
                'sector_id': sectorId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Close Job
     * @param jobId
     * @param force
     * @param ignoreJobMatches
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchSPJobsCloseJob(
        jobId: number,
        force: boolean = false,
        ignoreJobMatches: boolean = false,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job/{job_id}/close',
            path: {
                'job_id': jobId,
            },
            query: {
                'force': force,
                'ignore_job_matches': ignoreJobMatches,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Job Shifts
     * @param jobId
     * @returns SimpleShift Successful Response
     * @throws ApiError
     */
    public getSPJobsGetJobShifts(
        jobId: number,
    ): CancelablePromise<Array<SimpleShift>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/job/{job_id}/shifts',
            path: {
                'job_id': jobId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shift
     * @param shiftId
     * @returns SimpleShift Successful Response
     * @throws ApiError
     */
    public getSPJobsGetShift(
        shiftId: number,
    ): CancelablePromise<SimpleShift> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/shift/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Shift
     * @param shiftId
     * @param requestBody
     * @param force
     * @returns SimpleShift Successful Response
     * @throws ApiError
     */
    public patchSPJobsEditShift(
        shiftId: number,
        requestBody: ShiftEdit,
        force: boolean = false,
    ): CancelablePromise<SimpleShift> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/shift/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            query: {
                'force': force,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shift
     * @param shiftId
     * @param force
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteSPJobsDeleteShift(
        shiftId: number,
        force: boolean = false,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/jobs/shift/{shift_id}',
            path: {
                'shift_id': shiftId,
            },
            query: {
                'force': force,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shift Detailed
     * @param shiftId
     * @returns BaseShift Successful Response
     * @throws ApiError
     */
    public getSPJobsGetShiftDetailed(
        shiftId: number,
    ): CancelablePromise<BaseShift> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/shift/{shift_id}/detailed',
            path: {
                'shift_id': shiftId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create New Shift
     * @param jobId
     * @param requestBody
     * @param force
     * @returns SimpleShift Successful Response
     * @throws ApiError
     */
    public postSPJobsCreateNewShift(
        jobId: number,
        requestBody: ShiftIn,
        force: boolean = false,
    ): CancelablePromise<SimpleShift> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/shift/new/{job_id}',
            path: {
                'job_id': jobId,
            },
            query: {
                'force': force,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Shift Job
     * @param shiftId
     * @param jobId
     * @param force
     * @returns SimpleShift Successful Response
     * @throws ApiError
     */
    public patchSPJobsEditShiftJob(
        shiftId: number,
        jobId: number,
        force: boolean = false,
    ): CancelablePromise<SimpleShift> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/shift/{shift_id}/change_job/{job_id}',
            path: {
                'shift_id': shiftId,
                'job_id': jobId,
            },
            query: {
                'force': force,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Job Matches
     * @param jobId
     * @returns SimpleJobMatchWithCoach Successful Response
     * @throws ApiError
     */
    public getSPJobsGetJobMatches(
        jobId: number,
    ): CancelablePromise<Array<SimpleJobMatchWithCoach>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/job/{job_id}/job_matches',
            path: {
                'job_id': jobId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Request Job For Job Match
     * Request a job as a coach in order to have it be processes by selenium/ internal employees. Please note that coaches can only try and claim jobs that are not yet claimed by other coaches (in other words: the job status has to be "open")
     *
     * Args:
     * - `job_id`, int; The id of the job to request
     * - `coach_id`, int; The id of the coach to request the job for
     *
     * Returns:
     * - `SimpleJobMatch`; The created job match, that has been reserved in the calendar of the coach.
     * @param jobId
     * @param coachId
     * @returns SimpleJobMatch Successful Response
     * @throws ApiError
     */
    public postSPJobsRequestJobForJobMatch(
        jobId: number,
        coachId: number,
    ): CancelablePromise<SimpleJobMatch> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/job/{job_id}/request_job_as_coach/{coach_id}',
            path: {
                'job_id': jobId,
                'coach_id': coachId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Job Match
     * @param jobMatchId
     * @returns SimpleJobMatch Successful Response
     * @throws ApiError
     */
    public getSPJobsGetJobMatch(
        jobMatchId: number,
    ): CancelablePromise<SimpleJobMatch> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/job_match/{job_match_id}',
            path: {
                'job_match_id': jobMatchId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel Planned Job Match
     * @param jobMatchId
     * @param force
     * @returns SimpleJobMatch Successful Response
     * @throws ApiError
     */
    public patchSPJobsCancelPlannedJobMatch(
        jobMatchId: number,
        force: boolean = false,
    ): CancelablePromise<SimpleJobMatch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job_match/{job_match_id}/close',
            path: {
                'job_match_id': jobMatchId,
            },
            query: {
                'force': force,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Job Match Detailed
     * @param jobMatchId
     * @returns BaseJobMatch Successful Response
     * @throws ApiError
     */
    public getSPJobsGetJobMatchDetailed(
        jobMatchId: number,
    ): CancelablePromise<BaseJobMatch> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/job_match/{job_match_id}/detailed',
            path: {
                'job_match_id': jobMatchId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create New Job Match
     * @param jobId
     * @param coachId
     * @returns SimpleJobMatch Successful Response
     * @throws ApiError
     */
    public postSPJobsCreateNewJobMatch(
        jobId: number,
        coachId: number,
    ): CancelablePromise<SimpleJobMatch> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/job_match/new/{job_id}/{coach_id}',
            path: {
                'job_id': jobId,
                'coach_id': coachId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Notify Coach About Job Match
     * @param jobMatchId
     * @param sendNotification
     * @param anonymous
     * @returns SimpleJobMatch Successful Response
     * @throws ApiError
     */
    public postSPJobsNotifyCoachAboutJobMatch(
        jobMatchId: number,
        sendNotification: boolean = true,
        anonymous: boolean = false,
    ): CancelablePromise<SimpleJobMatch> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/job_match/{job_match_id}/notify/coach',
            path: {
                'job_match_id': jobMatchId,
            },
            query: {
                'send_notification': sendNotification,
                'anonymous': anonymous,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reply To Job Match Uuid Coach
     * @param jobMatchCoachToken
     * @param accept
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchSPJobsReplyToJobMatchUuidCoach(
        jobMatchCoachToken: string,
        accept: boolean,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job_match/reply/coach/{job_match_coach_token}',
            path: {
                'job_match_coach_token': jobMatchCoachToken,
            },
            query: {
                'accept': accept,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reply To Job Match Id Coach
     * @param jobMatchId
     * @param accept
     * @param force
     * @returns SimpleJobMatch Successful Response
     * @throws ApiError
     */
    public patchSPJobsReplyToJobMatchIdCoach(
        jobMatchId: number,
        accept: boolean,
        force: boolean = false,
    ): CancelablePromise<SimpleJobMatch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job_match/reply/coach/{job_match_id}/job_id',
            path: {
                'job_match_id': jobMatchId,
            },
            query: {
                'accept': accept,
                'force': force,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Info By Uuid Coach
     * @param jobMatchCoachToken
     * @returns JobMatchCoachReplyInformation Successful Response
     * @throws ApiError
     */
    public getSPJobsGetInfoByUuidCoach(
        jobMatchCoachToken: string,
    ): CancelablePromise<JobMatchCoachReplyInformation> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/job_match/reply/coach/info/{job_match_coach_token}',
            path: {
                'job_match_coach_token': jobMatchCoachToken,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Notify Branch About Job Matches
     * @param jobId
     * @param requestBody
     * @param anonymous
     * @param sendNotification
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public postSPJobsNotifyBranchAboutJobMatches(
        jobId: number,
        requestBody: NotifyBranchParts,
        anonymous: boolean = false,
        sendNotification: boolean = true,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/job/{job_id}/notify/branch',
            path: {
                'job_id': jobId,
            },
            query: {
                'anonymous': anonymous,
                'send_notification': sendNotification,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reply To Job Match Uuid Branch
     * @param jobBranchToken
     * @param jobMatchId
     * @param accept
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchSPJobsReplyToJobMatchUuidBranch(
        jobBranchToken: string,
        jobMatchId: number,
        accept: boolean,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job_match/reply/branch/{job_branch_token}/{job_match_id}',
            path: {
                'job_branch_token': jobBranchToken,
                'job_match_id': jobMatchId,
            },
            query: {
                'accept': accept,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Close Job By Uuid Branch
     * @param jobBranchToken
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchSPJobsCloseJobByUuidBranch(
        jobBranchToken: string,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job_match/reply/branch/{job_branch_token}/close/all',
            path: {
                'job_branch_token': jobBranchToken,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Info By Uuid Branch
     * @param jobBranchToken
     * @returns JobBranchReplyInformation Successful Response
     * @throws ApiError
     */
    public getSPJobsGetInfoByUuidBranch(
        jobBranchToken: string,
    ): CancelablePromise<JobBranchReplyInformation> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/job_match/reply/branch/info/{job_branch_token}',
            path: {
                'job_branch_token': jobBranchToken,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Schedule Job Match
     * @param jobMatchId
     * @param skipBranchAwaiting
     * @param autoCloseOverlapping
     * @param sendNotification
     * @returns SimpleJobMatch Successful Response
     * @throws ApiError
     */
    public patchSPJobsScheduleJobMatch(
        jobMatchId: number,
        skipBranchAwaiting: boolean = false,
        autoCloseOverlapping: boolean = false,
        sendNotification: boolean = true,
    ): CancelablePromise<SimpleJobMatch> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job_match/{job_match_id}/schedule',
            path: {
                'job_match_id': jobMatchId,
            },
            query: {
                'skip_branch_awaiting': skipBranchAwaiting,
                'auto_close_overlapping': autoCloseOverlapping,
                'send_notification': sendNotification,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Job Matches
     * @param requestedByCoach
     * @param requestBody
     * @returns SimpleJobMatchWithCoachAndJob Successful Response
     * @throws ApiError
     */
    public postSPJobsGetJobMatches(
        requestedByCoach: boolean = false,
        requestBody?: (Array<string> | null),
    ): CancelablePromise<Array<SimpleJobMatchWithCoachAndJob>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/job_matches',
            query: {
                'requested_by_coach': requestedByCoach,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Competence To Job
     * @param competenceId
     * @param jobId
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public postSPJobsAddCompetenceToJob(
        competenceId: number,
        jobId: number,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/jobs/job/{job_id}/competence/{competence_id}',
            path: {
                'competence_id': competenceId,
                'job_id': jobId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Competence From Job
     * @param competenceId
     * @param jobId
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public deleteSPJobsRemoveCompetenceFromJob(
        competenceId: number,
        jobId: number,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/jobs/job/{job_id}/competence/{competence_id}',
            path: {
                'competence_id': competenceId,
                'job_id': jobId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Competence To Job Bulk
     * @param jobId
     * @param requestBody
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public patchSPJobsAddCompetenceToJobBulk(
        jobId: number,
        requestBody: Array<number>,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/jobs/job/{job_id}/competence/add/bulk',
            path: {
                'job_id': jobId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Competence From Job Bulk
     * @param jobId
     * @param requestBody
     * @returns SimpleJob Successful Response
     * @throws ApiError
     */
    public deleteSPJobsRemoveCompetenceFromJobBulk(
        jobId: number,
        requestBody: Array<number>,
    ): CancelablePromise<SimpleJob> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/jobs/job/{job_id}/competence/add/bulk',
            path: {
                'job_id': jobId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Available Jobs
     * Get available jobs for a coach
     *
     * Args:
     *
     * - `coach_id`, int; The id of the coach for which to get the available jobs
     *
     * - `limit`, int; The maximum amount of jobs to return, used for pagination
     *
     * - `offset`, int; The offset to start from, used for pagination
     *
     * - `filter_start_date`, date; The start date to filter jobs on
     *
     * - `filter_end_date`, date; The end date to filter jobs on
     *
     * - `radius`, int; The radius in meters to search for jobs around the coach's location
     *
     * Returns:
     *
     * - `amount`, int; The total amount of available jobs
     *
     * - `jobs`, List[SearchAvailableJobsResult]; The available jobs for the coach
     *
     * > The given distance to a job is in kilometers
     * @param coachId
     * @param limit
     * @param offset
     * @param filterStartDate
     * @param filterEndDate
     * @param radius
     * @returns SearchAvailableJobsResult Successful Response
     * @throws ApiError
     */
    public getSPJobsGetAvailableJobs(
        coachId: number,
        limit: number = 30,
        offset?: number,
        filterStartDate?: (string | null),
        filterEndDate?: (string | null),
        radius?: (number | null),
    ): CancelablePromise<SearchAvailableJobsResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/jobs/available_jobs',
            query: {
                'limit': limit,
                'offset': offset,
                'filter_start_date': filterStartDate,
                'filter_end_date': filterEndDate,
                'radius': radius,
                'coach_id': coachId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
