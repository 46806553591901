import React from 'react';

import { Box, Card, CardContent, Grid, MenuItem, Radio, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import TagManager from 'react-gtm-module';

import { AvailabilityIn, BaseStub } from 'assets';
import { Button, TextField } from 'components/Elements/Forms/Inputs';
import AvailabilityGrid_V2 from 'components/Functional/Availability/AvailabilityGrid_V2';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import { IFlowPageProps } from '..';

interface ISchedulePage extends IFlowPageProps {
  data: BaseStub;
  setData: (baseStub: BaseStub) => void;
}

//TODO: heeft een betere naam nodig
const SchedulePage: React.FunctionComponent<ISchedulePage> = ({ data, setData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentWidth = useWidth();
  const [selectedDate, setSelectedDate] = React.useState<string>(
    data.data_bundle.students[0].start_date
      ? data.data_bundle.students[0].start_date
      : 'volgende_week'
  );
  const label = useLabel();

  const handleLocation = (event: boolean) => {
    const tempData = { ...data };
    tempData.data_bundle.students[0].online_tutoring = event;
    setData(tempData);
  };

  const tagManagerArgs = {
    dataLayer: {
      page: 'availability',
      data: data,
    },
    dataLayerName: 'PageDataLayer',
  };

  TagManager.dataLayer(tagManagerArgs);

  const setLessonComment = (info: string) => {
    const tempData = { ...data };
    tempData.data_bundle.students[0].lesson_comment = info;
    setData(tempData);
  };

  const handleAdd = (item: AvailabilityIn) => {
    const tempData = { ...data };
    if (!tempData.data_bundle.students[0].availability) {
      tempData.data_bundle.students[0].availability = [];
      setData(tempData);
    }

    if (tempData.data_bundle.students[0].availability) {
      tempData.data_bundle.students[0].availability.push(item);
      setData(tempData);
    } else {
      tempData.data_bundle.students[0].availability = [item];
      setData(tempData);
    }
  };

  const handleDelete = (item: AvailabilityIn) => {
    const tempData = { ...data };
    const index = tempData.data_bundle.students[0].availability.indexOf(item);
    if (index !== -1) {
      tempData.data_bundle.students[0].availability.splice(index, 1);
      setData(tempData);
    }
  };

  const handleStarttDate = (value: string) => {
    setSelectedDate(value);
    const tempData = { ...data };
    tempData.data_bundle.students[0].start_date = value;
    setData(tempData);
  };

  const handleNext = async (event: any) => {
    if (
      data.data_bundle.students[0].availability === undefined ||
      data.data_bundle.students[0].availability.length < 1
    ) {
      enqueueSnackbar('Geef a.u.b. uw beschikbaarheid op.', { variant: 'error' });

      return;
    }

    if (data.data_bundle.students[0].start_date === undefined) {
      enqueueSnackbar('Geef a.u.b. een datum op wanneer u wenst te starten.', { variant: 'error' });

      return;
    }

    const tempdata = { ...data };
    tempdata.data_bundle.students[0].start_date = selectedDate;
    setData(tempdata);

    event.detail();
  };

  React.useEffect(() => {
    document.addEventListener('onNext', handleNext);

    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [data]);

  enum StartDatesEnum {
    //options volgende weeek, over 2 weken, over 3 weken, later dan 3 weken
    Volgende_week = 'Volgende week',
    Over_2_weken = 'Over 2 weken',
    Over_3_weken = 'Over 3 weken',
    Later_dan_3_weken = 'Later dan 3 weken',
  }

  return (
    <div className="availability">
      <Card elevation={0}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ marginBottom: '8px' }}>
                Vink aan op welke momenten uw kind allemaal beschikbaar is voor bijles. Op basis van
                deze beschikbaarheid plannen wij de eerste bijles in.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography display="inline" color="#1fd165" fontWeight={'bold'}>
                Tip:
              </Typography>
              <Typography display="inline"> </Typography>
              <Typography display="inline">
                hoe meer beschikbaarheid u opgeeft, hoe sneller wij u kunnen helpen.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card elevation={0} sx={{ marginTop: '-25px' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={3} md={1.5}>
              <Typography display="inline" fontWeight={'bold'}>
                Legenda:
              </Typography>
            </Grid>
            <Grid item xs={9} md={10.5}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={2} md={2}>
                      <Box
                        sx={{
                          backgroundColor: '#f5f5f5',
                          height: '20px',
                          borderRadius: '5px',
                          border: '1px black solid',
                          mr: '10px',
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} md={10}>
                      <Typography display="inline">Niet beschikbaar</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={2} md={2}>
                      <Box
                        sx={{
                          backgroundColor: '#1fd165',
                          height: '20px',
                          borderRadius: '5px',
                          border: '1px black solid',
                          mr: '10px',
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} md={10}>
                      <Typography display="inline">Beschikbaar</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '10px' }}>
              <Typography>
                (Klik op de grijze vakjes om deze groen te maken indien u beschikbaar bent.)
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sx={{ marginTop: "-1px" }}>
              <AvailabilityGrid
                user_locations={[]}
                availability={data.data_bundle.students[0].availability ? data.data_bundle.students[0].availability : []}
                onAdd={handleAdd}
                onDelete={handleDelete}
              />
            </Grid> */}
            <Grid item xs={12} sx={{ marginTop: '-1px' }}>
              <AvailabilityGrid_V2
                availabilities={
                  data.data_bundle.students[0].availability
                    ? data.data_bundle.students[0].availability
                    : []
                }
                handleAdd={handleAdd}
                handleRemove={handleDelete}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card elevation={0} sx={{ marginTop: '-15px' }}>
        <CardContent>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ margin: '10px 0px' }} fontWeight="bold">
                    Wanneer wenst u te starten?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    backgroundColor="#f5f5f5"
                    border="unset"
                    value={selectedDate || {}}
                    onChange={e => handleStarttDate(e.target.value)}>
                    {Object.keys(StartDatesEnum).map(item => (
                      <MenuItem value={item}>{item.replaceAll('_', ' ')}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} sx={{ marginTop: currentWidth === 'xs' ? '-10px' : 'unset' }}>
              <Grid container>
                <Grid item xs={12} sx={{ marginTop: currentWidth === 'xs' ? '10px' : 'unset' }}>
                  <Typography sx={{ margin: '10px 0px' }} fontWeight="bold">
                    Locatie voorkeur:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2.5}>
                    <Grid item xs={6}>
                      <Button
                        variant={
                          data.data_bundle.students[0].online_tutoring
                            ? data.data_bundle.students[0].online_tutoring === false
                              ? 'contained'
                              : 'outlined'
                            : 'contained'
                        }
                        // onClick={() => { setSelectedButton("Aan huis"); setPrefferedTutoring(false); }}
                        onClick={() => handleLocation(false)}
                        sx={{
                          padding: '10px 20px',
                          minWidth: '100%',
                          backgroundColor: data.data_bundle.students[0].online_tutoring
                            ? data.data_bundle.students[0].online_tutoring === false
                              ? label?.main_color
                              : '#f5f5f5'
                            : label?.main_color,
                          alignItems: 'left',
                        }}
                        startIcon={
                          <Radio
                            checked={
                              data.data_bundle.students[0].online_tutoring
                                ? data.data_bundle.students[0].online_tutoring === false
                                : true
                            }
                            sx={{
                              padding: 'unset',
                              float: 'left',
                              color: label?.main_color,
                              '&.Mui-checked': { color: 'white' },
                            }}
                          />
                        }>
                        <Typography
                          color={
                            data.data_bundle.students[0].online_tutoring
                              ? data.data_bundle.students[0].online_tutoring === false
                                ? 'white'
                                : 'black'
                              : 'white'
                          }>
                          Aan huis
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        variant={
                          data.data_bundle.students[0].online_tutoring === true
                            ? 'contained'
                            : 'outlined'
                        }
                        // onClick={() => { setSelectedButton("Online"); setPrefferedTutoring(true); }}
                        onClick={() => handleLocation(true)}
                        sx={{
                          padding: '10px 20px',
                          minWidth: '100%',
                          backgroundColor:
                            data.data_bundle.students[0].online_tutoring === true
                              ? label?.main_color
                              : '#f5f5f5',
                        }}
                        startIcon={
                          <Radio
                            checked={data.data_bundle.students[0].online_tutoring === true}
                            sx={{
                              padding: 'unset',
                              color: label?.main_color,
                              '&.Mui-checked': { color: 'white' },
                            }}
                          />
                        }>
                        <Typography
                          color={
                            data.data_bundle.students[0].online_tutoring === true
                              ? 'white'
                              : 'black'
                          }>
                          Online
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card
        elevation={0}
        sx={{
          marginTop: '-15px',
          marginBottom: '-20px',
        }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ margin: '10px 0px' }} fontWeight="bold">
                Heeft u nog specifieke voorkeuren/ wensen?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder={'Bijvoorbeeld: Wij zoeken een coach die ervaring heeft met dyslexie. '}
                fullWidth
                rows={4}
                multiline
                backgroundColor="#f5f5f5"
                border="unset"
                value={data.data_bundle.students?.[0].lesson_comment || ''}
                onChange={e => setLessonComment(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default SchedulePage;
