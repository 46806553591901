export const SWPackages = [
  {
    title: 'Mini pakket',
    price: 31,
    discount: 64,
    hours: 16,
    maxScore: 5,
    selected: false,
    advice: false,
    displayMatch: true,
    priority: 3,
  },
  {
    title: 'Succes pakket',
    price: 28,
    discount: 168,
    hours: 24,
    minScore: 5,
    maxScore: 19,
    selected: false,
    advice: false,
    displayMatch: true,
    priority: 1,
  },
  {
    title: 'Succes plus pakket',
    price: 24,
    discount: 440,
    hours: 40,
    minScore: 19,
    selected: false,
    advice: false,
    displayMatch: true,
    priority: 2,
  },
  {
    title: 'Losse les',
    price: 35,
    discount: 0,
    hours: 1,
    minScore: 0,
    selected: false,
    advice: false,
    displayMatch: true,
    priority: 4,
  },
];
export const OBPackages = [
  {
    title: 'Klein pakket',
    price: 30,
    discount: 90,
    hours: 15,
    maxScore: 5,
    selected: false,
    advice: false,
    displayMatch: true,
    priority: 3,
  },
  {
    title: 'Middel pakket',
    price: 27,
    discount: 216,
    hours: 24,
    minScore: 5,
    maxScore: 19,
    selected: false,
    advice: false,
    displayMatch: true,
    priority: 1,
  },
  {
    title: 'Groot pakket',
    price: 23,
    discount: 494,
    hours: 38,
    minScore: 19,
    selected: false,
    advice: false,
    displayMatch: true,
    priority: 2,
  },
  {
    title: 'Losse les',
    price: 36,
    hours: 1,
    minScore: 0,
    selected: false,
    advice: false,
    displayMatch: true,
    priority: 4,
  },
];
export const VBPackages = [
  {
    title: 'Losse les',
    price: 24,
    hours: 1,
    maxScore: 5,
    selected: false,
    noMatch: true,
    advice: true,
    priority: 3,
  },
  {
    title: 'Strippenkaart klein',
    price: 30,
    discount: 48,
    hours: 12,
    minScore: 5,
    maxScore: 11,
    selected: false,
    noMatch: true,
    advice: true,
    priority: 2,
  },
  {
    title: 'Strippenkaart groot',
    price: 28,
    discount: 144,
    hours: 24,
    minScore: 11,
    selected: false,
    noMatch: true,
    advice: true,
    priority: 1,
  },
];

export const BEUPackages = [
  {
    title: 'Losse les',
    price: 34,
    hours: 1,
    maxScore: 5,
    selected: false,
    noMatch: true,
    advice: true,
    priority: 3,
  },
  {
    title: 'Strippenkaart klein',
    price: 30,
    discount: 48,
    hours: 12,
    minScore: 5,
    maxScore: 11,
    selected: false,
    noMatch: true,
    advice: true,
    priority: 2,
  },
  {
    title: 'Strippenkaart groot',
    price: 28,
    discount: 144,
    hours: 24,
    minScore: 1,
    selected: false,
    noMatch: true,
    advice: true,
    priority: 1,
  },
];
