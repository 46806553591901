import React, { useEffect, useState } from 'react';

import 'moment/locale/nl';
import { LinearProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import createTypography from '@mui/material/styles/createTypography';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';
import { HashRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { AppClient, RegularUserWithGroupsAndTypes } from 'assets';
import CustomModal from 'components/Templates/Pages/CustomModal';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';
import { NavContextProvider } from 'functions/Hooks/useNav';
import { ProductStoreContextProvider } from 'functions/Hooks/useProductsStore';
import ChangePassword from 'views/Pages/Authorization/ChangePassword/ChangePassword';
import CreatePassword from 'views/Pages/Authorization/CreatePassword/CreatePassword';
import Login from 'views/Pages/Authorization/Login/Login';
import Reset from 'views/Pages/Authorization/Reset/Reset';
import CoachJobPage from 'views/Pages/Flows/JobFlowCoach';
import ProviderJobPage from 'views/Pages/Flows/JobFlowProvider';
import Landing from 'views/Pages/Flows/LandingFlow';
import SignUpFlowCoaches from 'views/Pages/Flows/SignupFlowCoaches';
import SignUpFlowGuardians from 'views/Pages/Flows/SignupFlowGuardian';
import InvoicePaymentPage from 'views/PortalShared/CollectionPayment/CollectionPaymentPage';
import CollectionPaymentPage from 'views/PortalShared/InvoicePayment/InvoicePaymentPage';
import PaymentStatusPage from 'views/PortalShared/Payment/Components/PaymentStatusPage/PaymentStatusPage';
import { PaymentStateProvider } from 'views/PortalShared/Payment/hooks/usePaymentState';

import useUser from './functions/Hooks/useUser';
import standardTheme, { breakpoints, font, palette, typography } from './theme';

export const setToken = (TOKEN: string) => {
  appClient = new AppClient({
    BASE: process.env.REACT_APP_BRANCH,
    TOKEN,
  });
};

export let appClient = new AppClient({
  BASE: process.env.REACT_APP_BRANCH,
  TOKEN: (localStorage.getItem('jwt') as string)?.replace('Bearer ', ''),
});

const loading = () => <LinearProgress />;

// Containers
const DefaultLayout = React.lazy(() => import('./components/Functional/Containers/DefaultLayout'));

// Pages
const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));

function App() {
  const { jwt, getUser } = useUser();
  const history = useHistory();
  const [theme, setTheme] = useState(standardTheme);
  const currentLabel = useLabel();
  const prevMonth = moment().subtract(1, 'months');
  const currentWidth = useWidth();

  const checkUnaccorded = async (user: RegularUserWithGroupsAndTypes) => {
    if (user && user?.coach !== null && user?.coach !== undefined) {
      appClient.coaches
        .getCoachesViewCoachToAccord(user.coach.id, prevMonth.year(), prevMonth.month() + 1, true)
        .then(lessonSchema => {
          if (lessonSchema.length > 0) {
            history.push('../#/FinancienCoach');
          } else {
            history.push('../#/Dashboard');
          }
        })
        .catch(e => {
          if (e.status === 406) {
            history.push('../#/FinancienCoach');
          } else {
            history.push('../#/Dashboard');
          }
        });
    } else {
      history.push('../#/Dashboard');
    }
  };

  //Check if a user is authenticated and redirect to the correct page
  const checkIfAuthenticated = async () => {
    if (jwt() && getUser) {
      getUser()
        .then(userData => {
          switch (userData.first_landing_status) {
            case 'legacy':
              history.push('/Landing');
              break;
            default: {
              //Redirect to the page the user has visited before logging out in a different way than clicking "log out"
              const href = window.location.href.toLocaleLowerCase();
              if (href.includes('redirectto=')) {
                const hrefSplit = href.split('redirectto=');
                //Redirect to dashboard if redirectTo is empty
                if (hrefSplit[1] == '#/') {
                  history.push('../#/Dashboard');
                } else {
                  history.push(`../${hrefSplit[1]}`);
                }
              } else if (window.location.href.toLocaleLowerCase().includes('login')) {
                checkUnaccorded(userData);
              }
              break;
            }
          }
        })
        .catch(r => {
          if (r.status == 404) {
            history.push('../400');
          } else if (r.status >= 500 && r.status < 600) {
            history.push('../500');
          }
        });
    }
  };

  useEffect(() => {
    if (history) checkIfAuthenticated();
  }, [history]);

  //Changes theme based on label and screen size
  React.useLayoutEffect(() => {
    let paletteTemp = palette;
    let typographyTemp = typography;

    //Change theme based on label
    if (currentLabel) {
      //Colour scheme
      paletteTemp = createPalette({
        primary: {
          main: currentLabel.main_color as string,
          light: currentLabel.light_color as string,
          dark: currentLabel.dark_color as string,
          contrastText: currentLabel.contrast_color as string,
        },
      });

      //Document title, logo
      document.title = currentLabel?.name + ' portaal';
      if (document.getElementById('favicon')) {
        (document.getElementById('favicon') as unknown as { href: string }).href =
          process.env.REACT_APP_PROFILEPHOTOBUCKET + '/' + currentLabel?.logo;
      }

      //tags
      const tagManager = document.getElementById('googletagmanager');
      tagManager?.append(
        `<iframe src='https://www.googletagmanager.com/ns.html?id=${currentLabel?.gtm_id}' height='0' width='0' style='display:none;visibility:hidden'></iframe>`
      );
    }

    //Change font size for mobile
    if (currentWidth === 'xs') {
      typographyTemp = createTypography(paletteTemp, {
        fontFamily: font,
        h1: {
          font: 'normal normal bold 20px/26px Source Sans Pro',
          textTransform: 'none',
        },
        h2: {
          font: 'normal normal bold 16px/20px Source Sans Pro',
          textTransform: 'none',
        },
        h3: {
          font: 'normal normal bold 14px/20px Source Sans Pro',
          textTransform: 'none',
        },
        h4: {
          font: 'normal normal normal 14px/20px Source Sans Pro',
          textTransform: 'none',
        },
        h5: {
          font: 'normal normal bold 20px/26px Source Sans Pro',
          textTransform: 'none',
        },
        body1: { font: 'normal normal normal 14px/20px Source Sans Pro' },
        body2: {
          font: 'normal normal normal 14px/20px Source Sans Pro',
          color: paletteTemp.text.secondary,
        },
        button: {
          font: 'normal normal bold 14px/16px Source Sans Pro',
          textTransform: 'none',
        },
        subtitle2: {
          fontFamily: "'Dancing Script', cursive",
          fontWeight: '600',
          fontSize: '35px',
        },
      });
    }

    //Apply new theme elements
    if (paletteTemp !== palette || typographyTemp !== typography) {
      setTheme(
        createTheme({
          palette: paletteTemp,
          typography: typographyTemp,
          breakpoints,
        })
      );
    }
  }, [currentWidth, currentLabel]);

  moment.locale('nl');

  return (
    <ThemeProvider theme={theme}>
      <NavContextProvider>
        <ProductStoreContextProvider>
          <PaymentStateProvider>
            <CookiesProvider>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                maxSnack={3}>
                <CustomModal>
                  <React.Suspense fallback={loading()}>
                    <Switch>
                      <Route exact path="/login" render={props => <Login {...props} />} />
                      <Route
                        path="/wachtwoord-wijzigen/:token"
                        render={props => <ChangePassword {...props} />}
                      />
                      <Route path="/wachtwoord-aanmaken/:token" render={() => <CreatePassword />} />
                      <Route path="/wachtwoord-aanmaken" render={props => <Login {...props} />} />
                      <Route exact path="/reset" render={() => <Reset />} />

                      <Route exact path="/404" render={props => <Page404 {...props} />} />
                      <Route exact path="/500" render={props => <Page500 {...props} />} />

                      <Route
                        exact
                        path="/sp/branch/:branchToken"
                        render={props => <ProviderJobPage {...props} />}
                      />
                      <Route
                        exact
                        path="/sp/coach/:jobToken"
                        render={props => <CoachJobPage {...props} />}
                      />
                      <Route path="/PaymentStatus" render={() => <PaymentStatusPage />} />
                      <Route path="/CoachSignUp/:key" render={() => <SignUpFlowCoaches />} />
                      <Route path="/OuderSignUp/:key" render={() => <SignUpFlowGuardians />} />
                      <Redirect from="#/OuderSignUp/:key" to="/OuderSignUp/:key" />
                      <Route path="/OuderSignUp/" render={() => <SignUpFlowGuardians />} />
                      <Route exact path="/Landing" render={() => <Landing />} />
                      <Route exact path="/Collection" render={() => <CollectionPaymentPage />} />
                      <Route exact path="/Invoice" render={() => <InvoicePaymentPage />} />

                      <Redirect exact from="/#" to="/login" />
                      <Redirect from="/CoachSignUp" to="/login" />
                      <Route
                        path="/"
                        render={() => (
                          <HashRouter>
                            <DefaultLayout />
                          </HashRouter>
                        )}
                      />
                    </Switch>
                  </React.Suspense>
                </CustomModal>
              </SnackbarProvider>
            </CookiesProvider>
          </PaymentStateProvider>
        </ProductStoreContextProvider>
      </NavContextProvider>
    </ThemeProvider>
  );
}

export default App;
