import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import createTypography from '@mui/material/styles/createTypography';
import { createBreakpoints } from '@mui/system';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    // xs: true; // adds the `mobile` breakpoint
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

export const font = "'Source Sans Pro', sans-serif";

export const palette = createPalette({
  text: {
    primary: '#3C3C3C',
    secondary: '#6F6F6F',
  },
  primary: {
    main: '#f17435',
    light: '#ffa563',
    dark: '#b84501',
    contrastText: '#fff',
  },
  secondary: {
    main: '#19857b',
  },
  error: { main: red.A400 },
  background: { default: '#fff', paper: '#F5F5F5' },
});

export const breakpoints = createBreakpoints({
  values: {
    // xs: 0,
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
});

export const typography = createTypography(palette, {
  fontFamily: font,
  h1: {
    font: 'normal normal bold 25px/32px Source Sans Pro',
    textTransform: 'none',
  },
  h2: {
    font: 'normal normal bold 21px/27px Source Sans Pro',
    textTransform: 'none',
  },
  h3: {
    font: 'normal normal bold 18px/26px Source Sans Pro',
    textTransform: 'none',
  },
  h4: {
    font: 'normal normal normal 18px/26px Source Sans Pro',
    textTransform: 'none',
  },
  h5: {
    font: 'normal normal bold 25px/32px Source Sans Pro',
    textTransform: 'none',
  },
  body1: {
    font: 'normal normal normal 18px/26px Source Sans Pro',
  },
  body2: {
    font: 'normal normal normal 18px/26px Source Sans Pro',
    color: palette.text.secondary
  },
  button: {
    font: `normal normal bold 18px/23px Source Sans Pro`,
    textTransform: 'none',
  },
  subtitle2: {
    // font: "normal normal bold 35px/30px Dancing Script",
    fontFamily: "'Dancing Script', cursive",
    fontWeight: '600',
    fontSize: '35px',
  },
});

// A custom theme for this app
const theme = createTheme({
  palette,
  typography,
  breakpoints,
});

export default theme;
