/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum JobMatchStatusEnum {
    OPEN = 'open',
    CLOSED = 'closed',
    AWAITING_COACH = 'awaiting_coach',
    ACCEPTED_BY_COACH = 'accepted_by_coach',
    REJECTED_BY_COACH = 'rejected_by_coach',
    SCHEDULED = 'scheduled',
    SENT_TO_BRANCH = 'sent_to_branch',
}
