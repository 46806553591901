import React, { FormEvent } from 'react';

import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { t, Translation } from 'i18n';

import { Button, TextField } from 'components/Elements/Forms/Inputs';
import useLabel from 'functions/Hooks/useLabel';

interface IChangePasswordFormMobileProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  state: string;
  password: string;
  passwordComfirm: string;
  setPassword: (value: string) => void;
  setPasswordComfirm: (value: string) => void;
}

const ChangePasswordFormMobile: React.FunctionComponent<IChangePasswordFormMobileProps> = ({
  handleSubmit,
  state,
  password,
  passwordComfirm,
  setPassword,
  setPasswordComfirm,
}) => {
  const label = useLabel();

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container>
          <Grid item md={12}>
            <CardHeader
              title={
                <Typography
                  variant="h3"
                  color={'#3C3C3B'}
                  sx={{
                    fontSize: '22px',
                    lineHeight: '1.2',
                  }}>
                  <Translation i18nKey="translation.auth.editpw.header" values={{ label: label?.name }} />
                  <Typography
                    component="span"
                    variant="h3"
                    color="primary"
                    sx={{ fontSize: '22px' }}>
                    {t('translation.auth.editpw.headerportal')}
                  </Typography>
                  .
                </Typography>
              }
              subheader={
                <Typography
                  fontSize={'16px'}
                  sx={{
                    marginTop: '20px',
                    color: '#6F6F6F',
                  }}
                  lineHeight={'1.2'}>
                  {t('translation.auth.editpw.subheader')}
                </Typography>
              }
              sx={{ paddingTop: '0px' }}
            />
          </Grid>

          <Grid item xs={12} style={{ marginLeft: '10px' }}>
            <form noValidate onSubmit={handleSubmit}>
              {state !== 'success' && (
                <Grid item xs={12} md={8}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    error={password !== passwordComfirm || state === 'error'}
                    required
                    fullWidth
                    name="password"
                    label="Nieuw wachtwoord"
                    type="password"
                    id="password"
                    onChange={event => setPassword(event.target.value)}
                    autoComplete="new-password"
                  />
                </Grid>
              )}
              {state !== 'success' && (
                <Grid item xs={12} md={8}>
                  <TextField
                    error={password !== passwordComfirm || state === 'error'}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="passwordConfirm"
                    label="Bevestig nieuw wachtwoord"
                    type="password"
                    id="passwordConfirm"
                    onChange={event => setPasswordComfirm(event.target.value)}
                    autoComplete="new-password"
                  />
                </Grid>
              )}
              {password !== passwordComfirm && (
                <Alert severity="error">{t('translation.auth.editpw.errorNoMatch')}</Alert>
              )}
              {state === 'error' && <Alert severity="error">{t('translation.auth.editpw.error')}</Alert>}
              {state === 'success' && <Alert severity="success">{t('translation.auth.editpw.succes')}</Alert>}
              {state === 'loading' && <LinearProgress />}
              {state !== 'success' && (
                <Button type="submit" variant="contained" color="primary" sx={{ padding: '10px' }}>
                  <Typography> {t('translation.auth.editpw.submit')}</Typography>
                </Button>
              )}
              {/* <Box mt={5}>
                <Copyright />
              </Box> */}
            </form>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ChangePasswordFormMobile;
