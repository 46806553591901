import * as React from 'react';
import { useEffect, useState } from 'react';

import { ArrowDropDown, CheckCircleRounded, Lens } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  CheckboxProps,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';

import { BaseAvailability, GenericStatus } from 'assets';
import { Button } from 'components/Elements/Forms/Inputs';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';
import IAvailability, { DayNumber } from 'interfaces/Availability';
import { ILocation } from 'interfaces/Location';

export type asyncFunction = (
  availability: IAvailability
) => Promise<void | GenericStatus | BaseAvailability> | void;
interface AvailabilityProps {
  availability?: IAvailability[];
  user_locations?: ILocation[] | null;
  onLocationChange?: (
    availability: IAvailability[],
    location: ILocation
  ) => Promise<IAvailability> | void;
  onDelete?: asyncFunction;
  onAdd?: asyncFunction;
  disabled?: boolean;
}

const Cell = styled(Grid)({ height: '70px' });

const BorderedCell = styled(Cell)({
  border: '1px solid rgb(245, 245, 245)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '70px',
});

interface DayAvailability {
  location?: ILocation;
  '07:00-10:00': boolean;
  '10:00-13:00': boolean;
  '13:00-16:00': boolean;
  '16:00-19:00': boolean;
  '19:00-22:00': boolean;
  '07:00-22:00': boolean;
}

type DayPart =
  | '07:00-10:00'
  | '10:00-13:00'
  | '13:00-16:00'
  | '16:00-19:00'
  | '19:00-22:00'
  | '07:00-22:00';

const CheckBoxRound = styled((props: CheckboxProps) => (
  <Checkbox
    color="primary"
    icon={<Lens sx={{ color: '#E8E8E8' }} />}
    checkedIcon={<CheckCircleRounded />}
    {...props}
  />
))({});

const AvailabilityGrid: React.FunctionComponent<AvailabilityProps> = props => {
  const [currentWidth, setCurrentWidth] = useState(1200);
  const screenSize = useWidth();
  const theme = useTheme();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const label = useLabel();
  const [availability, setAvailability] = useState<DayAvailability[]>([
    {
      location: props.user_locations?.[0],
      '07:00-10:00': false,
      '10:00-13:00': false,
      '13:00-16:00': false,
      '16:00-19:00': false,
      '19:00-22:00': false,
      '07:00-22:00': false,
    },
    {
      location: props.user_locations?.[0],
      '07:00-10:00': false,
      '10:00-13:00': false,
      '13:00-16:00': false,
      '16:00-19:00': false,
      '19:00-22:00': false,
      '07:00-22:00': false,
    },
    {
      location: props.user_locations?.[0],
      '07:00-10:00': false,
      '10:00-13:00': false,
      '13:00-16:00': false,
      '16:00-19:00': false,
      '19:00-22:00': false,
      '07:00-22:00': false,
    },
    {
      location: props.user_locations?.[0],
      '07:00-10:00': false,
      '10:00-13:00': false,
      '13:00-16:00': false,
      '16:00-19:00': false,
      '19:00-22:00': false,
      '07:00-22:00': false,
    },
    {
      location: props.user_locations?.[0],
      '07:00-10:00': false,
      '10:00-13:00': false,
      '13:00-16:00': false,
      '16:00-19:00': false,
      '19:00-22:00': false,
      '07:00-22:00': false,
    },
    {
      location: props.user_locations?.[0],
      '07:00-10:00': false,
      '10:00-13:00': false,
      '13:00-16:00': false,
      '16:00-19:00': false,
      '19:00-22:00': false,
      '07:00-22:00': false,
    },
    {
      location: props.user_locations?.[0],
      '07:00-10:00': false,
      '10:00-13:00': false,
      '13:00-16:00': false,
      '16:00-19:00': false,
      '19:00-22:00': false,
      '07:00-22:00': false,
    },
  ]);
  const [locations, setLocations] = useState(props.user_locations);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const days = [
    {
      mobile: 'Ma',
      default: 'Maandag',
    },
    {
      mobile: 'Di',
      default: 'Dinsdag',
    },
    {
      mobile: 'Wo',
      default: 'Woensdag',
    },
    {
      mobile: 'Do',
      default: 'Donderdag',
    },
    {
      mobile: 'Vr',
      default: 'Vrijdag',
    },
    {
      mobile: 'Za',
      default: 'Zaterdag',
    },
    {
      mobile: 'Zo',
      default: 'Zondag',
    },
  ];

  const handleCheckboxChange = async (index: number, dayPart: DayPart, checked: boolean) => {
    const change = [...availability];
    let toDelete: IAvailability[] = [];
    setIsSaving(true);
    change[index] = {
      ...availability[index],
      [dayPart]: checked,
      location: change[index].location || locations?.[0],
    };
    if (checked && props.onAdd) {
      const [start_time, end_time] = dayPart.split('-');

      const avObject: IAvailability = {
        day_number: (index + 1) as DayNumber,
        start_time,
        end_time,
        user_location: availability[index].location || locations?.[0],
      };
      await props.onAdd(avObject);

      if (dayPart === '07:00-22:00') {
        toDelete = props.availability!.filter(item => {
          return (
            item.day_number === index + 1 &&
            (item.start_time !== start_time || item.end_time !== end_time)
          );
        });
        change[index]['07:00-10:00'] = false;
        change[index]['10:00-13:00'] = false;
        change[index]['13:00-16:00'] = false;
        change[index]['16:00-19:00'] = false;
        change[index]['19:00-22:00'] = false;
      } else if (change[index]['07:00-22:00']) {
        toDelete = props.availability!.filter(item => {
          return (
            item.day_number === index + 1 &&
            (item.start_time === '07:00' || item.end_time !== '22:00')
          );
        });
        change[index]['07:00-22:00'] = false;
      }
    } else if (!checked) {
      toDelete = props.availability!.filter(item => {
        const [start_time, end_time] = dayPart.split('-');

        return (
          item.day_number === index + 1 &&
          (item.start_time >= start_time || item.end_time >= end_time)
        );
      });
    }
    if (toDelete && props.onDelete) {
      for (let index = 0; index < toDelete.length; index++) {
        const element = toDelete[index];
        try {
          await props.onDelete(element);
        } catch (e) {
          // Empty
        }
      }
    }
    setIsSaving(false);
    setAvailability(change);
  };

  const handleLocationChange = async (index: string, location: ILocation) => {
    const change = [...availability];
    change[Number(index)].location = location;
    setAvailability(change);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setLocations(props.user_locations);
  }, [props.user_locations]);

  useEffect(() => {
    setCurrentWidth(ref.current ? ref.current.offsetWidth : 1200);
  }, [((ref.current as HTMLDivElement) || <div />).offsetWidth]);

  /** function to prevent double entry if a full day is selected */
  const filterAvailabilties = (availabilities: IAvailability[]) => {
    if (availabilities) {
      const tempAvailabilties: Array<IAvailability> = [...availabilities];
      const found: Array<IAvailability> = [];
      tempAvailabilties.forEach((availability: IAvailability) => {
        if (availability.start_time === '07:00:00' && availability.end_time === '22:00:00') {
          found.push(availability);
        }
      });

      found.forEach((availability: IAvailability) => {
        availabilities.forEach((value: IAvailability) => {
          if (value.day_number === availability.day_number && availability.id != value.id) {
            const index = tempAvailabilties.indexOf(value);
            if (index > -1) {
              tempAvailabilties.splice(index, 1);
            }
          }
        });
      });

      return tempAvailabilties;
    }
  };

  useEffect(() => {
    if (locations && props.availability && props.availability.length > 0) {
      const change = [...availability];
      const av = filterAvailabilties(props.availability);
      if (av) {
        av.forEach(item => {
          item.start_time = item.start_time.slice(0, 5);
          item.end_time = item.end_time.slice(0, 5);
          let dayPart: DayPart = '07:00-22:00';
          if (item.end_time <= '10:00') dayPart = '07:00-10:00';
          if (item.start_time >= '10:00' && item.end_time <= '13:00') dayPart = '10:00-13:00';
          if (item.start_time >= '13:00' && item.end_time <= '16:00') dayPart = '13:00-16:00';
          if (item.start_time >= '16:00' && item.end_time <= '19:00') dayPart = '16:00-19:00';
          if (item.start_time >= '19:00' && item.end_time <= '22:00') dayPart = '19:00-22:00';
          change[item.day_number - 1][dayPart] = true;

          if (!change[item.day_number - 1].location)
            change[item.day_number - 1].location = item.user_location || locations?.[0];
        });
        change.forEach(item => {
          if (!item.location) item.location = locations?.[0];
        });
        setAvailability(change);
      }
    }
  }, [props.availability, locations]);

  return (
    <div>
      {props.user_locations!.length > 1 ? (
        <Grid container ref={ref}>
          {isSaving && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
          {currentWidth > 850 ? (
            <>
              <Grid item direction={'column'} xs="auto">
                <Cell item />
                <Cell item xs="auto" sx={{ padding: '25px 10px' }}>
                  <Typography>07:00 - 10:00</Typography>
                </Cell>
                <Cell item xs="auto" sx={{ padding: '25px 10px' }}>
                  <Typography>10:00 - 13:00</Typography>
                </Cell>
                <Cell item xs="auto" sx={{ padding: '25px 10px' }}>
                  <Typography>13:00 - 16:00</Typography>
                </Cell>
                <Cell item xs="auto" sx={{ padding: '25px 10px' }}>
                  <Typography>16:00 - 19:00</Typography>
                </Cell>
                <Cell item xs="auto" sx={{ padding: '25px 10px' }}>
                  <Typography>19:00 - 22:00</Typography>
                </Cell>
                <Cell item xs="auto" sx={{ padding: '25px 10px' }}>
                  <Typography>Hele dag</Typography>
                </Cell>
              </Grid>
              {days.map((day, index) => (
                <Grid item xs sx={{ width: '10.28% !important' }}>
                  <Cell container justifyContent="center" xs={12}>
                    <Grid item xs="auto">
                      <Typography variant="h3">{day.default}</Typography>
                    </Grid>
                    <Grid item xs={12} />
                    {props.user_locations!.length > 1 && (
                      <Grid item xs={12} sx={{ margin: '0px 0px' }}>
                        <Typography
                          noWrap
                          textAlign="center"
                          sx={
                            currentWidth <= 950
                              ? {
                                  fontSize: '10px',
                                  textOverflow: 'ellipsis ',
                                }
                              : {
                                  fontSize: '14px',
                                  textOverflow: 'ellipsis ',
                                }
                          }>
                          {availability[index].location
                            ? `${availability[index].location?.street}`
                            : 'Geen locatie'}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} />
                    {locations!.length > 1 && (
                      <Grid item xs="auto">
                        <IconButton
                          id={String(index)}
                          sx={{ padding: '0px 0px' }}
                          onClick={handleClick}>
                          <ArrowDropDown
                            sx={{
                              height: '23px',
                              width: '23px',
                            }}
                          />
                        </IconButton>
                      </Grid>
                    )}
                  </Cell>
                  <BorderedCell item xs={12}>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['07:00-10:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '07:00-10:00', checked)
                      }
                    />
                  </BorderedCell>
                  <BorderedCell item xs={12}>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['10:00-13:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '10:00-13:00', checked)
                      }
                    />
                  </BorderedCell>
                  <BorderedCell item xs={12}>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['13:00-16:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '13:00-16:00', checked)
                      }
                    />
                  </BorderedCell>
                  <BorderedCell item xs={12}>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['16:00-19:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '16:00-19:00', checked)
                      }
                    />
                  </BorderedCell>
                  <BorderedCell item xs={12}>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['19:00-22:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '19:00-22:00', checked)
                      }
                    />
                  </BorderedCell>
                  <BorderedCell item xs={12}>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['07:00-22:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '07:00-22:00', checked)
                      }
                    />
                  </BorderedCell>
                </Grid>
              ))}
            </>
          ) : (
            <>
              <Grid
                item
                xs={currentWidth <= 350 ? 'auto' : 2}
                sx={{
                  marginTop: screenSize === 'xs' ? '80px' : '90px',
                  width: currentWidth <= 350 ? '33px' : 'auto',
                }}>
                {days.map((day, index) => (
                  <Cell item justifyContent={screenSize === 'xs' ? 'center' : ''}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Typography
                        component="p"
                        sx={{
                          [theme.breakpoints.down('sm')]: {
                            textAlign: 'center',
                            // paddingTop: "10px"
                          },
                        }}>
                        {day.mobile}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto" sm={12} sx={{ margin: '0px 0px' }}>
                      <Typography
                        noWrap
                        textAlign={screenSize === 'xs' ? 'center' : 'left'}
                        sx={{
                          [theme.breakpoints.down('sm')]: {
                            display: 'none',
                            fontSize: '12px',
                            textOverflow: 'ellipsis ',
                          },
                          display: locations && locations.length > 1 ? 'block' : 'none',
                        }}>
                        {availability[index].location
                          ? `${availability[index].location?.street}`
                          : 'Geen locatie'}
                      </Typography>
                    </Grid>
                    {locations!.length > 1 && (
                      <Button
                        id={String(index)}
                        onClick={handleClick}
                        fullWidth={screenSize === 'xs'}
                        sx={{
                          fontSize: '12px',
                          padding: '2px',
                          height: '12px',
                        }}>
                        Verander
                      </Button>
                    )}
                  </Cell>
                ))}
              </Grid>
              <Grid item container direction={'column'} xs>
                <Cell
                  item
                  sx={{
                    padding: '10px',
                    marginBottom: '10px',
                  }}>
                  <Typography
                    textAlign="center"
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        // display: "none",
                        fontSize: '12px',
                      },
                    }}>
                    07:00 <br /> tot <br /> 10:00
                  </Typography>
                </Cell>
                {days.map((_e, index) => (
                  <BorderedCell item xs>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['07:00-10:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '07:00-10:00', checked)
                      }
                    />
                  </BorderedCell>
                ))}
              </Grid>
              <Grid item container direction={'column'} xs>
                <Cell
                  item
                  sx={{
                    padding: '10px',
                    marginBottom: '10px',
                  }}>
                  <Typography
                    textAlign="center"
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        // display: "none",
                        fontSize: '12px',
                      },
                    }}>
                    10:00 <br /> tot <br /> 13:00
                  </Typography>
                </Cell>
                {days.map((_e, index) => (
                  <BorderedCell item xs>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['10:00-13:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '10:00-13:00', checked)
                      }
                    />
                  </BorderedCell>
                ))}
              </Grid>
              <Grid item container direction={'column'} xs>
                <Cell
                  item
                  sx={{
                    padding: '10px',
                    marginBottom: '10px',
                  }}>
                  <Typography
                    textAlign="center"
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        // display: "none",
                        fontSize: '12px',
                      },
                    }}>
                    13:00 <br /> tot <br /> 16:00
                  </Typography>
                </Cell>
                {days.map((_e, index) => (
                  <BorderedCell item xs>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['13:00-16:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '13:00-16:00', checked)
                      }
                    />
                  </BorderedCell>
                ))}
              </Grid>
              <Grid item container direction={'column'} xs>
                <Cell
                  item
                  sx={{
                    padding: '10px',
                    marginBottom: '10px',
                  }}>
                  <Typography
                    textAlign="center"
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        // display: "none",
                        fontSize: '12px',
                      },
                    }}>
                    16:00 <br /> tot <br /> 19:00
                  </Typography>
                </Cell>
                {days.map((_e, index) => (
                  <BorderedCell item xs>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['16:00-19:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '16:00-19:00', checked)
                      }
                    />
                  </BorderedCell>
                ))}
              </Grid>
              <Grid item container direction={'column'} xs>
                <Cell
                  item
                  sx={{
                    padding: '10px',
                    marginBottom: '10px',
                  }}>
                  <Typography
                    textAlign="center"
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        // display: "none",
                        fontSize: '12px',
                      },
                    }}>
                    19:00 <br /> tot <br /> 22:00
                  </Typography>
                </Cell>
                {days.map((_e, index) => (
                  <BorderedCell item xs>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['19:00-22:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '19:00-22:00', checked)
                      }
                    />
                  </BorderedCell>
                ))}
              </Grid>
              <Grid item container direction={'column'} xs>
                <Cell
                  item
                  sx={{
                    padding: '10px',
                    marginBottom: '10px',
                  }}>
                  <Typography
                    textAlign="center"
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        // display: "none",
                        fontSize: '12px',
                      },
                    }}>
                    Hele dag
                  </Typography>
                </Cell>
                {days.map((_e, index) => (
                  <BorderedCell item xs>
                    <CheckBoxRound
                      disabled={isSaving}
                      checked={availability[index]['07:00-22:00'] || false}
                      onChange={({ target: { checked } }) =>
                        handleCheckboxChange(index, '07:00-22:00', checked)
                      }
                    />
                  </BorderedCell>
                ))}
              </Grid>
            </>
          )}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
            {locations?.map(location => (
              <MenuItem onClick={() => handleLocationChange(anchorEl!.id, location)}>
                {location.street}, {location.city}
              </MenuItem>
            ))}
          </Popover>
        </Grid>
      ) : (
        // start jack availgrid
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={1.5} />
              {days.map((day, index) => (
                // the first and last boxes have rounded corners
                <Grid item xs={1.5} marginTop={'25px'}>
                  <Box
                    sx={{
                      backgroundColor: label?.main_color,
                      border: '1px #CCCCCC solid',
                      height: '50px',
                    }}
                    style={{
                      borderTopLeftRadius: index === 0 ? 10 : 0,
                      borderTopRightRadius: index === 6 ? 10 : 0,

                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Typography fontWeight="bold" align="center" color={'white'}>
                      {day.mobile.toUpperCase()}
                    </Typography>
                  </Box>
                </Grid>
              ))}
              {days.map((day, index) => (
                <Grid container xs={12}>
                  <Grid item xs={1.5}>
                    <Box
                      sx={{
                        backgroundColor: index == 5 ? 'white' : '#f5f5f5',
                        border: index == 5 ? '' : '1px #CCCCCC solid',
                        borderTopLeftRadius: index === 0 ? 10 : 0,
                        borderBottomLeftRadius: index === 6 ? 10 : 0,

                        height: index == 5 ? '10px' : '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Typography
                        align="center"
                        fontSize="13px"
                        fontWeight={index == 6 ? 'bold' : 'normal'}>
                        {
                          [
                            '07:00-10:00',
                            '10:00-13:00',
                            '13:00-16:00 ',
                            '16:00-19:00 ',
                            '19:00-22:00 ',
                            '',
                            'Hele dag ',
                          ][index]
                        }
                      </Typography>
                    </Box>
                  </Grid>
                  {days.map((dayPart, index_dayPart) => (
                    <Grid item xs={1.5}>
                      <Box
                        sx={{
                          border: index == 5 ? '' : '0.2px #CCCCCC solid',
                          backgroundColor:
                            index == 5
                              ? 'white'
                              : // if the daypart is checked, the background color is green, otherwise it is white
                              Object.values(availability[index_dayPart])[
                                  index == 6 ? index : index + 1
                                ]
                              ? '#1fd165'
                              : '#f5f5f5',
                          height: index == 5 ? '10px' : '50px',
                          borderBottomRightRadius: index === 6 && index_dayPart === 6 ? 10 : 0,
                        }}
                        onClick={
                          index == 5 || isSaving || props.disabled
                            ? () => {
                                /* Do nothing */
                              }
                            : async () => {
                                await handleCheckboxChange(
                                  index_dayPart,
                                  Object.keys(availability[index_dayPart])[
                                    index == 6 ? index : index + 1
                                  ] as DayPart,
                                  !Object.values(availability[index_dayPart])[
                                    index == 6 ? index : index + 1
                                  ]
                                );
                              }
                        }>
                        <CheckBoxRound
                          sx={{ opacity: 0 }}
                          disabled={isSaving}
                          checked={
                            Object.values(availability[index_dayPart])[
                              index == 6 ? index : index + 1
                            ]
                          }
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AvailabilityGrid;
