/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseCalendarEntry } from '../models/BaseCalendarEntry';
import type { BaseCoachSP } from '../models/BaseCoachSP';
import type { CalendarEntryEdit } from '../models/CalendarEntryEdit';
import type { CalendarEntryIn } from '../models/CalendarEntryIn';
import type { CalendarEntryWithDistance } from '../models/CalendarEntryWithDistance';
import type { CoachMapViewFilters } from '../models/CoachMapViewFilters';
import type { CoachWithLocationAndShiftsAvailabilityType } from '../models/CoachWithLocationAndShiftsAvailabilityType';
import type { GenericStatus } from '../models/GenericStatus';
import type { LatLongWithName } from '../models/LatLongWithName';
import type { SearchCalendarEntry } from '../models/SearchCalendarEntry';
import type { SimpleCalendarEntry } from '../models/SimpleCalendarEntry';
import type { SimpleJobsWithShiftsAndCount } from '../models/SimpleJobsWithShiftsAndCount';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SPCoachesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Add Calendar Entry To Coach
     * @param coachId
     * @param userLocationId
     * @param requestBody
     * @param force
     * @returns SimpleCalendarEntry Successful Response
     * @throws ApiError
     */
    public postSPCoachesAddCalendarEntryToCoach(
        coachId: number,
        userLocationId: number,
        requestBody: CalendarEntryIn,
        force: boolean = false,
    ): CancelablePromise<SimpleCalendarEntry> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/coaches/coach/{coach_id}/add_calendar_entry/location/{user_location_id}',
            path: {
                'coach_id': coachId,
                'user_location_id': userLocationId,
            },
            query: {
                'force': force,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                400: `Bad Request`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Calendar Entry Conflicts When Adding
     * @param coachId
     * @param requestBody
     * @returns BaseCalendarEntry Successful Response
     * @throws ApiError
     */
    public postSPCoachesViewCalendarEntryConflictsWhenAdding(
        coachId: number,
        requestBody: CalendarEntryIn,
    ): CancelablePromise<Array<BaseCalendarEntry>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/coaches/coach/{coach_id}/add_calendar_entry/view/conflicts',
            path: {
                'coach_id': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Calendar Entries From Coach
     * @param coachId
     * @param startDate
     * @param endDate
     * @returns BaseCalendarEntry Successful Response
     * @throws ApiError
     */
    public getSPCoachesGetCalendarEntriesFromCoach(
        coachId: number,
        startDate: string = '2024-08-07',
        endDate: string = '2024-08-21',
    ): CancelablePromise<Array<BaseCalendarEntry>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/coaches/coach/{coach_id}/calendar_entries',
            path: {
                'coach_id': coachId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Calendar Entries
     * @param requestBody
     * @param coachId
     * @param offset
     * @param limit
     * @returns CalendarEntryWithDistance Successful Response
     * @throws ApiError
     */
    public postSPCoachesSearchCalendarEntries(
        requestBody: SearchCalendarEntry,
        coachId?: (number | null),
        offset?: number,
        limit: number = 100,
    ): CancelablePromise<Array<CalendarEntryWithDistance>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/coaches/coach/calendar_entries/search',
            query: {
                'coach_id': coachId,
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Calendar Entry Location
     * @param calendarEntryId
     * @param userLocationId
     * @returns BaseCalendarEntry Successful Response
     * @throws ApiError
     */
    public patchSPCoachesChangeCalendarEntryLocation(
        calendarEntryId: number,
        userLocationId: number,
    ): CancelablePromise<BaseCalendarEntry> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/coaches/coach/calendar_entry/{calendar_entry_id}/change_location/{user_location_id}',
            path: {
                'calendar_entry_id': calendarEntryId,
                'user_location_id': userLocationId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Calendar Entry
     * @param calendarEntryId
     * @param requestBody
     * @returns BaseCalendarEntry Successful Response
     * @throws ApiError
     */
    public patchSPCoachesChangeCalendarEntry(
        calendarEntryId: number,
        requestBody: CalendarEntryEdit,
    ): CancelablePromise<BaseCalendarEntry> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/coaches/coach/calendar_entry/{calendar_entry_id}/edit',
            path: {
                'calendar_entry_id': calendarEntryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Calendar Entry
     * @param calendarEntryId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteSPCoachesDeleteCalendarEntry(
        calendarEntryId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/coaches/coach/calendar_entry/{calendar_entry_id}/delete',
            path: {
                'calendar_entry_id': calendarEntryId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Competence To Coach
     * @param competenceId
     * @param coachId
     * @returns BaseCoachSP Successful Response
     * @throws ApiError
     */
    public postSPCoachesAddCompetenceToCoach(
        competenceId: number,
        coachId: number,
    ): CancelablePromise<BaseCoachSP> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/coaches/coach/{coach_id}/competence/{competence_id}',
            path: {
                'competence_id': competenceId,
                'coach_id': coachId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Competence From Coach
     * @param competenceId
     * @param coachId
     * @returns BaseCoachSP Successful Response
     * @throws ApiError
     */
    public deleteSPCoachesRemoveCompetenceFromCoach(
        competenceId: number,
        coachId: number,
    ): CancelablePromise<BaseCoachSP> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/coaches/coach/{coach_id}/competence/{competence_id}',
            path: {
                'competence_id': competenceId,
                'coach_id': coachId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Favourite Branch To Coach
     * Add a favourite branch to a coach.
     *
     * Args:
     * branch (Branch): The branch to be added as a favourite.
     * coach (Coach): The coach to add the favourite branch to.
     * db (Session): The database session.
     *
     * Returns:
     * BaseCoachSP: The updated coach object with the added favourite branch.
     * @param branchId
     * @param coachId
     * @returns BaseCoachSP Successful Response
     * @throws ApiError
     */
    public postSPCoachesAddFavouriteBranchToCoach(
        branchId: number,
        coachId: number,
    ): CancelablePromise<BaseCoachSP> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/coaches/coach/{coach_id}/branches/{branch_id}',
            path: {
                'branch_id': branchId,
                'coach_id': coachId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Favourite Branch From Coach
     * Removes a favourite branch from a coach.
     *
     * Args:
     * branch (Branch): The branch to be removed from the coach's favourite branches.
     * coach (Coach): The coach from which the branch will be removed.
     * db (Session): The database session.
     *
     * Returns:
     * BaseCoachSP: The updated coach object.
     * @param branchId
     * @param coachId
     * @returns BaseCoachSP Successful Response
     * @throws ApiError
     */
    public deleteSPCoachesRemoveFavouriteBranchFromCoach(
        branchId: number,
        coachId: number,
    ): CancelablePromise<BaseCoachSP> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/coaches/coach/{coach_id}/branches/{branch_id}',
            path: {
                'branch_id': branchId,
                'coach_id': coachId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Competence To Coach Bulk
     * @param coachId
     * @param requestBody
     * @returns BaseCoachSP Successful Response
     * @throws ApiError
     */
    public patchSPCoachesAddCompetenceToCoachBulk(
        coachId: number,
        requestBody: Array<number>,
    ): CancelablePromise<BaseCoachSP> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/sp/coaches/coach/{coach_id}/competence/bulk',
            path: {
                'coach_id': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Competence From Coach Bulk
     * @param coachId
     * @param requestBody
     * @returns BaseCoachSP Successful Response
     * @throws ApiError
     */
    public deleteSPCoachesRemoveCompetenceFromCoachBulk(
        coachId: number,
        requestBody: Array<number>,
    ): CancelablePromise<BaseCoachSP> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sp/coaches/coach/{coach_id}/competence/bulk',
            path: {
                'coach_id': coachId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Coach Sp
     * @param coachId
     * @returns BaseCoachSP Successful Response
     * @throws ApiError
     */
    public getSPCoachesGetCoachSp(
        coachId: number,
    ): CancelablePromise<BaseCoachSP> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/coaches/coach/{coach_id}/sp_coach',
            path: {
                'coach_id': coachId,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Jobs By Coach
     * @param coachId
     * @param startDate
     * @param endDate
     * @param offset
     * @param limit
     * @returns SimpleJobsWithShiftsAndCount Successful Response
     * @throws ApiError
     */
    public getSPCoachesGetJobsByCoach(
        coachId: number,
        startDate: string = '2024-07-15',
        endDate: string = '2024-09-13',
        offset?: (number | null),
        limit?: (number | null),
    ): CancelablePromise<SimpleJobsWithShiftsAndCount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sp/coaches/coach/{coach_id}/worked_shifts',
            path: {
                'coach_id': coachId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Location Of Search Query In Map View
     * @param query
     * @returns LatLongWithName Successful Response
     * @throws ApiError
     */
    public postSPCoachesGetLocationOfSearchQueryInMapView(
        query: string,
    ): CancelablePromise<LatLongWithName> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/coaches/map_view/location_query',
            query: {
                'query': query,
            },
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Coaches In Map View
     * @param requestBody
     * @returns CoachWithLocationAndShiftsAvailabilityType Successful Response
     * @throws ApiError
     */
    public postSPCoachesGetCoachesInMapView(
        requestBody: CoachMapViewFilters,
    ): CancelablePromise<Array<CoachWithLocationAndShiftsAvailabilityType>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sp/coaches/map_view',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                300: `Popup notification`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
