/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum JobStatusEnum {
    OPEN = 'open',
    AWAITING_COACHES = 'awaiting_coaches',
    ACCEPTED_BY_COACHES = 'accepted_by_coaches',
    AWAITING_BRANCH = 'awaiting_branch',
    SCHEDULED = 'scheduled',
    CLOSED = 'closed',
}
