/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseTag } from '../models/BaseTag';
import type { GenericStatus } from '../models/GenericStatus';
import type { TagEdit } from '../models/TagEdit';
import type { TagIn } from '../models/TagIn';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TagsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * View Tag
     * @param tagId
     * @returns BaseTag Successful Response
     * @throws ApiError
     */
    public getTagsViewTag(
        tagId: number,
    ): CancelablePromise<BaseTag> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tags/{tag_id}',
            path: {
                'tag_id': tagId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Tag
     * @param tagId
     * @param requestBody
     * @returns BaseTag Successful Response
     * @throws ApiError
     */
    public patchTagsEditTag(
        tagId: number,
        requestBody: TagEdit,
    ): CancelablePromise<BaseTag> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/tags/{tag_id}',
            path: {
                'tag_id': tagId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Tag
     * @param tagId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteTagsDeleteTag(
        tagId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/tags/{tag_id}',
            path: {
                'tag_id': tagId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Tag
     * @param requestBody
     * @returns BaseTag Successful Response
     * @throws ApiError
     */
    public postTagsCreateTag(
        requestBody: TagIn,
    ): CancelablePromise<BaseTag> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tags/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
