import React, { useEffect, useState } from 'react';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { appClient } from 'App';
import { JobMatchCoachReplyInformation, SimpleShift } from 'assets';
import swLogo from 'assets/img/brand/logo-studyworks-origineel1.png';
import { capitalizeFirstLetter } from 'functions/HelperFunctions/stringOps';

interface JobProps {}
function formatDate(shift: SimpleShift) {
  return (
    capitalizeFirstLetter(
      new Date(shift.date).toLocaleDateString('nl', {
        weekday: 'long',
        day: '2-digit',
        month: 'long',
      })
    ) +
    ' ' +
    shift.start_time.substring(0, shift.start_time.length - 3) +
    '-' +
    shift.end_time.substring(0, shift.end_time.length - 3)
  );
}

const CoachJobPage: React.FunctionComponent<JobProps> = () => {
  const { jobToken } = useParams<{ jobToken: string }>();
  const [jobMatch, setJobMatch] = React.useState<JobMatchCoachReplyInformation>();
  const [branchGone, setBranchGone] = React.useState<boolean>(false);
  const [chosen, setChosen] = React.useState<string>();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    appClient.spJobs
      .getSPJobsGetInfoByUuidCoach(jobToken)
      .then(res => {
        setJobMatch(res);
      })
      .catch(() => {
        setBranchGone(true);
      });
  }, []);

  function Reply(res: boolean): void {
    appClient.spJobs.patchSPJobsReplyToJobMatchUuidCoach(jobToken, res);
  }
  const ContactCard = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          borderRadius: '34px',
          p: '10px',
          boxShadow: 20,
        }}>
        <Grid
          container
          item
          xs={12}
          sx={{
            justifyContent: 'center',
            alignContent: 'center',
            height: '100%',
          }}>
          <Box
            sx={{
              justifyContent: 'center',
              alignContent: 'center',
              height: '100%',
            }}>
            <ContactText />
          </Box>
        </Grid>
      </Dialog>
    );
  };
  const ChoiceModal = ({ chosen }: { chosen: string }) => {
    const yes = chosen === 'y';

    return (
      <Modal open>
        <Grid
          container
          item
          xs={12}
          sx={{
            justifyContent: 'center',
            alignContent: 'center',
            height: '100%',
          }}>
          <Card
            sx={{
              width: '50%',
              borderRadius: '34px',
              p: '10px',
              boxShadow: 20,
            }}>
            <CardContent
              sx={{
                height: '90%',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                opacity: 1,
              }}>
              <Typography variant="h1" sx={{ mb: 2 }}>
                {yes
                  ? `Bedankt voor je aanmelding bij de dienst van ${jobMatch?.job.name}!`
                  : `Bedankt voor het doorgeven dat ${jobMatch?.job.name} geen goede match is!`}
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#f5f5f5',
                  p: '10px',
                  borderRadius: '34px',
                }}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  {jobMatch?.job.name}
                </Typography>
                <Grid container item xs={12}>
                  <LocationOnIcon />
                  <Grid container item xs={11}>
                    <Typography sx={{ mb: 2 }}>
                      {jobMatch?.job.location.street}, {jobMatch?.job.location.zipcode}{' '}
                      {jobMatch?.job.location.city}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <Typography fontWeight="bold" sx={{ mb: 2 }}>
                      Over deze vacature
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <Typography sx={{ mb: 2 }}>{jobMatch?.job.description}</Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Typography fontWeight="bold" sx={{ mb: 2 }}>
                    Werkdagen
                  </Typography>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      height: '300px',
                      overflowY: 'scroll',
                    }}>
                    {jobMatch?.job.shifts?.map((shift, i: number) => {
                      return (
                        <Grid container item xs={12} key={i}>
                          <Typography sx={{ mb: 2 }}>{formatDate(shift)}</Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Modal>
    );
  };
  const ContactStack = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => handleOpen()}
        sx={{ '&:hover': { cursor: 'pointer' } }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="15%">
          <PhoneIcon sx={{ fontSize: '35px' }} />
          <ContactMailIcon sx={{ fontSize: '35px' }} />
        </Stack>
        <Box>
          <img
            src={swLogo}
            alt="logo"
            style={{
              height: 'auto',
              width: 'auto',
              maxWidth: '250px',
              maxHeight: '300px',
            }}
          />
        </Box>
      </Stack>
    );
  };
  function ContactText() {
    return (
      <Grid container item xs={12} sx={{}}>
        <Grid container item xs={12} sx={{}}>
          <Typography variant="h3" gutterBottom>
            Kinderdagverblijven
          </Typography>
        </Grid>
        <Grid container item xs={12} sx={{}}>
          <Typography variant="body1">
            Email: <a href="mailto:kinderopvang@studyworks.nl"> kinderopvang@studyworks.nl</a>
          </Typography>
        </Grid>
        <Grid container item xs={12} sx={{}}>
          <Typography variant="body1">
            Phone number: <a href="tel:0636232579">0636232579</a>
          </Typography>
        </Grid>
        <Grid container item xs={12} sx={{}}>
          <Typography variant="h3" gutterBottom sx={{ mt: 2 }}>
            Scholen / Samenwerkingen
          </Typography>
        </Grid>
        <Grid container item xs={12} sx={{}}>
          <Typography variant="body1">
            Email: <a href="mailto:samenwerkingen@studyworks.nl"> samenwerkingen@studyworks.nl</a>
          </Typography>
        </Grid>
        <Grid container item xs={12} sx={{}}>
          <Typography variant="body1">
            Phone number: <a href="tel:0639613401">0639613401</a>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return branchGone ? (
    <Box
      sx={{
        position: 'absolute',
        backgroundColor: '#f5f5f5',
        p: '30px 10%',
        minHeight: '100%',
        minWidth: '100%',
      }}>
      <ContactStack />
      <Modal open={branchGone}>
        <Grid
          container
          item
          xs={12}
          sx={{
            height: '100%',
            justifyContent: 'center',
            alignContent: 'center',
          }}>
          <Card
            sx={{
              width: '80%',
              minHeight: '30%',
              maxHeight: '90%',
              borderRadius: '34px',
              p: '4px',
              boxShadow: 20,
            }}>
            <CardContent
              sx={{
                height: '100%',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                opacity: 1,
              }}>
              <Grid
                container
                item
                xs={12}
                sx={{
                  height: '100%',
                  overflowY: 'auto',
                }}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  Dienstverzoek niet gevonden
                </Typography>

                <Typography variant="h4" sx={{ mb: 2 }}>
                  De dienst/ vacature die je probeert te vinden is niet gevonden. Dit kan komen
                  doordat je al eerder hebt gereageerd en wij hem daarom gesloten/ ingepland hebben
                  of doordat iemand anders je voor is geweest (en al ingepland is). Als je bent
                  ingepland ontvang je een bevestiging. Mocht u vragen hebben kunt u contact opnemen
                  met Studyworks.
                </Typography>
                <ContactText />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Modal>
    </Box>
  ) : (
    //can only see the job if the branch is still active
    // job_matches.status == 'awaiting_coach' && job.status == 'awaiting_coaches'
    <>
      <ContactCard />
      <Box
        sx={{
          position: 'absolute',
          backgroundColor: '#f5f5f5',
          p: '30px 10%',
          minHeight: '100%',
        }}>
        <ContactStack />
        <Typography
          variant="h1"
          sx={{
            mb: 2,
            color: '#E67534',
          }}>
          Op basis van jouw beschikbaarheid hebben we de volgende vacature gevonden:
        </Typography>
        <Grid container item xs={12} sx={{ justifyContent: 'center' }}>
          <Card
            sx={{
              width: '100%',
              height: '600px',
              borderRadius: '34px',
              p: '20px',
            }}>
            <CardContent
              sx={{
                height: '90%',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                opacity: 1,
              }}>
              <Typography variant="h1" sx={{ mb: 2 }}>
                {jobMatch?.job.name}
              </Typography>
              <Grid container item xs={12}>
                <LocationOnIcon />
                <Grid container item xs={11}>
                  <Typography sx={{ mb: 2 }}>
                    {jobMatch?.job.location.street}, {jobMatch?.job.location.zipcode}{' '}
                    {jobMatch?.job.location.city}
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Typography fontWeight="bold" sx={{ mb: 2 }}>
                    Over deze vacature
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Typography sx={{ mb: 2 }}>{jobMatch?.job.description}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Typography fontWeight="bold" sx={{ mb: 2 }}>
                  Werkdagen
                </Typography>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    maxHeight: '300px',
                    overflowY: 'scroll',
                  }}>
                  {jobMatch?.job.shifts?.map((shift, i: number) => {
                    return (
                      <Grid container item xs={12} key={i}>
                        <Typography sx={{ mb: 2 }}>{formatDate(shift)}</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid
            container
            item
            xs={12}
            sx={{
              justifyContent: 'flex-end',
              height: '10%',
              mt: '20px',
            }}>
            <Button
              variant="outlined"
              sx={{
                width: '35%',
                height: '50px',
                borderRadius: '34px',
              }}
              onClick={() => {
                setChosen('n');
                Reply(false);
              }}>
              Ik kan niet
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '35%',
                height: '50px',
                borderRadius: '34px',
                ml: 2,
              }}
              onClick={() => {
                setChosen('y');
                Reply(true);
              }}>
              Ik wil werken
            </Button>
          </Grid>
        </Grid>
      </Box>
      {chosen && <ChoiceModal chosen={chosen} />}
    </>
  );
};

export default CoachJobPage;
