/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedGuardianWithChildren } from '../models/AdvancedGuardianWithChildren';
import type { AdvancedLesson } from '../models/AdvancedLesson';
import type { AdvancedStudent } from '../models/AdvancedStudent';
import type { AdvancedStudentWithGuardian } from '../models/AdvancedStudentWithGuardian';
import type { AvailabilityEdit } from '../models/AvailabilityEdit';
import type { AvailabilityIn } from '../models/AvailabilityIn';
import type { BaseAvailability } from '../models/BaseAvailability';
import type { BaseLabel } from '../models/BaseLabel';
import type { BaseLesson } from '../models/BaseLesson';
import type { BaseNote } from '../models/BaseNote';
import type { BasePipeLog } from '../models/BasePipeLog';
import type { BaseTag } from '../models/BaseTag';
import type { BaseUserLocation } from '../models/BaseUserLocation';
import type { Body_create_guardian_guardians_new_label__label_id__post } from '../models/Body_create_guardian_guardians_new_label__label_id__post';
import type { BundleIn } from '../models/BundleIn';
import type { GenericStatus } from '../models/GenericStatus';
import type { GuardianAccordedMonths } from '../models/GuardianAccordedMonths';
import type { GuardianEdit } from '../models/GuardianEdit';
import type { NoteIn } from '../models/NoteIn';
import type { PrivateGuardianInformation } from '../models/PrivateGuardianInformation';
import type { RegularBundleDetail } from '../models/RegularBundleDetail';
import type { RegularGuardian } from '../models/RegularGuardian';
import type { RegularGuardianWithChildren } from '../models/RegularGuardianWithChildren';
import type { RegularStudent } from '../models/RegularStudent';
import type { RegularStudentWithGuardian } from '../models/RegularStudentWithGuardian';
import type { SimpleCoach } from '../models/SimpleCoach';
import type { SimpleLesson } from '../models/SimpleLesson';
import type { StudentEdit } from '../models/StudentEdit';
import type { StudentIn } from '../models/StudentIn';
import type { StudentProfileOpenLessonRequests } from '../models/StudentProfileOpenLessonRequests';
import type { UserLocationIn } from '../models/UserLocationIn';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GuardiansService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Guardian
     * @param labelId
     * @param requestBody
     * @returns RegularGuardian Successful Response
     * @throws ApiError
     */
    public postGuardiansCreateGuardian(
        labelId: number,
        requestBody: Body_create_guardian_guardians_new_label__label_id__post,
    ): CancelablePromise<RegularGuardian> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/guardians/new/label/{label_id}',
            path: {
                'label_id': labelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Guardian
     * @param guardianId
     * @param requestBody
     * @returns RegularGuardian Successful Response
     * @throws ApiError
     */
    public postGuardiansEditGuardian(
        guardianId: number,
        requestBody: GuardianEdit,
    ): CancelablePromise<RegularGuardian> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/guardians/edit/{guardian_id}',
            path: {
                'guardian_id': guardianId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Guardian Label
     * @param guardianId
     * @param labelId
     * @returns BaseLabel Successful Response
     * @throws ApiError
     */
    public postGuardiansChangeGuardianLabel(
        guardianId: number,
        labelId: number,
    ): CancelablePromise<BaseLabel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/guardians/change_label/{guardian_id}/{label_id}',
            path: {
                'guardian_id': guardianId,
                'label_id': labelId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Guardian
     * @param guardianId
     * @returns AdvancedGuardianWithChildren Successful Response
     * @throws ApiError
     */
    public getGuardiansViewGuardian(
        guardianId: number,
    ): CancelablePromise<AdvancedGuardianWithChildren> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/view/{guardian_id}',
            path: {
                'guardian_id': guardianId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Guardian Simple
     * @param guardianId
     * @returns RegularGuardianWithChildren Successful Response
     * @throws ApiError
     */
    public getGuardiansViewGuardianSimple(
        guardianId: number,
    ): CancelablePromise<RegularGuardianWithChildren> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/view/{guardian_id}/simple',
            path: {
                'guardian_id': guardianId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Guardian Notes
     * @param guardianId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getGuardiansViewGuardianNotes(
        guardianId: number,
    ): CancelablePromise<(Array<BaseNote> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/view/{guardian_id}/notes',
            path: {
                'guardian_id': guardianId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Guardian Note
     * @param guardianId
     * @param requestBody
     * @returns BaseNote Successful Response
     * @throws ApiError
     */
    public postGuardiansAddGuardianNote(
        guardianId: number,
        requestBody: NoteIn,
    ): CancelablePromise<BaseNote> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/guardians/view/{guardian_id}/notes',
            path: {
                'guardian_id': guardianId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Student Location
     * @param studentId
     * @param requestBody
     * @returns RegularStudent Successful Response
     * @throws ApiError
     */
    public postGuardiansAddStudentLocation(
        studentId: number,
        requestBody: UserLocationIn,
    ): CancelablePromise<RegularStudent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/guardians/student/{student_id}/add_location',
            path: {
                'student_id': studentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Student Notes
     * @param studentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getGuardiansViewStudentNotes(
        studentId: number,
    ): CancelablePromise<(Array<BaseNote> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/view/student/{student_id}/notes',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Student Note
     * @param studentId
     * @param requestBody
     * @returns BaseNote Successful Response
     * @throws ApiError
     */
    public postGuardiansAddStudentNote(
        studentId: number,
        requestBody: NoteIn,
    ): CancelablePromise<BaseNote> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/guardians/view/student/{student_id}/notes',
            path: {
                'student_id': studentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Student To Guardian
     * @param guardianId
     * @param requestBody
     * @returns RegularStudent Successful Response
     * @throws ApiError
     */
    public postGuardiansAddStudentToGuardian(
        guardianId: number,
        requestBody: StudentIn,
    ): CancelablePromise<RegularStudent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/guardians/{guardian_id}/add_student',
            path: {
                'guardian_id': guardianId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Add Disciplines To Student
     * @param studentId
     * @param requestBody
     * @returns RegularStudent Successful Response
     * @throws ApiError
     */
    public patchGuardiansBulkAddDisciplinesToStudent(
        studentId: number,
        requestBody: Array<number>,
    ): CancelablePromise<RegularStudent> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/guardians/student/{student_id}/add_disciplines/bulk',
            path: {
                'student_id': studentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Remove Disciplines From Student
     * @param studentId
     * @param requestBody
     * @returns RegularStudent Successful Response
     * @throws ApiError
     */
    public patchGuardiansBulkRemoveDisciplinesFromStudent(
        studentId: number,
        requestBody: Array<number>,
    ): CancelablePromise<RegularStudent> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/guardians/student/{student_id}/remove_disciplines/bulk',
            path: {
                'student_id': studentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Student
     * @param studentId
     * @param requestBody
     * @returns RegularStudent Successful Response
     * @throws ApiError
     */
    public patchGuardiansEditStudent(
        studentId: number,
        requestBody: StudentEdit,
    ): CancelablePromise<RegularStudent> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/guardians/student/{student_id}',
            path: {
                'student_id': studentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Student
     * @param studentId
     * @returns RegularStudentWithGuardian Successful Response
     * @throws ApiError
     */
    public getGuardiansViewStudent(
        studentId: number,
    ): CancelablePromise<RegularStudentWithGuardian> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/student/{student_id}',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Availability
     * @param availabilityId
     * @param requestBody
     * @returns BaseAvailability Successful Response
     * @throws ApiError
     */
    public patchGuardiansEditAvailability(
        availabilityId: number,
        requestBody: AvailabilityEdit,
    ): CancelablePromise<BaseAvailability> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/guardians/student/availability/{availability_id}/edit',
            path: {
                'availability_id': availabilityId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Availability Location
     * @param availabilityId
     * @param userLocationId
     * @returns BaseAvailability Successful Response
     * @throws ApiError
     */
    public patchGuardiansChangeAvailabilityLocation(
        availabilityId: number,
        userLocationId: number,
    ): CancelablePromise<BaseAvailability> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/guardians/student/availability/{availability_id}/change_location/{user_location_id}',
            path: {
                'availability_id': availabilityId,
                'user_location_id': userLocationId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Availability
     * @param availabilityId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteGuardiansDeleteAvailability(
        availabilityId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/guardians/student/availability/{availability_id}/delete',
            path: {
                'availability_id': availabilityId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Student Advanced
     * @param studentId
     * @returns AdvancedStudentWithGuardian Successful Response
     * @throws ApiError
     */
    public getGuardiansViewStudentAdvanced(
        studentId: number,
    ): CancelablePromise<AdvancedStudentWithGuardian> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/student/{student_id}/advanced',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Coach From Student
     * @param studentId
     * @param coachId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteGuardiansDeleteCoachFromStudent(
        studentId: number,
        coachId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/guardians/student/{student_id}/coach/{coach_id}',
            path: {
                'student_id': studentId,
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Student Coaches
     * @param studentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getGuardiansViewStudentCoaches(
        studentId: number,
    ): CancelablePromise<(Array<SimpleCoach> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/student/{student_id}/coaches',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Guardian Coaches
     * @param guardianId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getGuardiansViewGuardianCoaches(
        guardianId: number,
    ): CancelablePromise<(Array<SimpleCoach> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/{guardian_id}/coaches',
            path: {
                'guardian_id': guardianId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Guardian Ical
     * @param icalToken
     * @returns any Successful Response
     * @throws ApiError
     */
    public getGuardiansViewGuardianIcal(
        icalToken: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/guardian/ics/{ical_token}',
            path: {
                'ical_token': icalToken,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Student Schedule
     * @param studentId
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public getGuardiansViewStudentSchedule(
        studentId: number,
        start?: (string | null),
        end?: (string | null),
    ): CancelablePromise<(Array<SimpleLesson> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/student/{student_id}/schedule',
            path: {
                'student_id': studentId,
            },
            query: {
                'start': start,
                'end': end,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Availability To Student
     * @param studentId
     * @param userLocationId
     * @param requestBody
     * @returns BaseAvailability Successful Response
     * @throws ApiError
     */
    public postGuardiansAddAvailabilityToStudent(
        studentId: number,
        userLocationId: number,
        requestBody: AvailabilityIn,
    ): CancelablePromise<BaseAvailability> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/guardians/student/{student_id}/add_availability/location/{user_location_id}',
            path: {
                'student_id': studentId,
                'user_location_id': userLocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit User Location
     * @param userLocationId
     * @param requestBody
     * @returns BaseUserLocation Successful Response
     * @throws ApiError
     */
    public patchGuardiansEditUserLocation(
        userLocationId: number,
        requestBody: UserLocationIn,
    ): CancelablePromise<BaseUserLocation> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/guardians/student/location/{user_location_id}/edit',
            path: {
                'user_location_id': userLocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User Location
     * @param userLocationId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteGuardiansDeleteUserLocation(
        userLocationId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/guardians/student/location/{user_location_id}/delete',
            path: {
                'user_location_id': userLocationId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Pipe For Student
     * @param pipeId
     * @param studentId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchGuardiansChangePipeForStudent(
        pipeId: number,
        studentId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/guardians/student/{student_id}/change_pipe/{pipe_id}',
            path: {
                'pipe_id': pipeId,
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pipe Log
     * @param studentId
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getGuardiansGetPipeLog(
        studentId: number,
        offset?: number,
        limit: number = 500,
    ): CancelablePromise<(Array<BasePipeLog> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/student/{student_id}/pipe_log',
            path: {
                'student_id': studentId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Pin Student
     * @param studentId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchGuardiansPinStudent(
        studentId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/guardians/student/{student_id}/pin',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unpin Student
     * @param studentId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchGuardiansUnpinStudent(
        studentId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/guardians/student/{student_id}/unpin',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tag Student
     * @param studentId
     * @param tagId
     * @returns AdvancedStudentWithGuardian Successful Response
     * @throws ApiError
     */
    public postGuardiansTagStudent(
        studentId: number,
        tagId: number,
    ): CancelablePromise<AdvancedStudentWithGuardian> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/guardians/student/{student_id}/tag/{tag_id}',
            path: {
                'student_id': studentId,
                'tag_id': tagId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Untag Student
     * @param studentId
     * @param tagId
     * @returns AdvancedStudent Successful Response
     * @throws ApiError
     */
    public deleteGuardiansUntagStudent(
        studentId: number,
        tagId: number,
    ): CancelablePromise<AdvancedStudent> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/guardians/student/{student_id}/tag/{tag_id}',
            path: {
                'student_id': studentId,
                'tag_id': tagId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View All Tags
     * @returns any Successful Response
     * @throws ApiError
     */
    public getGuardiansViewAllTags(): CancelablePromise<(Array<BaseTag> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/student/tags/all',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Hide Inactive
     * @param studentId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public postGuardiansHideInactive(
        studentId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/guardians/student/{student_id}/hide_inactive',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Private Guardian
     * @param guardianId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getGuardiansViewPrivateGuardian(
        guardianId: number,
    ): CancelablePromise<(PrivateGuardianInformation | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/guardian/{guardian_id}/private',
            path: {
                'guardian_id': guardianId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Student Profile
     * @param studentId
     * @returns StudentProfileOpenLessonRequests Successful Response
     * @throws ApiError
     */
    public getGuardiansViewStudentProfile(
        studentId: number,
    ): CancelablePromise<StudentProfileOpenLessonRequests> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/student/{student_id}/profile',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Guardian
     * @param guardianId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteGuardiansDeleteGuardian(
        guardianId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/guardians/guardian/{guardian_id}/delete',
            path: {
                'guardian_id': guardianId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Student
     * @param studentId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteGuardiansDeleteStudent(
        studentId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/guardians/student/{student_id}/delete',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Select Bundle
     * @param icalToken
     * @param requestBody
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public postGuardiansSelectBundle(
        icalToken: string,
        requestBody: BundleIn,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/guardians/guardian/{ical_token}/bundle',
            path: {
                'ical_token': icalToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Bundles
     * @param guardianId
     * @returns RegularBundleDetail Successful Response
     * @throws ApiError
     */
    public getGuardiansGetBundles(
        guardianId: number,
    ): CancelablePromise<Array<RegularBundleDetail>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/guardian/{guardian_id}/bundles',
            path: {
                'guardian_id': guardianId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View New Clients
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public getGuardiansViewNewClients(
        start?: (string | null),
        end?: (string | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/guardian/new_clients/download',
            query: {
                'start': start,
                'end': end,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Lessons Accorded
     * @param guardianId
     * @param month
     * @param year
     * @returns BaseLesson Successful Response
     * @throws ApiError
     */
    public getGuardiansGetLessonsAccorded(
        guardianId: number,
        month?: (number | null),
        year?: (number | null),
    ): CancelablePromise<Array<BaseLesson>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/guardian/{guardian_id}/lessons/accorded',
            path: {
                'guardian_id': guardianId,
            },
            query: {
                'month': month,
                'year': year,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Lessons From Guardian
     * Get all the lessons from a guardian
     *
     * Args:
     * month (Optional[int], optional): Month of the lessons. Defaults to None.
     * year (Optional[int], optional): Year of the lessons. Defaults to None.
     * accorded (Optional[bool], optional): Accorded status of the lessons. Defaults to None.
     * is_legacy (Optional[bool], optional): Legacy status of the lessons. Defaults to None.
     *
     * Returns:
     * List[AdvancedLesson]: List of lessons
     *
     *
     * # FIXME MOVE THIS TO A SERVICE
     * @param guardianId
     * @param month
     * @param year
     * @param accorded
     * @param isLegacy
     * @returns AdvancedLesson Successful Response
     * @throws ApiError
     */
    public getGuardiansGetLessonsFromGuardian(
        guardianId: number,
        month?: number,
        year?: number,
        accorded?: boolean,
        isLegacy: boolean = false,
    ): CancelablePromise<Array<AdvancedLesson>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/guardian/{guardian_id}/lessons',
            path: {
                'guardian_id': guardianId,
            },
            query: {
                'month': month,
                'year': year,
                'accorded': accorded,
                'is_legacy': isLegacy,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Lessons Accorded Months
     * @param guardianId
     * @returns GuardianAccordedMonths Successful Response
     * @throws ApiError
     */
    public getGuardiansGetLessonsAccordedMonths(
        guardianId: number,
    ): CancelablePromise<Array<GuardianAccordedMonths>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/guardians/guardian/{guardian_id}/lessons/accorded/months',
            path: {
                'guardian_id': guardianId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
