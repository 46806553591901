import * as React from 'react';
import { useEffect, useState } from 'react';

import { School } from '@mui/icons-material';
import { CardActions, CardHeader, Collapse } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

import { appClient } from 'App';
import { SimpleStudentWithGuardian } from 'assets';
import Card from 'components/Elements/Card';
import { Button } from 'components/Elements/Forms/Inputs';
import useUser from 'functions/Hooks/useUser';

interface TutoringQuestionsProps {}
type Status =
  | 'idle'
  | 'has student'
  | 'has no student'
  | 'student is of coach'
  | 'student is not of coach';

const TutoringQuestions: React.FunctionComponent<TutoringQuestionsProps> = () => {
  const [status, setStatus] = useState<Status>('idle');
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUser();
  const [students, setStudents] = useState<SimpleStudentWithGuardian[]>([]);

  const handleNext = (event: CustomEvent<() => void>) => {
    if (status === 'has no student' || status === 'student is of coach') event.detail();
    else {
      switch (status) {
        case 'idle':
          enqueueSnackbar('Geef eerst antwoord', { variant: 'info' });
          break;
        case 'has student':
          enqueueSnackbar('Is deze leerling van jou?', { variant: 'info' });
          break;
        case 'student is not of coach':
          enqueueSnackbar('Neem eerst contact met ons op', { variant: 'error' });
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext as EventListener);

    return () => {
      document.removeEventListener('onNext', handleNext as EventListener);
    };
  }, [status]);

  useEffect(() => {
    appClient.coaches.getCoachesViewCoachStudents(user!.coach!.id).then(setStudents);
  }, [user]);

  return (
    <div>
      <Collapse in={status === 'idle'}>
        <Typography variant="h2" color="initial">
          Geef je op dit moment bijles?
        </Typography>
        <CardActions>
          <Button onClick={() => setStatus('has student')}>Ja</Button>
          <Button onClick={() => setStatus('has no student')}>nee</Button>
        </CardActions>
      </Collapse>
      <Collapse in={students.length > 0 && status !== 'idle' && status !== 'has no student'}>
        <Typography variant="h2" color="initial">
          Klopt het dat je aan deze student(en) bijles geeft?
        </Typography>
        {students.map(({ disciplines, ...student }) => (
          <Card elevation={0} sx={{ margin: '16px 0px' }}>
            <CardHeader
              title={`${student.first_name} ${student.last_name}`}
              s
              subheader={`${student.school_level} ${student.school_year}`}
            />
            <CardHeader
              sx={{ paddingTop: '0px' }}
              avatar={<School />}
              title="Vakken"
              subheader={disciplines?.map(
                (discipline, index) =>
                  `${discipline.name}${index !== (disciplines?.length || 0) - 1 ? ',' : ''} `
              )}
            />
          </Card>
        ))}
        {status === 'has student' && (
          <CardActions>
            <Button onClick={() => setStatus('student is of coach')}>Ja</Button>
            <Button onClick={() => setStatus('student is not of coach')}>Nee</Button>
          </CardActions>
        )}
      </Collapse>
      <Collapse
        in={
          (status === 'has no student' && students.length === 0) || status === 'student is of coach'
        }>
        <Typography variant="h3" color="initial">
          Mooi! Dan mag je door naar het volgende onderdeel.
        </Typography>
      </Collapse>

      {(status === 'student is not of coach' ||
        (status === 'has no student' && students.length > 0) ||
        (students.length === 0 && status !== 'idle' && status !== 'has no student')) && (
        <>
          <Typography>
            Hmm, dan is er iets misgegaan met de verhuizing naar het nieuwe portaal. Je moet even
            contact opnemen met ons team om door te gaan. Zij kunnen je helpen. Ons team is
            bereikbaar via recruitment@studyworks.nl en{' '}
            <a href="tel:+31 6 397 60 255">+31 6 6 397 60 255</a>
          </Typography>
        </>
      )}
    </div>
  );
};

export default TutoringQuestions;
