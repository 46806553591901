import React, { useEffect, useState } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import theme from 'theme';

import { appClient } from 'App';
import {
  BaseCoachSPForBranch,
  JobBranchReplyInformation,
  JobMatchBranchReplyInformation,
} from 'assets';
import swLogo from 'assets/img/brand/logo-studyworks-origineel1.png';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

import AcceptModal from './Components/AcceptModal';
import CoachCard from './Components/CoachCard';



const ProviderJobPage: React.FunctionComponent<JobProps> = () => {
  const { branchToken } = useParams<{ branchToken: string }>();
  const [job, setJob] = React.useState<JobBranchReplyInformation>();
  const [matches, setMatches] = React.useState<JobMatchBranchReplyInformation[]>([]);
  const [branchGone, setBranchGone] = React.useState<boolean>(false);
  const [coachAccepted, setCoachAccepted] = React.useState<boolean>(false);
  const [selectedCoach, setSelectedCoach] = React.useState<BaseCoachSPForBranch>();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const currentWidth = useWidth();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    appClient.spJobs
      .getSPJobsGetInfoByUuidBranch(branchToken)
      .then(res => {
        setJob(res);
        setMatches(res.job_matches);
      })
      .catch(() => {
        setBranchGone(true);
      });
  }, []);

  return branchGone ? (
    <Modal open={branchGone}>
      <Grid
        container
        item
        xs={12}
        sx={{
          justifyContent: 'center',
          alignContent: 'center',
          height: '100%',
        }}>
        <Card
          sx={{
            position: 'absolute' as const,
            top: '25%',
            left: currentWidth === 'xs' ? '5%' : '25%',
            width: currentWidth === 'xs' ? '5%' : '50%',
            height: currentWidth === 'xs' ? '5%' : '50%',
            borderRadius: '15px',
            p: '10px',
            boxShadow: 20,
          }}>
          <CardContent
            sx={{
              height: '90%',
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              opacity: 1,
            }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h1" sx={{ mb: 2 }} textAlign={'center'}>
                  Branch niet gevonden
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  De branch die u probeert te bereiken is niet gevonden. Het kan zijn dat de branch
                  is verwijderd omdat u de coach heeft geaccepteerd.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Modal>
  ) : (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        height: '100%',
      }}>
      <Container>
        <Grid
          container
          spacing={2}
          sx={{ padding: currentWidth === 'xs' ? '20px 0px' : '20px 40px' }}>
          <Grid item xs={1} md={1.5}>
            <IconButton>
              <a href="tel:06 36232579" style={{ color: 'black' }}>
                <PhoneIcon sx={{ fontSize: '24px' }} />
              </a>
            </IconButton>
          </Grid>
          <Grid item xs={1} md={1.5}>
            <IconButton>
              <a href="mailto:Kinderopvang@studyworks.nl" style={{ color: 'black' }}>
                <ContactMailIcon sx={{ fontSize: '24px' }} />
              </a>
            </IconButton>
          </Grid>

          <Grid item xs={10} md={9}>
            <Grid
              container
              justifyContent={'flex-end'}
              sx={{
                paddingRight: '40px',
                marginTop: currentWidth === 'xs' ? '10px' : 'unset',
              }}>
              <Grid item xs={7} md={3.5}>
                <img
                  src={swLogo}
                  alt="logo"
                  style={{ height: currentWidth === 'xs' ? '10px' : '40px' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {coachAccepted && job && (
          <AcceptModal coach={selectedCoach!} job={job} coachAccepted={coachAccepted} />
        )}
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            height: '100%',
          }}>
          <Card
            sx={{
              borderRadius: '15px',
              p: '15px',
            }}>
            <CardContent>
              <CardHeader
                title={job?.description}
                titleTypographyProps={{
                  color: theme.palette.primary.main,
                  margin: '-15px 0px 0px -10px',
                }}
              />
              <Grid container sx={{ marginTop: '10px' }} spacing={2}>
                <Grid item xs={2} md={0.5} sx={{ justifyContent: 'center' }}>
                  <LocationOnIcon />
                </Grid>
                <Grid item xs={10} md={11.5}>
                  <Typography sx={{ mb: 2 }}>
                    {job?.location.street}, {job?.location.zipcode} {job?.location.city}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={2} md={0.5} sx={{ justifyContent: 'center' }}>
                  <AccessTimeIcon />
                </Grid>
                <Grid item xs={10} md={11.5} sx={{ overflowY: 'auto' }}>
                  <Grid container>
                    {job?.shifts?.map((shift, i: number) => {
                      return (
                        <Grid item xs={12} key={i}>
                          <Typography sx={{ mb: 2 }}>
                            {/* {new Date(shift.date).toLocaleDateString('nl', { weekday: 'long', day: '2-digit', month: 'long' })}{', '} */}
                            {moment(shift.date).format('dddd').charAt(0).toLocaleUpperCase()}
                            {moment(shift.date).format('dddd').slice(1)}{' '}
                            {moment(shift.date).format('DD')} {moment(shift.date).format('MMMM')},{' '}
                            {shift.start_time.substring(0, shift.start_time.length - 3)} -{' '}
                            {shift.end_time.substring(0, shift.end_time.length - 3)}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <Grid container justifyContent={currentWidth === 'xs' ? 'unset' : 'flex-end'}>
              <Grid item xs={12} md={2.5}>
                {/* <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => {
                                            appClient.spJobs.patchSPJobsCloseJobByUuidBranch(branchToken).then(() =>
                                                enqueueSnackbar("Dienst is geannuleerd", { variant: "success" })
                                            )
                                        }}
                                        sx={{
                                            marginLeft: currentWidth==="xs"?"unset":"12%",
                                            marginBottom: "25px"
                                        }}
                                    >
                                        <Typography>
                                            Annuleer dienst
                                        </Typography>
                                    </Button> */}
              </Grid>
            </Grid>
          </Card>

          <Grid
            container
            sx={{
              m: '40px 0 10px 0',
              left: '20px',
            }}>
            <CardHeader
              title={'Beschikbare coaches'}
              titleTypographyProps={{ color: theme.palette.primary.main }}
            />
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: 'center',
              paddingBottom: '40px',
            }}
            spacing={4}>
            {matches.map((match: JobMatchBranchReplyInformation, index) => {
              return (
                <>
                  {job && (
                    <Grid item xs={12}>
                      <CoachCard
                        match={match}
                        job={job}
                        key={index}
                        setSelectedCoach={setSelectedCoach}
                        setCoachAccepted={setCoachAccepted}
                      />
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

interface JobProps {}

export default ProviderJobPage;
