import * as React from 'react';
import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { appClient } from 'App';
import { BaseUserLocation } from 'assets';
import AvailabilityGrid from 'components/Functional/Availability/AvailabilityGrid';
import useUser from 'functions/Hooks/useUser';
import IAvailability, { DayNumber } from 'interfaces/Availability';




interface AvailabilityProps {}

const Availability: React.FunctionComponent<AvailabilityProps> = () => {
  const { user } = useUser();
  const [user_locations, setUser_locations] = useState<BaseUserLocation[]>([]);
  const [availability, setAvailability] = useState<IAvailability[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleNext = (event: CustomEvent<() => void>) => {
    if (availability?.length > 0) event.detail();
    else enqueueSnackbar('Voeg minimaal een tijdsvak in jou beschikbaarheid', { variant: 'error' });
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext as EventListener);

    return () => {
      document.removeEventListener('onNext', handleNext as EventListener);
    };
  }, [availability]);

  useEffect(() => {
    appClient.coaches.getCoachesViewCoachViaId(user?.coach?.id || 0).then(coach => {
      setUser_locations(coach.user_locations || []);
      setAvailability(
        coach.availabilities?.map(availability => ({
          ...availability,
          day_number: availability.day_number as DayNumber,
        })) || []
      );
    });
  }, [user]);

  return (
    <>
      <Typography variant="h2" color="initial">
        We horen graag van je wanneer je beschikbaar bent om bijles te geven.
      </Typography>
      <Typography sx={{ marginBottom: '35px' }}>
        Dit gaat om je beschikbaarheid per weekdag voor een gemiddelde week. Ben je over het
        algemeen op woensdagavond beschikbaar om bijles te geven? <br /> Dan geef je dat hier aan.
        Je kan meerdere tijdstippen op een dag aangeven, als je bijvoorbeeld alleen in de ochtend en
        avond kan. De precieze data en tijdstippen voor bijlessen geef je hier niet aan - dat
        overleg je met je bijleskind. We vragen dit zodat we een algemeen idee krijgen met welke
        bijleskinderen we je het beste kunnen matchen.
      </Typography>
      <AvailabilityGrid
        user_locations={user_locations}
        availability={availability}
        onDelete={availabilityItem => {
          return appClient.coaches
            .deleteCoachesDeleteAvailability(availabilityItem?.id || 0)
            .then(() => {
              setAvailability(availability.filter(search => search.id !== availabilityItem.id));
            });
        }}
        onAdd={availabilityItem => {
          return appClient.coaches
            .postCoachesAddAvailabilityToCoach(
              user?.coach?.id || 0,
              availabilityItem.user_location?.id || 0,
              availabilityItem
            )
            .then(r => {
              setAvailability([
                ...availability,
                {
                  ...r,
                  day_number: r.day_number as DayNumber,
                },
              ]);
            });
        }}
      />
      {/* <AvailabilityComponent
      onPost={(availabilityItem) => {appClient.coaches.postCoachesAddAvailabilityToCoach(user?.coach?.id || 0, availabilityItem.user_location?.id || 0, availabilityItem)}}
      onPut={(availabilityItem) => {
        appClient.coaches.patchCoachesEditAvailability(availabilityItem.id || 0, availabilityItem)
        appClient.coaches.patchCoachesChangeAvailabilityLocation(availabilityItem.id || 0, availabilityItem?.user_location?.id || 0)
      }}
      onDelete={(availabilityItem)=>{
        appClient.coaches.deleteCoachesDeleteAvailability(availabilityItem?.id || 0)
      }}
      locations={user_locations}
      availability={availability}
    /> */}
    </>
  );
};

export default Availability;
