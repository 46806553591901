import { Grid, TextField, Paper, Button, IconButton, Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SignatureCanvas from 'react-signature-canvas';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import CreateIcon from '@mui/icons-material/Create';

import { Context } from '../../UserStore';
import './Contract.css';
import { ArrowForwardIos } from '@mui/icons-material';
import { Box } from '@mui/system';

//Dit voor de helft oude code van het vorige coach portaal. Er kan nog veel veranderen, maar het werkt.

const Signature = ({ setSignature, allowSign }) => {
  let [sigPad, setSigPad] = useState({});
  const clearSignature = () => {
    sigPad.clear();
    setSignature(undefined);
  };

  const undoSignature = () => {
    let data = sigPad.toData();
    if (data) {
      data.pop();
      sigPad.fromData(data);
      setSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'));
    }
  };

  // Calls the /contract API. returns URL of contract.
  return (
    <Grid container sx={{ borderStyle: 'solid' }}>
      <Grid item xs={3} md={1}>
        <Button
          sx={{ backgroundColor: 'lightgray' }}
          className="clear-button"
          onClick={clearSignature}>
          <DeleteIcon />
        </Button>
        <Button
          sx={{ backgroundColor: 'lightgray' }}
          className="undo-button"
          onClick={undoSignature}>
          <UndoIcon />
        </Button>
      </Grid>
      <Grid item xs>
        {/* {allowSign?( */}
        <SignatureCanvas
          onEnd={() => setSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'))}
          penColor="black"
          canvasProps={{ className: 'sig-canvas' }}
          ref={setSigPad}
        />
        {/* ):(
          <Box width={"100%"} height={"200px"} backgroundColor={"lightgray"}/>
        )} */}
      </Grid>
    </Grid>
  );
};

export default Signature;
