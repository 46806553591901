import { Grid, Card, Typography, Container, styled } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTheme } from '@mui/material/styles';

import './StartPage.css';
import './Index.css';
import People from 'assets/img/brand/peoplelaughingsohappy.jpg';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';

const StyledTimeLine = styled(TimelineItem)({
  '&::before': { display: 'none' },
  marginBottom: '0px',
  '& .MuiTimelineContent-root': { padding: '0px 16px' },
  '& .MuiTimelineSeparator-root': {
    '& .MuiTimelineDot-filled': {
      height: '12px',
      width: '12px',
      margin: '6.5px 0px',
      padding: '0px',
    },
  },
});

const StartPage = ({ setData, data }) => {
  const theme = useTheme();
  const themeColor = theme.palette.primary.main;

  React.useEffect(() => {
    document.addEventListener('onNext', handleNext);
    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [data]);

  const handleNext = async event => {
    event.detail();
  };

  return (
    <Grid container sx={{ marginTop: '10px' }}>
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: '20px',
          [theme.breakpoints.down('sm')]: { padding: '16px !important' },
        }}>
        <Typography variant="h1">Welkom bij StudyWorks!</Typography>
        <Typography>
          Om een officiële StudyWorker te worden, vragen wij je profiel volledig aan te vullen en de
          benodige documenten te ondertekenen
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Container>
          <Timeline>
            <StyledTimeLine>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Welkom</TimelineContent>
            </StyledTimeLine>

            <StyledTimeLine>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Persoonsgegevens</TimelineContent>
            </StyledTimeLine>

            <StyledTimeLine>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Schoolgegevens</TimelineContent>
            </StyledTimeLine>

            <StyledTimeLine>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Beschikbaarheid</TimelineContent>
            </StyledTimeLine>

            <StyledTimeLine>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Paspoort / ID-kaart</TimelineContent>
            </StyledTimeLine>

            <StyledTimeLine>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Overeenkomst</TimelineContent>
            </StyledTimeLine>

            <StyledTimeLine>
              <TimelineSeparator>
                <TimelineDot />
              </TimelineSeparator>
              <TimelineContent>Akkoord</TimelineContent>
            </StyledTimeLine>
          </Timeline>
        </Container>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          borderRadius: '10px',
          backgroundImage: `url(${People})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}></Grid>
    </Grid>
  );
};

export default StartPage;
