/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedLesson } from '../models/AdvancedLesson';
import type { AdvancedLessonEdit } from '../models/AdvancedLessonEdit';
import type { AllCoachCancelCodes } from '../models/AllCoachCancelCodes';
import type { BaseDiscipline } from '../models/BaseDiscipline';
import type { BaseLesson } from '../models/BaseLesson';
import type { BaseLessonRequest } from '../models/BaseLessonRequest';
import type { GenericStatus } from '../models/GenericStatus';
import type { LessonEdit } from '../models/LessonEdit';
import type { LessonIn } from '../models/LessonIn';
import type { LessonInDispute } from '../models/LessonInDispute';
import type { LessonInSimple } from '../models/LessonInSimple';
import type { LessonRequestEdit } from '../models/LessonRequestEdit';
import type { LessonRequestIn } from '../models/LessonRequestIn';
import type { SimpleLesson } from '../models/SimpleLesson';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LessonsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * New Lesson Request
     * @param studentId
     * @param requestBody
     * @returns BaseLessonRequest Successful Response
     * @throws ApiError
     */
    public postLessonsNewLessonRequest(
        studentId: number,
        requestBody: LessonRequestIn,
    ): CancelablePromise<BaseLessonRequest> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/lessons/new/request/student/{student_id}',
            path: {
                'student_id': studentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Last Lesson Request
     * @param studentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getLessonsViewLastLessonRequest(
        studentId: number,
    ): CancelablePromise<(BaseLessonRequest | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/lessons/requests/last/{student_id}',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View All Lesson Request
     * @param studentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getLessonsViewAllLessonRequest(
        studentId: number,
    ): CancelablePromise<(Array<BaseLessonRequest> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/lessons/requests/open/{student_id}',
            path: {
                'student_id': studentId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Schedule Lesson
     * Schedule a lesson for a student.
     *
     * Args:
     * lesson (LessonInSimple): The lesson details.
     * bundle_service (BundleService, optional): The bundle service. Defaults to Depends().
     * student (Student, optional): The student. Defaults to Permission("schedule_lessons", find_student).
     * db (Session, optional): The database session. Defaults to Depends(get_db).
     * current_user (User, optional): The current user. Defaults to Depends(get_current_active_user).
     *
     * Raises:
     * HTTPException: If there is already a lesson planned for the student or coach.
     * HTTPException: If the lesson start datetime is in the past more than a month ago.
     * HTTPException: If there is a CoachAccordance for the coach and student in the same time period.
     *
     * Returns:
     * Lesson: The scheduled lesson.
     *
     *
     * # TODO set security for this endpoint, only for coaches
     * @param studentId
     * @param requestBody
     * @returns BaseLesson Successful Response
     * @throws ApiError
     */
    public postLessonsScheduleLesson(
        studentId: number,
        requestBody: LessonInSimple,
    ): CancelablePromise<BaseLesson> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/lessons/schedule/student/{student_id}',
            path: {
                'student_id': studentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Schedule Lesson Advanced
     * @param studentId
     * @param coachId
     * @param requestBody
     * @param accordate
     * @param force
     * @returns SimpleLesson Successful Response
     * @throws ApiError
     */
    public postLessonsScheduleLessonAdvanced(
        studentId: number,
        coachId: number,
        requestBody: LessonIn,
        accordate: boolean = true,
        force: boolean = false,
    ): CancelablePromise<SimpleLesson> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/lessons/schedule/student/{student_id}/{coach_id}',
            path: {
                'student_id': studentId,
                'coach_id': coachId,
            },
            query: {
                'accordate': accordate,
                'force': force,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel Lesson
     * @param lessonId
     * @param cancelCode
     * @returns BaseLesson Successful Response
     * @throws ApiError
     */
    public patchLessonsCancelLesson(
        lessonId: number,
        cancelCode?: AllCoachCancelCodes,
    ): CancelablePromise<BaseLesson> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/lessons/lesson/{lesson_id}/cancel',
            path: {
                'lesson_id': lessonId,
            },
            query: {
                'cancel_code': cancelCode,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Lesson
     * @param lessonId
     * @returns BaseLesson Successful Response
     * @throws ApiError
     */
    public getLessonsViewLesson(
        lessonId: number,
    ): CancelablePromise<BaseLesson> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/lessons/lesson/{lesson_id}',
            path: {
                'lesson_id': lessonId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Lesson Advanced
     * @param lessonId
     * @returns SimpleLesson Successful Response
     * @throws ApiError
     */
    public getLessonsViewLessonAdvanced(
        lessonId: number,
    ): CancelablePromise<SimpleLesson> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/lessons/lesson/{lesson_id}/advanced',
            path: {
                'lesson_id': lessonId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Lesson
     * @param lessonId
     * @param requestBody
     * @returns BaseLesson Successful Response
     * @throws ApiError
     */
    public patchLessonsEditLesson(
        lessonId: number,
        requestBody: LessonEdit,
    ): CancelablePromise<BaseLesson> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/lessons/lesson/{lesson_id}/edit',
            path: {
                'lesson_id': lessonId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Advanced Edit Lesson
     * @param lessonId
     * @param requestBody
     * @returns BaseLesson Successful Response
     * @throws ApiError
     */
    public patchLessonsAdvancedEditLesson(
        lessonId: number,
        requestBody: AdvancedLessonEdit,
    ): CancelablePromise<BaseLesson> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/lessons/lesson/{lesson_id}/edit/advanced',
            path: {
                'lesson_id': lessonId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Dispute Lesson
     * @param lessonId
     * @param requestBody
     * @returns BaseLesson Successful Response
     * @throws ApiError
     */
    public patchLessonsDisputeLesson(
        lessonId: number,
        requestBody: LessonInDispute,
    ): CancelablePromise<BaseLesson> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/lessons/lesson/{lesson_id}/dispute',
            path: {
                'lesson_id': lessonId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Resolve Dispute Lesson
     * @param lessonId
     * @returns BaseLesson Successful Response
     * @throws ApiError
     */
    public patchLessonsResolveDisputeLesson(
        lessonId: number,
    ): CancelablePromise<BaseLesson> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/lessons/lesson/{lesson_id}/resolve_dispute',
            path: {
                'lesson_id': lessonId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Disputed Lessons
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getLessonsViewDisputedLessons(
        offset?: number,
        limit: number = 30,
    ): CancelablePromise<(Array<SimpleLesson> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/lessons/disputed',
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Open Lesson Requests
     * @param limit
     * @param offset
     * @returns any Successful Response
     * @throws ApiError
     */
    public getLessonsOpenLessonRequests(
        limit: number = 30,
        offset?: number,
    ): CancelablePromise<(Array<BaseLessonRequest> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/lessons/requests/open',
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Close Lesson Request
     * @param lessonRequestId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchLessonsCloseLessonRequest(
        lessonRequestId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/lessons/requests/{lesson_request_id}/close',
            path: {
                'lesson_request_id': lessonRequestId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Lesson Request
     * @param lessonRequestId
     * @param requestBody
     * @returns BaseLessonRequest Successful Response
     * @throws ApiError
     */
    public patchLessonsEditLessonRequest(
        lessonRequestId: number,
        requestBody: LessonRequestEdit,
    ): CancelablePromise<BaseLessonRequest> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/lessons/requests/{lesson_request_id}/edit',
            path: {
                'lesson_request_id': lessonRequestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Disciplines
     * @returns any Successful Response
     * @throws ApiError
     */
    public getLessonsViewDisciplines(): CancelablePromise<(Array<BaseDiscipline> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/lessons/disciplines',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Transfer Hours To Bundle
     * @param lessonId
     * @returns AdvancedLesson Successful Response
     * @throws ApiError
     */
    public patchLessonsTransferHoursToBundle(
        lessonId: number,
    ): CancelablePromise<AdvancedLesson> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/lessons/lesson/{lesson_id}/transfer_hours_to_bundle',
            path: {
                'lesson_id': lessonId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
