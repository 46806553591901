import React, { FunctionComponent, useState } from 'react';

import { Backdrop, CardActions, CardContent, CardHeader, Paper } from '@mui/material';

export interface CustomModalProps {}

export interface ICustomModal {
  handleOpen: (options?: ICustomModalOptions) => void;
  handleClose: () => void;
}

export interface ICustomModalOptions {
  title?: string;
  subHeader?: string;
  actions?: React.ReactNode;
  content?: React.ReactNode;
}

export const CustomModalContext = React.createContext<ICustomModal | undefined>(undefined);

const CustomModal: FunctionComponent<CustomModalProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [subHeader, setSubHeader] = useState<string>('');
  const [content, setContent] = useState<React.ReactNode>(undefined);
  const [actions, setActions] = useState<React.ReactNode>(undefined);

  const handleOpen = (options?: ICustomModalOptions) => {
    setIsOpen(true);
    options?.title && setTitle(options?.title);
    options?.subHeader && setSubHeader(options?.subHeader);
    options?.content && setContent(options?.content);
    options?.actions && setActions(options?.actions);
  };

  const handleClose = () => {
    setIsOpen(false);
    setTitle('');
    setSubHeader('');
    setContent(undefined);
    setActions(undefined);
  };

  return (
    <CustomModalContext.Provider
      value={{
        handleOpen,
        handleClose,
      }}>
      {children}
      <Backdrop
        id="contractBackdrop"
        open={isOpen}
        style={{
          zIndex: '5000',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Paper elevation={3} style={{ maxWidth: '90vw' }}>
          {title && subHeader && (
            <CardHeader title={title ? title : ''} subheader={subHeader ? subHeader : ''} />
          )}
          {content && (
            <CardContent
              sx={{
                overflowY: 'auto',
                maxHeight: '70vh',
              }}>
              {content}
            </CardContent>
          )}

          {actions && <CardActions disableSpacing>{actions}</CardActions>}
        </Paper>
      </Backdrop>
    </CustomModalContext.Provider>
  );
};

export const useCustomModal = () => React.useContext(CustomModalContext);

export default CustomModal;
