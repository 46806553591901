import React, { useEffect, useRef, useState } from 'react';

import { Card, CardContent, CardHeader, Grid, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "dayjs/locale/nl";
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18n';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { useSnackbar } from 'notistack';

import { BaseStub, EmailValidationOut } from 'assets';
import { TextField } from 'components/Elements/Forms/Inputs';
import { checkCity, checkDateYMD, checkMail, checkZip, ValidateEmail } from 'functions/HelperFunctions/Regex';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import { validateEmailSendGrid } from 'functions/Hooks/validateEmailSendGrid';

import {
  CheckCity,
  CheckDateOfBirth,
  CheckFirstName,
  CheckGender,
  CheckHouseNumber,
  CheckLastName,
  CheckPhone,
  CheckStreetName,
  CheckZipCode,
} from './Models/errorChecks';
import { IFlowPageProps, valueType } from '..';

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: 0,
      paddingLeft: 10,
      paddingRight: 20,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 10
      },
      "& .MuiInputBase-input": {
        padding: 15,
        paddingLeft: 0
      }
    }
  }
});

interface IInformationPageProps extends IFlowPageProps {
  data: BaseStub;
  setData: (baseStub: BaseStub) => void;
  secretkey: string;
}

const InformationPage: React.FunctionComponent<IInformationPageProps> = ({
  data,
  setData,
  setMailExist,
}) => {
  const { zipcode } = data.data_bundle;
  const [updateDom, setUpdateDom] = useState<boolean>(false);
  const [emailResponse] = useState<EmailValidationOut>();
  const { enqueueSnackbar } = useSnackbar();
  const currentWidth = useWidth();
  const [validateError, setValidateError] = useState<boolean>(false);
  const previousEmail = useRef<string>('');
  const classes = useStyles()

  // Converts dates in format DD/MM/YYYY or YYYY-MM-DD to dayjs object
  const dateToDayjs = (date: string): dayjs.Dayjs => {
    let dateSeperated = date.split('/')
    let djs = undefined

    if (dateSeperated.length === 3) {
      djs = dayjs().set('year', parseInt(dateSeperated[2])).set('month', parseInt(dateSeperated[1]) - 1).set('date', parseInt(dateSeperated[0]))
    }
    else {
      dateSeperated = date.split('-')

      djs = dayjs().set('year', parseInt(dateSeperated[0])).set('month', parseInt(dateSeperated[1]) - 1).set('date', parseInt(dateSeperated[2]))
    }

    return djs;
  }

  const [dateOfBirth, setDateOfBirth] = useState<Dayjs>();

  // Sets default date of birth if it is not set
  useEffect(() => {
    if (dateOfBirth === undefined && data.data_bundle.students?.[0]?.date_of_birth) {
      setDateOfBirth(dateToDayjs(data.data_bundle.students?.[0]?.date_of_birth))
    }
  }, [data.data_bundle.students?.[0]?.date_of_birth])

  React.useEffect(() => {
    if (updateDom) {
      setUpdateDom(false);
    }
  }, [updateDom]);

  React.useEffect(() => {
    //Trigger rerender
  }, [data.data_bundle.email_address]);

  //Save data from a certain information field to the databundle
  const saveData = (name: string, value: valueType) => {
    const change = { ...data };
    change.data_bundle.country = 'nederland';
    change.data_bundle[name] = value;

    if (change.data_bundle.zipcode && change.data_bundle.city && change.data_bundle.street) {
      change.data_bundle.user_locations = [
        {
          zipcode: change.data_bundle.zipcode,
          city: change.data_bundle.city,
          street: change.data_bundle.street,
          country: 'nederland',
        },
      ];
    }
    setData(change);
  };

  const saveDataChild = (name: string, value: valueType) => {
    const change = { ...data };
    if (change?.data_bundle?.students?.length > 0) change.data_bundle.students[0][name] = value;
    else {
      change.data_bundle.students = [{ [name]: value }];
    }
    setData(change);
  };

  const capitalize = (s: string): string => {
    if (typeof s !== 'string') return '';

    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  //Handles what happens when next is pressed. Validates data in data_bundle
  const handleNext = async (event: any) => {
    //TODO, dit kan veeeeel korter (for loop)
    const emailValidation: EmailValidationOut = await validateEmailSendGrid(
      data.data_bundle?.email_address
    );

    if (
      !data.data_bundle?.students?.[0]?.first_name ||
      !data.data_bundle?.students?.[0]?.last_name ||
      !data.data_bundle?.street ||
      !data.data_bundle.students[0].gender ||
      !data.data_bundle?.house_number ||
      !(await checkZip(data.data_bundle.zipcode)) ||
      !data.data_bundle.students[0].date_of_birth ||
      data.data_bundle.students[0].date_of_birth === 'Invalid date' ||
      !checkDateYMD(data.data_bundle.students[0].date_of_birth) ||
      !data.data_bundle.city ||
      (await checkCity(data.data_bundle.cityity)) ||
      !data.data_bundle?.first_name ||
      !data.data_bundle?.last_name ||
      !ValidateEmail(data.data_bundle.email_address) ||
      !data.data_bundle.phone_number ||
      !isValidPhoneNumber(data.data_bundle.phone_number, 'NL')
    ) {
      enqueueSnackbar(
        'De ingevulde gegevens kloppen niet, controleer deze en pas aan waar nodig is!',
        { variant: 'error' }
      );
      setValidateError(true);

      return;
    } else if (
      emailValidation &&
      emailValidation.verdict === 'Risky' &&
      previousEmail.current !== data.data_bundle.email_address
    ) {
      //Check if email-address has been verified before, if it is the first time a validation comes back negative a warning should be thrown.
      previousEmail.current = data.data_bundle.email_address;
      enqueueSnackbar(
        'U hebt mogelijk een incorrect e-mailadres ingevuld. Kunt u het e-mailadres nog een keer controleren? Indien het klopt, gelieve nog een keer op Volgende te klikken',
        {
          variant: 'info',
          anchorOrigin:
            currentWidth === 'xs'
              ? {
                vertical: 'top',
                horizontal: 'center',
              }
              : {
                vertical: 'bottom',
                horizontal: 'center',
              },
        }
      );
      setValidateError(true);

      return;
    } else {
      previousEmail.current = data.data_bundle.email_address;
      setValidateError(false);
    }

    if (emailResponse && emailResponse.found && emailResponse.verdict != 'Invalid') {
      setMailExist?.(true);
    } else {
      setMailExist?.(false);
    }

    event.detail();
  };

  useEffect(() => {
    document.addEventListener('onNext', handleNext);

    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [data]);

  return (
    <div className="algemeen">
      <Card elevation={0}>
        <CardContent>
          <Grid container>
            <Grid item sm={4}>
              <CardHeader
                title={'Vul de gegevens van uw kind in:'}
                titleTypographyProps={{ fontSize: '18px' }}
                sx={{
                  marginBottom: '0px',
                  paddingBottom: '10px',
                  paddingTop: '0px',
                  paddingLeft: '0px',
                }}
              />
            </Grid>
          </Grid>

          <Grid container direction={'row'} spacing={2.5}>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                name={'fName'}
                label={'Voornaam kind'}
                backgroundColor="#f5f5f5"
                // error={!data.data_bundle?.students?.[0]?.first_name && data.data_bundle?.students?.[0]?.first_name !== undefined}
                error={CheckFirstName(validateError, data.data_bundle?.students?.[0]?.first_name)}
                dontShowBorder
                required
                fullWidth
                value={data.data_bundle?.students?.[0]?.first_name || ''}
                onChange={e => saveDataChild('first_name', capitalize(e.target.value))}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                name={'lName'}
                label={'Achternaam kind'}
                backgroundColor="#f5f5f5"
                // error={!data.data_bundle?.students?.[0]?.last_name && data.data_bundle?.students?.[0]?.last_name !== undefined}
                error={CheckLastName(validateError, data.data_bundle?.students?.[0]?.last_name)}
                dontShowBorder
                required
                fullWidth
                value={data.data_bundle?.students?.[0]?.last_name || ''}
                onChange={e => saveDataChild('last_name', e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={3.25}>
              <TextField
                fullWidth
                select
                label="Geslacht"
                required
                backgroundColor="#f5f5f5"
                //error={!data.data_bundle.students?.[0]?.gender && data.data_bundle.students?.[0].gender !== undefined}
                error={CheckGender(validateError, data.data_bundle.students?.[0].gender)}
                dontShowBorder
                // defaultValue={"Geslacht"}
                value={data.data_bundle.students?.[0]?.gender || {}}>
                {/* <MenuItem selected={true}>Geslacht</MenuItem> */}
                <MenuItem onClick={() => saveDataChild('gender', 'mannelijk')} value="mannelijk">
                  Man
                </MenuItem>
                <MenuItem onClick={() => saveDataChild('gender', 'vrouwelijk')} value="vrouwelijk">
                  Vrouw
                </MenuItem>
                <MenuItem onClick={() => saveDataChild('gender', 'overig')} value="overig">
                  Overig
                </MenuItem>
              </TextField>
            </Grid>
            {currentWidth != 'xs' && <Grid item md={2.75} />}
            <Grid item xs={6} md={3.25}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="nl"
              >
                <DatePicker
                  className={classes.root}
                  label={t('translation.common.formLabels.dateOfBirth')}
                  format="DD/MM/YYYY"
                  slots={{
                    textField: params =>
                      <TextField
                        {...params}
                        error={CheckDateOfBirth(
                          validateError,
                          data.data_bundle.students?.[0]?.date_of_birth
                        )}
                      />,
                  }}
                  disableOpenPicker={currentWidth !== "xs"}
                  value={dateOfBirth}
                  localeText={{ fieldYearPlaceholder: (params) => 'J'.repeat(params.digitAmount), }}
                  slotProps={{
                    textField: {
                      variant: 'filled',
                      required: true,
                      size: 'small',
                      error: CheckDateOfBirth(
                        validateError,
                        data.data_bundle.students?.[0]?.date_of_birth
                      ),
                      onBlur: (e) => {
                        let dayString = '';
                        dayString = e.target.value || '';

                        const day = dateToDayjs(dayString).format("YYYY-MM-DD")
                        setDateOfBirth(dateToDayjs(dayString))
                        saveDataChild('date_of_birth', day)
                      },
                      InputProps: {
                        disableUnderline: true,
                        defaultValue: "DD/MM/JJJJ",

                        sx: {
                          height: "46px",
                          bgcolor: "#f5f5f5",
                          borderRadius: "20px",
                          ...(currentWidth === 'xs' && {
                            fontSize: '14px',
                            lineHeight: '16px',
                            padding: '0px',
                            margin: '-2px 0px -2px 0px',
                          }),
                        },
                      },
                    },
                  }}
                  onChange={() => { }}
                  onAccept={(day) => {
                    let dayString = '';

                    if (day !== null) {
                      dayString = dayjs(day)?.format("YYYY-MM-DD") || '';
                    }

                    setDateOfBirth(dayjs(day))
                    saveDataChild('date_of_birth', dayString)
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: '20px' }}>
              <TextField
                id="standard-basic"
                name={'postal-code'}
                label={'Postcode'}
                backgroundColor="#f5f5f5"
                error={CheckZipCode(validateError, zipcode)}
                dontShowBorder
                helperText={
                  zipcode && !checkZip(zipcode)
                    ? 'Gelieve uw postcode in te vullen met het format 1234AB'
                    : ''
                }
                fullWidth
                required
                value={data.data_bundle?.zipcode || ''}
                onChange={e => saveData('zipcode', e.target.value.toUpperCase().replace(' ', ''))}
              />
            </Grid>
            <Grid item xs={6} sx={{ marginTop: '20px' }}>
              <TextField
                id="standard-basic"
                name="addressNumber"
                label={'Huisnummer'}
                backgroundColor="#f5f5f5"
                fullWidth
                required
                value={data.data_bundle?.house_number || ''}
                onChange={e => saveData('house_number', e.target.value)}
                error={CheckHouseNumber(validateError, data.data_bundle.house_number)}
                // error={ !/^[1-9][0-9]*.*$/.test(data.data_bundle.house_number) && data.data_bundle.house_number !== undefined}
                dontShowBorder
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                name={'street-address'}
                label={'Straatnaam'}
                backgroundColor="#f5f5f5"
                // error={!data.data_bundle?.street && data.data_bundle?.street !== undefined}
                error={CheckStreetName(validateError, data.data_bundle?.street)}
                dontShowBorder
                fullWidth
                required
                value={data.data_bundle?.street || ''}
                onChange={e => saveData('street', capitalize(e.target.value))}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="standard-basic"
                name={'city'}
                label={'Woonplaats'}
                backgroundColor="#f5f5f5"
                // border="unset"
                // error={!data.data_bundle?.city && data.data_bundle?.city !== undefined}
                error={CheckCity(validateError, data.data_bundle?.city)}
                dontShowBorder
                fullWidth
                required
                value={data.data_bundle?.city || ''}
                onChange={e => saveData('city', capitalize(e.target.value))}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card
        elevation={0}
        sx={{
          marginTop: '-12px',
          marginBottom: '-20px',
        }}>
        <CardContent>
          <Grid container>
            <Grid item sm={4} md={12}>
              <CardHeader
                title={'Vul hier uw basisgegevens in:'}
                titleTypographyProps={{ fontSize: '18px' }}
                sx={{
                  // marginTop: "10px",
                  marginBottom: currentWidth === 'xs' ? '0px' : '0px',
                  paddingBottom: '10px',
                  paddingTop: '0px',
                  paddingLeft: '0px',
                }}
              />
            </Grid>

            <Grid item sm={8} md={12}>
              <Grid container spacing={2.5}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-basic"
                    name={'fName'}
                    label={'Voornaam'}
                    backgroundColor="#f5f5f5"
                    // error={!data.data_bundle?.first_name && data.data_bundle?.first_name !== undefined}
                    error={CheckFirstName(validateError, data.data_bundle?.first_name)}
                    dontShowBorder
                    required
                    fullWidth
                    value={data.data_bundle?.first_name || ''}
                    onChange={e => saveData('first_name', capitalize(e.target.value))}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-basic"
                    name={'lName'}
                    label={'Achternaam'}
                    backgroundColor="#f5f5f5"
                    // error={!data.data_bundle?.last_name && data.data_bundle?.last_name !== undefined}
                    error={CheckLastName(validateError, data.data_bundle?.last_name)}
                    dontShowBorder
                    required
                    fullWidth
                    value={data.data_bundle?.last_name || ''}
                    onChange={e => saveData('last_name', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="standard-basic"
                    name={'Mail'}
                    label={'E-mail'}
                    backgroundColor="#f5f5f5"
                    required
                    fullWidth
                    error={
                      data.data_bundle?.email_address && !checkMail(data.data_bundle?.email_address)
                    }
                    dontShowBorder
                    value={data.data_bundle?.email_address || ''}
                    onChange={e => saveData('email_address', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3.25}>
                  <TextField
                    id="standard-basic"
                    name={'tel'}
                    label={'Mobiele nummer'}
                    backgroundColor="#f5f5f5"
                    required
                    fullWidth
                    value={data.data_bundle?.phone_number || ''}
                    onChange={e => saveData('phone_number', e.target.value)}
                    // error={data.data_bundle?.phone_number !== undefined && !checkPhone(data.data_bundle?.phone_number)}
                    error={CheckPhone(validateError, data.data_bundle?.phone_number)}
                    dontShowBorder
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default InformationPage;
