import React, { FormEvent } from 'react';

import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { t, Translation } from 'i18n';

import { Button, TextField } from 'components/Elements/Forms/Inputs';
import useLabel from 'functions/Hooks/useLabel';

interface ICreatePasswordFormMobileProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  status: string;
  setPassword: (value: string) => void;
  setPasswordConfirm: (value: string) => void;
}

const CreatePasswordFormMobile: React.FunctionComponent<ICreatePasswordFormMobileProps> = ({
  handleSubmit,
  setPassword,
  setPasswordConfirm,
}) => {
  const label = useLabel();

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container>
          <Grid item md={12}>
            <CardHeader
              title={
                <Typography
                  variant="h3"
                  color={'#3C3C3B'}
                  sx={{
                    fontSize: '22px',
                    lineHeight: '1.2',
                  }}>
                  <Translation i18nKey="translation.auth.createpw.header" values={{ label: label?.name }} />
                  <Typography
                    component="span"
                    variant="h3"
                    color="primary"
                    sx={{
                      fontSize: '22px',
                      marginLeft: '5px',
                    }}>
                    {t('translation.auth.createpw.headerportal')}
                  </Typography>
                  .
                </Typography>
              }
              subheader={
                <Typography
                  fontSize={'16px'}
                  sx={{
                    marginTop: '20px',
                    color: '#6F6F6F',
                  }}
                  lineHeight={'1.2'}>
                  {t('translation.auth.createpw.subheader')}
                </Typography>
              }
              sx={{ paddingTop: '0px' }}
            />
          </Grid>

          <Grid item xs={12} style={{ marginLeft: '10px' }}>
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} md={10}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Nieuw wachtwoord"
                    type="password"
                    id="password"
                    onChange={e => setPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item xs={12} md={10}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="passwordConfirm"
                    label="Bevestig nieuw wachtwoord"
                    type="password"
                    id="passwordConfirm"
                    onChange={e => setPasswordConfirm(e.target.value)}
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item xs={12} sx={{ marginTop: '15px' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ padding: '10px' }}>
                    <Typography>{t('translation.auth.createpw.submitBTN')}</Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>

        <Grid item xs={12} md={10}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordConfirm"
            label="Bevestig nieuw wachtwoord"
            type="password"
            id="passwordConfirm"
            onChange={e => setPasswordConfirm(e.target.value)}
            autoComplete="new-password"
          />
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '15px' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ padding: '10px' }}>
            <Typography>Wachtwoord instellen</Typography>
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CreatePasswordFormMobile;
