import React, { useState } from 'react';

import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';

interface ThankYouPageProps {}

const ThankYouPage: React.FunctionComponent<ThankYouPageProps> = () => {
  const [isTransfered, setIsTransfered] = useState<boolean>(false);

  //TODO: Dit wordt nu elke rerender afgevuurd
  React.useEffect(() => {
    if (!isTransfered) {
      // appClient.stubs
      setIsTransfered(true);
    }
  });

  return (
    <Grid item xs={12}>
      <Card elevation={3} sx={{ borderRadius: '15px' }}>
        <CardHeader title={'Hartelijk bedankt voor uw aanvraag!'} />
        <CardContent>
          <Typography>Wij nemen zo spoedig mogelijk contact met u op.</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ThankYouPage;
