import React from 'react'

import { ChevronLeft } from '@mui/icons-material'
import { CardHeader, Grid } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { Button } from 'components/Elements/Forms/Inputs'
import useNavContext from 'functions/Hooks/useNav'

const InvoicePaymentHeader = () => {
  const history = useHistory()
  const NavProvider = useNavContext()

  const handleBack = () => {
    NavProvider.setShow(true)
    history.push("/PakketAanschaffen")
    // history.goBack()
  }

  return (
    <Grid container sx={{ bgcolor: "#F2F2F2" }}>
      <Grid item xs={1.5}>
        <Button
          onClick={handleBack}
        >
          <ChevronLeft
            sx={{
              color: "#6F6F6F",
              fontSize: "22px",
            }}
          />
        </Button>
      </Grid>
      <Grid item xs={9}>
        <CardHeader
          title={"Nieuw bijlespakket"}
          titleTypographyProps={{
            color: "#6F6F6F"
          }}
          sx={{ marginTop: "-15px" }}
        />
      </Grid>
      <Grid item xs={1.5} />
    </Grid>
  )
}

export default InvoicePaymentHeader