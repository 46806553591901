import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CurrentStudyLevelCoachEnum, SchoolLevelCoachEnum, SchoolLevelEnum_Output } from 'assets';
import { ITextFieldProps, MenuItemSelect, TextField } from 'components/Elements/Forms/Inputs';

//Receive either a save function and a name, or a set function
type SchoolLevelSelectSaveProps = {
  saveSchoolLevelParent: (name: string, value: string) => void;
  saveKey: string;
  setSchoolLevelParent?: never;
  defaultSchoolLevel?: SchoolLevelCoachEnum;
  schoolLevelVariant: 'highschool' | 'higherEducation' | 'noPrimarySchool' | 'all';
} & ITextFieldProps;

type SchoolLevelSelectSetProps = {
  setSchoolLevelParent: (s: SchoolLevelEnum_Output) => void;
  saveSchoolLevelParent?: never;
  saveKey?: never;
  defaultSchoolLevel?: SchoolLevelCoachEnum;
  schoolLevelVariant: 'highschool' | 'higherEducation' | 'noPrimarySchool' | 'all';
} & ITextFieldProps;

type SchoolLevelSelectProps = SchoolLevelSelectSaveProps | SchoolLevelSelectSetProps;

//Select element designed to return a school level
const SchoolLevelSelect = ({
  setSchoolLevelParent,
  saveSchoolLevelParent,
  saveKey,
  defaultSchoolLevel,
  schoolLevelVariant,
  ...selectProps
}: SchoolLevelSelectProps) => {
  const schoolLevels = Object.keys(SchoolLevelEnum_Output);
  const [schoolLevelOptions, setSchoolLevelOptions] = useState<string[]>(schoolLevels);
  const [schoolLevel, setSchoolLevel] = useState<string | undefined>();

  //Change between different types of school levels. "all" needs no filter.
  useEffect(() => {
    let levels: string[] = schoolLevels;

    switch (schoolLevelVariant) {
      case 'highschool':
        levels = schoolLevels.filter(
          level => level != 'MBO' && level != 'HBO' && level != 'WO' && level != 'BASISSCHOOL'
        );
        break;
      case 'higherEducation':
        levels = schoolLevels.filter(level =>
          Object.keys(CurrentStudyLevelCoachEnum).includes(level)
        );
        break;
      case 'noPrimarySchool':
        levels = schoolLevels.filter(level => Object.keys(SchoolLevelCoachEnum).includes(level));
        break;
      default:
        break;
    }

    setSchoolLevelOptions(levels);
  }, [schoolLevelVariant]);

  return (
    <Grid item>
      <TextField
        {...selectProps}
        sx={{
          ...selectProps.sx,
          borderRadius: '15px',
        }}
        select
        onChange={e => {
          //Different save/set functions are possible
          const c = e.target.value;
          setSchoolLevel(c);

          if (setSchoolLevelParent) {
            setSchoolLevelParent(c as SchoolLevelEnum_Output);
          }

          if (saveSchoolLevelParent) {
            saveSchoolLevelParent(saveKey, c);
          }
        }}
        defaultValue={defaultSchoolLevel}
        value={schoolLevel}>
        {schoolLevelOptions.map(school_level => (
          <MenuItemSelect key={school_level} value={school_level.toLowerCase().replace('_', '-')}>
            {school_level.toLowerCase().replace('_', ' ')}
          </MenuItemSelect>
        ))}
      </TextField>
    </Grid>
  );
};

export default SchoolLevelSelect;
