/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseTask } from '../models/BaseTask';
import type { Body_import_jobs_open_selenium_supervisor_import_jobs_csv_post } from '../models/Body_import_jobs_open_selenium_supervisor_import_jobs_csv_post';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SeleniumService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Import Jobs Open
     * This endpoint is comparable to `/sp/jobs/new/import/bulk`
     *
     * This is only for bulk-importing all open jobs. The csv is provided by the selenium-supervisor
     *
     * The logic is closely the same, but the lowest-level working of making the actual job and shifts are different as they use different CSV-files.
     *
     * Required:
     *
     * - An api-key that is passed in the header of the request.
     *
     * Args:
     *
     * - file: The CSV-file that contains the jobs that need to be imported.
     *
     * Returns:
     *
     * - The task that is created to do the import. This is a celery task.
     * @param formData
     * @returns BaseTask Successful Response
     * @throws ApiError
     */
    public postSeleniumImportJobsOpen(
        formData: Body_import_jobs_open_selenium_supervisor_import_jobs_csv_post,
    ): CancelablePromise<BaseTask> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/selenium/supervisor/import/jobs/csv',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
