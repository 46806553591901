/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CoachesNearStudentResult } from '../models/CoachesNearStudentResult';
import type { DisciplinesRadiusQuery } from '../models/DisciplinesRadiusQuery';
import type { GenericCount } from '../models/GenericCount';
import type { LessonRequestMapStudentResult } from '../models/LessonRequestMapStudentResult';
import type { Matchesresult } from '../models/Matchesresult';
import type { StudentsNearCoachResult } from '../models/StudentsNearCoachResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MatcherService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Match Student
     * Returns a list of possible matches for a student.
     *
     * Args:
     * - `student_id`: The student to match.
     *
     * - `is_online`: Whether the lesson should be online or not.
     *
     * - `filter_coach_level`: Whether to filter the coaches by their level.
     *
     * > When filtering coaches on their level, the returned coaches will have the level of the student or higher.
     * @param studentId
     * @param isOnline
     * @param filterCoachLevel
     * @returns Matchesresult Successful Response
     * @throws ApiError
     */
    public getMatcherMatchStudent(
        studentId: number,
        isOnline: boolean = false,
        filterCoachLevel: boolean = false,
    ): CancelablePromise<Array<Matchesresult>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/matcher/match/{student_id}',
            path: {
                'student_id': studentId,
            },
            query: {
                'is_online': isOnline,
                'filter_coach_level': filterCoachLevel,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Find Near For Student Old
     * @param studentId
     * @param limit
     * @param offset
     * @returns CoachesNearStudentResult Successful Response
     * @throws ApiError
     */
    public getMatcherFindNearForStudentOld(
        studentId: number,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<Array<CoachesNearStudentResult>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/matcher/near/{student_id}/student',
            path: {
                'student_id': studentId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Find Near For Student New With Filters
     * @param studentId
     * @param requestBody
     * @param limit
     * @param offset
     * @returns CoachesNearStudentResult Successful Response
     * @throws ApiError
     */
    public postMatcherFindNearForStudentNewWithFilters(
        studentId: number,
        requestBody: DisciplinesRadiusQuery,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<Array<CoachesNearStudentResult>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/matcher/near/{student_id}/student',
            path: {
                'student_id': studentId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Find Near For Coach Radius
     * @param coachId
     * @param requestBody
     * @param limit
     * @param offset
     * @returns StudentsNearCoachResult Successful Response
     * @throws ApiError
     */
    public postMatcherFindNearForCoachRadius(
        coachId: number,
        requestBody: DisciplinesRadiusQuery,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<Array<StudentsNearCoachResult>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/matcher/near/{coach_id}/coach',
            path: {
                'coach_id': coachId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Find Near For Coach Count
     * @param coachId
     * @returns GenericCount Successful Response
     * @throws ApiError
     */
    public getMatcherFindNearForCoachCount(
        coachId: number,
    ): CancelablePromise<GenericCount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/matcher/near/coach/{coach_id}/count',
            path: {
                'coach_id': coachId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Find Lesson Requests
     * @returns LessonRequestMapStudentResult Successful Response
     * @throws ApiError
     */
    public getMatcherFindLessonRequests(): CancelablePromise<Array<LessonRequestMapStudentResult>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/matcher/students/locations',
            errors: {
                404: `Not found`,
            },
        });
    }

}
