/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CalendarEntryStatusEnum {
    AVAILABLE = 'available',
    UNAVAILABLE = 'unavailable',
    RESERVED = 'reserved',
    SCHEDULED = 'scheduled',
    UNKNOWN = 'unknown',
}
