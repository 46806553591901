/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Provinces {
    UTRECHT = 'Utrecht',
    GRONINGEN = 'Groningen',
    FLEVOLAND = 'Flevoland',
    DRENTHE = 'Drenthe',
    NOORD_HOLLAND = 'Noord-Holland',
    GELDERLAND = 'Gelderland',
    ZUID_HOLLAND = 'Zuid-Holland',
    FRYSL_N = 'Fryslân',
    ZEELAND = 'Zeeland',
    NOORD_BRABANT = 'Noord-Brabant',
    OVERIJSSEL = 'Overijssel',
    LIMBURG = 'Limburg',
}
