
import React, { useContext, useState } from 'react'

interface INavProviderProps{
  children: React.ReactNode
}

interface INavContexProps{
  show:boolean
  setShow:React.Dispatch<React.SetStateAction<boolean>>
}

export const NavContex = React.createContext<INavContexProps | null>(null)


export const NavContextProvider: React.FC< INavProviderProps> = ({children}) => {
  const [show,setShow] =useState<boolean>(true)



  return (
    <NavContex.Provider value={{
      show,
      setShow
    }}>
        {children}
    </NavContex.Provider>
  )
}

export const useNavContext = () => {
  const context = useContext(NavContex)
  if(!context){
    throw new Error("useNavContext must be used within a NavContextProvider")
  }

  return context
}



export default useNavContext