import React, { useState } from 'react';
import '../Index.css';

import UploadIcon from '@mui/icons-material/Upload';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { t, Translation } from 'i18n';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';

import { appClient } from 'App';
import { BaseStub } from 'assets';
import { TextField } from 'components/Elements/Forms/Inputs';
import UploadButton from 'components/Functional/Buttons/UploadButton';
import SchoolLevelSelect from 'components/Functional/Select/SchoolLevelSelect';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

import SubjectPage from './Components/SubjectsPage';

interface SchoolProps {
  setData: (d: BaseStub) => void;
  data: BaseStub;
}
const School: React.FunctionComponent<SchoolProps> = ({ setData, data }) => {
  const [tempData, setTempData] = useState<BaseStub>({ ...data });
  const { enqueueSnackbar } = useSnackbar();
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const currentWidth = useWidth();

  React.useEffect(() => {
    if (!data.data_bundle.is_pabo) {
      data.data_bundle.is_pabo = false;
    }
    if (!data.data_bundle.is_kdv) {
      data.data_bundle.is_kdv = false;
    }
    if (!data.data_bundle.is_coach) {
      data.data_bundle.is_coach = false;
    }
    if (data.data_bundle.grades) {
      setUploadedFileName(t('translation.signUpCoaches.schoolInformation.previouslyUploadedGrades'));
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('onNext', handleNext);

    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [data]);

  const saveData = (name: string, value: string) => {
    const change = { ...tempData };
    if (name === 'school_level') {
      change.data_bundle.disciplines = [];
    }
    change.data_bundle[name] = value;

    if (change.data_bundle.finished_current_study === true) {
      change.data_bundle.school_year = 0;
    }

    setTempData(change);
    setData(change);

    toggleSubjects();
  };

  const toggleSubjects = () => {
    if (!data.data_bundle.is_coach && !data.data_bundle.is_pabo && data.data_bundle.is_kdv) {
      return false;
    } else {
      return true;
    }
  };

  //Run checks on data before going to next page
  const handleNext = async (event: any) => {
    if (!data.data_bundle.school_level) {
      enqueueSnackbar('Middelbare school is verplicht', { variant: 'error' });

      return;
    }

    if (!data.data_bundle.is_pabo && !data.data_bundle.is_coach && !data.data_bundle.is_kdv) {
      enqueueSnackbar('Selecteer op zijn minst 1 optie', { variant: 'error' });

      return;
    }

    if (data.data_bundle.is_coach === 'true') {
      if (!(data.data_bundle.disciplines.length > 0)) {
        enqueueSnackbar('Selecteer op zijn minst 1 vak om bijles in te geven', {
          variant: 'error',
        });

        return;
      }
    }

    event.detail();
  };

  const capitalize = (s: any) => {
    if (typeof s !== 'string') return '';

    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  //Handle when a user uploads a grades list to the server. Save the file name and adds attachment to the stub.
  const handleChangeUpload = async (e: any) => {
    const attachment: File | undefined = e.target.files[0];
    if (attachment) {
      appClient.stubs
        .postStubsAddAttachement('grades_key', data.secret_key, { attachment })
        .then(() => {
          setUploadedFileName(e.target.files[0].name);
          enqueueSnackbar(t('translation.common.alerts.uploadGrades.success'), { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar(t('translation.common.alerts.uploadGrades.fail'), { variant: 'error' });
        });
    }
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          borderRadius: '20px',
          marginTop: '20px',
        }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item sm={4} sx={{ padding: '-10px' }}>
              <CardHeader
                title={'Schoolgegevens'}
                subheader={
                  'Geef je schoolgegevens aan ons door, zodat wij je zo goed mogelijk kunnen matchen met leerlingen die jouw kennis en kunde goed kunnen gebruiken!'
                }
                sx={{
                  marginTop: '-20px',
                  marginBottom: isMobile ? '0px' : '20px',
                  padding: currentWidth === 'xs' ? '0px' : 'unset',
                }}
              />
            </Grid>
            <Grid item sm={8}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <SchoolLevelSelect
                    sx={{ marginTop: '15px' }}
                    backgroundColor="#f5f5f5"
                    border="unset"
                    required
                    fullWidth
                    variant="outlined"
                    label="Mijn hoogst afgeronde middelbareschoolopleiding"
                    saveKey="school_level"
                    saveSchoolLevelParent={saveData}
                    defaultSchoolLevel={data.data_bundle.school_level}
                    schoolLevelVariant="highschool"
                  />
                </Grid>
                <Grid item xs={12}>
                  <SchoolLevelSelect
                    sx={{ marginY: '15px' }}
                    backgroundColor="#f5f5f5"
                    border="unset"
                    required
                    fullWidth
                    variant="outlined"
                    label="Mijn hoogst afgeronde of bezige mbo-, hbo- of wo-opleiding"
                    saveKey="current_study_level"
                    saveSchoolLevelParent={saveData}
                    defaultSchoolLevel={data.data_bundle.current_study_level}
                    schoolLevelVariant="higherEducation"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={data.data_bundle.current_study}
                    id="current_study"
                    backgroundColor="#f5f5f5"
                    border="unset"
                    fullWidth
                    variant="outlined"
                    label="Naam van mijn opleiding (meest relevant voor StudyWorks)"
                    name="current_study"
                    // zeroMinWidth
                    onChange={e => saveData('current_study', capitalize(e.target.value))}
                  />
                </Grid>
                <Grid container xs={12}>
                  <Grid item md={6} xs={4}>
                    <TextField
                      select
                      style={{ marginTop: '15px' }}
                      value={
                        data.data_bundle.school_year && data.data_bundle.school_year.toString()
                      }
                      defaultValue={'1'}
                      disabled={data.data_bundle.finished_current_study === 'true'}
                      id="school_year"
                      backgroundColor="#f5f5f5"
                      border="unset"
                      fullWidth
                      variant="outlined"
                      label="Jaar"
                      name="school_year"
                      onChange={e => saveData('school_year', e.target.value)}>
                      {[...Array(6)].map((_, i) => (
                        <MenuItem key={i + 1} value={i + 1}>
                          {`Jaar ${i + 1}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={8}>
                    <FormControlLabel
                      sx={{ margin: '15px' }}
                      control={
                        <Checkbox
                          checked={tempData.data_bundle.finished_current_study === 'true'}
                          onChange={e =>
                            saveData('finished_current_study', e.target.checked.toString())
                          }
                          name="finished_current_study"
                        />
                      }
                      label="Ik heb deze studie afgerond."
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ margin: currentWidth === 'xs' ? '0px' : '15px' }}
                          checked={tempData.data_bundle.is_coach === 'true'}
                          onChange={e => {
                            saveData('is_coach', e.target.checked.toString());
                          }}
                          name="is_coach"
                        />
                      }
                      label="Ik wil bijles geven."
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ margin: currentWidth === 'xs' ? '0px' : '15px' }}
                          checked={tempData.data_bundle.is_pabo === 'true'}
                          onChange={e => saveData('is_pabo', e.target.checked.toString())}
                          name="is_pabo "
                        />
                      }
                      label=" Ik wil op een school werken."
                    />
                  </Grid>
                  <Grid item md={7} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ margin: currentWidth === 'xs' ? '0px' : '15px' }}
                          checked={tempData.data_bundle.is_kdv === 'true'}
                          onChange={e => saveData('is_kdv', e.target.checked.toString())}
                          name="is_kdv "
                        />
                      }
                      label="Ik wil in de kinderopvang werken."
                    />
                  </Grid>
                  <Grid item marginTop="16px">
                    <Typography variant="h3">
                      {t('translation.signUpCoaches.schoolInformation.uploadTitle')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">
                      <Translation
                        i18nKey="translation.signUpCoaches.schoolInformation.uploadExplanation"
                        components={[
                          <br />,
                          <a
                            href="https://duo.nl/particulier/uittreksel-diplomagegevens-downloaden.jsp"
                            style={{ color: 'darkgray' }}>
                            mijn duo
                          </a>,
                        ]}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} marginTop="32px">
                    <Grid container justifyContent="flex-end">
                      <Grid item xs={7} md={4}>
                        <Stack direction="column">
                          <UploadButton
                            buttonTranslation={
                              <Translation i18nKey="translation.common.buttons.uploadGrades" />
                            }
                            handleChange={handleChangeUpload}
                            endIcon={<UploadIcon />}
                          />
                          {uploadedFileName !== '' && (
                            <Typography
                              variant="h4"
                              color="darkgray"
                              fontStyle="italic"
                              fontSize="15px"
                              lineHeight="18px">
                              {uploadedFileName}
                            </Typography>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Divider
        sx={{
          marginTop: '20px',
          marginBottom: '25px',
          marginLeft: '30px',
          marginRight: '20px',
        }}
      />
      <Collapse in={toggleSubjects()}>
        {data.data_bundle.is_coach === 'true' && (
          <Card
            elevation={0}
            sx={{
              borderRadius: '20px',
              marginTop: '20px',
            }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item sm={4}>
                  <CardHeader
                    title={'Laat ons weten waar je graag bijles in wilt geven.'}
                    subheader={
                      'Bij StudyWorks gaan wij ervan uit dat je kunt helpen met de basisschoolvakken. Deze vakken staan dan ook al automatisch voor jou aangevinkt! '
                    }
                    sx={{
                      marginTop: '-20px',
                      marginBottom: isMobile ? '0px' : '20px',
                    }}
                  />
                </Grid>
                <Grid item sm={8}>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12}>
                      <SubjectPage data={data} setData={setData} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Collapse>
    </>
  );
};

export default School;
