import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';

import { AppBar, CardHeader, Container, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import BEUBanner from 'assets/img/brand/BEU_header_COREcontainer.jpg';
import SWLogo from 'assets/img/brand/logo-text-dark.svg';
import OBLogo from 'assets/img/brand/LOGO_OB.png';
import BEULogo from 'assets/img/brand/LogoBijlesEU.png';
import OBBanner from 'assets/img/brand/OB_header_COREcontainer.jpg';
import OELogo from 'assets/img/brand/OE_logo.png';
import SWBanner from 'assets/img/brand/Oudercontainer_header.png';
import VBLBanner from 'assets/img/brand/VBL_header_COREcontainer.jpg';
import VBLLogo from 'assets/img/brand/VlakbijlesLogo.png';
import { Button } from 'components/Elements/Forms/Inputs';
import ProgressSpinner from 'components/Elements/ProgressSpinner';
import useLabel from 'functions/Hooks/useLabel';
import useUser from 'functions/Hooks/useUser';

import Availability from './Pages/Availability';
import CoachProfile from './Pages/CoachProfile';
import Explanation from './Pages/Explanation';
import GuardianAvailabilities from './Pages/GuardianAvailabilities';
import GuardianLessons from './Pages/GuardianLessons';
import GuardianPersonalDetails from './Pages/GuardianPersonalDetails';
import PersonalDetails from './Pages/PersonalDetails';
import SchoolDetails from './Pages/SchoolDetails';
import StudentDetails from './Pages/StudentDetails';
import TutoringQuestions from './Pages/TutoringQuestions';
import Welcome from './Pages/Welcome';

interface LandingProps {}

interface IPage {
  title: string;
  Component: FunctionComponent<Record<string, unknown>>;
}

const scrollToRef = () => window.scrollTo(0, document.documentElement.offsetTop);

// Some old users have a legacy landing page. This is a flow to update their old account to the new core.
const Landing: React.FunctionComponent<LandingProps> = () => {
  const { user } = useUser();
  const [pages, setPages] = useState<IPage[]>([
    {
      title: 'Welkom',
      Component: Welcome,
    },
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentPage = pages[currentIndex];
  const [trigger, setTrigger] = useState(false);
  const label = useLabel();
  const history = useHistory();

  const handleScroll = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 75 && !trigger) setTrigger(true);
    if (scrolled < 75 && trigger) setTrigger(false);
  };

  useEffect(() => {
    if (user) {
      let change = [
        {
          title: 'Voltooid!',
          Component: Explanation,
        },
      ];

      if (user.first_landing_status !== 'new') {
        change = [
          {
            title: 'Welkom',
            Component: Welcome,
          },
        ];
        change = change.concat([
          ...(user?.coach
            ? [
                {
                  title: 'Bijles geven',
                  Component: TutoringQuestions,
                },
                {
                  title: 'Persoonlijke gegevens',
                  Component: PersonalDetails,
                },
                {
                  title: 'Beschikbaarheid',
                  Component: Availability,
                },
                {
                  title: 'Schoolgegevens',
                  Component: SchoolDetails,
                },
                {
                  title: 'Personaliseer',
                  Component: CoachProfile,
                },
              ]
            : [
                {
                  title: 'Huidige lessen',
                  Component: GuardianLessons,
                },
                {
                  title: 'Persoonlijke gegevens',
                  Component: GuardianPersonalDetails,
                },
                {
                  title: 'Studentgegevens',
                  Component: StudentDetails,
                },
                {
                  title: 'Beschikbaarheid',
                  Component: GuardianAvailabilities,
                },
              ]),
          {
            title: 'Voltooid!',
            Component: Explanation,
          },
        ]);
      }
      setPages(change);
    } else {
      history.push('/login');
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [trigger]);

  useEffect(() => {
    setTrigger(false);
    scrollToRef();
  }, [currentPage]);

  const whiteLabelBanner = () => {
    if (label?.name) {
      switch (label.name.toLowerCase()) {
        case 'studyworks':
          return SWBanner;
          break;
        case 'bijles.eu':
          return BEUBanner;
          break;
        case 'online-bijles.com':
          return OBBanner;
          break;
        case 'online-examentraining.com':
          return OBBanner;
          break;
        case 'vlakbijles':
          return VBLBanner;
          break;
      }
    } else {
      return SWBanner;
    }
  };

  const whiteLabelLogo = () => {
    if (label?.name) {
      switch (label.name.toLowerCase()) {
        case 'studyworks':
          return SWLogo;
        case 'bijles.eu':
          return BEULogo;
        case 'online-bijles.com':
          return OBLogo;
        case 'online-examentraining.com':
          return OELogo;
        case 'vlakbijles':
          return VBLLogo;
      }
    } else {
      return SWLogo;
    }
  };

  const handleNext = async () => {
    const event = new CustomEvent('onNext', {
      detail: () => setCurrentIndex(currentIndex + 1),
    });
    await document.dispatchEvent(event);
  };

  return (
    <div>
      <Grid container direction={'column'}>
        <Grid item xs={12} sx={{ padding: '20px' }}>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12}>
              <img
                src={whiteLabelLogo()}
                alt="logo"
                style={{
                  float: 'left',
                  height: '60px',
                  objectFit: 'contain',
                  maxWidth: '150px',
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ height: '114px' }}>
          <AppBar
            sx={{ backgroundImage: `url(${whiteLabelBanner()})` }}
            elevation={0}
            position={!trigger ? 'static' : 'fixed'}>
            <Container
              sx={{
                paddingTop: '16px',
                paddingBottom: '16px',
              }}>
              <CardHeader
                sx={{ padding: '0px' }}
                title={'Stap ' + (currentIndex + 1)}
                titleTypographyProps={{
                  color: 'white',
                  variant: 'h3',
                }}
                subheader={currentPage.title}
                subheaderTypographyProps={{
                  color: 'white',
                  variant: 'h1',
                }}
                avatar={
                  <div
                    style={{
                      borderRight: '1px solid rgba(255, 255, 255, .5)',
                      paddingRight: '15px',
                    }}>
                    <ProgressSpinner
                      max={pages.length}
                      value={currentIndex + 1}
                      variant="h5"
                      textColor="white"
                      circularProgressProps={{
                        sx: { color: 'white' },
                        size: 75,
                      }}
                    />
                  </div>
                }
              />
            </Container>
          </AppBar>
        </Grid>
      </Grid>

      <Container
        sx={{
          paddingTop: '16px',
          paddingBottom: '16px',
        }}>
        <Grid container>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Grid container direction={'column'}>
                <currentPage.Component />
              </Grid>
            </Grid>
            <Grid item xs>
              {currentIndex > 0 && (
                <Button variant="outlined" onClick={() => setCurrentIndex(currentIndex - 1)}>
                  <Typography variant={'body2'} component={'p'}>
                    Vorige
                  </Typography>
                </Button>
              )}
            </Grid>

            <Grid item xs="auto">
              <Button variant="contained" color="primary" onClick={() => handleNext()}>
                {currentIndex < 5 ? (
                  <Typography variant={'body2'} component={'p'}>
                    Naar stap {currentIndex + 2}:{' '}
                    {pages[currentIndex + 1] ? pages[currentIndex + 1].title : 'voltooid'}
                  </Typography>
                ) : (
                  <Typography variant={'body2'} component={'p'}>
                    Naar het portaal
                  </Typography>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Landing;
