import React from 'react';

import { AccountCircle, Check, Dashboard, LocationOn } from '@mui/icons-material';
import { Avatar, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';

import { appClient } from 'App';
import {
  BaseCoachSPForBranch,
  JobBranchReplyInformation,
  JobMatchBranchReplyInformation,
  SimpleCompetence,
} from 'assets';
import { Button } from 'components/Elements/Forms/Inputs';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

interface ICoachCardProps {
  match: JobMatchBranchReplyInformation;
  job: JobBranchReplyInformation;
  key: number;
  setSelectedCoach: (value: BaseCoachSPForBranch) => void;
  setCoachAccepted: (value: boolean) => void;
}

const CoachCard: React.FunctionComponent<ICoachCardProps> = ({
  match,
  job,
  setSelectedCoach,
  setCoachAccepted,
}) => {
  const { branchToken } = useParams<{ branchToken: string }>();
  const currentWidth = useWidth();

  const coach = match.coach;

  return (
    <Card
      sx={{
        borderRadius: '15px',
        boxShadow: ' 0px 0px 20px #00000033',
        p: '20px',
      }}>
      <CardContent
        sx={{
          height: '90%',
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          opacity: 1,
        }}>
        <Grid container>
          <Grid item xs={12}>
            <CardHeader
              title={
                <Grid container>
                  <Box
                    sx={{
                      alignContent: 'center',
                      marginRight: currentWidth === 'xs' ? '10px' : 'unset',
                    }}>
                    {coach.profile_picture && coach.profile_picture != undefined ? (
                      <Avatar
                        alt={coach.user?.first_name}
                        src={coach.profile_picture}
                        sx={{
                          width: currentWidth === 'xs' ? '40px' : '70px',
                          height: currentWidth === 'xs' ? '40px' : '70px',
                        }}
                      />
                    ) : (
                      <AccountCircle sx={{ fontSize: currentWidth === 'xs' ? '45px' : '84px' }} />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <Stack direction="column">
                      <Typography variant="h4" fontWeight="bold">
                        {coach.user?.first_name} {coach.user?.last_name}
                      </Typography>
                      <Stack direction="row" spacing={2}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <LocationOn
                            sx={{
                              color: '#888787',
                              fontSize: '12px',
                            }}
                          />
                          <Typography
                            sx={{
                              font: 'normal normal normal 18px/23px Source Sans Pro',
                              letterSpacing: '0px',
                              color: '#888787',
                              opacity: 1,
                              fontSize: '12px',
                            }}>
                            {coach.user_locations[0].city}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Dashboard
                            sx={{
                              color: '#888787',
                              fontSize: '12px',
                            }}
                          />
                          <Typography
                            sx={{
                              font: 'normal normal normal 18px/23px Source Sans Pro',
                              letterSpacing: '0px',
                              color: '#888787',
                              opacity: 1,
                              fontSize: '12px',
                            }}>
                            {job?.sector.name}
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              }
              titleTypographyProps={{ margin: '-20px 0px 0px -15px' }}
              action={
                currentWidth !== 'xs' && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      appClient.spJobs
                        .patchSPJobsReplyToJobMatchUuidBranch(branchToken, match.id, true)
                        .then(() => {
                          setSelectedCoach(coach);
                          setCoachAccepted(true);
                        });
                    }}>
                    <Typography>Accepteer coach</Typography>
                  </Button>
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 600 }}>
                      Competenties van {coach.user?.first_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {coach.competences
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((competence: SimpleCompetence) => {
                          return (
                            <Grid item>
                              <Stack direction={'row'}>
                                <Check color={'primary'} />
                                <Typography>{competence.name}</Typography>
                              </Stack>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {currentWidth === 'xs' && (
          <Box sx={{ display: 'flex' }}>
            <Button
              fullWidth
              variant="contained"
              sx={{}}
              onClick={() => {
                appClient.spJobs
                  .patchSPJobsReplyToJobMatchUuidBranch(branchToken, match.id, true)
                  .then(() => {
                    setSelectedCoach(coach);
                    setCoachAccepted(true);
                  });
              }}>
              <Typography>Accepteer coach</Typography>
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CoachCard;
