import React from 'react';

import { AccountCircle, Dashboard, LocationOn } from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import theme from 'theme';

import { BaseCoachSPForBranch, JobBranchReplyInformation } from 'assets';
import { useWidth } from 'functions/Hooks/useDeviceDetect';

interface IAcceptModalProps {
  coach: BaseCoachSPForBranch;
  job: JobBranchReplyInformation;
  coachAccepted: boolean;
}

const AcceptModal: React.FunctionComponent<IAcceptModalProps> = ({ coach, job, coachAccepted }) => {
  const currentWidth = useWidth();

  return (
    <Modal open={coachAccepted}>
      <Card
        sx={{
          position: 'absolute' as const,
          top: currentWidth === 'xs' ? '5%' : '25%',
          left: currentWidth === 'xs' ? '5%' : '25%',
          width: currentWidth === 'xs' ? '5%' : '50%',
          height: currentWidth === 'xs' ? '5%' : '50%',
          borderRadius: '15px',
          p: '20px',
          boxShadow: 20,
        }}>
        <CardContent>
          <CardHeader
            title={'Coach geaccepteerd'}
            titleTypographyProps={{
              color: theme.palette.primary.main,
              margin: '-15px 0px 0px -10px',
            }}
          />
          <Grid container>
            <Card
              elevation={0}
              sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: '15px',
              }}>
              <CardContent>
                <Grid container>
                  <CardHeader
                    title={job?.description}
                    titleTypographyProps={{
                      margin: '-15px 0px 0px -10px',
                      fontSize: '22px',
                    }}
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={0.3}>
                      <LocationOn />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography sx={{}}>
                        {job?.location.street}, {job?.location.zipcode} {job?.location.city}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                      Werkdagen
                    </Typography>
                    <Grid container item xs={12}>
                      {job?.shifts?.map((shift, i: number) => {
                        return (
                          <Grid container item xs={12} key={i}>
                            <Typography sx={{ mb: 2 }}>
                              {new Date(shift.date).toLocaleDateString('nl', {
                                weekday: 'long',
                                day: '2-digit',
                                month: 'long',
                              })}
                              {', '}
                              {shift.start_time.substring(0, shift.start_time.length - 3)} -{' '}
                              {shift.end_time.substring(0, shift.end_time.length - 3)}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Card
            elevation={0}
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '15px',
              marginTop: '20px',
              padding: '20px 10px',
            }}>
            <CardHeader
              title={
                <Grid container>
                  <Box sx={{ alignContent: 'center' }}>
                    {coach.profile_picture && coach.profile_picture != undefined ? (
                      <Avatar
                        alt={coach.user?.first_name}
                        src={coach.profile_picture}
                        sx={{
                          width: currentWidth === 'xs' ? '40px' : '70px',
                          height: currentWidth === 'xs' ? '40px' : '70px',
                        }}
                      />
                    ) : (
                      <AccountCircle sx={{ fontSize: currentWidth === 'xs' ? '45px' : '84px' }} />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <Stack direction="column">
                      <Typography variant="h4" fontWeight="bold">
                        {coach.user?.first_name} {coach.user?.last_name}
                      </Typography>
                      <Stack direction="row" spacing={2}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <LocationOn
                            sx={{
                              color: '#888787',
                              fontSize: '12px',
                            }}
                          />
                          <Typography
                            sx={{
                              font: 'normal normal normal 18px/23px Source Sans Pro',
                              letterSpacing: '0px',
                              color: '#888787',
                              opacity: 1,
                              fontSize: '12px',
                            }}>
                            {coach.user_locations[0].city}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Dashboard
                            sx={{
                              color: '#888787',
                              fontSize: '12px',
                            }}
                          />
                          <Typography
                            sx={{
                              font: 'normal normal normal 18px/23px Source Sans Pro',
                              letterSpacing: '0px',
                              color: '#888787',
                              opacity: 1,
                              fontSize: '12px',
                            }}>
                            {job?.sector.name}
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              }
              titleTypographyProps={{ margin: '-20px 0px -20px -15px' }}
            />
          </Card>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default AcceptModal;
