/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedLesson } from '../models/AdvancedLesson';
import type { AssociatedLesson } from '../models/AssociatedLesson';
import type { BaseBundleDetailLessonAssociation } from '../models/BaseBundleDetailLessonAssociation';
import type { RegularBundleDetail } from '../models/RegularBundleDetail';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BundlesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Bundle
     * TODO refactor this to use the bundle_service and add the correct permissions
     *
     * <br>
     * Retrieve a bundle by its ID.
     *
     * <br>
     * Args:
     *
     * - bundle_id: int
     *
     * <br>
     * Returns:
     *
     * - `RegularBundleDetail`
     *
     * <br>
     * HTTPExceptions:
     *
     * - 404: Bundle not found
     *
     * - 403: Not enough permissions to view bundles
     * @param bundleId
     * @returns RegularBundleDetail Successful Response
     * @throws ApiError
     */
    public getBundlesGetBundle(
        bundleId: number,
    ): CancelablePromise<RegularBundleDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bundles/bundle/{bundle_id}',
            path: {
                'bundle_id': bundleId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Bundle
     * <br>
     * Update a bundle by its ID.
     *
     * <br>
     * Args:
     *
     * - bundle_id: `int`
     *
     * - updated_bundle: `BundleDetailEdit`
     *
     * - total_hours: `Optional[float]`
     *
     * - remaining_hours: `Optional[float]`
     *
     * - start_date: `Optional[datetime]`
     *
     * - end_date: `Optional[datetime]`
     *
     * <br>
     * Returns: The updated bundle that was edited
     *
     * - `RegularBundleDetail`
     *
     * <br>
     * HTTPExceptions:
     *
     * - 404: Bundle not found
     *
     * - 403: Not enough permissions to edit bundles
     * @param bundleId
     * @param endDate
     * @param totalHours
     * @returns RegularBundleDetail Successful Response
     * @throws ApiError
     */
    public patchBundlesUpdateBundle(
        bundleId: number,
        endDate?: (string | null),
        totalHours?: (number | null),
    ): CancelablePromise<RegularBundleDetail> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/bundles/bundle/{bundle_id}',
            path: {
                'bundle_id': bundleId,
            },
            query: {
                'end_date': endDate,
                'total_hours': totalHours,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Bundles
     * @param guardianId
     * @returns RegularBundleDetail Successful Response
     * @throws ApiError
     */
    public getBundlesGetBundles(
        guardianId: number,
    ): CancelablePromise<Array<RegularBundleDetail>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bundles/bundles/{guardian_id}',
            path: {
                'guardian_id': guardianId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Bundle Lessons
     * @param bundleId
     * @returns AdvancedLesson Successful Response
     * @throws ApiError
     */
    public getBundlesGetBundleLessons(
        bundleId: number,
    ): CancelablePromise<Array<AdvancedLesson>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bundles/bundle/{bundle_id}/lessons',
            path: {
                'bundle_id': bundleId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Associated Lessons
     * <br>
     * Retrieve all lessons associated with a bundle by its ID.
     *
     * <br>
     * Args:
     *
     * - bundle_id: `int`
     *
     * <br>
     * Returns:
     *
     * - `List[BaseLesson]`
     *
     * <br>
     * HTTPExceptions:
     *
     * - 404: Bundle not found
     *
     * - 403: Not enough permissions to view bundles
     * @param bundleId
     * @returns AssociatedLesson Successful Response
     * @throws ApiError
     */
    public getBundlesGetAssociatedLessons(
        bundleId: number,
    ): CancelablePromise<Array<AssociatedLesson>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/bundles/bundle/{bundle_id}/associated_lessons',
            path: {
                'bundle_id': bundleId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Associate Lesson
     * <br>
     * Associate a lesson with the next bundle.
     *
     * <br>
     * Args:
     *
     * - lesson_id: `int`
     *
     * <br>
     * Returns:
     *
     * - `BaseBundleDetailLessonAssociation`
     *
     * <br>
     * HTTPExceptions:
     *
     * - 404: Lesson not found
     *
     * - 403: Not enough permissions to edit bundles
     * @param lessonId
     * @returns BaseBundleDetailLessonAssociation Successful Response
     * @throws ApiError
     */
    public postBundlesAssociateLesson(
        lessonId: number,
    ): CancelablePromise<Array<BaseBundleDetailLessonAssociation>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/bundles/bundle/associate_lesson/{lesson_id}',
            path: {
                'lesson_id': lessonId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
