import React, { useState } from 'react';
import './Summary.css';

import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import { Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import TagManager from 'react-gtm-module';

import { appClient } from 'App';
import { BaseDiscipline } from 'assets';
import { Button } from 'components/Elements/Forms/Inputs';
import AvailabilityGrid_V2 from 'components/Functional/Availability/AvailabilityGrid_V2';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import { BEUPackages, OBPackages, SWPackages, VBPackages } from './Models/packages';
import { IBundleFlowPageProps, IPackage } from '..';
import SummaryDesktop from '../Components/SummaryDesktop';

const SummaryPage: React.FunctionComponent<IBundleFlowPageProps> = ({ bundle, setBundle }) => {
  const label = useLabel();
  const currentWidth = useWidth();
  const [vakken, setVakken] = useState<BaseDiscipline[]>();
  const [packages, setPackages] = useState<IPackage[]>();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  React.useEffect(() => {
    switch (label?.name.toLowerCase()) {
      case 'studyworks':
        setPhone('030-7440538');
        setEmail('info@studyworks.nl');
        break;
      case 'online-bijles.com':
        setPhone('06-40344017');
        setEmail('info@online-bijles.com');
        break;
      case 'vlakbijles':
        setPhone('088-1155630');
        setEmail('vlakbijles@studyworks.nl');
        break;
      case 'online-examentraining.com':
        setPhone('06-40213409');
        setEmail('info@online-examentraining.com');
        break;
      case 'bijles.eu':
        setPhone('06-33818964');
        setEmail('info@bijleseu.nl');
        break;
      default:
        setPhone('030 744 05 38');
        setEmail('info@studyworks.nl');
        break;
    }
  }, [phone, email]);
  const tagManagerArgs = {
    dataLayer: {
      page: 'summary',
      data: bundle,
    },
    dataLayerName: 'PageDataLayer',
  };

  TagManager.dataLayer(tagManagerArgs);

  const handleNext = async (event: any) => {
    event.detail();
  };

  React.useEffect(() => {
    /**TODO: white label packages */
    if (!packages) {
      if (label?.name) {
        switch (label.name.toLowerCase()) {
          case 'studyworks':
            setPackages(SWPackages);
            break;
          case 'bijles.eu':
            setPackages(BEUPackages);
            break;
          case 'online-bijles.com':
            setPackages(OBPackages);
            break;
          case 'vlakbijles':
            setPackages(VBPackages);
            break;
        }
      } else {
        setPackages(SWPackages);
      }
    }
  }, [packages]);

  React.useEffect(() => {
    if (!vakken) {
      appClient.lessons.getLessonsViewDisciplines().then(r => {
        if (r !== null) {
          setVakken(r);
        }
      });
    }
  }, [vakken]);

  React.useEffect(() => {
    document.addEventListener('onNext', handleNext);

    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [bundle]);

  const getDisiplinesNames = (disipline: number): string => {
    let name = '';
    const vak = vakken?.find(vak => vak.id === disipline);
    if (vak) {
      name = vak?.name;
    }

    return name;
  };

  type LIProps = {
    a: string;
    b: string;
  };
  const ListItemSUMM = ({ a, b }: LIProps): JSX.Element => {
    return (
      <>
        <Grid item xs={12}>
          <Typography fontSize={14} fontWeight="bold">
            {a}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '-7px' }}>
          <Typography fontSize={13}>{b}</Typography>
        </Grid>
      </>
    );
  };

  const splitMail = (value: string): string => {
    const split = value.split('@');

    return split[0] + '@' + split[1];
  };

  return currentWidth === 'xs' ? (
    <Box sx={{ margin: '0px 20px' }} display="flex">
      <Grid container spacing={2.5}>
        <Grid
          item
          xs={12}
          sx={{
            mt: '20px',
            mb: '20px',
          }}>
          <Typography fontWeight={'bold'} fontSize={24} mb={'10px'}>
            Bedankt voor uw aanmelding!
          </Typography>
          <Typography>
            Uw aanmelding is compleet. U krijgt zo een bevestigsmail met daarin een uitnodigingslink
            voor onze online omgeving. Wij gaan zo snel mogenlijk voor u aan de slag. Indien u nog
            vragen heeft kunt u ons altijd contact met ons opnemen.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '-5px' }}>
          <Grid
            container
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '15px',
              padding: '5px',
            }}>
            <Grid item xs={12} sx={{ padding: '10px 15px 0px 15px' }}>
              <Typography fontWeight={'bold'} fontSize={24}>
                Gegevens van uw kind
              </Typography>
            </Grid>
            <Grid container xs={12} sx={{ padding: '10px 15px 10px 15px' }}>
              <Grid item xs={5}>
                <ListItemSUMM
                  a={'Voornaam: '}
                  b={`${bundle.data_bundle.students[0].first_name} `}
                />
                <ListItemSUMM
                  a={'Achternaam: '}
                  b={`${bundle.data_bundle.students[0].last_name}`}
                />
                <ListItemSUMM
                  a={'Geboortedatum: '}
                  b={bundle.data_bundle.students[0].date_of_birth}
                />
                <ListItemSUMM a={'Geslacht: '} b={bundle.data_bundle.students[0].gender} />
                <ListItemSUMM
                  a={'Locatievoorkeur: '}
                  b={bundle.data_bundle.online_tutoring ? 'Online' : 'Aan huis'}
                />
              </Grid>
              <Grid item xs={1} justifyContent="center" alignItems="center">
                <Divider
                  sx={{
                    width: '10px',
                    height: '80%',
                  }}
                  orientation="vertical"
                />
              </Grid>
              <Grid item xs={6}>
                <ListItemSUMM a={'Niveau: '} b={bundle.data_bundle.students[0].school_level} />
                <ListItemSUMM a={'Leerjaar: '} b={bundle.data_bundle.students[0].school_year} />
                <ListItemSUMM
                  a={'Vakken: '}
                  b={bundle.data_bundle.students[0].disciplines
                    .map((discipline: number) => getDisiplinesNames(discipline))
                    .join(', ')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '15px',
              padding: '5px',
            }}>
            <Grid item xs={12} sx={{ padding: '10px 15px 0px 15px' }}>
              <Typography fontWeight={'bold'} fontSize={24}>
                Uw gegevens
              </Typography>
            </Grid>
            <Grid container sx={{ padding: '10px 15px 10px 15px' }}>
              <Grid item xs={5}>
                <ListItemSUMM a={'Voornaam: '} b={`${bundle.data_bundle.first_name}`} />
                <ListItemSUMM a={'Achternaam: '} b={` ${bundle.data_bundle.last_name}`} />
                <ListItemSUMM a={'Telefoonnummer: '} b={bundle.data_bundle.phone_number} />
                <ListItemSUMM a={'E-mail:'} b={splitMail(bundle.data_bundle.email_address)} />
              </Grid>
              <Grid item xs={1} justifyContent="center" alignItems="center">
                <Divider
                  sx={{
                    width: '10px',
                    height: '80%',
                  }}
                  orientation="vertical"
                />
              </Grid>
              <Grid item xs={6}>
                {bundle.data_bundle.payment_method == 'incasso' && (
                  <ListItemSUMM a={'IBAN: '} b={bundle.data_bundle.iban} />
                )}
                <ListItemSUMM
                  a={'Pakket: '}
                  b={
                    bundle.data_bundle.package == 'none' ? 'Losse les' : bundle.data_bundle.package
                  }
                />
                <ListItemSUMM a={'Termijn: '} b={bundle.data_bundle.term} />
                <ListItemSUMM
                  a={'Betaalmethode: '}
                  b={
                    bundle.data_bundle.payment_method == 'incasso'
                      ? 'Automatische incasso'
                      : 'Losse factuur'
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '15px',
              padding: '5px',
            }}>
            <Grid item xs={12} sx={{ padding: '10px 15px 10px 15px' }}>
              <Typography fontWeight={'bold'} fontSize={24}>
                Beschikbaarheid
              </Typography>
            </Grid>
            {/* <Grid container xs={12} sx={{ mt:"-20px" }} justifyContent="center" alignItems="center">
                <AvailabilityGrid
                  user_locations={[]}
                  availability={bundle.data_bundle.students[0].availability ? bundle.data_bundle.students[0].availability : []}
                  onAdd={() => { }}
                  onDelete={() => { }}
                  disabled={true}
                />
              </Grid> */}

            <Grid
              container
              xs={12}
              sx={{ mt: '-20px' }}
              justifyContent="center"
              alignItems="center">
              <AvailabilityGrid_V2
                availabilities={
                  bundle.data_bundle.students[0].availability
                    ? bundle.data_bundle.students[0].availability
                    : []
                }
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: currentWidth === 'xs' ? '10px' : 'unset' }}>
          <Typography fontWeight={'bold'}>Nog vragen?</Typography>
          <Typography />
          <Grid container>
            <Grid item xs={6}>
              <Button
                variant="text"
                startIcon={<PhoneIcon sx={{ fontSize: 12 }} />}
                href={'tel:' + phone}
                sx={{ backgroundColor: '#f5f5f5' }}>
                <Typography fontSize={12}>{phone}</Typography>
              </Button>
            </Grid>

            <Grid container xs={6}>
              <Button
                variant="text"
                startIcon={<MailIcon sx={{ fontSize: 12 }} />}
                href={'mailto:' + email}
                sx={{ backgroundColor: '#f5f5f5' }}>
                <Typography fontSize={12}>{email}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <SummaryDesktop
      bundle={bundle}
      setBundle={setBundle}
      vakken={vakken}
      phone={phone}
      email={email}
    />
  );
};

export default SummaryPage;
