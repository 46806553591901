import * as React from 'react';
import { useEffect, useState } from 'react';

import { CardActions, CardContent, CardHeader, LinearProgress, Modal, Paper } from '@mui/material';
import QRCode from 'qrcode.react';

import { appClient } from 'App';
import { UserUri } from 'assets';
import { Button, TextField } from 'components/Elements/Forms/Inputs';

interface TwoFPSetupProps {
  status: string;
  onClose: () => void;
}

const TwoFPSetup: React.FunctionComponent<TwoFPSetupProps> = ({ status }) => {
  const [qrCode, setQrCode] = useState<UserUri>();

  useEffect(() => {
    if (status === 'TwoFPSetUp') {
      appClient.auth.getAuthGetSecret().then(async r => {
        await appClient.auth.patchAuthGetSecret(r);
        appClient.auth.postAuthGetQrUri(r).then(setQrCode);
      });
    }
  }, [status]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    const formdata = new FormData(e.currentTarget);
    const response = await appClient.auth.postAuthLoginWithMfa({
      mfa: formdata.get('mfa_code') as string,
    });

    const { access_token } = response;
    const jwt = `Bearer ${access_token}`;
    localStorage.setItem('jwt', jwt);
    // props.history.push("/");
    window.location.reload();
  };

  return (
    <Modal open={status === 'TwoFPSetUp'}>
      <Paper
        component={'form'}
        onSubmit={handleSubmit}
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
        }}>
        <CardHeader title="2FP instellen" />
        <CardContent
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}>
          {qrCode ? (
            <QRCode
              value={qrCode.uri}
              style={{
                width: '60%',
                height: '60%',
              }}
            />
          ) : (
            <LinearProgress />
          )}
        </CardContent>
        <CardActions>
          {/* <FormControlLabel control={<Checkbox value={checked} onChange={(_e, checked)=> setChecked(checked)}/>} label='Ik heb de QR code gescanned met een authenticator app' /> */}
          <TextField fullWidth label="Tweefactorauthenticatie code " name="mfa_code" />
          <Button
            variant="contained"
            sx={{
              ml: '8px',
              p: 'auto 32px',
            }}
            type="submit">
            Accepteren
          </Button>
        </CardActions>
      </Paper>
    </Modal>
  );
};

export default TwoFPSetup;
