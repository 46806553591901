import React from 'react';

import { Container, Grid, Link, Typography } from '@mui/material';

import { BaseStub } from 'assets';
import { Button } from 'components/Elements/Forms/Inputs';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import { IExtendedBundleIn } from '..';

interface FooterButtonsProps {
  data: any;
  setData: (stub: BaseStub) => void;
  bundle?: IExtendedBundleIn;
  setBundle?: (bundle: IExtendedBundleIn) => void;
  currentIndex: number;
  pages: any;
  handleNext: () => void;
  handlePref: () => void;
  setSkipAdvice: (advice: boolean) => void;
  setSkipPayment: (payment: boolean) => void;
  payLater: boolean;
  setPayLater: (pay: boolean) => void;
}

const FooterButtons: React.FunctionComponent<FooterButtonsProps> = ({
  data,
  setData,
  bundle,
  setBundle,
  currentIndex,
  handleNext,
  handlePref,
  setSkipAdvice,
  setSkipPayment,
  setPayLater,
}) => {
  const currentWidth = useWidth();
  const label = useLabel();

  const handleSkipAdvice = () => {
    setSkipAdvice(true);
    const tempData = { ...data };
    tempData.data_bundle.package = 'skip_advice';
    tempData.data_bundle.skip_advice = true;
    setData(tempData);

    if (setBundle && bundle) {
      const tempBundle = { ...bundle };
      tempBundle.data_bundle.package = 'skip_advice';
      tempBundle.data_bundle.skip_advice = true;
      setBundle(tempBundle);
    }

    handleNext();
  };

  const handleSkipPackage = () => {
    if (data) {
      const tempData = { ...data };
      tempData.data_bundle.package = 'Geen pakket geselecteerd';
      tempData.data_bundle.skip_package = true;
      setData(tempData);
    }

    if (setBundle && bundle) {
      const tempBundle = { ...bundle };
      tempBundle.data_bundle.package = 'Geen pakket geselecteerd';
      tempBundle.data_bundle.skip_package = true;
      setBundle(tempBundle);
    }

    handleNext();
  };

  const handleSkipPayment = () => {
    setSkipPayment(true);
    setPayLater(true);
    /**TODO: set payment-> ik betaal mijn pakket later */
    handleNext();
  };

  const handleSkipEvent = () => {
    switch (currentIndex) {
      case 3:
        handleSkipAdvice();
        break;
      case 4:
        handleSkipPackage();
        break;
      case 5:
        handleSkipPayment();
        break;
    }
  };

  const handlePrefClick = () => {
    switch (currentIndex) {
      case 3:
        handlePref();
        break;
      case 4:
        if (bundle && setBundle) {
          const tempData = { ...bundle };
          tempData.data_bundle.skip_advice = false;
          // tempData.data_bundle.package = "";
          setBundle(tempData);
        }
        handlePref();
        break;
      case 5:
        handlePref();
        break;
      default:
        handlePref();
    }
  };

  return (
    <Container maxWidth={'md'} sx={{}}>
      <Grid
        container
        sx={{
          mb: '35px',
          padding: currentWidth === 'xs' ? 'unset' : '0px 15px',
        }}>
        <Grid item xs={4.5}>
          {currentIndex != 3 && currentIndex != 0 && (
            <Button
              variant="outlined"
              fullWidth={currentWidth === 'xs' ? true : false}
              onClick={() => handlePrefClick()}
              sx={{
                height: '40px',
                minWidth: currentWidth === 'xs' ? 'unset' : '150px',
              }}>
              <Typography sx={{ fontSize: currentWidth === 'xs' ? '16px' : '18px' }}>
                Vorige
              </Typography>
            </Button>
          )}
        </Grid>

        <Grid item xs={4.5} container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            fullWidth={currentWidth === 'xs' ? true : false}
            onClick={() => handleNext()}
            sx={{
              height: '40px',
              minWidth: currentWidth === 'xs' ? 'unset' : '150px',
            }}>
            <Typography sx={{ fontSize: currentWidth === 'xs' ? '16px' : '18px' }}>
              {currentIndex == 4 ? 'Afronden' : currentIndex === 3 ? 'Geef advies' : 'Volgende'}
            </Typography>
          </Button>
        </Grid>
        {currentIndex == 3 && (
          <Grid
            container
            xs={12}
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              mt: '10px',
              marginBottom: currentWidth === 'xs' ? '-5px' : 'unset',
            }}>
            <Link
              // onClick={currentIndex === 3 ? () => handleSkipPayment() : () => handleSkipPackage()}
              onClick={handleSkipEvent}
              sx={{
                fontSize: '12px',
                color: label?.main_color,
                textDecorationColor: label?.main_color,
              }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: label?.main_color,
                  textDecorationColor: label?.main_color,
                }}>
                {currentIndex == 3 ? 'Sla deze stap over' : 'Kies pakket later'}
              </Typography>
            </Link>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default FooterButtons;
