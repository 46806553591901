import React, { FormEvent, useState } from 'react';

import { LinearProgress } from '@mui/material';
import { RouteComponentProps, useParams } from 'react-router-dom';

import { appClient } from 'App';
import { PasswordResetIn } from 'assets';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import ChangePasswordDesktop from './Desktop/ChangePasswordDesktop';
import ChangePasswordMobile from './Mobile/ChangePasswordMobile';

export interface ChangePasswordProps extends RouteComponentProps {}

const ChangePassword: React.FunctionComponent<ChangePasswordProps> = () => {
  const currentWidth = useWidth();
  const label = useLabel();
  const [password, setPassword] = useState('');
  const [passwordComfirm, setPasswordComfirm] = useState('');
  const [state, setState] = useState('');
  const { token } = useParams<{ token: string }>();

  const handleSubmit = (eventL: FormEvent<HTMLFormElement>) => {
    eventL.preventDefault();
    setState('loading');

    if (password === passwordComfirm) {
      const data: PasswordResetIn = { password: password };

      appClient.auth
        .postAuthResetUserPassword(token, data)
        .then(res => {
          if (res.status === '200') {
            setState('success');
            localStorage.clear();
          } else if (res.status === 'ok') {
            setState('success');
            localStorage.clear();
          } else {
            setState('error');

            return;
          }
        })
        .catch(() => {
          setState('error');
        });
    }
  };

  return (
    <>
      {label ? (
        currentWidth === 'xs' ? (
          <ChangePasswordMobile
            handleSubmit={handleSubmit}
            state={state}
            password={password}
            passwordComfirm={passwordComfirm}
            setPassword={setPassword}
            setPasswordComfirm={setPasswordComfirm}
          />
        ) : (
          <ChangePasswordDesktop
            handleSubmit={handleSubmit}
            state={state}
            password={password}
            passwordComfirm={passwordComfirm}
            setPassword={setPassword}
            setPasswordComfirm={setPasswordComfirm}
          />
        )
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default ChangePassword;
