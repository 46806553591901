/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasePipe } from '../models/BasePipe';
import type { BaseStage } from '../models/BaseStage';
import type { CRMPipeCoaches } from '../models/CRMPipeCoaches';
import type { CRMPipeStubs } from '../models/CRMPipeStubs';
import type { CRMPipeStudents } from '../models/CRMPipeStudents';
import type { PipeEdit } from '../models/PipeEdit';
import type { PipeIn } from '../models/PipeIn';
import type { PipeQuery } from '../models/PipeQuery';
import type { RegularPipe } from '../models/RegularPipe';
import type { StageEdit } from '../models/StageEdit';
import type { StageIn } from '../models/StageIn';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PipesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * View Coach Stages
     * @returns BaseStage Successful Response
     * @throws ApiError
     */
    public getPipesViewCoachStages(): CancelablePromise<Array<BaseStage>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pipes/stages/coach',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * View Guardian Stages
     * @returns BaseStage Successful Response
     * @throws ApiError
     */
    public getPipesViewGuardianStages(): CancelablePromise<Array<BaseStage>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pipes/stages/guardian',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * View Stub Stages
     * @returns BaseStage Successful Response
     * @throws ApiError
     */
    public getPipesViewStubStages(): CancelablePromise<Array<BaseStage>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pipes/stages/stub',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * View Pipe
     * @param pipeId
     * @returns BasePipe Successful Response
     * @throws ApiError
     */
    public getPipesViewPipe(
        pipeId: number,
    ): CancelablePromise<BasePipe> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pipes/view/{pipe_id}',
            path: {
                'pipe_id': pipeId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Coaches
     * @param pipeId
     * @param requestBody
     * @returns CRMPipeCoaches Successful Response
     * @throws ApiError
     */
    public postPipesViewCoaches(
        pipeId: number,
        requestBody?: (PipeQuery | null),
    ): CancelablePromise<CRMPipeCoaches> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pipes/view/{pipe_id}/coaches',
            path: {
                'pipe_id': pipeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Students
     * @param pipeId
     * @param requestBody
     * @returns CRMPipeStudents Successful Response
     * @throws ApiError
     */
    public postPipesViewStudents(
        pipeId: number,
        requestBody?: (PipeQuery | null),
    ): CancelablePromise<CRMPipeStudents> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pipes/view/{pipe_id}/students',
            path: {
                'pipe_id': pipeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * View Stubs
     * @param pipeId
     * @param requestBody
     * @returns CRMPipeStubs Successful Response
     * @throws ApiError
     */
    public postPipesViewStubs(
        pipeId: number,
        requestBody?: (PipeQuery | null),
    ): CancelablePromise<CRMPipeStubs> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pipes/view/{pipe_id}/stubs',
            path: {
                'pipe_id': pipeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Pipe
     * @param pipeId
     * @param requestBody
     * @returns BasePipe Successful Response
     * @throws ApiError
     */
    public patchPipesEditPipe(
        pipeId: number,
        requestBody: PipeEdit,
    ): CancelablePromise<BasePipe> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/pipes/edit/{pipe_id}',
            path: {
                'pipe_id': pipeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Pipe
     * @param stageId
     * @param requestBody
     * @returns BasePipe Successful Response
     * @throws ApiError
     */
    public postPipesCreatePipe(
        stageId: number,
        requestBody: PipeIn,
    ): CancelablePromise<BasePipe> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pipes/create/stage/{stage_id}',
            path: {
                'stage_id': stageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Stage
     * @param requestBody
     * @returns BaseStage Successful Response
     * @throws ApiError
     */
    public postPipesCreateStage(
        requestBody: StageIn,
    ): CancelablePromise<BaseStage> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pipes/create/stage',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Stage
     * @param stageId
     * @param requestBody
     * @returns BaseStage Successful Response
     * @throws ApiError
     */
    public patchPipesEditStage(
        stageId: number,
        requestBody: StageEdit,
    ): CancelablePromise<BaseStage> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/pipes/edit/stage/{stage_id}',
            path: {
                'stage_id': stageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Pipe
     * @param newPipeId
     * @param pipeId
     * @returns RegularPipe Successful Response
     * @throws ApiError
     */
    public patchPipesChangePipe(
        newPipeId: number,
        pipeId: number,
    ): CancelablePromise<RegularPipe> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/pipes/edit/pipe/{pipe_id}/change_pipe/{new_pipe_id}',
            path: {
                'new_pipe_id': newPipeId,
                'pipe_id': pipeId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
