/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_add_image_to_product_products_product__product_id__add_image_patch } from '../models/Body_add_image_to_product_products_product__product_id__add_image_patch';
import type { FullProduct } from '../models/FullProduct';
import type { ProductIn } from '../models/ProductIn';
import type { RegularProduct } from '../models/RegularProduct';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Product
     * TODO refactor this to use the product_service and add the correct permissions
     *
     * <br>
     * Retrieve a product by its ID.
     *
     * <br>
     * Args:
     *
     * - product_id: int
     *
     * Returns:
     *
     * - `RegularProduct`
     *
     * <br>
     * HTTPExceptions:
     *
     * - 404: Product not found
     *
     * - 403: Not enough permissions to view products
     * @param productId
     * @returns RegularProduct Successful Response
     * @throws ApiError
     */
    public getProductsGetProduct(
        productId: number,
    ): CancelablePromise<RegularProduct> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products/product/{product_id}',
            path: {
                'product_id': productId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Product
     * @param productId
     * @param requestBody
     * @returns FullProduct Successful Response
     * @throws ApiError
     */
    public patchProductsUpdateProduct(
        productId: number,
        requestBody: ProductIn,
    ): CancelablePromise<FullProduct> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products/product/{product_id}',
            path: {
                'product_id': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Products
     * @param isActive
     * @param labelId
     * @returns RegularProduct Successful Response
     * @throws ApiError
     */
    public getProductsGetProducts(
        isActive?: (boolean | null),
        labelId?: (number | null),
    ): CancelablePromise<Array<RegularProduct>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products/products',
            query: {
                'is_active': isActive,
                'label_id': labelId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Public Products
     * @param labelId
     * @returns RegularProduct Successful Response
     * @throws ApiError
     */
    public getProductsGetPublicProducts(
        labelId: number,
    ): CancelablePromise<Array<RegularProduct>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products/products/public',
            query: {
                'label_id': labelId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Products Advanced
     * @returns FullProduct Successful Response
     * @throws ApiError
     */
    public getProductsGetProductsAdvanced(): CancelablePromise<Array<FullProduct>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/products/products/advanced',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Create Product
     * @param requestBody
     * @returns RegularProduct Successful Response
     * @throws ApiError
     */
    public postProductsCreateProduct(
        requestBody: ProductIn,
    ): CancelablePromise<RegularProduct> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/products/product',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Image To Product
     * @param productId
     * @param formData
     * @returns RegularProduct Successful Response
     * @throws ApiError
     */
    public patchProductsAddImageToProduct(
        productId: number,
        formData: Body_add_image_to_product_products_product__product_id__add_image_patch,
    ): CancelablePromise<RegularProduct> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/products/product/{product_id}/add_image',
            path: {
                'product_id': productId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
