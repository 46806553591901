import React, { useContext, useState } from 'react';

interface IGuardianProviderProps {
  children: React.ReactNode;
}

interface IGuardianContexProps {
  guardianId: number | undefined;
  SetGuardianId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const GuardianContext = React.createContext<IGuardianContexProps | null>(null);

/* THis product hook is used to get product between pages */
export const GuardianContextProvider: React.FC<IGuardianProviderProps> = ({ children }) => {
  const [guardianId, SetGuardianId] = useState<number>();

  return (
    <GuardianContext.Provider
      value={{
        guardianId,
        SetGuardianId,
      }}>
      {children}
    </GuardianContext.Provider>
  );
};

export const useGuardianContext = () => {
  const context = useContext(GuardianContext);
  if (!context) {
    throw new Error('useGuardianContext must be used within a GuardianContextProvider');
  }

  return context;
};

export default useGuardianContext;
