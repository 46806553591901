import React from 'react';

import { CardHeader, Grid } from '@mui/material';

import { EnumOrderType } from 'assets';
import Factuur from 'views/PortalShared/Payment/Components/PaymentMethodCards/Factuur';
import Incasso from 'views/PortalShared/Payment/Components/PaymentMethodCards/Incasso';

interface IPamentModuleProps {
  selectedPaymentMethod: EnumOrderType;
  setSelectedPaymentMethod: (value: EnumOrderType) => void;
}
const PaymentModule: React.FC<IPamentModuleProps> = ({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CardHeader
          title={'Kies uw betalingsvoorkeur'}
          sx={{
            paddingBottom: 'unset',
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Incasso
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Factuur
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentModule;
