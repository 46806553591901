import React, { useState } from 'react';

import { Avatar, Box, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
// import { useSnackbar } from 'notistack';

import { appClient } from 'App';
import { RegularProduct } from 'assets';
import fiene from 'assets/img/brand/FieneVierkant.png';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import { IExtendedBundleIn, IFlowPageProps } from '..';
import PackageCardMobile from '../Components/PackageCardMobile';

export interface IPersonalAdvicePageProps extends IFlowPageProps {
  bundle: IExtendedBundleIn;
  setBundle: (bundle: IExtendedBundleIn) => void;
  SetPackageModalOpen: (open: boolean) => void;
}

export interface RegularProductExtends extends RegularProduct {
  selected?: boolean;
}

const PersonalAdvice: React.FunctionComponent<IPersonalAdvicePageProps> = ({
  bundle,
  setBundle,
}) => {
  const label = useLabel();
  // const { enqueueSnackbar } = useSnackbar();
  const [score, setScore] = useState<number>(0);
  const currentWidth = useWidth();

  const [selectedProducts, setSelectedProducts] = useState<RegularProduct[]>([]);

  const [products, setProducts] = useState<RegularProductExtends[]>();
  const [skipAdvice, setSkipAdvice] = useState<boolean>(false);
  const [advicedPackage, setAdvicedPackage] = useState<RegularProduct>();
  const [loading, setLoading] = useState<boolean>(true);

  React.useEffect(() => {
    const data = bundle;
    data.data_bundle.products = selectedProducts;
    if (selectedProducts[0]) {
      bundle.data_bundle.package = selectedProducts[0].name;
    } else {
      bundle.data_bundle.package = selectedProducts;
    }
    setBundle(data);
  }, [selectedProducts]);

  React.useEffect(() => {
    setScore(bundle.data_bundle.students[0].score);
  });

  React.useEffect(() => {
    setSkipAdvice(bundle.data_bundle.skip_advice);
  }, [bundle.data_bundle.package]);

  React.useEffect(() => {
    if (label) {
      appClient.products.getProductsGetPublicProducts(label?.id).then((r: RegularProduct[]) => {
        setProducts(r.filter((prod: RegularProduct) => prod.is_active === true));
      });
    }
  }, []);

  //Calculate the adviced product based on libele model score
  React.useEffect(() => {
    if (products) {
      const normalePackages: RegularProductExtends[] = [];

      let isAdviced: RegularProductExtends;
      if (products) {
        let div: number | undefined = undefined;
        products.forEach(product => {
          if (product.bundle_details) {
            if (!isAdviced) {
              isAdviced = product;
            }

            //substract the score from the hours and abstract is to exclude minus values
            const num = Math.abs(product.bundle_details?.total_hours - score);

            if (div === undefined) {
              div = num;
            }

            // check if the number is lower than the previous number to set the product
            if (num <= div) {
              div = num;
              setAdvicedPackage(product);
            }
            normalePackages.push(product);
          }
        });
      }
    }
  }, [products, score]);

  React.useEffect(() => {
    if (loading) {
      setTimeout(function () {
        setLoading(false);
      }, 3000);
    }
  }, [loading]);

  const handleNext = async (event: any) => {
    // if (bundle.data_bundle.products.length === 0) {
    //   enqueueSnackbar('Selecteer a.u.b. een pakket', { variant: 'error' });

    //   return;
    // }

    event.detail();
  };

  React.useEffect(() => {
    document.addEventListener('onNext', handleNext);

    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [bundle]);

  const showPackageName = (pname: string): string => {
    let name = '';
    switch (pname) {
      case 'Strippenkaart klein':
        name = 'kleine Strippenkaart';
        break;
      case 'Strippenkaart groot':
        name = 'grote Strippenkaart';
        break;
      default:
        name = pname;
    }

    return name;
  };

  const getSelected = (p: RegularProduct): boolean => {
    let selected: boolean = false;
    const found = selectedProducts.find(product => product.id === p.id);
    if (found) {
      selected = true;
    } else {
      selected = false;
    }

    return selected;
  };

  return (
    <div className="advice">
      {!skipAdvice && loading ? (
        <Box
          sx={{
            marginLeft: '25px',
            marginRight: '25px',
            height: '70vh',
            pt: '16px',
          }}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignContent="center">
              <CircularProgress />
              <Grid item xs={12}>
                <Typography sx={{ textAlign: 'center' }}>Berekenen van pakketten</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Card elevation={0} sx={{ marginTop: '10px' }}>
            <CardContent>
              <Grid container>
                <Grid item xs={skipAdvice ? 12 : 9} md={skipAdvice ? 12 : 10}>
                  <Grid container>
                    <Grid item xs={skipAdvice ? 12 : 11}>
                      <Grid
                        container
                        sx={{
                          backgroundColor: skipAdvice ? 'unset' : '#f5f5f5',
                          borderRadius: '15px',
                        }}>
                        {!skipAdvice && (
                          <Grid
                            item
                            xs={12}
                            sx={{ padding: currentWidth === 'xs' ? '10px' : '5px 10px 5px 10px' }}>
                            <Typography
                              sx={{
                                fontSize: currentWidth === 'xs' ? '14px' : '18px',
                              }}>
                              Wat fijn dat ik u mag helpen met uw pakketkeuze! Op basis van uw
                              ingevulde antwoorden raad ik u aan om te kiezen voor
                              {advicedPackage && advicedPackage.name.includes('Strippenkaart')
                                ? ' een '
                                : ' het '}
                              {loading ? (
                                ''
                              ) : advicedPackage ? (
                                <span style={{ fontWeight: '600' }}>
                                  {showPackageName(advicedPackage.name) + '.'}
                                </span>
                              ) : (
                                'strippenkaart'
                              )}
                              <br />
                            </Typography>
                          </Grid>
                        )}

                        {!skipAdvice ? (
                          <Grid
                            item
                            xs={12}
                            sx={{ padding: currentWidth === 'xs' ? '10px' : '5px 10px 5px 10px' }}>
                            {/* <Typography sx={{ fontSize: currentWidth === 'xs' ? '14px' : '18px' }}>
                              Wenst u te betalen in termijnen? Kies dan voor een van onze voordelige
                              pakket in combinatie met een automatische incasso.
                            </Typography> */}
                            <Typography sx={{ fontSize: currentWidth === 'xs' ? '14px' : '18px' }}>
                              Hieronder kunt u de pakketten zien die wij aanbieden. Deze pakketten
                              kunnen na het afronden van het aanmeldproces in het portaal
                              aangeschaft worden.
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            sx={{ padding: currentWidth === 'xs' ? '10px' : '5px 10px 5px 0px' }}>
                            <Typography sx={{ fontSize: currentWidth === 'xs' ? '14px' : '18px' }}>
                              Hieronder kunt u de pakketten zien die wij aanbieden. Bij een groter
                              bijlespakket ontvangt u meer korting op de uurprijs. Wenst u te
                              betalen in termijnen? Kies dan voor onze voordelige pakketten in
                              combinatie met een automatische incasso.
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {!skipAdvice && (
                      <Grid item xs={1}>
                        <Box marginTop={'10px'}>
                          <Box
                            sx={{
                              position: 'absolute',
                              width: '60px',
                              height: '40px',
                              backgroundColor: '#f5f5f5',
                              ml: '-10px',
                              borderBottomLeftRadius: '16px',
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              width: '60px',
                              height: '40px',
                              backgroundColor: 'white',
                              mr: '30px',
                              borderBottomLeftRadius: '25px',
                            }}
                          />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {!skipAdvice && (
                  <Grid item xs={3} md={2}>
                    <Avatar
                      sx={{
                        border: 'solid 2px',
                        backgroundColor: '#f5f5f5',
                        color: label?.main_color,
                        width: currentWidth === 'xs' ? 80 : 120,
                        height: currentWidth === 'xs' ? 80 : 120,
                      }}>
                      <Avatar
                        src={fiene}
                        alt="fiene"
                        sx={{
                          width: currentWidth === 'xs' ? 80 : 120,
                          height: currentWidth === 'xs' ? 80 : 120,
                        }}
                      />
                    </Avatar>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>

          <Card elevation={0} sx={{ marginTop: currentWidth === 'xs' ? '-40px' : '-60px' }}>
            <CardContent>
              <Grid container>
                <Grid item xs={12} sx={{ margin: '30px 0px 10px 0px' }}>
                  <Typography fontWeight={'bold'}>
                    Hieronder kunt u de pakketten zien die wij aanbieden:
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2.5}>
                    {products &&
                      products.map(p => {
                        return (
                          <Grid item xs={12} md={6}>
                            <PackageCardMobile
                              score={score}
                              advice={p.name === advicedPackage?.name ? true : false}
                              selected={getSelected(p)}
                              skipAdvice={skipAdvice}
                              product={p}
                              selectedProducts={selectedProducts}
                              setSelectedProducts={setSelectedProducts}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card
            elevation={0}
            sx={{
              marginTop: '-25px',
              marginBottom: '-45px',
            }}>
            <CardContent>
              <Grid container>
                <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                  <Typography
                    sx={{
                      color: '#A2A2A2',
                      fontSize: '14px',
                      textAlign: 'center',
                    }}>
                    *Eenmalig €35,- administratiekosten
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </div>
  );
};

export default PersonalAdvice;
