import React, { useEffect, useState } from 'react';

import { FormControlLabel, Grid, Radio } from '@mui/material';

import { appClient } from 'App';
import { BaseDiscipline } from 'assets';

interface SubjectPageProps {
  data: any;
  setData: any;
}

const SubjectPage: React.FunctionComponent<SubjectPageProps> = ({ data, setData }) => {
  const [update, setUpdate] = useState<boolean>(false);
  const [subjects, setSubjects] = useState<BaseDiscipline[]>([]);

  React.useEffect(() => {
    if (update === true) {
      setUpdate(false);
    }
  }, [update]);

  //Retrieves all possible subjects from the back-end
  useEffect(() => {
    appClient.lessons.getLessonsViewDisciplines().then(r => {
      if (r !== null) {
        setSubjects(r);
      }
    });
  }, []);

  const setVak = (vak: BaseDiscipline) => {
    const change = { ...data };
    if (change.data_bundle.disciplines == undefined) {
      change.data_bundle.disciplines = [];
    }

    if (change.data_bundle.disciplines?.indexOf(vak.id) == -1) {
      change.data_bundle.disciplines?.push(vak.id);
      setData(change);
    } else {
      const index = change.data_bundle.disciplines.indexOf(vak.id);
      change.data_bundle.disciplines.splice(index, 1);
      setData(change);
    }
  };

  // const setComment = (info) => {
  //     const tempParent = { ...parent }
  //     tempParent.students[0].comment = info
  //     updateParent(tempParent)
  // }

  const schoolVakkenRadio = (vakken: BaseDiscipline[]) => {
    const toReturn = [];
    const filteredSubjects = vakken
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(vak => !vak.is_primary_school);
    const ammountLimmit = Math.floor(filteredSubjects.length / 3);

    let column = [];
    for (let index = 0; index < filteredSubjects.length; index++) {
      const vak = filteredSubjects[index];
      column.push(
        <FormControlLabel
          value="huis"
          control={
            <Radio
              checked={
                data.data_bundle.disciplines?.indexOf(vak.id) == -1 ||
                data.data_bundle.disciplines == undefined
                  ? false
                  : true
              }
              onClick={() => setVak(vak)}
            />
          }
          label={vak.name}
          sx={{ width: '100%' }}
        />
      );
      if (column.length >= ammountLimmit) {
        toReturn.push(
          <Grid item xs={12} sm>
            {column}
          </Grid>
        );
        column = [];
      }
    }

    return toReturn;
  };

  return (
    <div>
      <React.Fragment>
        <Grid container>{subjects && schoolVakkenRadio(subjects)}</Grid>
      </React.Fragment>
    </div>
  );
};

export default SubjectPage;
