/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseGroup } from '../models/BaseGroup';
import type { BaseGroupWithMembers } from '../models/BaseGroupWithMembers';
import type { BaseNotification } from '../models/BaseNotification';
import type { BasePermission } from '../models/BasePermission';
import type { BaseRole } from '../models/BaseRole';
import type { BaseRoleWithMembers } from '../models/BaseRoleWithMembers';
import type { BaseUser } from '../models/BaseUser';
import type { BaseUserWithOrderPreferences } from '../models/BaseUserWithOrderPreferences';
import type { EditUser } from '../models/EditUser';
import type { EditUserSimple } from '../models/EditUserSimple';
import type { EmailValidationIn } from '../models/EmailValidationIn';
import type { EmailValidationOut } from '../models/EmailValidationOut';
import type { EnumOrderType } from '../models/EnumOrderType';
import type { GenericCount } from '../models/GenericCount';
import type { GenericStatus } from '../models/GenericStatus';
import type { GroupEdit } from '../models/GroupEdit';
import type { GroupIn } from '../models/GroupIn';
import type { NotificationsOut } from '../models/NotificationsOut';
import type { PasswordResetIn } from '../models/PasswordResetIn';
import type { PasswordResetRequestIn } from '../models/PasswordResetRequestIn';
import type { RegularUser } from '../models/RegularUser';
import type { RegularUserWithGroupsAndTypes } from '../models/RegularUserWithGroupsAndTypes';
import type { RoleEdit } from '../models/RoleEdit';
import type { RoleIn } from '../models/RoleIn';
import type { Token } from '../models/Token';
import type { UserIn } from '../models/UserIn';
import type { UserMfaIn } from '../models/UserMfaIn';
import type { UserSecret } from '../models/UserSecret';
import type { UserSecretIn } from '../models/UserSecretIn';
import type { UserUri } from '../models/UserUri';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create
     * @param requestBody
     * @returns RegularUser Successful Response
     * @throws ApiError
     */
    public postAuthCreate(
        requestBody: UserIn,
    ): CancelablePromise<RegularUser> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/user/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Request User Password Reset
     * @param requestBody
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public postAuthRequestUserPasswordReset(
        requestBody: PasswordResetRequestIn,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/user/password/reset/request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset User Password
     * @param token
     * @param requestBody
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public postAuthResetUserPassword(
        token: string,
        requestBody: PasswordResetIn,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/user/password/reset/{token}',
            path: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Secret
     * @returns UserSecret Successful Response
     * @throws ApiError
     */
    public getAuthGetSecret(): CancelablePromise<UserSecret> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/secret',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Qr Uri
     * @param requestBody
     * @returns UserUri Successful Response
     * @throws ApiError
     */
    public postAuthGetQrUri(
        requestBody: UserSecretIn,
    ): CancelablePromise<UserUri> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/user/uri',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Secret
     * @param requestBody
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchAuthGetSecret(
        requestBody: UserSecretIn,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/set/secret',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login With Mfa
     * @param requestBody
     * @returns Token Successful Response
     * @throws ApiError
     */
    public postAuthLoginWithMfa(
        requestBody: UserMfaIn,
    ): CancelablePromise<Token> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/user/mfa/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Group
     * @param requestBody
     * @returns BaseGroup Successful Response
     * @throws ApiError
     */
    public postAuthCreateGroup(
        requestBody: GroupIn,
    ): CancelablePromise<BaseGroup> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/group/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Role
     * @param requestBody
     * @returns BaseRole Successful Response
     * @throws ApiError
     */
    public postAuthCreateRole(
        requestBody: RoleIn,
    ): CancelablePromise<BaseRole> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/role/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Roles
     * @returns any Successful Response
     * @throws ApiError
     */
    public getAuthGetAllRoles(): CancelablePromise<(Array<BaseRole> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/roles/all',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Search All Roles
     * @param searchTerm
     * @returns any Successful Response
     * @throws ApiError
     */
    public getAuthSearchAllRoles(
        searchTerm: string,
    ): CancelablePromise<(Array<BaseRole> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/roles/search/{search_term}',
            path: {
                'search_term': searchTerm,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Groups
     * @returns any Successful Response
     * @throws ApiError
     */
    public getAuthGetAllGroups(): CancelablePromise<(Array<BaseGroup> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/groups/all',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get All Users
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getAuthGetAllUsers(
        offset?: number,
        limit: number = 10,
    ): CancelablePromise<(Array<BaseUser> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users',
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search All Groups
     * @param searchTerm
     * @returns any Successful Response
     * @throws ApiError
     */
    public getAuthSearchAllGroups(
        searchTerm: string,
    ): CancelablePromise<(Array<BaseGroup> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/groups/search/{search_term}',
            path: {
                'search_term': searchTerm,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Permissions
     * @returns any Successful Response
     * @throws ApiError
     */
    public getAuthGetAllPermissions(): CancelablePromise<(Array<BasePermission> | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/permissions/all',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Single Role
     * @param roleId
     * @returns BaseRole Successful Response
     * @throws ApiError
     */
    public getAuthGetSingleRole(
        roleId: number,
    ): CancelablePromise<BaseRole> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/roles/{role_id}',
            path: {
                'role_id': roleId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Single Role
     * @param roleId
     * @param requestBody
     * @returns BaseRole Successful Response
     * @throws ApiError
     */
    public patchAuthEditSingleRole(
        roleId: number,
        requestBody: RoleEdit,
    ): CancelablePromise<BaseRole> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/roles/{role_id}',
            path: {
                'role_id': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Single Role Members
     * @param roleId
     * @param offset
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public getAuthGetSingleRoleMembers(
        roleId: number,
        offset?: number,
        limit: number = 25,
    ): CancelablePromise<(BaseRoleWithMembers | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/roles/{role_id}/members',
            path: {
                'role_id': roleId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Single Group
     * @param groupId
     * @returns BaseGroupWithMembers Successful Response
     * @throws ApiError
     */
    public getAuthGetSingleGroup(
        groupId: number,
    ): CancelablePromise<BaseGroupWithMembers> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/groups/{group_id}',
            path: {
                'group_id': groupId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Single Group
     * @param groupId
     * @param requestBody
     * @returns BaseGroupWithMembers Successful Response
     * @throws ApiError
     */
    public patchAuthEditSingleGroup(
        groupId: number,
        requestBody: GroupEdit,
    ): CancelablePromise<BaseGroupWithMembers> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/groups/{group_id}',
            path: {
                'group_id': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Promote User To Group
     * @param userId
     * @param groupId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchAuthPromoteUserToGroup(
        userId: number,
        groupId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/{user_id}/promote/group/{group_id}',
            path: {
                'user_id': userId,
                'group_id': groupId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Demote User From Group
     * @param groupId
     * @param userId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchAuthDemoteUserFromGroup(
        groupId: number,
        userId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/{user_id}/demote/group/{group_id}',
            path: {
                'group_id': groupId,
                'user_id': userId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Promote User To Role
     * @param roleId
     * @param userId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchAuthPromoteUserToRole(
        roleId: number,
        userId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/{user_id}/promote/role/{role_id}',
            path: {
                'role_id': roleId,
                'user_id': userId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Demote User From Role
     * @param roleId
     * @param userId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchAuthDemoteUserFromRole(
        roleId: number,
        userId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/{user_id}/demote/role/{role_id}',
            path: {
                'role_id': roleId,
                'user_id': userId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Attach Permissions To Role
     * @param roleId
     * @param requestBody
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchAuthBulkAttachPermissionsToRole(
        roleId: number,
        requestBody: Array<number>,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/role/{role_id}/attach_permissions/bulk',
            path: {
                'role_id': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Remove Permissions From Role
     * @param roleId
     * @param requestBody
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchAuthBulkRemovePermissionsFromRole(
        roleId: number,
        requestBody: Array<number>,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/role/{role_id}/remove_permissions/bulk',
            path: {
                'role_id': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Notifications
     * @returns NotificationsOut Successful Response
     * @throws ApiError
     */
    public getAuthGetNotifications(): CancelablePromise<NotificationsOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/notifications',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Unread Notifications
     * @returns NotificationsOut Successful Response
     * @throws ApiError
     */
    public getAuthGetUnreadNotifications(): CancelablePromise<NotificationsOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/notifications/unread',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Single Notification
     * @param notificationId
     * @returns BaseNotification Successful Response
     * @throws ApiError
     */
    public getAuthGetSingleNotification(
        notificationId: number,
    ): CancelablePromise<BaseNotification> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/notifications/{notification_id}/read',
            path: {
                'notification_id': notificationId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Mark Notification Read
     * @param notificationId
     * @returns BaseNotification Successful Response
     * @throws ApiError
     */
    public patchAuthMarkNotificationRead(
        notificationId: number,
    ): CancelablePromise<BaseNotification> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/notifications/{notification_id}/mark_read',
            path: {
                'notification_id': notificationId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Current User
     * @returns RegularUserWithGroupsAndTypes Successful Response
     * @throws ApiError
     */
    public getAuthGetCurrentUser(): CancelablePromise<RegularUserWithGroupsAndTypes> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/me',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get Current User Permissions
     * @returns string Successful Response
     * @throws ApiError
     */
    public getAuthGetCurrentUserPermissions(): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/permissions',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get User
     * @param userId
     * @returns RegularUserWithGroupsAndTypes Successful Response
     * @throws ApiError
     */
    public getAuthGetUser(
        userId: number,
    ): CancelablePromise<RegularUserWithGroupsAndTypes> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit User
     * @param userId
     * @param requestBody
     * @returns RegularUserWithGroupsAndTypes Successful Response
     * @throws ApiError
     */
    public patchAuthEditUser(
        userId: number,
        requestBody: EditUserSimple,
    ): CancelablePromise<RegularUserWithGroupsAndTypes> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit User Advanced
     * @param userId
     * @param requestBody
     * @returns RegularUserWithGroupsAndTypes Successful Response
     * @throws ApiError
     */
    public patchAuthEditUserAdvanced(
        userId: number,
        requestBody: EditUser,
    ): CancelablePromise<RegularUserWithGroupsAndTypes> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/{user_id}/advanced',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Complete First Landing
     * @param userId
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public patchAuthCompleteFirstLanding(
        userId: number,
    ): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/{user_id}/complete_first_landing',
            path: {
                'user_id': userId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User Count
     * @returns GenericCount Successful Response
     * @throws ApiError
     */
    public getAuthGetUserCount(): CancelablePromise<GenericCount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users/count',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Validate Email Address
     * @param requestBody
     * @returns EmailValidationOut Successful Response
     * @throws ApiError
     */
    public postAuthValidateEmailAddress(
        requestBody: EmailValidationIn,
    ): CancelablePromise<EmailValidationOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/users/email_validation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login For Access Token
     * @param userId
     * @returns Token Successful Response
     * @throws ApiError
     */
    public postAuthLoginForAccessToken(
        userId: number,
    ): CancelablePromise<Token> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/log_in_as/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Access From Refresh Token
     * Gets a new access token from a refresh token. also returns a new refresh token
     * @param requestBody
     * @returns Token Successful Response
     * @throws ApiError
     */
    public postAuthGetAccessFromRefreshToken(
        requestBody: Token,
    ): CancelablePromise<Token> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/access_from_refresh',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Preferred Order Type
     * @param orderType
     * @returns BaseUserWithOrderPreferences Successful Response
     * @throws ApiError
     */
    public patchAuthChangePreferredOrderType(
        orderType: EnumOrderType,
    ): CancelablePromise<BaseUserWithOrderPreferences> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/change_preferred_order_type',
            query: {
                'order_type': orderType,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Disable User
     * Disables a user
     * @returns GenericStatus Successful Response
     * @throws ApiError
     */
    public deleteAuthDisableUser(): CancelablePromise<GenericStatus> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/disable_user',
            errors: {
                404: `Not found`,
            },
        });
    }

}
