import React, { useState } from 'react';

import { Close } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  styled,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useSnackbar } from 'notistack';

import { TextField } from 'components/Elements/Forms/Inputs';
import { checkIBAN } from 'functions/HelperFunctions/Regex';
import { useWidth } from 'functions/Hooks/useDeviceDetect';
import useLabel from 'functions/Hooks/useLabel';

import { IBundleFlowPageProps } from '..';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  borderRadius: '15px',
  '& .MuiDialog-paper': { borderRadius: '15px' },
  '& .MuiDialogContent-root': { padding: theme.spacing(2) },
  '& .MuiDialogActions-root': { padding: theme.spacing(1) },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface IPaymentMethodPageProps extends IBundleFlowPageProps {
  SetPaymentModalOpen: (open: boolean) => void;
}

const PaymentMethodPageOld: React.FunctionComponent<IPaymentMethodPageProps> = ({
  bundle,
  setBundle,
  setPayLater,
  skipPayment,
  SetPaymentModalOpen,
}) => {
  const currentWidth = useWidth();
  const { enqueueSnackbar } = useSnackbar();
  const [paymentMethod, setPaymentMethod] = useState<string>('none');
  const [cardBool, setCardBool] = useState<boolean>(false);
  const [ibanCheckIconVisible, setIbanCheckIconVisible] = useState<boolean>(false);

  const label = useLabel();

  const handlePaymentMethodChange = (event: string): void => {
    const tempdata = { ...bundle };
    tempdata.data_bundle.payment_method = event;
    setBundle(tempdata);

    setPaymentMethod(event);

    if (event === 'achteraf') {
      tempdata.data_bundle.accept_incasso = false;
      if (setPayLater) {
        setPayLater(true);
      }
    } else {
      tempdata.data_bundle.accept_incasso = true;
      if (setPayLater) {
        setPayLater(false);
      }
    }

    setBundle(tempdata);
  };

  const handleNext = async (event: any) => {
    if (
      bundle.data_bundle.payment_method === 'Losse factuur' ||
      bundle.data_bundle.payment_method === 'achteraf' ||
      bundle.data_bundle.payment_method === 'none'
    ) {
      SetPaymentModalOpen(true);

      return;
    }

    if (skipPayment) {
      const tempdata = { ...bundle };
      tempdata.data_bundle.payment_method = 'Geen betaalmethode gekozen';
      setBundle(tempdata);
    } else {
      if (paymentMethod == 'incasso') {
        if (bundle.data_bundle.iban) {
          const r = await fetch(
            'https://openiban.com/validate/' +
              bundle.data_bundle.iban +
              '?getBIC=true&validateBankCode=true'
          ).then(r => {
            return r.json();
          });
          if (r.valid === false) {
            enqueueSnackbar('Uw IBAN lijkt niet te kloppen, check het a.u.b. nog een keer', {
              variant: 'error',
            });

            return;
          }
        } else {
          enqueueSnackbar('Vul a.u.b uw IBAN in.', { variant: 'error' });

          return;
        }
      }
    }

    event.detail();
  };

  const checkValidIbanRegex = (event: any) => {
    if (checkIBAN(event.target.value)) {
      setIbanCheckIconVisible(true);
    } else {
      setIbanCheckIconVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('onNext', handleNext);

    return () => {
      document.removeEventListener('onNext', handleNext);
    };
  }, [bundle]);

  React.useEffect(() => {
    if (bundle.data_bundle.payment_method) {
      setPaymentMethod(bundle.data_bundle.payment_method);
    } else {
      const tempdata = { ...bundle };
      tempdata.data_bundle.payment_method = 'none';
      setBundle(tempdata);
    }
  }, [bundle]);

  const handleIBAN = (IBAN: string): void => {
    const tempData = { ...bundle };
    tempData.data_bundle.iban = IBAN;
    setBundle(tempData);
  };

  const Capatilize = (value: string): string => {
    if (value) {
      value = value.toLocaleUpperCase();
    }

    return value;
  };

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
        }}
        {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}>
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const handleTermSelect = (value: number) => {
    const tempBundle = { ...bundle };
    tempBundle.data_bundle.term = value;
    setBundle(tempBundle);
  };

  const showAutoIncassoText = () => {
    {
      /* Volledige ontzorging */
    }
    if (bundle.data_bundle.package === 'Losse les') {
      return (
        <Grid item xs={12} md={8} sx={{ marginBottom: '0px' }}>
          <Typography fontSize={14}>€10,- korting op de administratiekosten.</Typography>
        </Grid>
      );
    } else if (
      paymentMethod !== 'incasso' &&
      bundle.data_bundle.package !== 'Geen pakket geselecteerd'
    ) {
      return (
        <Grid item xs={12} md={8} sx={{ marginBottom: '20px' }}>
          <Typography fontSize={14}>Bepaal zelf het aantal betaaltermijnen</Typography>
        </Grid>
      );
    } else if (paymentMethod === 'none') {
      return (
        <Grid item xs={12} md={8} sx={{ marginBottom: '0px' }}>
          <Typography fontSize={14}>Volledige ontzorging</Typography>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} md={8} sx={{ marginBottom: '20px' }}>
          <Typography fontSize={14} />
        </Grid>
      );
    }
  };

  const ShowPaymentInfoText = () => {
    if (bundle.data_bundle.package === 'Losse les') {
      return (
        <Grid item xs={12}>
          <Typography>
            Maak hieronder een keuze hoe u wenst te betalen voor uw bijlessen. Wanneer u kiest voor
            een automatische incasso heeft u de volgende voordelen:
            <ul style={{ margin: '3px 0px -10px -22px' }}>
              <li>Volledige ontzorging;</li>
              <li>€10,- korting op de administratiekosten.</li>
              {/* <li>
                                Incasso vindt aan het begin van de maand plaats.
                            </li> */}
            </ul>
          </Typography>
        </Grid>
      );
    } else if (paymentMethod === 'incasso') {
      return (
        <Grid item xs={12}>
          <Typography>
            Maak hieronder een keuze hoe u wenst te betalen voor uw bijlessen. Wanneer u kiest voor
            een automatische incasso heeft u de volgende voordelen:
            <ul style={{ margin: '3px 0px -10px -25px' }}>
              <li>Volledige ontzorging;</li>
              <li>Bepaal zelf in hoeveel termijnen u betaalt;</li>
              <li>€10,- korting op de administratiekosten.</li>
              {/* <li>
                                Maandelijkse incasso.
                            </li> */}
            </ul>
          </Typography>
        </Grid>
      );
    } else if (paymentMethod === 'achteraf' || paymentMethod === 'none') {
      return (
        <Grid item xs={12}>
          <Typography>
            Maak hieronder een keuze hoe u wenst te betalen voor uw bijlessen. Wanneer u kiest voor
            een automatische incasso heeft u de volgende voordelen:
            <ul style={{ margin: '3px 0px -10px -25px' }}>
              <li>Volledige ontzorging;</li>
              <li>Bepaal zelf in hoeveel termijnen u betaalt;</li>
              <li>€10,- korting op de administratiekosten.</li>
              {/* <li>
                                Maandelijkse incasso.
                            </li> */}
            </ul>
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <Box>
      <Card elevation={0}>
        <CardContent>
          <Grid container>
            {/* <Grid item xs={12}>
                            <Typography>
                                Maak hieronder een keuze hoe u wenst te betalen voor uw bijlessen.
                                U kunt kiezen uit onze handige automatische incasso of handmatig overmaken middels losse facturen.
                                De betalingen vinden altijd aan het einde van de maand plaats.
                            </Typography>
                        </Grid> */}
            {ShowPaymentInfoText()}
          </Grid>
        </CardContent>
      </Card>

      <Card
        elevation={0}
        sx={{
          marginTop: '-40px',
          marginBottom: '-40px',
        }}>
        <CardContent>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Typography
                fontWeight={'bold'}
                sx={{
                  marginTop: '30px',
                  marginBottom: currentWidth === 'xs' ? '20px' : '10px',
                }}>
                Maak uw keuze:
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} sx={{ marginTop: '-15px' }}>
              <Card
                elevation={paymentMethod == 'incasso' ? 10 : 0}
                sx={{
                  backgroundColor: paymentMethod == 'incasso' ? label?.main_color : '#f5f5f5',
                  color: paymentMethod == 'incasso' ? 'white' : 'black',
                  marginBottom: '20px',
                  textAlign: 'center',
                  borderRadius: '20px',
                  // padding: "10px"
                  minHeight: currentWidth === 'xs' ? 'unset' : '135px',
                }}
                onClick={() => {
                  handlePaymentMethodChange('incasso');
                }}>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginBottom: currentWidth === 'xs' ? '-20px' : 'unset' }}>
                  <Grid item xs={10} />
                  <Grid item xs={2} sx={{ marginTop: '5px' }}>
                    <IconButton
                      onClick={() => {
                        setCardBool(true);
                      }}>
                      <InfoIcon
                        sx={{
                          color:
                            paymentMethod === 'none'
                              ? '#767271'
                              : paymentMethod === 'achteraf'
                              ? '#767271'
                              : 'white',
                        }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <CardHeader
                      sx={{
                        paddingTop: 'unset',
                        marginTop: '-10px',
                      }}
                      title={'Automatische incasso'}
                      titleTypographyProps={{
                        // marginLeft: currentWidth === "xs" ? "40px" : "unset"
                        textAlign: 'center',
                      }}
                    />
                  </Grid>
                </Grid>
                <CardContent sx={{ paddingTop: '5px' }}>
                  <Grid container justifyContent={'center'} sx={{ marginTop: '-10px' }}>
                    {showAutoIncassoText()}
                  </Grid>

                  {paymentMethod == 'incasso' && (
                    <Grid container justifyContent={'center'}>
                      <Grid item xs={12} md={8}>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            textAlign: 'left',
                            marginTop: bundle.data_bundle.package != 'Losse les' ? '-15px' : '10px',
                            marginLeft: '15px',
                          }}>
                          IBAN *
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <TextField
                          sx={{
                            border: ibanCheckIconVisible ? '2px solid green' : 'unset',
                            borderRadius: '22px',
                            // marginTop: "20px",
                            // marginBottom: "-5px",
                            color: '#f5f5f5',
                            '& label.Mui-focused': {
                              color: 'black',
                              border: ibanCheckIconVisible ? '1px solid green' : 'black',
                            },
                          }}
                          // label={"IBAN"}
                          backgroundColor="#f5f5f5"
                          required
                          fullWidth
                          value={Capatilize(bundle.data_bundle?.iban) || ''}
                          onChange={e => {
                            handleIBAN(e.target.value); //todo: add to init
                            checkValidIbanRegex(e);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {ibanCheckIconVisible && (
                                  <CheckIcon
                                    sx={{
                                      color: 'green',
                                      width: '20px',
                                    }}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      {bundle.data_bundle.package !== 'Losse les' &&
                        bundle.data_bundle.package !== 'Geen pakket geselecteerd' && (
                          <>
                            <Grid item xs={12} md={8}>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  textAlign: 'left',
                                  marginTop: '10px',
                                  marginLeft: '15px',
                                }}>
                                Termijnen
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <TextField
                                sx={{
                                  borderRadius: '22px',
                                  // marginTop: "10px",
                                  marginBottom: '10px',
                                  textAlign: 'left',
                                  color: '#f5f5f5',
                                }}
                                // label={"IBAN"}
                                backgroundColor="#f5f5f5"
                                select
                                required
                                fullWidth
                                value={bundle.data_bundle.term || 1}
                                onChange={e => {
                                  handleTermSelect(e.target.value as unknown as number);
                                }}>
                                {/* <MenuItem value={0}>Termijnen</MenuItem> */}
                                <MenuItem value={1}>1 termijn</MenuItem>
                                <MenuItem value={2}>2 termijnen</MenuItem>
                                <MenuItem value={3}>3 termijnen</MenuItem>
                                <MenuItem value={4}>4 termijnen</MenuItem>
                              </TextField>
                            </Grid>
                          </>
                        )}
                    </Grid>
                  )}
                </CardContent>
              </Card>

              <BootstrapDialog
                onClose={() => setCardBool(false)}
                aria-labelledby="customized-dialog-title"
                open={cardBool}
                sx={{ borderRadius: '15px' }}>
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={() => setCardBool(false)}>
                  <Typography textAlign={'center'} fontWeight={'bold'}>
                    {paymentMethod == 'incasso' ? 'Automatische incasso' : 'Losse factuur'}
                  </Typography>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  <Typography gutterBottom textAlign={'center'}>
                    {paymentMethod == 'incasso'
                      ? 'Bij een automatische incasso geeft u ons toestemming om een bepaald geldbedrag van uw rekening af te schrijven voor de bijlessen.'
                      : 'U ontvangt elke maand een factuur welke u binnen 14 dagen handmatig dient over te maken. Per opgestelde factuur wordt €2,50 administratiekosten in rekening gebracht.'}
                  </Typography>
                </DialogContent>
              </BootstrapDialog>
            </Grid>
            <Grid item xs={12} md={6} sx={{ marginTop: '-15px' }}>
              <Card
                elevation={paymentMethod == 'achteraf' ? 10 : 0}
                sx={{
                  backgroundColor: paymentMethod == 'achteraf' ? label?.main_color : '#f5f5f5',
                  color: paymentMethod == 'achteraf' ? 'white' : 'black',
                  marginBottom: '20px',
                  textAlign: 'center',
                  borderRadius: '20px',
                  // height: '134px',
                  // padding: "10px"
                }}
                onClick={() => {
                  handlePaymentMethodChange('achteraf');
                }}>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginBottom: currentWidth === 'xs' ? '-20px' : 'unset' }}>
                  <Grid item xs={10} />
                  <Grid item xs={2} sx={{ marginTop: '5px' }}>
                    <IconButton
                      onClick={() => {
                        setCardBool(true);
                      }}>
                      <InfoIcon
                        sx={{
                          color:
                            paymentMethod === 'none'
                              ? '#767271'
                              : paymentMethod === 'incasso'
                              ? '#767271'
                              : 'white',
                        }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <CardHeader
                      sx={{
                        paddingTop: 'unset',
                        marginTop: currentWidth === 'xs' ? '-10px' : '-10px',
                      }}
                      title={'Losse factuur'}
                      titleTypographyProps={{
                        // marginLeft: currentWidth === "xs" ? "40px" : "unset"
                        textAlign: 'center',
                      }}
                    />
                  </Grid>
                </Grid>
                <CardContent
                  sx={{
                    paddingTop: '5px',
                    marginBottom: '10px',
                  }}>
                  <Typography fontSize={14} sx={{ marginTop: '-10px' }}>
                    + €2,50 administratiekosten per verstuurde factuur
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PaymentMethodPageOld;
